import CustomLabel from '@src/@core/components/ui/badge/CustomLabel'
import Button from '@src/@core/components/ui/button'
import CopyOnClick from '@src/@core/components/ui/click-and-copy'
import { ORDER_SOURCE, ORDER_TYPES, TENANT_TYPE } from '@src/App.constants'
import { EditPencil } from '@src/assets/data/assets'
import noSalesChannelImage from '@src/assets/images/omniful/no_sales_channel_image.svg'
import Notes from '@src/assets/images/svg/Notes'
import { INVENTORY_ROUTES } from '@src/views/inventory/inventory.constants'
import RemarksModal from '@src/views/Purchases/components/PuchaseOrder/pages/RemarksModal'
import { DELIVERY_TYPE, ORDER_STATUS } from '@src/views/sales/constant/orders.constants'
import OrderTagsMapper from '@src/views/sales/live-orders/pages/packingOrderWise/StationPages/packingOrderTabsPages/OrderTagsMapper'
import AddFreeTags from '@src/views/sales/pending-actions/components/AddFreeTags/AddFreeTags'
import { PATH_NAME, REASONS_FOR_ON_HOLD } from '@src/views/sales/sales.constant'
import { OrderDetailsRow } from '@src/views/sales/sales.utils'
import classNames from 'classnames'
import { t } from 'i18next'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Info, Plus, RefreshCcw } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Card, UncontrolledTooltip } from 'reactstrap'

export const Hide_parent_reason = ({orderDetails}) => {
  return useMemo(() => {
    const { reason } = orderDetails || {};
    return (
      reason === REASONS_FOR_ON_HOLD.INVENTORY_NOT_PRESENT.label ||
      reason === REASONS_FOR_ON_HOLD.SKU_NOT_VALID.label
    );
  }, [orderDetails]);
};


export const GetHideParentReason = ({orderDetails}) => {
  const { reason } = orderDetails || {};
  return (
    reason === REASONS_FOR_ON_HOLD.INVENTORY_NOT_PRESENT.label ||
    reason === REASONS_FOR_ON_HOLD.SKU_NOT_VALID.label
  );
};

export const RenderStoRequestId = ({ orderDetails, handleShowSTODetail }) => {
  const currentPathname = window.location.pathname

  return (
    <CopyOnClick
      id={orderDetails?.stock_transfer_request_details?.id}
      handleDetails={currentPathname === PATH_NAME.stockTransferOrder || currentPathname === INVENTORY_ROUTES.STOCK_TRANSFER || !orderDetails.stock_transfer_request_details.id ? null : handleShowSTODetail}
      columnKey='sto_request_id'
    >
      {orderDetails?.stock_transfer_request_details?.display_id}
    </CopyOnClick>
  );
};


export const RenderOrderTags = ({ orderDetails }) => {
  return (
    <div className="d-flex flex-wrap gap-8px">
      <OrderTagsMapper
        customIcon={() => (
          <span className="text-primary">+{orderDetails?.tags?.length - 3}</span>
        )}
        tagsArray={orderDetails?.tags} />
    </div>
  );
};


export const RenderTags = ({ orderDetails, hasEditOrderDetailsPermission, toggleAddLocationAttributesModal }) => {
  return (
    <div className="d-flex align-items-center flex-wrap gap-8px">
      {orderDetails?.custom_labels?.length > 0 ? (
        <>
          {hasEditOrderDetailsPermission && (
            <div
              className="text-primary txt-sub-rg cursor-pointer bg-primary-lighter width-24px height-24px flex-center-center rounded-12px"
              onClick={toggleAddLocationAttributesModal}
            >
              <Plus size={16} />
            </div>
          )}
          <OrderTagsMapper
            customIcon={() => (
              <span className="text-primary">+{orderDetails.custom_labels?.length - 2}</span>
            )}
            tagsArray={orderDetails.custom_labels}
            viewItems={2}
          />
        </>
      ) : (
        <>
          {hasEditOrderDetailsPermission ? (
            <Button className="p-0 shadow-none" ofStyle="noBackground" onClick={toggleAddLocationAttributesModal}>
              {t('Add Tag')}
            </Button>
          ) : (
            '-'
          )}
        </>
      )}
    </div>
  );
};

export const RenderPaymentStatus = ({ orderDetails, syncButtons, handleSyncPaymentStatus }) => {
  const sales_loading = useSelector((state) => state.sales.loading)

  return (
    <div className="flex-center-start gap-12px">
      {orderDetails?.payment_method} 
      {syncButtons?.sync_payment_status && (
        <>
          <Button
            id="sync_payment_status"
            className="p-4px rounded-4px"
            ofStyle="noBackground"
            onClick={handleSyncPaymentStatus}
            disabled={sales_loading.syncOrderPaymentStatus}
          >
            <RefreshCcw
              className={classNames({ 'sync-rotate-icon': sales_loading.syncOrderPaymentStatus })}
              size={16}
              color={sales_loading.syncOrderPaymentStatus ? 'var(--bs-primary-light)' : 'var(--bs-primary)'}
            />
          </Button>
          <UncontrolledTooltip offset={[0, 7]} autohide={false} target="sync_payment_status">
            {t(`Syncing updates payment status and invoice details from ${orderDetails.sales_channel?.store_name}`)}
          </UncontrolledTooltip>
        </>
      )}
    </div>
  );
};


export const RenderSalesChannel = ({ orderDetails}) => {
  return (
    <span className='text-black'>
      <img
        width={60}
        height={20}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = noSalesChannelImage; // Fallback image
        }}
        className="object-fit-contain rounded shadow-1"
        src={orderDetails.sales_channel?.logo_url || noSalesChannelImage}
        alt={orderDetails.sales_channel?.store_name}
      />
      <span className="ms-50">{orderDetails.sales_channel?.store_name}</span>
    </span>
  );
};


export const RenderRefreshSalesChannel = ({ orderDetails }) => {
  return (
    <div>
      <img
        width={60}
        height={20}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = noSalesChannelImage; // Fallback image
        }}
        className="object-fit-contain rounded shadow-1"
        src={orderDetails.sales_channel.logo_url || noSalesChannelImage}
        alt={orderDetails.sales_channel.name}
        loading='lazy'
      />
      <span className="ms-50">{orderDetails.sales_channel.name}</span>
    </div>
  );
};


export const RenderAttachment = ({
  orderDetails,
  handleToggleAttachmentSidebar,
  hasEditOrderDetailsPermission,
  hasEditAttachmentButton,
}) => {
  return (
    orderDetails?.attachments?.length > 0 ? (
      <span 
        className="text-primary cursor-pointer flex-center-start gap-4px" 
        onClick={handleToggleAttachmentSidebar}
      >
        <span>{`${orderDetails.attachments.length} File${orderDetails.attachments.length > 1 ? 's' : ''}`}</span>
        {hasEditOrderDetailsPermission && hasEditAttachmentButton && <EditPencil width={'14px'} height={'14px'} />}
      </span>
    ) : (
      hasEditOrderDetailsPermission && hasEditAttachmentButton && (
        <Button 
          className="p-0 shadow-none" 
          ofStyle='noBackground' 
          onClick={handleToggleAttachmentSidebar}
        >
          {t('Attach Files')}
        </Button>
      )
    )
  );
};


export const RenderSellerWithCodeComponent = ({orderDetails}) => {
  const { name, code } = orderDetails?.seller || {};

  return (
    <div className="d-flex align-items-center gap-50 omniful-button p-0 shadow-none omniful-regular-btn bg-transparent">
      {/* Render Seller logic directly here */}
      <span>{name ? `${name}${code ? ` (${code})` : ''}` : '-'}</span>

      {/* Info icon with tooltip */}
      <span className="p-0 cursor-pointer d-flex align-items-center">
        <Info size={16} id="seller-info-basicCard" />
        <UncontrolledTooltip offset={[0, 5]} target="seller-info-basicCard">
          <div className="text-start fw-bold">
            <span className="me-10px">{t('Seller Name')}:</span> {name || '-'}<br />
            <span className="me-10px">{t('Seller Code')}:</span> {code || '-'}
          </div>
        </UncontrolledTooltip>
      </span>
    </div>
  );
};


const BasicDetailsCard = ({ orderDetails, hasEditOrderDetailsPermission, toggleAddLocationAttributesModal, isOpenAddLocationAttributesModal, syncButtons, handleSyncPaymentStatus, handleNavigateToReturnOrders=()=>{}, handleOpenSplittedOrderDetailSideSheet, handleToggleAttachmentSidebar, hasEditAttachmentButton, handleShowSTODetail }) => {
  const [isNotesShown, setIsNotesShown] = useState(false)
  const [isTruncated, setIsTruncated] = useState(false)
  const NoteDiscriptionContainer = useRef()

  const hasRefferenceSalesChannel = orderDetails.source === ORDER_SOURCE.MANUAL.label && orderDetails.sales_channel.name

  const { t } = useTranslation()
  const tenantType = useSelector(store => store.auth.userData.tenant.type)

  useEffect(() => {
    if (NoteDiscriptionContainer.current) {
      const container = NoteDiscriptionContainer.current;
      setIsTruncated(container.scrollHeight > container.clientHeight);
    }
  }, [orderDetails.order_note])

  const handleNotesDiscriptions = () => {
    setIsNotesShown(!isNotesShown)
  }

  const basicDetailsData = [
    {
      id: 'sto_request_id',
      entityName: 'STO Request ID',
      entityValue: <RenderStoRequestId orderDetails={orderDetails} handleShowSTODetail={handleShowSTODetail} />,
      omit: orderDetails.type !== ORDER_TYPES.STO.value || !orderDetails.stock_transfer_request_details.display_id
    },
    {
      id: 'order_tags',
      entityName: 'Order Tags',
      entityValue: <RenderOrderTags orderDetails={orderDetails}/>,
      omit: orderDetails.tags.length <= 0
    },
    {
      id: 'created_at',
      entityName: 'Created At',
      entityValue: orderDetails.order_created_at,
    },
    {
      id: 'tags',
      entityName: 'Tags',
      entityValue: <RenderTags orderDetails={orderDetails} hasEditOrderDetailsPermission={hasEditOrderDetailsPermission} toggleAddLocationAttributesModal={toggleAddLocationAttributesModal}/>,
      omit: orderDetails.type === ORDER_TYPES.RTS.value
    },
    {
      id: 'ordered_quantity',
      entityName: 'Ordered Quantity',
      entityValue: orderDetails.ordered_quantity,
    },
    {
      id: 'number_of_items',
      entityName: 'Number of Items',
      entityValue: orderDetails.total_item_count
    },
    {
      id: 'delivery_date',
      entityName: 'Delivery Date',
      entityValue: orderDetails.slot.display_date,
      omit: !orderDetails.slot?.display_date
    },
    {
      id: 'delivery_slot',
      entityName: 'Delivery Slot',
      entityValue: `${orderDetails.slot.display_start_time} - ${orderDetails.slot.display_end_time}`,
      omit: !orderDetails.slot?.display_date
    },
    {
      id: 'payment_status',
      entityName: 'Payment Status',
      entityValue: <RenderPaymentStatus orderDetails={orderDetails} syncButtons={syncButtons} handleSyncPaymentStatus={handleSyncPaymentStatus}/>,
      omit: !orderDetails.payment_method
    },
    {
      id: 'payment_mode',
      entityName: 'Payment Mode',
      entityValue: orderDetails.invoice.payment_mode,
      omit: !orderDetails.invoice.payment_mode
    },
    {
      id: 'hub_name',
      entityName: 'Hub Name',
      entityValue: orderDetails.status === ORDER_STATUS.ON_HOLD.name? orderDetails.assigned_hub?.name : orderDetails.hub?.name,
      omit: orderDetails.status === ORDER_STATUS.ON_HOLD.name && !orderDetails.assigned_hub?.name
    },
    {
      id: 'seller_name',
      entityName: 'Seller',
      entityValue: <RenderSellerWithCodeComponent orderDetails={orderDetails} />,
      omit: tenantType === TENANT_TYPE.SELF_USAGE
    },
    {
      id: 'source',
      entityName: 'Source',
      entityValue: orderDetails.display_source,
    },
    {
      id: 'sales_channel',
      entityName: 'Sales Channel',
      entityValue: <RenderSalesChannel orderDetails={orderDetails}/>,
      omit: orderDetails.source === ORDER_SOURCE.MANUAL.label
    },
    {
      id: 'return_status',
      entityName: 'Return Status',
      entityValue: <CustomLabel title={orderDetails.return_status} className="order-status-tag" />,
      omit: orderDetails.status === ORDER_STATUS.ON_HOLD.name || !orderDetails.return_status
    },
    {
      id: 'return_order_count',
      entityName: 'Return Order Count',
      entityValue: (
        <span className='text-primary cursor-pointer' onClick={handleNavigateToReturnOrders}>
          {orderDetails.return_order_count} {t('Return(s)')}
        </span>
      ),
      omit: orderDetails.status === ORDER_STATUS.ON_HOLD.name || !orderDetails.return_order_count
    },
    {
      id: 'delivery_attempts',
      entityName: 'Delivery Attempts',
      entityValue: orderDetails.shipment.number_of_retries,
      omit: orderDetails.status === ORDER_STATUS.ON_HOLD.name || orderDetails.shipment.number_of_retries <= 0
    },
    {
      id: 'parent_reason',
      entityName: 'Reason',
      entityValue: orderDetails.reason,
      omit: !orderDetails.reason || <Hide_parent_reason orderDetails={orderDetails}/> || orderDetails.status !== ORDER_STATUS.ON_HOLD.name
    },
    {
      id: 'reason_for_on_hold',
      entityName: 'Reason for On Hold',
      entityValue: orderDetails.forceful_onhold_reason,
      omit: !orderDetails.forceful_onhold_reason || orderDetails.status !== ORDER_STATUS.ON_HOLD.name
    },
    {
      id: 'parent_order_id',
      entityName: 'Parent Order ID',
      entityValue: (
        <span className='text-primary cursor-pointer' onClick={handleOpenSplittedOrderDetailSideSheet}>
          {orderDetails.parent_order_details.display_id}
        </span>
      ),
      omit: !orderDetails.is_split_order
    },
    {
      id: 'shipping_method',
      entityName: 'Shipping Method',
      entityValue: `${orderDetails.ssc_order_details.shipping_company} (${t('Sales Channel')})`,
      omit: !orderDetails.ssc_order_details.shipping_company
    },
    {
      id: 'delivery_type',
      entityName: 'Delivery Type',
      entityValue: DELIVERY_TYPE[orderDetails.delivery_type.toUpperCase()]?.label,
      omit: !(orderDetails.delivery_type && orderDetails.delivery_type !== DELIVERY_TYPE.NORMAL_DELIVERY.name)
    },
    {
      id: 'partial_fulfillment',
      entityName: 'Partial Fulfillment',
      entityValue: t('Allowed'),
      omit: !orderDetails.is_partial_fulfillment_allowed
    },
    {
      id: 'attachment',
      entityName: 'Attachments',
      entityValue: <RenderAttachment orderDetails={orderDetails} handleToggleAttachmentSidebar={handleToggleAttachmentSidebar} hasEditOrderDetailsPermission={hasEditOrderDetailsPermission} hasEditAttachmentButton={hasEditAttachmentButton}/>,
      omit: !(hasEditOrderDetailsPermission && hasEditAttachmentButton)
    },
    {
      id: 'reorder_reason',
      entityName: 'Reorder Reason',
      entityValue: orderDetails.reorder_details?.reason,
      omit: !orderDetails.is_reorder || !orderDetails.reorder_details?.reason,
    },
    {
      id: 'original_order',
      entityName: 'Original Order',
      entityValue: <CopyOnClick
        id={orderDetails.reorder_details?.previous_order_display_id}
        handleDetails={null}
        columnKey='original_order'
      >
        {orderDetails.reorder_details?.previous_order_display_id}
      </CopyOnClick>,
      omit: !orderDetails.is_reorder
    },
    {
      id: 'reference_sales_channel',
      entityName: 'Reference Sales Channel',
      entityValue: <RenderRefreshSalesChannel orderDetails={orderDetails}/>,
      omit: !(hasRefferenceSalesChannel)
    },
    {
      id: 'order_note',
      entityName: 'Note',
      entityValue: <>
        <pre ref={NoteDiscriptionContainer} className='order-note-wrapper bg-transparent txt-body-rg two-line-truncate mb-0'>{orderDetails.order_note}</pre>   {isTruncated && <div>
          <Button
            ofStyle="noBackground"
            ofType="compressed"
            className="txt-body-rg py-2px px-0 box-shadow-0" onClick={handleNotesDiscriptions}>{t('Read More')}</Button>
        </div>}
      </>,
      omit: !orderDetails.order_note
    }
  ]

  return (
    <>

      <Card className='bg-white w-100 rounded-3  details-card mt-24px'>
        <div className='card-header py-12px px-16px rounded-top-2  text-secondary txt-h3-md'>
          <span>{t('Basic Details')}</span>
        </div>
        <div className='basic-details-row-wrapper'>
          {basicDetailsData
            .filter((item) => !item.omit)
            .map((item) => (
              <OrderDetailsRow key={item.id} entityNameColSpan={4} entityValueColSpan={8} entityName={item.entityName} entityValue={item.entityValue || '-'} />
            ))}
        </div>
        {orderDetails.type !== ORDER_TYPES.RTS.value ? (
          <AddFreeTags isOpen={isOpenAddLocationAttributesModal} toggle={toggleAddLocationAttributesModal} orderDetails={orderDetails} />
        ) : null}
      </Card>
      <RemarksModal
        title='Note'
        icon={<Notes color={'var(--bs-primary)'} />}
        isRemarksShow={isNotesShown}
        setIsRemarksShow={setIsNotesShown}
        remarksDiscription={orderDetails.order_note}
      />
    </>
  )
}

export default BasicDetailsCard