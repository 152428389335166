import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import RuleRow from '../components/RuleRow'

/**
   * A React functional component representing the trigger actions in a rule creation/edit interface.
   *
   * @param {object} props - The component props.
   * @param {object} props.formObject - The form object containing control, errors, watch, and setValue functions.
   * @param {boolean} props.isView - A boolean indicating whether the component is in view mode.
   * @param {array} props.actionValues - An array of action values.
   * @param {object} props.propsMap - An object containing API mappings.
   * @param {function} props.onConditionOrActionChange - A callback function to get value when condition or action change.
   * @return {JSX.Element} The JSX element representing the component.
   */
const Action = (props) => {
  const { 
    formObject,
    isView,
    actionValues,
    propsMap,
    onConditionOrActionChange,
    showHelperText
  } = props

  const {t} = useTranslation()
  const {control, watch} = formObject
  const { append: appendAction, remove: removeAction } = useFieldArray({
    control,
    name: 'actionValues',
    shouldUnregister: true
  })

  const actionsRows = watch('actionValues')
  const actionRowsLength = actionsRows.length
  const usedActionValuesSet = new Set(watch('actionValues')?.map((actionValue) => actionValue.condition?.value))
  const actionValueOptions = actionValues.filter((action) => !usedActionValuesSet.has(action.key_name)).map((action) => {
    return {
      label: action.display_name,
      value: action.key_name
    }
  })

  return (
    <div className="p-24px border border-dark-2 bg-light-2 d-flex flex-column gap-24px rounded-12px">
      {showHelperText && <div className="d-flex flex-column gap-12px pb-24px separator">
        <div className="txt-h3-sb text-dark">{t('Then Do This')}</div>
        <div className="txt-body-rg text-dark-5">{t('Choose Action')}</div>
      </div>}
      {
        actionsRows.map((field, index) => {
          const isLastRow = (index === actionRowsLength - 1)
          if (isLastRow && (actionValueOptions?.length === 0 || isView)) return null
          return (
            <RuleRow
              key={field.id}
              formObject={formObject}
              fieldsName='actionValues'
              append={appendAction}
              remove={removeAction}
              index={index}
              isLastRow={isLastRow}
              conditions={actionValues}
              conditionOptions={actionValueOptions}
              conditionLabel={'Select Action'}
              propsMap={propsMap}
              disabled={isView}
              onConditionOrActionChange={onConditionOrActionChange}
              logicType={t('Then')}
            />
          )
        })
      }
    </div>
  )
}

export default Action