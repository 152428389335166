import CustomTag from '@src/@core/components/ui/badge/CustomTag';
// @ts-expect-error FIX ME
import noSkuImage from '@src/assets/images/omniful/no_sku_image.svg';
import { onSkuImgError } from '@src/utility/Utils';
import { showSkuTypeTag } from '@src/views/sales/sales.utils';
import classNames from 'classnames';
import React from 'react';
import { components } from 'react-select';

// Define types for the props expected by this component
interface iSkuCustomDropdownOptionProps {
  data: {
    label: string;
    type: string;
    logo?: string;
    value: string;
    name: string;
  };
}

// Custom dropdown option for displaying SKU information, including an SKU tag and image.
const SkuCustomDropdownOption: React.FC<iSkuCustomDropdownOptionProps> = (props) => {
  const {
    data: { label, name, type, logo, value }
  } = props;

  // Get the SKU tag to display
  const skuTag = showSkuTypeTag(type);
  const { Option } = components;

  return (
    // @ts-expect-error FIX ME
    <Option key={value} {...props}>
      <div className='flex-center-between' title={label}>
        <div className={classNames('d-flex gap-12px', {
          'width-90-per':!!skuTag,
          'w-100':!skuTag
        })}>
          <img
            onError={({ currentTarget }) => onSkuImgError(currentTarget)}
            src={logo || noSkuImage}
            className="sku-img h-full w-full object-fit-fill"
            width="40"
            height="40"
            loading="lazy"
            alt={label}
          />
          <div className={classNames('flex-start-center flex-column text-truncate', {
            'w-75':!!skuTag,
            'w-100':!skuTag
          })}>
            <div className='txt-sub-rg text-dark text-truncate w-100' title={name}>{name}</div>
            <div className='txt-asst-rg text-dark-5 text-truncate w-100' title={label}>SKU Code: {label}</div>
          </div>
        </div>
        {skuTag && <div>
          <CustomTag className='text-info bg-info-light' title={skuTag} />
        </div>}
      </div>
    </Option>
  );
};

export default SkuCustomDropdownOption;
