import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import CreateEditRulesSideSheet from '@src/components/rule/createEditRule/CreateEditRuleSideSheet'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { API_KEY, createAutomationRule, editAutomationRule } from '../giftItemAutomation.api'
import { RULE_SIDESHEET_TITLES } from '../giftItemAutomation.constants'
import { checkZeroQuantityOrWeight, createActionBodyForRequest, handleGenerateValueForCreateRule, propsMapFunc } from '../giftItemAutomation.utils'
import CustomActionComponent from './customActionComponent'

const GiftItemCreateSection = (props) => {
  const { ruleSideSheet, setRuleSideSheet, conditionGroup, actionGroup, filter, defaultFormValues, activeRule, isSingleAutomationRuleFetching, isSingleAutomationRuleError } = props

  // Hooks
  const { t } = useTranslation();
  const queryClient = useQueryClient()

  // Variables
  const selectedSellerId = filter.seller?.value

  // Function
  const propsMap = propsMapFunc({selectedSellerId})

  const handleCloseRulesSideSheet = () => {
    setRuleSideSheet({isOpen:false, isEdit:false, isView:false})
  }

  const handleGetCreateEditRuleSideSheetTitle = () => {
    if (ruleSideSheet.isEdit) {
      return t(RULE_SIDESHEET_TITLES.EDIT)
    } else if (ruleSideSheet.isView) {
      return t(RULE_SIDESHEET_TITLES.VIEW)
    }
    return t(RULE_SIDESHEET_TITLES.CREATE)
  }

  const handleFormSuccess = () => {
    queryClient.invalidateQueries({queryKey: [API_KEY.FETCH_AUTOMATION_RULES]})
    const toastMessage = `Automation Rule ${ruleSideSheet.isEdit ? 'Updated' : 'Created'} Successfully`
    CustomToast(toastMessage, { my_type: 'success' })
    setRuleSideSheet({ isOpen: false, isEdit: false, isView: false })
  }

  const handleFormatData = (selectedConditionEntities, conditions, logicalOperators) => {
    const defaultLogicalOperator = logicalOperators?.[0]?.key_name
    const conditionsMap = {}
    conditions.forEach((condition) => {
      conditionsMap[condition.key_name] = condition
    })
    return selectedConditionEntities.map((entity) => {
      return {
        name: entity.condition.value,
        operator: entity.operator ? entity.operator.value : null,
        value: entity.value ? handleGenerateValueForCreateRule(entity.value, conditionsMap[entity.condition.value], entity.operator) : null,
        logical_operator: defaultLogicalOperator
      }
    })
  }

  const {mutate: createRule, isPending: isRuleCreating} = createAutomationRule({
    onSuccess: handleFormSuccess
  })

  const {mutate: editRule, isPending: isRuleEditing} = editAutomationRule({
    onSuccess: handleFormSuccess
  })

  const handleCreateEditRule = (data) => {
    
    const body = {}
    body.name = data.name
    body.description = data.description
    body.is_active = data.is_active

    const conditionGroupLogicalOperators = conditionGroup.logical_operators
    const conditionData = handleFormatData(data.triggerConditions, conditionGroup.conditions, conditionGroupLogicalOperators)
    const firstCondition = conditionData.shift()
    const actionsData = createActionBodyForRequest(data.actionValues[0].value)

    const { isZeroQuantity, isZeroWeight } = checkZeroQuantityOrWeight(data.actionValues[0].value)
    
    if (isZeroQuantity && isZeroWeight) {
      CustomToast('Item quantity and weight cannot be 0', { my_type: 'error' })
      return
    } else if (isZeroQuantity) {
      CustomToast('Item quantity cannot be 0', { my_type: 'error' })
      return
    } else if (isZeroWeight) {
      CustomToast('Item weight cannot be 0', { my_type: 'error' })
      return
    }

    body.condition = firstCondition
    body.condition.sub_conditions = conditionData
    body.action_group = {
      actions: actionsData.actions,
      logical_operator: actionGroup.logical_operators[0].key_name
    }
    
    if (ruleSideSheet.isEdit) {
      editRule({body, ruleId: activeRule.id, seller_id: selectedSellerId})
    } else {
      createRule({body, seller_id: selectedSellerId})
    }
  }


  return (
    <div>
      <CreateEditRulesSideSheet
        isOpen={ruleSideSheet.isOpen} 
        isEdit={ruleSideSheet.isEdit} 
        isView={ruleSideSheet.isView}
        toggle={handleCloseRulesSideSheet} 
        triggerConditions={conditionGroup.conditions}
        actionValues={actionGroup.actions}
        handleCreateEditRule={handleCreateEditRule}
        isFormSubmitting={isRuleCreating || isRuleEditing}
        propsMap={propsMap}
        defaultFormValues={defaultFormValues}
        sideSheetTitle={handleGetCreateEditRuleSideSheetTitle()}
        isLoading={isSingleAutomationRuleFetching}
        isError={isSingleAutomationRuleError}
        CustomActionComponent={CustomActionComponent}
      />
    </div>
  )
}

export default GiftItemCreateSection