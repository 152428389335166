import SideSheet from '@src/@core/components/ui/sideSheet'
import SidesheetHeader from '@src/@core/components/ui/sidesheet-header'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBody } from 'reactstrap'

import Button from '@src/@core/components/ui/button'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { getBatchesForSKUCode } from '@src/views/sales/store/store'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import SearchBatchAndUpdateQuantity from './SearchBatchAndUpdateQuantity'
import './SelectBatchSideSheet.scss'
import SelectBatchSkuDetailCard from './SelectBatchSkuDetailCard'
import UpdateOrderSkuQuantity from './UpdateOrderSkuQuantity'

const SelectBatchSideSheet = ({isOpen, toggle, itemDetails, selectedSeller, selectedSkuBatches, handleIncreaseQtyOfSelectedBatch, handleDecreaseQtyOfSelectedBatch, handleChangeQtyOfSelectedBatch, handleConfirmSelectedSideSheet, uniqueItemId, orderedQty, setOrderedQty}) => {
  //states
  const orderedQtyState = useSelector(state => state.sales.createOrderItems?.[uniqueItemId]?.quantity)
  const skuBatchDetails = useSelector(state => state.sales.skuBatchDetails)
  //hooks
  const { t } = useTranslation()
  const dispatch = useDispatch()
  
  const orderQuantity = orderedQty ? orderedQty : orderedQtyState

  useEffect(() => {
    dispatch(getBatchesForSKUCode({sellerId: selectedSeller?.value, sellerSkuId: itemDetails.seller_sku_id }))
  }, [itemDetails])
  const totalSelectedBatch = selectedSkuBatches ?  Object.values(selectedSkuBatches).reduce((acc, item) => acc + item.quantity, 0) : 0
      
  //functions
  return (
    <SideSheet modalClassName="modal-slide-in select-batch-side-sheet d-flex flex-column" isOpen={isOpen} toggle={toggle}>
      <SidesheetHeader
        hasClearButton
        title={t('Select Batch')}
        clearButtonHandler={toggle}
      />
      <PerfectScrollbar>
        <ModalBody className='p-24px d-flex flex-column gap-36px'>
          <SelectBatchSkuDetailCard sku={itemDetails}/>
          <UpdateOrderSkuQuantity sku={itemDetails} orderQuantity={orderQuantity} uniqueItemId={uniqueItemId} totalSelectedBatch={totalSelectedBatch} setOrderedQty={setOrderedQty} />
          {skuBatchDetails ?
            <SearchBatchAndUpdateQuantity 
              skuBatchDetails={skuBatchDetails} 
              selectedSkuBatches={selectedSkuBatches} 
              uniqueItemId={uniqueItemId} 
              handleIncreaseQtyOfSelectedBatch={handleIncreaseQtyOfSelectedBatch}
              handleDecreaseQtyOfSelectedBatch={handleDecreaseQtyOfSelectedBatch}
              handleChangeQtyOfSelectedBatch={handleChangeQtyOfSelectedBatch}
              totalSelectedBatch = {totalSelectedBatch}
              orderQuantity={orderQuantity}
            /> : <ComponentSpinner/>}
        </ModalBody>
      </PerfectScrollbar>
      <div className='p-16px border-top border-dark-2'>
        <Button onClick={handleConfirmSelectedSideSheet} disabled = {totalSelectedBatch !== orderQuantity} className='justify-content-center w-100'>{t('Confirm')}</Button>
      </div>

    </SideSheet>
  )
}

export default SelectBatchSideSheet