import Button from '@src/@core/components/ui/button'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import SKUDetailsCol from '@src/@core/components/ui/sku-details/index'
import { HUBS_CONFIGURATION, PRICE_BREAK_DOWN_SECTION, TENANT_TYPE } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import PrintManualAwb from '@src/components/PrintManualAwb'
import { printMultipleAwb } from '@src/utility/Utils'
import { AbilityContext } from '@src/utility/context/Can'
import { BUNDLE } from '@src/views/catalog/catalog.constants'
import SimpleSkuDetailModal from '@src/views/catalog/components/simple-sku-detail-modal/SimpleSkuDetailModal'
import ReasonsModal from '@src/views/inventory/components/inventoryOperations/grn/GrnItemsView/ReasonsModal'
import DetailsCard from '@src/views/returns/components/detailsCard'
import ReturnReasonsPopup from '@src/views/returns/components/popup/returnReasonsPopup'
import { RETURN_ORDER_CATEGORY, RETURN_ORDER_TABS, RETURN_STATUS } from '@src/views/returns/returns.constants'
import { markOrdersAsReturnedTohHub } from '@src/views/returns/store'
import ExpandableChildTable from '@src/views/sales/components/ExpandableChildTable/ExpandableChildTable'
import { SHIPPING_ENTITY, SKU_TYPES } from '@src/views/sales/constant/orders.constants'
import CancelShipmentPopUp from '@src/views/sales/create/CancelShipmentPopUp'
import { PriceBreakDownSection } from '@src/views/sales/pending-actions/pages/ordersForApproval/PriceBreakDownSection'
import { fetchAWB, resetSuccess } from '@src/views/sales/store/store'
import classNames from 'classnames'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Card, CardTitle, Spinner } from 'reactstrap'
import BundleSkuDetailsSideSheet from '../../return-grn-order-processing/revamp-create-return-order-processing/components/BundleSkuDetailsSideSheet'

const ReturnItemsDetailTable = (props) => {
  const {order_items, returnOrderDetail} = props

  const {t} = useTranslation()

  const [isMoreDetailPopupOpen, setMoreDetailPopupOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState([])
  const [returnReasonsPopUp, setReturnReasonsPopUp] = useState({ isOpen: false, reasonsList: [] })
  const [skuForDetails, setSkuForDetails] = useState({})
  const [openBundleSkuDetailsSidebar, setOpenBundleSkuDetailsSidebar] = useState(false)
  const [openSkuDetailsSidebar, setOpenSkuDetailsSidebar] = useState(false)

  const handleMoreDetailPopup = () => {
    setMoreDetailPopupOpen(prev => !prev)
  }
  const handleSelectItem = (item) => {
    setSelectedItem(item?.qc_fail_reasons)
    if (item) {
      handleMoreDetailPopup()
    }
  }

  const handleReturnReasonsPopUp = ({reasonsList}) => {
    if (reasonsList) {
      setReturnReasonsPopUp({ isOpen: true, reasonsList })
    } else {
      setReturnReasonsPopUp({ isOpen: false, reasonsList: [] })
    }
  }
    
  const handleBundleSkuDetailsSidebar = (data) => {   
    if (data) {
      const formattedData = {
        ...data,
        ...data.seller_sku,
        child_skus: data.seller_sku.child_skus
      }
      setSkuForDetails(formattedData)
      setOpenBundleSkuDetailsSidebar(true)
    } else {
      setOpenBundleSkuDetailsSidebar(false)
    }
  }
    
  const handleSkuDetailsSidebar = (data) => {
    setSkuForDetails(data)
    setOpenSkuDetailsSidebar(!openSkuDetailsSidebar)
  }  

  const tableColumns = useMemo(() => {
    return [
      {
        id: 1,
        name: t('SKU Details'),
        minWidth: '340px',
        maxWidth: '500px',
        cell: (row) => {
          return (
            <SKUDetailsCol
              skuDetailData={{
                isValidSku: true,
                id: row.id,
                image: {
                  src: row.seller_sku.images?.[0]?.default,
                  alt: row.seller_sku.name
                },
                details: {
                  name: row.seller_sku.name,
                  skuCode: row.seller_sku_code,
                  columnKey: 'returns_sku_details',
                  handleSkuDetails: () => {
                    const { type } = row.seller_sku
                    return type === BUNDLE ? handleBundleSkuDetailsSidebar(row) : handleSkuDetailsSidebar(row)
                  },
                },
                customTag: {
                  isCustomTagPresent: row.seller_sku?.type === SKU_TYPES.BUNDLE.key,
                  className:'text-info bg-info-light',
                  title: 'Kit'
                }
              }}
            />
          )
        }
      },
      {
        id: 2,
        name: <div className="w-100 text-end">{returnOrderDetail?.type === RETURN_ORDER_TABS.COURIER_PARTNER_RETURN.name ? t('Shipped Qty') : t('Delivered Qty')}</div>,
        minWidth: '120px',
        omit: returnOrderDetail?.type === RETURN_ORDER_TABS.OFF_SYSTEM_RETURN.name,
        cell: (row) => <div className="w-100 text-end">{row.delivered_quantity || 0}</div>
      },
      {
        id: 3,
        name: <div className="w-100 text-end">{t('Return Qty')}</div>,
        minWidth: '120px',
        cell: (row) => <div className="w-100 text-end">{row.return_quantity || 0}</div>
      },
      {
        id: 4,
        name: <div className="w-100 text-end">{t('Return Weight')}</div>,
        minWidth: '120px',
        omit: !returnOrderDetail?.is_weighted,
        cell: (row) => <div className="w-100 text-end">{row.display_return_weight || '-'}</div>
      },
      {
        id: 5,
        name: <div className="w-100 text-end">{t('Passed Qty')}</div>,
        minWidth: '120px',
        omit: !(returnOrderDetail?.status === RETURN_STATUS.QC_PROCESSED.name || returnOrderDetail?.status === RETURN_STATUS.RETURN_COMPLETED.name  || returnOrderDetail?.status === RETURN_STATUS.QC_IN_PROGRESS.name),
        cell: (row) => <div className="w-100 text-end text-success">{row.pass_quantity || 0}</div>
      },
      {
        id: 6,
        name: <div className="w-100 text-end">{t('Failed Qty')}</div>,
        omit: !(returnOrderDetail?.status === RETURN_STATUS.QC_PROCESSED.name || returnOrderDetail?.status === RETURN_STATUS.RETURN_COMPLETED.name  || returnOrderDetail?.status === RETURN_STATUS.QC_IN_PROGRESS.name),
        minWidth: '120px',
        cell: (row) => <div className="w-100 text-end text-danger">{row.fail_quantity || 0}</div>
      },
      {
        id: 7,
        name: <div className="w-100 text-end">{t('Refunded Qty')}</div>,
        omit: returnOrderDetail?.status !== 'Return Completed',
        minWidth: '120px',
        cell: (row) => <div className="w-100 text-end">{row.refunded_quantity || 0}</div>
      },
      {
        id: 8,
        name: <div className="w-100 text-end">{t('Unit Price')}</div>,
        minWidth: '160px',
        cell: (row) => <div className="w-100 text-end">{row.unit_price.toFixed(2)}&nbsp;
          {returnOrderDetail?.invoice.currency}</div>
      },
      {
        id: 9,
        name: <div className="w-100 text-end">{t('Discount')}</div>,
        minWidth: '160px',
        cell: (row) => <div className="w-100 text-end">{row.discount.toFixed(2)}&nbsp;
          {returnOrderDetail?.invoice.currency}</div>
      },
      {
        id: 10,
        name: <div className="w-100 text-end">{t('Tax(%)')}</div>,
        minWidth: '120px',
        cell: (row) => <div className="w-100 text-end">{row.tax_percent}</div>
      },
      {
        id:11,
        name: <div className="w-100 text-end">{t('Tax Amount')}</div>,
        minWidth: '160px',
        cell: (row) => <div className="w-100 text-end">{row.tax.toFixed(2)}&nbsp;
          {returnOrderDetail?.invoice.currency}</div>
      },
      {
        id: 12,
        name: <div className="w-100 text-end">{t('Total Price')}</div>,
        minWidth: '160px',
        cell: (row) => <div className="w-100 text-end">{row.total.toFixed(2)}&nbsp;
          {returnOrderDetail?.invoice.currency}</div>
      },
      {
        id: 13,
        name: t('Failed Reason'),
        minWidth: '120px',
        omit: !(returnOrderDetail?.status === RETURN_STATUS.QC_PROCESSED.name || returnOrderDetail?.status === RETURN_STATUS.RETURN_COMPLETED.name  || returnOrderDetail?.status === RETURN_STATUS.QC_IN_PROGRESS.name),
        cell: (row) => {
          return (
            <div className="flex-center-center w-100">
              {row.qc_fail_reasons?.length > 0 ? (
                <Button
                  ofType="compressed"
                  ofStyle="noBackground"
                  className="txt-sub-rg"        
                  onClick={() => handleSelectItem(row)}>
                  {t('More Details')}
                </Button>
              ) : (
                '-'
              )}
            </div>
          )              
        } 
      },
      {
        id: 14,
        name: t('Return Reasons'),
        minWidth: '160px',
        omit: (returnOrderDetail?.status === RETURN_STATUS.QC_PROCESSED.name || returnOrderDetail?.status === RETURN_STATUS.RETURN_COMPLETED.name  || returnOrderDetail?.status === RETURN_STATUS.QC_IN_PROGRESS.name),
        cell: (row) => {
          return (
            <div className="flex-center-center w-100">
              {row.return_reasons?.length > 0 ? (
                <Button
                  ofType="compressed"
                  ofStyle="noBackground"
                  className="txt-sub-rg"
                  onClick={() => handleReturnReasonsPopUp({ reasonsList: row.return_reasons })}>
                  {t('More Details')}
                </Button>
              ) : (
                '-'
              )}
            </div>
          )
        }
      }
    ]
  }, [order_items])

  const childTableColumns = useMemo(() => {
    return [
      {
        id: 1,
        name: t('SKU Details'),
        minWidth: '200px',
        maxWidth: '300px',
        cell: (row) => {
          return (
            <SKUDetailsCol
              skuDetailData={{
                isValidSku: true,
                id: row.id,
                image: {
                  src: row.images?.[0]?.default,
                  alt: row.name
                },
                details: {
                  name: row.name,
                  skuCode: row.seller_sku_code,
                  columnKey: 'sku_details',
                  handleSkuDetails: row.id ? () => (handleSkuDetailsSidebar(row)) : null,
                }
              }}
            />
          )
        }
      },
      {
        id: 2,
        name: <div className="w-100 text-end">{t('Qty Per Kit')}</div>,
        minWidth: '120px',
        cell: (row) => <div className="w-100 text-end">{row.quantity || 0}</div>
      },
      {
        id: 3,
        name: <div className="w-100 text-end">{t('Return Qty')}</div>,
        minWidth: '120px',
        cell: (row) => <div className="w-100 text-end">{row.return_quantity || 0}</div>
      },
      {
        id: 4,
        name: <div className="w-100 text-end">{t('Return Weight')}</div>,
        minWidth: '120px',
        omit: !returnOrderDetail?.is_weighted,
        cell: (row) => <div className="w-100 text-end">{row.display_return_weight || '-'}</div>
      },
      {
        id: 5,
        name: <div className="w-100 text-end">{t('Passed Qty')}</div>,
        minWidth: '120px',
        omit: !(returnOrderDetail?.status === RETURN_STATUS.QC_PROCESSED.name || returnOrderDetail?.status === RETURN_STATUS.RETURN_COMPLETED.name  || returnOrderDetail?.status === RETURN_STATUS.QC_IN_PROGRESS.name),
        cell: (row) => <div className="w-100 text-end text-success">{row.pass_quantity || 0}</div>
      },
      {
        id: 6,
        name: <div className="w-100 text-end">{t('Failed Qty')}</div>,
        omit: !(returnOrderDetail?.status === RETURN_STATUS.QC_PROCESSED.name || returnOrderDetail?.status === RETURN_STATUS.RETURN_COMPLETED.name  || returnOrderDetail?.status === RETURN_STATUS.QC_IN_PROGRESS.name),
        minWidth: '120px',
        cell: (row) => <div className="w-100 text-end text-danger">{row.fail_quantity || 0}</div>
      },
      {
        id: 7,
        name: <div className="w-100 text-end">{t('Refunded Qty')}</div>,
        omit: returnOrderDetail?.status !== RETURN_STATUS.RETURN_COMPLETED.name,
        minWidth: '120px',
        cell: (row) => <div className="w-100 text-end">{row.refunded_quantity || 0}</div>
      },
      {
        id: 8,
        name: <div className="w-100 text-end">{t('Unit Price')}</div>,
        minWidth: '160px',
        cell: (row) => <div className="w-100 text-end">{row.unit_price?.toFixed(2)}&nbsp;
          {returnOrderDetail?.invoice.currency}</div>
      },
      // {
      //     id: 8,
      //     name: t('Total Price'),
      //     minWidth: "120px",
      //     selector: (row) => row.total?.toFixed(2)
      // },
      {
        id: 9,
        width: '120px',
        omit: !(returnOrderDetail?.status === RETURN_STATUS.QC_PROCESSED.name || returnOrderDetail?.status === RETURN_STATUS.RETURN_COMPLETED.name  || returnOrderDetail?.status === RETURN_STATUS.QC_IN_PROGRESS.name),
        cell: (row) => {
          return (
            <div className="flex-center-center w-100">
              {row.qc_fail_reasons?.length > 0 ? (
                <Button
                  ofType="compressed"
                  ofStyle="noBackground"
                  onClick={() => handleSelectItem(row)}>
                  {t('More Details')}
                </Button>
              ) : (
                '-'
              )}
            </div>
          )
        }
      }
    ]
  }, [order_items])
    
  return (
    <>
      <ExpandableCustomTable
        loading={false}
        error={false}
        success={true}
        data={order_items || []}
        showPagination={false}
        columns={tableColumns}
        useReactPaginate={false}
        showColumnsTableHeader={false}
        expandableRows
        expandableRowsHideExpander
        expandableRowsComponent={ExpandableChildTable}
        expandableRowsComponentProps={{childTableColumns }}
        expandableRowExpanded={(row) => row.seller_sku?.type === SKU_TYPES.BUNDLE.key}
      />
      {/* Failed reasons and images modal */}
      <ReasonsModal
        openReasonsModal={isMoreDetailPopupOpen}
        setOpenReasonsModal={setMoreDetailPopupOpen}
        reasons={selectedItem}
        setReasons={setSelectedItem}
        title="Failed Items"
      />
      <ReturnReasonsPopup
        returnReasonsPopUp={returnReasonsPopUp}
        handleReturnReasonsPopUp={handleReturnReasonsPopUp}
      />
      <BundleSkuDetailsSideSheet
        isOpen={openBundleSkuDetailsSidebar}
        bundleSkuForDetails={skuForDetails}
        toggleBundleSkuModal={() => handleBundleSkuDetailsSidebar(false)}  
      />    
      <SimpleSkuDetailModal
        skuForDetails={skuForDetails}
        toggleSimpleSkuModal={() => handleSkuDetailsSidebar(false)}
        isOpen={openSkuDetailsSidebar} 
      />    
    </>
  )
}

const ReturnOrderDetailSummary = (props) => {
  const { details } = props
  const { t } = useTranslation()
  const itemDetails = details.filter(element => element !== false)
  return (
    <Card className="return-order-detail-summary m-0 border rounded-4">
      <CardTitle className="card-title mb-0 txt-h3-md">
        {t('Basic Details')}
      </CardTitle>
      <div className="card-body-container bg-white">
        {itemDetails.map((element) => (
          <div className="d-flex" key={element.key}>
            <div className="label text-dark-5">{element.key}</div>
            <div className="value d-flex gap-24px">
              <span className="text-dark-5">:</span>
              <span className="text-dark">{element.value || '-'}</span>
            </div>
          </div>
        ))}
      </div>
    </Card>
  )
}
const ItemDetail = (props) => {
  const {handleReturnOrderDetailModal, handleCreateReverseShipmentModal, handleOrderDetailSidebar, returnOrderToShowDetail, handleCreateReturnOrderProcessingModal, fetchParentData} = props

  const {t} = useTranslation()
  const dispatch = useDispatch()
  const ability = useContext(AbilityContext)
  const [searchParams] = useSearchParams()
  const activeReturnOrderPage = searchParams.get('currentTab')
  
  // Redux State
  const success = useSelector((state) => state.sales.success);
  const salesLoading = useSelector((state) => state.sales.loading);
  const loading = useSelector((state) => state.returns.loading);
  const selectedHubConfiguration = useSelector((state) => state.auth.selectedHubConfiguration);
  const returnOrderHubDetail = useSelector((state) => state.returns.returnOrderHubDetail);
  const returnOrderDetail = useSelector((state) => state.returns.returnOrderDetail);
  const reverseShipmentDetail = useSelector((state) => state.returns.reverseShipmentDetail);
  const tenantType = useSelector((state) => state.auth.userData.tenant.type);
  const selectedGlobalHub = useSelector((state) => state.auth.selectedGlobalHub);

  // Local Component State
  const [printAwbLoading, setPrintAwbLoading] = useState(false);
  const [cancelShipmentModal, setCancelShipmentModal] = useState(false);

  // Derived Variables
  const isExternalHub = selectedGlobalHub?.is_external_hub;

  const isLocationInventoryEnabled = activeReturnOrderPage === RETURN_ORDER_TABS.ALL.id
    ? returnOrderHubDetail?.configurations?.find((config) => config.configuration_type === HUBS_CONFIGURATION.LOCATION_INVENTORY.value)?.configuration_values?.enabled
    : selectedHubConfiguration?.find((config) => config.configuration_type === HUBS_CONFIGURATION.LOCATION_INVENTORY.value)?.configuration_values?.enabled;

  
  const showReturnToOriginBtn = returnOrderDetail?.type === RETURN_ORDER_TABS.CUSTOMER_RETURN.name && returnOrderDetail?.status === RETURN_STATUS.REVERSE_SHIPMENT_CREATED.name

  const hasCreateReverseShipmentPermission = ability.can(abilityMap.order.create_shipment.action, abilityMap.order.create_shipment.resource)

  const hasReturnQcPermission = ability.can(abilityMap.return.qc_order.action, abilityMap.return.qc_order.resource)
  const isViewOrderAllowed = ability.can(abilityMap.order.view.action, abilityMap.order.view.resource)

  const canPrintAwb = returnOrderDetail?.print_awb_allowed;
  const canPrintManualAwb = returnOrderDetail?.print_manual_awb_allowed;
  const canCreateShipment = hasCreateReverseShipmentPermission && !returnOrderDetail?.shipment_created;
  const canQCOrder = !isLocationInventoryEnabled && hasReturnQcPermission && returnOrderDetail?.status === RETURN_STATUS.RETURN_TO_ORIGIN.name && !isExternalHub;
  const canCancelShipment = returnOrderDetail?.is_cancel_shipment_allowed;

  const isSideSheetFooterShown = canPrintAwb || canPrintManualAwb || canCreateShipment || canQCOrder || showReturnToOriginBtn || canCancelShipment;
    
  const RETURN_DETAILS = 'Return details'
  const ADDITIONAL_ORDER_DETAILS = 'Additional Order Details'
  const COURIER_PARTNER_RETURN = RETURN_ORDER_CATEGORY.COURIER_PARTNER_RETURN.name

  const pickup_name = returnOrderDetail?.pickup_address?.first_name + ' ' + returnOrderDetail?.pickup_address?.last_name
  const addressComponents = [
    returnOrderDetail?.pickup_address?.address1,
    returnOrderDetail?.pickup_address?.address2,
    returnOrderDetail?.pickup_address?.city,
    returnOrderDetail?.pickup_address?.state,
    returnOrderDetail?.pickup_address?.country,
    returnOrderDetail?.pickup_address?.zip,
  ];
  const pickup_address = addressComponents.filter(address => address).join(', ');
    
  const returnsOrderDetails = [
    { key: t('Return Source'), value: returnOrderDetail?.source, card: RETURN_DETAILS },
    { key: t('Request Created At'), value: returnOrderDetail?.created_at, omit: returnOrderDetail?.type === COURIER_PARTNER_RETURN, card: RETURN_DETAILS },
    { key: t('Order Created At'), value: returnOrderDetail?.order_created_at, card: ADDITIONAL_ORDER_DETAILS },
    { key: t('Order Delivered At'), value: returnOrderDetail?.order_delivered_at, omit: returnOrderDetail?.type === COURIER_PARTNER_RETURN, card: ADDITIONAL_ORDER_DETAILS },
    { key: t('Total Shipped Qty'), value: returnOrderDetail?.delivered_quantity, card: ADDITIONAL_ORDER_DETAILS, omit: returnOrderDetail?.type !== COURIER_PARTNER_RETURN},
    { key: t('Total Delivered Qty'), value: returnOrderDetail?.delivered_quantity, omit: returnOrderDetail?.type === COURIER_PARTNER_RETURN, card: ADDITIONAL_ORDER_DETAILS },
    { key: t('Shipping Partner'), value: returnOrderDetail?.shipment?.shipping_partner_name, card: ADDITIONAL_ORDER_DETAILS },
    { key: t('Awb Number'), value: returnOrderDetail?.shipment?.awb_number, url: returnOrderDetail?.shipment?.tracking_url, card: ADDITIONAL_ORDER_DETAILS }
  ]
    
  const getReturnsFilteredOrderDetails = (card) => returnsOrderDetails.filter(detail => !detail.omit && detail.card === card)
    
  const openCancelShipmentModal = () => {
    setCancelShipmentModal(true)
  }
    
  const handlePrintAwbLabel = async () => {
    if (returnOrderDetail?.shipment?.awb_label) {
      printMultipleAwb(returnOrderDetail.shipment.awb_label)
    } else {
      setPrintAwbLoading(true)
      const params = {
        entity_id: returnOrderDetail.id,
        entity_type: SHIPPING_ENTITY.RETURN_ORDER.value
      }
      const res = await fetchAWB({ params })
      if (res?.is_success && res?.data?.awb_label) {
        printMultipleAwb(res.data.awb_label)
      }
      setPrintAwbLoading(false)
    }
  }

  const handleStartProcessing = () => {
    handleCreateReturnOrderProcessingModal(returnOrderToShowDetail)
  }

  const openCreateReverseShipmentModal = () => {
    handleReturnOrderDetailModal()
    handleCreateReverseShipmentModal({order:returnOrderDetail})
  }

  const handleMarkOrderArrivedAtHub = () => {
    const body = {
      is_reverse: true,
      shipment_updates: [
        {
          order_id: returnOrderDetail.id,
          awb_number: returnOrderDetail.shipment.awb_number,
          shipping_partner_tag: returnOrderDetail.shipment.shipping_partner_tag
        }
      ],
      status: RETURN_STATUS.REACHED_AT_HUB.key
    }
    dispatch(markOrdersAsReturnedTohHub({ body }))
  }

  useEffect(() => {
    if (reverseShipmentDetail) {
      handleReturnOrderDetailModal()
      handleCreateReverseShipmentModal({order:returnOrderDetail})
    }
  }, [reverseShipmentDetail])
  
  useEffect(() => {
    if (success.cancelShipment) {
      setCancelShipmentModal(false)
      handleReturnOrderDetailModal()
      fetchParentData()
      dispatch(resetSuccess())
    }
  },  [success.cancelShipment])

  return (
    <>
      <div className="pb-40px">
        <div className="mb-16px">
          <ReturnOrderDetailSummary
            details={[
              returnOrderDetail?.type !== RETURN_ORDER_TABS.OFF_SYSTEM_RETURN.name && { key: t('Order ID'), value: <div onClick={isViewOrderAllowed ? () => handleOrderDetailSidebar(returnOrderDetail) : null} className={classNames({'text-primary cursor-pointer': isViewOrderAllowed})} >{returnOrderDetail?.seller_sales_channel_order_id}</div>},
              { key: t('Total Return Qty'), value: returnOrderDetail?.total_return_quantity},
              { key: t('Return Order Type'), value: returnOrderDetail?.type},
              { key: t('Hub'), value: returnOrderDetail?.hub.name},
              tenantType !== TENANT_TYPE.SELF_USAGE && { key: t('Seller Name'), value: returnOrderDetail?.seller_name}
            ]}
          />
        </div>
        <div className="return-customer-delivery-card-container">
          <DetailsCard key={'Customer Details'} cardTitle={t('Customer Details')} details={
            [
              {key: t('Customer Name'), value: returnOrderDetail?.customer?.customer_name},
              {key: t('Mobile Number'), value: returnOrderDetail?.customer?.mobile},
              {key: t('Email ID'), value: returnOrderDetail?.customer?.email}
            ]
          }/>
          <DetailsCard
            key={RETURN_DETAILS}
            cardTitle={t(RETURN_DETAILS)}
            details={getReturnsFilteredOrderDetails(RETURN_DETAILS)}
          />
          <DetailsCard
            key={'Pickup Details'} cardTitle={t('Pickup Details')} details={
              [
                {key: t('Picker Name'), value: pickup_name },
                {key: t('Mobile Number'), value: returnOrderDetail?.pickup_address?.phone},
                {key: t('Email ID'), value: returnOrderDetail?.pickup_address?.email},
                {key: t('Address'), value: pickup_address}
              ]
            }
          />
          <DetailsCard
            key={ADDITIONAL_ORDER_DETAILS}
            cardTitle={t(ADDITIONAL_ORDER_DETAILS)}
            details={getReturnsFilteredOrderDetails(ADDITIONAL_ORDER_DETAILS)}
          />
        </div> 
        <div className="return-order-item-detals-table-container">
          <ReturnItemsDetailTable order_items={returnOrderDetail?.order_items} returnOrderDetail={returnOrderDetail}/>
        </div>

        {returnOrderDetail && <PriceBreakDownSection orderDetails={returnOrderDetail} orderType={PRICE_BREAK_DOWN_SECTION.RETURN_ORDER.value} show_price_section_keys={{show_additional_charges_section: true, show_grand_total: false, show_total_refund: false, show_total_refund_section: true, show_items_total: true, show_sub_total: false, show_total_discount: false, show_total_tax: false}} />}
      </div>

      {isSideSheetFooterShown && <SidesheetFooter className="flex-center-end gap-1">

        {canCancelShipment && <Button onClick={openCancelShipmentModal} ofStyle="outlined" loading={salesLoading.cancelShipment}>
          {t('Cancel Shipment')}
        </Button>}

        {canPrintAwb && <Button disabled={printAwbLoading} onClick={handlePrintAwbLabel}>
          {printAwbLoading && <Spinner size="sm" className="me-50" />}{t('Print AWB')}
        </Button>}

        {canPrintManualAwb && <PrintManualAwb entity_id={returnOrderDetail?.id} entity_type={SHIPPING_ENTITY.RETURN_ORDER.value} />}

        {canCreateShipment && <Button onClick={openCreateReverseShipmentModal}>
          {t('Create Shipment')}
        </Button>}

        {canQCOrder && <Button onClick={handleStartProcessing}>{
          t('Start Processing')}
        </Button>}

        {showReturnToOriginBtn && <Button
          onClick={handleMarkOrderArrivedAtHub}
          disabled={loading.markOrdersAsReturnedTohHub}
        >
          {loading.markOrdersAsReturnedTohHub && <Spinner size="sm" className="me-50" />}{t('Mark Return To Origin')}
        </Button>}

      </SidesheetFooter>}

      <CancelShipmentPopUp
        shipmentOrderID={returnOrderDetail?.shipment.shipment_order_id}
        deleteModalOpen={cancelShipmentModal}
        setDeleteModalOpen={setCancelShipmentModal}
        shipping_partner_tag={returnOrderDetail?.shipment.shipping_partner_tag}
        awb_number={returnOrderDetail?.shipment.awb_number}
      />
    </>
  )
}
export default ItemDetail