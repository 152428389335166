import AttributesIcon from '@src/assets/images/icons/AttributesIcon';
//@ts-expect-error fix
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg';
import { pluralizeUnit } from '@src/utility/Utils';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SideSheet from '../sideSheet';
import { getComponentType } from './attributes.constants';
import './styles.scss';

interface iAttributesOptions {
  group_name: string
  name: string
  price: string
  type: string
  values: string[]
}

interface iItemAttributes {
  source: string
  sales_channel_order_item_id: string
  quantity: number,
  attributes:iAttributesOptions []
}

interface iSkuDetails {
  seller_sku_code: string
  seller_id: string
  id: string
  image: null | string
  name: string
}

interface iAttributesProps {
  isOpen: boolean
  toggle: () => void
  skuDetails : iSkuDetails
  itemAttributes: iItemAttributes[]
  title?: string
}
 
const Attributes: React.FC<iAttributesProps> = ({
  isOpen,
  toggle,
  itemAttributes,
  skuDetails,
  title = 'All Attributes'
}) => {
  const { t } = useTranslation();
  const handleImageError = (currentTarget: HTMLImageElement) => {
    currentTarget.onerror = null;
    currentTarget.src = no_sku_image;
  };

  //@ts-expect-error fix
  return <SideSheet
    title={title}
    isOpen={isOpen}
    toggle={toggle}
    modalClassName="modal-slide-in attributes-sidesheet-container"
  >
    <div className='content-wrapper overflow-y-auto'>
      <div className='flex-center-start gap-18px sku-details-container'>
        <div className='rounded-10px flex-center-center border border-dark-2 image-container overflow-hidden'>
          <img
            onError={({ currentTarget }) => handleImageError(currentTarget)}
            src={skuDetails.image || no_sku_image}
            alt={skuDetails.name}
            className="sku-img h-full w-full object-fit-cover"
            width="80"
            height="80"
            loading="lazy"
          />
        </div>
        <div className='d-flex flex-column justify-content-center gap-6px'>
          <div className='txt-h3-md text-dark max-width-400 text-truncate' title={skuDetails.name}>
            {skuDetails.name}
          </div>
          <div className='flex-center-start'>
            <span className='txt-body-rg text-dark-6'>{t('SKU Code:')}</span>&nbsp;
            <span className='txt-body-md text-dark max-width-350 text-truncate' title={skuDetails.seller_sku_code}>
              {skuDetails.seller_sku_code}
            </span>
          </div>
        </div>
      </div>
      {itemAttributes.map((item: iItemAttributes) => <div
        key={item.sales_channel_order_item_id}
        className='rounded-10px border border-light-4'
      >
        <div className='border-bottom border-bottom-light-4 p-16px flex-center-start gap-8px'>
          <AttributesIcon width={20} height={20}/>
          <span className='txt-h3-md text-dark'>{pluralizeUnit(item.quantity, 'Item')}</span>
        </div>
        {item.attributes.map((attribute: iAttributesOptions, index: number) => <div
          key={index}
          className={classNames('mx-16px py-12px d-flex flex-column justify-content-center gap-4px', {
            'border-bottom border-bottom-light-4': index !== item.attributes.length - 1
          })}
        >
          <div className='txt-body-rg text-dark-6'>{attribute.name}</div>
          {getComponentType(attribute.type)({ values: attribute.values || [], additionalClasses: 'max-width-500' })}
        </div>)}
      </div>)}
    </div>
  </SideSheet>;
};

export default Attributes;