import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { bindToChannelEvent, initRealTime } from '@src/network/RealTime'
import { memo, useEffect, useRef } from 'react'
import { Info } from 'react-feather'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap'
import { bulkPrintAwbApi } from '../bulk-ship.apis'

const PrintAwbConfirmationPopUp = ({ isPopUpOpen, setIsPopUpOpen, selectedOrders, pickingWave }) => {
  const { t } = useTranslation()
  const hubId = useSelector(store => store.auth.selectedGlobalHubId)
  const pusherLoading = useSelector(state => state.auth.pusherLoading)
  const pusherResponse = useSelector(store => store.auth.pusherResponse)
  let toastId = useRef(null)

  // Print Bulk Awbs
  const { mutate: printBulkAwbs, isPending: isPrintBulkAwbsPending } = useApi({
    isMutation: true,
    apiKey: [...bulkPrintAwbApi.apiKey],
    apiFn: (body) => {
      return axiosInstance.post(bulkPrintAwbApi.url, body)
    },
    onSuccess: (data) => {
      const eventName = data?.data?.event
      bindToChannelEvent(eventName)
      toastId.current = CustomToast('AWBs generation is in progress', { my_type: 'info', duration: Infinity })
      handleClosePopUp()
    }
  })

  const handlePrintBulkAwbs = () => {
    const body = {
      order_ids: Object.values(selectedOrders).map(el => el.id),
      wave_id: String(pickingWave?.wave?.id),
      hub_id: hubId
    }
    initRealTime(() => {
      printBulkAwbs(body)
    })
  }

  const handleClosePopUp = () => setIsPopUpOpen(false)

  const buttonProps = {
    disabled: isPrintBulkAwbsPending || pusherLoading,
    ofStyle: 'outlined',
    onClick: handleClosePopUp
  }

  const confirmButtonProps = {
    disabled: isPrintBulkAwbsPending || pusherLoading,
    onClick: handlePrintBulkAwbs,
    loading: isPrintBulkAwbsPending || pusherLoading
  }

  useEffect(() => {
    if (pusherResponse['bulk-awb-print']) {
      if (toastId.current) {
        toast.dismiss(toastId.current)
      }
    }
  }, [pusherResponse])

  return (
    <Modal 
      isOpen={isPopUpOpen} size="lg" centered className="width-600" contentClassName="rounded-24px"
      toggle={handleClosePopUp}
    >
      <ModalBody className="p-24px">
        <div>
          <div className="d-flex justify-content-center">
            <Info color="var(--bs-primary)" size={164} fill="var(--bs-primary)" strokeColor="white" stroke="white" strokeWidth={1.5} />
          </div>
          <div className="mt-40px d-flex flex-column gap-16px">
            <div className="txt-h1-sb text-dark">{t('Print AWB')}</div>
            <div className="txt-body-rg text-dark">
              {t('AWBs will be generated in batches of 50, exclusively for orders with successfully created shipments.')}
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div className="d-flex gap-16px mt-40px">
              <Button {...buttonProps}>{t('Go Back')}</Button>
              <Button {...confirmButtonProps}>{t('Confirm')}</Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default memo(PrintAwbConfirmationPopUp)
