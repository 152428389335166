
import Button from '@src/@core/components/ui/button';
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast';
import SkuItemSkeleton from '@src/@core/components/ui/skeleton/sku-item';
import { useApi } from '@src/configs/react-query/useApi';
import { axiosInstance } from '@src/network/AxiosInstance';
import { getApiUrl } from '@src/utility/Utils';
import { SKU_TYPE_OPTIONS } from '@src/views/catalog/catalog.constants';
import { orderItemsWithBatchesConfigApi } from '@src/views/sales/sales.apis';
import { AUTOMATION_RULE_ORDER_ITEM_STATUS, CUSTOM_BATCH_SELECT, ORDER_ITEM_SOURCES } from '@src/views/sales/sales.constant';
import CustomDropdownItem from '@src/views/sales/shipmentOrders/createShipmentOrder/components/CustomDropdownItem';
import { addCreateOrderItems, alreadyAddedCreateOrderItems, calculateCreateOrderTotalWeight, clearCreateOrderItems, clearSellerSkusForCreateOrder, editCustomerAndShippingDetails, loadMoreSellerSkusForCreateOrder, updateCreatedOrderTotalWeight } from '@src/views/sales/store/store';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import ItemsTable from '../../pages/createOrder/ItemsTable';
import FreeItemsInfoModal from '../free-items-info-modal/FreeItemsInfoModal';
import './index.scss';

const EditOrderItems = forwardRef((props, ref) => {
  const { orderDetails, editOrderItems, setEditOrderItems } = props

  // Hooks

  useImperativeHandle(ref, () => ({
    handleItemEditingEnable: (mode) => handleItemEditingEnable(mode),
  }));

  const { t } = useTranslation()
  const inputRef = useRef(null)
  const dispatch = useDispatch()

  // store
  const { loading, sellerSkusForCreateOrder, sellerSkusForCreateOrderMeta, createOrderItems, createdOrderTotalWeight } = useSelector((store) => ({
    loading: store.sales.loading,
    sellerSkusForCreateOrder: store.sales.sellerSkusForCreateOrder,
    sellerSkusForCreateOrderMeta: store.sales.sellerSkusForCreateOrderMeta,
    createOrderItems: store.sales.createOrderItems,
    createdOrderTotalWeight: store.sales.createdOrderTotalWeight,
  }))

  // States
  const [searchParams, setSearchParams] = useState({})
  const [showAddItemsDropdown, setShowAddItemsDropdown] = useState(false)
  const [filteredSellerSkusForCreateOrder, setFilteredSellerSkusForCreateOrder] = useState([])
  const [selectedSkuBatches, setSelectedSkuBatches] = useState({})
  const [isSelectBatchError, setIsSelectedBatchError] = useState({})
  const [isFreeItemsInfoModalOpen, setIsFreeItemsInfoModalOpen] = useState(false)

  // Variables
  const isLastPage = sellerSkusForCreateOrderMeta?.current_page === sellerSkusForCreateOrderMeta?.last_page
  const currency = orderDetails.invoice.currency
  const hasGiftItem = orderDetails.automation_rule_items_details?.items_status === AUTOMATION_RULE_ORDER_ITEM_STATUS.ADDED

  const { totalQuantity, hasWeightedItem, totalItemsWeight } = useMemo(() => {
    const items = Object.values(createOrderItems);
    const total = items.reduce((sum, item) => sum + (item.quantity || 0), 0);
    const isWeighted = items.some(item => item.isWeighted);
    const totalWeight = calculateCreateOrderTotalWeight(items);

    return { totalQuantity: total, hasWeightedItem: isWeighted, totalItemsWeight: totalWeight };
  }, [createOrderItems]);

  const shouldDisableSaveChanges = totalQuantity <= 0 || (hasWeightedItem && totalItemsWeight === 0);

  // Forms
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      currency: { label: currency, value: currency }
    },
    mode: 'onChange',
  })

  // functions
  // This function is used to convert order items into edit table
  const addItemsInEditTable = (itemsData) => {
    const alreadyDataItems = {}
    const batches = {}
    itemsData.order_items.forEach((item) => {
      const isFreeItem = item.source === ORDER_ITEM_SOURCES.AUTOMATION_RULE.value && item.unit_price === 0
      if(isFreeItem) return
      
      alreadyDataItems[item.id] = {
        uniqueItemId: item.id,
        id: item.id,
        seller_sku_id: item.seller_sku.id,
        sku_code: item.seller_sku_code,
        name: item.seller_sku.name,
        image: item.seller_sku.images?.[0]?.default,
        cutoff_price: item.unit_price,
        sku_barcodes: item.seller_sku.barcodes,
        isWeighted: !!item.is_weighted,
        skuWeight: item.is_weighted
          ? {
            value: item.ordered_weight.value,
            uom: item.ordered_weight.uom,
          }
          : null,
        expiry_status: item.expiry_status,
        config: item.expiry_status ? item.sku_config : null,
        type: item.seller_sku.type,
        quantity: item.quantity,
        tax_percentage: item.tax_percent,
        tax_amount: Number((item.tax / item.quantity).toFixed(2)),
        customer_note: item.custom_attributes?.customer_note,
      }

      if (item.expiry_status === CUSTOM_BATCH_SELECT) {
        batches[item.id] = item.batches.reduce((acc, batch) => {
          acc[batch.number] = {
            batch_number: batch.number,
            quantity: batch.ordered_quantity,
          };
          return acc;
        }, {});
      }
    })
    dispatch(alreadyAddedCreateOrderItems(alreadyDataItems))
    dispatch(updateCreatedOrderTotalWeight(itemsData.total_weight.value || 0))
    setSelectedSkuBatches(batches)
  }

  // Queries
  // This Apis is used to get order items with batches config for edit order items table in both live orders and on hold orders
  const { mutate: getOrderItemsWithBatchesConfig } = useApi({
    isMutation: true,
    apiKey: [...orderItemsWithBatchesConfigApi.apiKey],
    apiFn: () => {
      setEditOrderItems(prev => ({ ...prev, isLoading: true }))
      const url = getApiUrl(orderItemsWithBatchesConfigApi.url, { orderId: orderDetails.id })
      return axiosInstance.get(url, { params: { sku_config_required: true } })
    },
    onSuccess: ({ data }) => {
      addItemsInEditTable(data)
      setEditOrderItems({ isOpen: true, isLoading: false })
    },
    onError: () => {
      setEditOrderItems(prev => ({ ...prev, isLoading: false }))
    }
  })

  const handleOrderItemProcessing = () => {
    if (hasBatches) {
      getOrderItemsWithBatchesConfig();
      return
    }

    addItemsInEditTable(orderDetails);
    setEditOrderItems({ isOpen: true, isLoading: false })
  }

  const handleItemEditingEnable = (mode) => {
    if (!mode) {
      // Disable edit mode, reset batches, and clear selected items
      dispatch(clearCreateOrderItems());
      setSelectedSkuBatches({});
      setIsSelectedBatchError({});
      setEditOrderItems(prev => ({ ...prev, isOpen: mode }))
      return;
    }

    if (hasGiftItem) {
      setIsFreeItemsInfoModalOpen(true)
    } else {
      handleOrderItemProcessing()
    }
  };

  const handleShowAddItemsDropdown = (open) => {
    setShowAddItemsDropdown(open)
  }

  const handleLoadMoreSku = () => {
    dispatch(
      loadMoreSellerSkusForCreateOrder({
        ...searchParams,
        seller_id: orderDetails.seller_id,
        page: sellerSkusForCreateOrderMeta.current_page + 1,
        per_page: 20,
        type: SKU_TYPE_OPTIONS.SIMPLE.value,
      })
    )
  }

  const handleScroll = (container) => {
    const { scrollTop, clientHeight, scrollHeight } = container
    if (scrollHeight - scrollTop === clientHeight && clientHeight !== 0 && scrollTop !== 0 && !isLastPage && !loading?.loadMoreSellerSkusForCreateOrder) {
      handleLoadMoreSku()
    }
  }

  const handleAddCreateOrderItems = (skuDetails) => {
    dispatch(
      addCreateOrderItems({
        uniqueItemId: Date.now(),
        seller_sku_id: skuDetails.sku.id,
        sku_code: skuDetails.sku.seller_sku_code,
        name: skuDetails.sku.name,
        image: skuDetails.sku.images?.[0]?.default,
        cutoff_price: skuDetails.sku.selling_price,
        sku_barcodes: skuDetails.sku.barcodes,
        isWeighted: skuDetails.sku.is_weighted,
        skuWeight: {
          value: skuDetails.sku.weight?.value,
          uom: skuDetails.sku.uom,
        },
        config: skuDetails.config,
        type: skuDetails.sku.product?.type,
        tax_percentage: skuDetails.sku.tax_percentage?.selling_price || 0,
        tax_amount: +(((skuDetails.sku.tax_percentage?.selling_price || 0) * skuDetails.sku.selling_price_excluding_tax) / 100).toFixed(2),
      })
    )
  }

  const handleAddItemFromDropdown = ({ skuDetails }) => {
    inputRef.current.focus()
    handleAddCreateOrderItems(skuDetails)
    handleShowAddItemsDropdown(false)
    inputRef.current.value = ''
    dispatch(clearSellerSkusForCreateOrder())
    setFilteredSellerSkusForCreateOrder([])
  }

  const getBatchesForSku = (uniqueItemId) => {
    return Object.values(selectedSkuBatches[uniqueItemId])
  }

  const handleSaveChanges = () => {

    if (Object.values(isSelectBatchError).some((ele) => ele)) {
      CustomToast('Please specify the batches before creating order', {
        my_type: 'error'
      })
      return
    }

    const order_items = Object.values(createOrderItems).map((item) => {
      const mappedItems = {
        id: item.id,
        seller_sku_code: item.sku_code?.trim(),
        selling_price: item.cutoff_price,
        total: +((item.cutoff_price + item.tax_amount) * item.quantity).toFixed(2),
        tax_percent: item.tax_percentage,
        tax: Number((item.tax_amount * item.quantity).toFixed(2)),
        item_barcodes: item.sku_barcodes,
        quantity: item.quantity,
        expiry_status: item.expiry_status,
        customer_note: item.customer_note,
        ordered_weight: item.isWeighted
          ? {
            value: parseFloat(item.skuWeight?.value),
            uom: item.skuWeight?.uom,
          }
          : undefined,
      }
      if (item.expiry_status === CUSTOM_BATCH_SELECT) mappedItems.batches = getBatchesForSku(item.uniqueItemId)
      return mappedItems
    })

    const body = {
      order_items,
      invoice: { currency: watch('currency').value },
      total_weight: {
        value: parseFloat(createdOrderTotalWeight),
        uom: 'kg'
      },
      edit_entity_type: 'order_items',
    }

    dispatch(editCustomerAndShippingDetails({ order_id: orderDetails.id, body }))
  }

  useEffect(() => {
    if (sellerSkusForCreateOrder.length) {
      const filteredSku = sellerSkusForCreateOrder.filter((sellerSku) => {
        return !Object.values(createOrderItems).some((orderItem) => orderItem.seller_sku_id === sellerSku.sku.id)
      })
      setFilteredSellerSkusForCreateOrder(filteredSku)
    } else {
      setFilteredSellerSkusForCreateOrder([])
    }
  }, [sellerSkusForCreateOrder])

  const hasBatches = orderDetails.order_items.some(item => item.expiry_status)

  return (
    <>
      {
        editOrderItems.isOpen ? (
          <div id='edit-order-items-parent-wrapper'>
            <ItemsTable
              register={register}
              control={control}
              inputRef={inputRef}
              handleShowAddItemsDropdown={handleShowAddItemsDropdown}
              selectedSeller={{ value: orderDetails.seller_id }}
              handleSubmit={handleSubmit}
              setSearchParams={setSearchParams}
              watch={watch}
              errors={errors}
              selectedSkuBatches={selectedSkuBatches}
              setSelectedSkuBatches={setSelectedSkuBatches}
              isSelectBatchError={isSelectBatchError}
              setIsSelectedBatchError={setIsSelectedBatchError}
              setIsCreateOrderButtonDisabled={() => { }}
              hideHeading={true}
              autoFocusOnTable={false}
              hubID={orderDetails.hub_id}
              expiryStatusesfilterOption={(option) => option.value !== 'custom'}
            />
            <div className='cancel-save-btn'>
              <Button onClick={() => handleItemEditingEnable(false)} ofStyle='outlined'>
                {t('Cancel')}
              </Button>
              <Button onClick={handleSaveChanges} disabled={shouldDisableSaveChanges} loading={loading.editCustomerAndShippingDetails}>
                {t('Save Changes')}
              </Button>
            </div>
            {showAddItemsDropdown && <Dropdown isOpen={showAddItemsDropdown} toggle={() => setShowAddItemsDropdown(false)} className='create-order-dropdown-button'>
              <DropdownToggle className='invisible'></DropdownToggle>
              {loading.getSellerSkusForCreateOrder && sellerSkusForCreateOrder.length === 0 ? (
                <SkuItemSkeleton />
              ) : (
                <>
                  {filteredSellerSkusForCreateOrder.length ? (
                    <DropdownMenu>
                      <PerfectScrollBar onYReachEnd={handleScroll}>
                        {filteredSellerSkusForCreateOrder.map((skuDetails) => {
                          return (
                            <div
                              key={skuDetails.sku.id}
                              id={`sku-${skuDetails.sku.id}`}
                              onClick={() => handleAddItemFromDropdown({ skuDetails })}
                            >
                              <CustomDropdownItem itemDetails={skuDetails} />
                            </div>
                          )
                        })}
                      </PerfectScrollBar>
                    </DropdownMenu>
                  ) : (
                    <DropdownMenu className='width-300'>
                      <DropdownItem className='sku-cards w-100'>
                        <div className='text-center'>{t('No record found !')}</div>
                      </DropdownItem>
                    </DropdownMenu>
                  )}
                </>
              )}
            </Dropdown>}
          </div>
        ) : null
      }
      <FreeItemsInfoModal 
        isOpen={isFreeItemsInfoModalOpen} 
        handleClose={() => setIsFreeItemsInfoModalOpen(false)} 
        handleContinue={() => {
          setIsFreeItemsInfoModalOpen(false);
          handleOrderItemProcessing();
        }} 
      />
    </>
  )
})

export default EditOrderItems
