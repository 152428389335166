export const customStyles = {
  control: (provided) => ({
    ...provided,
    boxShadow: 'none',
    borderRadius: '4px 4px 0 0',
    border: '1px solid transparent !important',
    borderBottom: '1px solid #EEEFF2 !important'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    width: '100%'
  }),
  placeholder: provided => ({
    ...provided,
    display: 'none'
  }),
  singleValue: provided => ({
    ...provided,
    display: 'none'
  }),
  multiValue: provided => ({
    ...provided,
    display: 'none'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    display: 'none'
  }),
  menu: (provided) => ({
    ...provided,
    margin: 0,
    position: 'static',
    display: 'block',
    backgroundColor: 'none',
    boxShadow: 'none',
    flexGrow: 1,
    overflowY: 'auto'
  }),
  menuList: (provided) => ({
    ...provided,
    minHeight: 'auto',
    maxHeight: '300px',
    position: 'static',
    padding: '0 12px',
    paddingBottom: 0
  }),
  group: (provided) => ({
    ...provided,
    padding: 0
  }),
  option: (provided) => ({
    ...provided,
    padding: 0,
    margin: '16px 0',
    backgroundColor: 'transparent !important',
    color: 'var(--bs-dark)',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    lineHeight: '20px',
    gap: '8px'
  })
}