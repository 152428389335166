export const FILTER_KEYS = {
  SELLER:'seller'
};

export const DEFAULT_VALUES = {
  name: '',
  is_active: true,
  description: '',
  triggerConditions: [
    {
      condition: null,
      operator: null,
      value: null
    }
  ],
  actionValues: [
    {
      condition: null,
      operator: null,
      value: null
    }
  ]
};

export const RULE_SIDESHEET_TITLES = {
  CREATE: 'Create Rule',
  EDIT: 'Edit Rule',
  VIEW: 'View Rule'
}