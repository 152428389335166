import Button from '@src/@core/components/ui/button'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import { Trash2 } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { INPUT_TYPES_MAP } from '../rule.constant'
import ComponentRenderer from './ComponentRenderer'

/**
 * A React functional component representing a single row in a rule creation/edit interface.
 * It handles the rendering of condition, operator, and value fields, as well as the removal of the rule.
 * @param {object} props - The component props.
 * @param {object} formObject - The form object containing control, errors, watch, and setValue functions.
 * @param {string} fieldsName - The name of the fields in the form object.
 * @param {function} append - A function to append a new rule to the form.
 * @param {function} remove - A function to remove a rule from the form.
 * @param {array} conditions - An array of condition options.
 * @param {array} conditionOptions - An array of condition options.
 * @param {string} conditionLabel - The label for the condition field.
 * @param {number} index - The index of the rule in the form.
 * @param {boolean} isLastRow - A boolean indicating whether the rule is the last row in the form.
 * @param {object} propsMap - An object containing API mappings.
 * @param {boolean} disabled - A boolean indicating whether the rule is disabled.
 * @param {function} onConditionOrActionChange - A callback function to get value when condition or action change.
 * @param {string} logicType - The logic type of the rule (for example: "If" or "Then").
 * @return {JSX.Element} The JSX element representing the rule row.
 */
const RuleRow = (props) => {
  const {formObject, fieldsName, append, remove, conditions, conditionOptions, conditionLabel, index, isLastRow, propsMap, disabled, onConditionOrActionChange, logicType} = props
  const { t } = useTranslation()
  const { control, formState: {errors}, watch, setValue, clearErrors, unregister } = formObject

  const selectedConditionDropdown = watch(`${fieldsName}.${index}.condition`)
  const selectedCondition = conditions?.find(condition => condition.key_name === selectedConditionDropdown?.value)
  const operatorOptions = selectedCondition?.operators?.map((el) => ({label: el.display_name, value: el.key_name, datatype: el.datatype}))
  const selectedOperator = watch(`${fieldsName}.${index}.operator`)
  const inputType = selectedCondition?.input_type
  const showOperatorAndValue = !isLastRow && inputType !== INPUT_TYPES_MAP.NOT_APPLICABLE

  const handleChangeCondition = (condition, index) => {
    unregister(`${fieldsName}.${index}.value`)
    setValue(`${fieldsName}.${index}.condition`, condition)
    clearErrors(`${fieldsName}.${index}.condition`)
    const selectedCondition = conditions?.find(ele => ele.key_name === condition.value)
    onConditionOrActionChange({...selectedCondition, ...props})
    const firstOperatorOfCondition = selectedCondition?.operators?.[0]
    if (firstOperatorOfCondition) {
      setValue(`${fieldsName}.${index}.operator`, {label: firstOperatorOfCondition.display_name, value: firstOperatorOfCondition.key_name, datatype: firstOperatorOfCondition.datatype})
    } else {
      setValue(`${fieldsName}.${index}.operator`, null)
    }
    setValue(`${fieldsName}.${index}.value`, null)
    if (isLastRow) {
      append({
        condition: null,
        operator: null,
        value: null
      }, { shouldFocus: false })
    }
  }

  const handleChangeOperator = (operator, index) => {
    setValue(`${fieldsName}.${index}.operator`, operator)
    setValue(`${fieldsName}.${index}.value`, null)
  }

  const handleRemoveRule = (index) => {
    remove(index)
  }

  return (
    <div className="flex-start-start gap-20px w-100 trigger-condition-row">
      <div className="lhs-side width-35-per flex-start-start gap-20px">
        <div className="py-10px border border-dark-2 bg-white rounded-8px field logical-operator-container text-center">
          {index === 0 ? logicType : t('And')}
        </div>
        <div className="flex-fill">
          <FloatingDropDown
            control={control}
            errors={errors}
            title={conditionLabel}
            name={`${fieldsName}.${index}.condition`}
            isRequired={!isLastRow}
            value={selectedConditionDropdown}
            options={conditionOptions}
            onChangeFunc={(value) => {
              handleChangeCondition(value, index)
            }}
            validationSchema={{
              [`${fieldsName}.${index}.condition`]: {
                required: (index === 0 && isLastRow) ? 'Required Field' : undefined
              }
            }}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="rhs-side width-65-per flex-start-end gap-20px">
        {showOperatorAndValue && <>
          <div className="py-10px px-2 border border-dark-2 bg-white rounded-8px">{t('Is')}</div>
          {operatorOptions?.length > 0 && <div className="width-150 flex-shrink-0">
            <FloatingDropDown
              control={control}
              errors={errors}
              title='Select Operator' 
              name={`${fieldsName}.${index}.operator`}  
              isRequired={!isLastRow}
              value={selectedOperator}
              validationSchema={{
                [`${fieldsName}.${index}.operator`]: {
                  required: !isLastRow ? 'Required Field' : false
                }
              }}
              onChangeFunc={(value) => {
                handleChangeOperator(value, index)
              }}
              options={operatorOptions}
              disabled={disabled}
            />
          </div>}
          <div className="flex-fill">
            <ComponentRenderer 
              key={`${fieldsName}.${index}.value`}
              name={`${fieldsName}.${index}.value`}
              formObject={formObject}
              index={index}
              selectedCondition={selectedCondition} 
              selectedOperator={selectedOperator}
              propsMap={propsMap}
              disabled={disabled}
              fieldsName={fieldsName}
            />
          </div>
        </>}
        {(!disabled && !isLastRow) && <div className="flex-center-start delete-row-btn-container">
          <Button ofStyle='noBackground' type='button' className="p-0" icon={() => <Trash2 size={16} color="var(--bs-danger)"/> }  onClick={() => handleRemoveRule(index)}/>
        </div>}
      </div>
    </div>
  )
}

export default RuleRow