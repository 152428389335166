import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import './CustomTag.scss'

const CustomTag = (props) => {
  const { title, icon, alignIcon, className, showTitle = true, ...rest } = props
  const { t } = useTranslation()
  const renderIcon = () => {
    if (!icon) return null
    return (
      <span className={classNames('flex-center-center', {'me-6px' : alignIcon === 'left', 'ms-6px': alignIcon === 'right'})}>
        {icon}
      </span>
    )
  }

  return (
    <div className={`custom-tag-box-class width-fit-content custom-badge rounded-10px txt-asst-md text-break ${className}`} {...rest}>
      <div className="flex-center-center px-6px py-2px">
        {(icon && alignIcon === 'left') && renderIcon()}
        <span className='custom-tag-content text-nowrap' title={showTitle && t(title)}>{t(title)}</span>
        {(icon && alignIcon === 'right') && renderIcon()}
      </div>
    </div>
  )
}

CustomTag.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  alignIcon: PropTypes.oneOf(['left', 'right']),
  className: PropTypes.string
}

CustomTag.defaultProps = {
  alignIcon: 'left',
  className: '',
  showTitle: true
}

export default CustomTag