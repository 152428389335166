import classNames from 'classnames';
import React from 'react';
import { iAttributeValueProp } from '../../attributes.constants';
import './styles.scss';

//need to make it dropdown for now keeping it as like text
const Dropdown: React.FC<iAttributeValueProp> = ({ values, additionalClasses}) => {
  return <div className={classNames('txt-body-md text-dark attribute-type-container', additionalClasses)}>
    {values.map((value:string, index:number) => <span key={index}>{value}{index !== values.length - 1 && ', '}</span>)}
  </div>;
};

export default Dropdown;