import CustomTag from '@src/@core/components/ui/badge/CustomTag'
import Button from '@src/@core/components/ui/button'
import NoRecordFound from '@src/@core/components/ui/noRecordFound'
import SidesheetFooter from '@src/@core/components/ui/sidesheet-footer'
import dragImage from '@src/assets/images/icons/drag-handle.svg'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSortable } from 'react-sortablejs'
import { Spinner } from 'reactstrap'
import './editRulePriority.scss'

/**
 * A React functional component to edit rule priority.
 *
 * @param {object} props - The component props.
 * @param {function} props.toggle - A function to toggle the sidesheet.
 * @param {array} props.automationRules - An array of automation rules.
 * @param {function} props.updatePriorityRules - A function to update the priority rules.
 * @param {boolean} props.isPriorityRulesUpdating - A boolean indicating whether the priority rules are being updated.
 * @return {JSX.Element} The JSX element representing the component.
 */
const EditRulePriority = (props) => {
  const {toggle, automationRules, updatePriorityRules, isPriorityRulesUpdating} = props
  const { t } = useTranslation()

  const [activeAutomationList, setActiveAutomationList] = useState([])
  const [inactiveAutomationList, setInactiveAutomationList] = useState([])

  const handlePriorityChange = () => {
    const allItems = activeAutomationList.concat(inactiveAutomationList)
    const body = {
      rules: automationRules.map((el, index) => {
        return {
          id: allItems[index].id,
          precedence: el.precedence
        }
      })
    }
    updatePriorityRules({ body })
  }

  useEffect(() => {
    const activeItem = []
    const inactiveItem = []
    automationRules.forEach((el) => {
      if (el.is_active) {
        activeItem.push(el)
      } else {
        inactiveItem.push(el)
      }
    })
    setActiveAutomationList(activeItem)
    setInactiveAutomationList(inactiveItem)
  }, [automationRules])

  return (
    <>
      {automationRules.length ?
        <section className='section'>
          <div className='d-flex gap-2 mb-5'>
            <div className="priority-rules-list" >
              {automationRules.map((automationRule) => (
                <div key={automationRule.id} className={classNames('d-flex gap-1 index priority-rule', { 'bg-light-4': !automationRule.is_active })}>
                  <span className='w-100 text-center'>
                    {automationRule.precedence}
                  </span>
                </div>
              ))}
            </div>
            <div className="d-flex flex-column w-100 gap-1">
              {activeAutomationList.length > 0 && <ReactSortable className="priority-rules-list name" list={activeAutomationList} setList={setActiveAutomationList}>
                {activeAutomationList.map((automation) => (
                  <div key={automation.id} className='d-flex gap-1 w-100'>
                    <div className='priority-rule name'>
                      <div className='d-flex gap-1 align-items-center'>
                        <p className='shipping-name m-0 p-0'>{t(automation.name)}</p>
                      </div>
                      <img src={dragImage} alt="Drag Image" />
                    </div>
                  </div>
                ))}
              </ReactSortable>}
              {inactiveAutomationList.length > 0 && <div className="priority-rules-list name">
                {inactiveAutomationList.map((automation) => (
                  <div key={automation.id} className='d-flex gap-1 w-100'>
                    <div className='priority-rule name bg-light-4'>
                      <div className=' d-flex gap-1 align-items-center'>
                        <p className='shipping-name m-0 p-0'>{t(automation.name)}</p>
                      </div>
                      <CustomTag className={'text-secondary bg-dark-1 border border-dark-1'} title={'Inactive'}/>
                    </div>
                  </div>
                ))}
              </div>}
            </div>
          </div>
          <SidesheetFooter>
            <Button onClick={toggle} ofStyle="outlined">{t('Cancel')}</Button>
            <Button onClick={handlePriorityChange} disabled={isPriorityRulesUpdating} icon={isPriorityRulesUpdating ? Spinner : null} iconSize="sm">{t('Save')}</Button>
          </SidesheetFooter>    
        </section>
        : <div className="h-100 d-flex flex-column align-items-center justify-content-center"><NoRecordFound /></div>}
    </>
  )
}

export default EditRulePriority