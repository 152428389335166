import { UploadCloudIcon } from '@src/assets/data/assets'
import { useRef } from 'react'
import { XCircle } from 'react-feather'
import './style.scss'

const ImageUploaderReturnOrderProcessing = ({ images, setImages, maxImages = 3 }) => {
  const fileInputRef = useRef(null)

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    
    if (file) {
      if(images.length < maxImages) {
        setImages([...images, file])
        fileInputRef.current.value = null
      }
    }
  }

  const removeImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index)
    setImages(updatedImages)
  }

  return (
    <div className="return-order-processing-img-upload-container">
      {images.length > 0 && images.map((image, index) => (
        <div key={index} className='images-wrapper'>
          <img src={URL.createObjectURL(image)} alt={`image-${index}`} className='image' />
          <XCircle className="remove-icon" size={16} onClick={() => removeImage(index)} fill='var(--bs-primary)' color='var(--bs-white)'/>
        </div>
      ))}

      {images.length < maxImages && <div className='upload-button p-8px bg-primary-lighter rounded-4px cursor-pointer'>
        <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleFileChange} id="image-upload" ref={fileInputRef} />
        <div htmlFor="image-upload" className="upload-icon cursor-pointer" onClick={() => fileInputRef.current.click()}>
          <UploadCloudIcon width={16} height={16} />
        </div>
      </div>}
    </div>
  )
}

export default ImageUploaderReturnOrderProcessing