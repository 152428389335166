import { DELIVERY_TYPE_OPTIONS } from '../sales.constant';

export const CREATE_ORDER_FORM_FIELD_NAMES = Object.freeze({
  ORDER_CREATION_TYPE: 'order_creation_type',
  SELLER: 'seller',
  ORDER_TYPE: 'order_type',
  PARTIAL_PICKING: 'partial_picking',
  CUSTOMER: 'customer',
  SHIPPING_ADDRESS: 'shipping_address',
  BILLING_ADDRESS: 'billing_address',
  ORDER_ALIAS: 'order_alias',
  DELIVERY_TYPE: 'delivery_type',
  PAYMENT_METHOD: 'payment_method',
  ORDER_NOTE: 'order_note',
  SALES_CHANNEL: 'sales_channel',
  CUSTOM_LABELS: 'custom_labels',
  ORDER_ITEMS: 'order_items',
  ATTACHMENTS: 'attachments',
  CURRENCY: 'currency',
  TOTAL_AMOUNT: 'total',
  TOTAL_WEIGHT: 'total_weight',
  IS_OMNIFUL_CUSTOMER: 'isOmnifulCustomer',
  REORDER_REASON: 'reorder_reason',
  SHOULD_REMOVE_ORDER_ITEMS: 'shouldRemoveOrderItems'
});

export const CREATE_ORDER_SCHEMA = Object.freeze({
  [CREATE_ORDER_FORM_FIELD_NAMES.ORDER_CREATION_TYPE]: {
    label: 'Order Creation Type',
    isRequired: true,
  },
  [CREATE_ORDER_FORM_FIELD_NAMES.SELLER]: {
    label: 'Seller',
    isRequired: true,
    isAsync: true,
  },
  [CREATE_ORDER_FORM_FIELD_NAMES.ORDER_TYPE]: {
    label: 'Order Type',
    isRequired: true,
  },
  [CREATE_ORDER_FORM_FIELD_NAMES.PARTIAL_PICKING]: {
    label: 'Allow Partial Order Fulfillment',
  },
  [CREATE_ORDER_FORM_FIELD_NAMES.CUSTOMER]: {
    label: 'Customer Details',
  },
  [CREATE_ORDER_FORM_FIELD_NAMES.SHIPPING_ADDRESS]: {
    label: 'Delivery Address',
  },
  [CREATE_ORDER_FORM_FIELD_NAMES.BILLING_ADDRESS]: {
    label: 'Billing Address',
  },
  [CREATE_ORDER_FORM_FIELD_NAMES.ORDER_ALIAS]: {
    label: 'Reference Order ID',
    isClearable: true
  },
  [CREATE_ORDER_FORM_FIELD_NAMES.DELIVERY_TYPE]: {
    label: 'Delivery Type',
    isRequired: true,
  },
  [CREATE_ORDER_FORM_FIELD_NAMES.PAYMENT_METHOD]: {
    label: 'Payment Method',
    isRequired: true,
  },
  [CREATE_ORDER_FORM_FIELD_NAMES.ORDER_NOTE]: {
    label: 'Note',
    isClearable: true
  },
  [CREATE_ORDER_FORM_FIELD_NAMES.SALES_CHANNEL]: {
    label: 'Reference Sales Channel',
    isAsync: true,
    isClearable: true
  },
  [CREATE_ORDER_FORM_FIELD_NAMES.CUSTOM_LABELS]: {
    label: 'Tags',
    isAsync: true,
    isMulti: true,
    isClearable: true
  },
  [CREATE_ORDER_FORM_FIELD_NAMES.REORDER_REASON]: {
    label: 'Reason for Reordering',
    isAsync: true,
    isClearable: true
  },
  [CREATE_ORDER_FORM_FIELD_NAMES.ATTACHMENTS]: {
    label: 'Attachments',
  },
});

export const ORDER_CREATION_TYPE = Object.freeze({
  BULK:{ label: 'Bulk', value: 'bulk' },
  SINGLE: { label: 'Single', value: 'single' },
});

export const ORDER_TYPE = Object.freeze({
  B2C:  { label: 'B2C', value: 'b2c' },
  B2B: { label: 'B2B', value: 'b2b' },
});

export const PAYMENT_METHODS = Object.freeze({
  PREPAID: { label: 'Prepaid', value: 'prepaid' },
  POSTPAID: { label: 'Postpaid', value: 'postpaid' }
});

export const DEFAULT_VALUES = Object.freeze({
  [CREATE_ORDER_FORM_FIELD_NAMES.ORDER_CREATION_TYPE]: ORDER_CREATION_TYPE.BULK,
  [CREATE_ORDER_FORM_FIELD_NAMES.SELLER]: null,
  [CREATE_ORDER_FORM_FIELD_NAMES.ORDER_TYPE]: ORDER_TYPE.B2C,
  [CREATE_ORDER_FORM_FIELD_NAMES.PARTIAL_PICKING]: false,
  [CREATE_ORDER_FORM_FIELD_NAMES.CUSTOMER]: null,
  [CREATE_ORDER_FORM_FIELD_NAMES.BILLING_ADDRESS]: null,
  [CREATE_ORDER_FORM_FIELD_NAMES.SHIPPING_ADDRESS]: null,
  [CREATE_ORDER_FORM_FIELD_NAMES.ORDER_ALIAS]: '',
  [CREATE_ORDER_FORM_FIELD_NAMES.DELIVERY_TYPE]: DELIVERY_TYPE_OPTIONS[0],
  [CREATE_ORDER_FORM_FIELD_NAMES.PAYMENT_METHOD]: PAYMENT_METHODS.PREPAID,
  [CREATE_ORDER_FORM_FIELD_NAMES.ORDER_NOTE]: '',
  [CREATE_ORDER_FORM_FIELD_NAMES.SALES_CHANNEL]: null,
  [CREATE_ORDER_FORM_FIELD_NAMES.CUSTOM_LABELS]: null,
  [CREATE_ORDER_FORM_FIELD_NAMES.ATTACHMENTS]: [],
  [CREATE_ORDER_FORM_FIELD_NAMES.ORDER_ITEMS]: [],
  [CREATE_ORDER_FORM_FIELD_NAMES.CURRENCY]: null,
  [CREATE_ORDER_FORM_FIELD_NAMES.TOTAL_AMOUNT]: 0,
  [CREATE_ORDER_FORM_FIELD_NAMES.TOTAL_WEIGHT]: 0,
  [CREATE_ORDER_FORM_FIELD_NAMES.IS_OMNIFUL_CUSTOMER]: true,
  [CREATE_ORDER_FORM_FIELD_NAMES.REORDER_REASON]: null,
  [CREATE_ORDER_FORM_FIELD_NAMES.SHOULD_REMOVE_ORDER_ITEMS]: false
});

export const ORDER_RECREATION_TYPE = {
  DUPLICATE: 'duplicate',
  RE_ORDER: 'reOrder'
};
// iCustomerAndAddressFromValues
export const CUSTOMER_ADDRESS_FIELDS = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  PHONE: 'phone',
  ADDRESS_1: 'address1',
  ADDRESS_2: 'address2',
  COUNTRY: 'country',
  STATE: 'state',
  CITY: 'city',
  ZIP: 'zip',
} as const;

export const EDIT_CUSTOMER_ADDRESS_MODE = {
  CUSTOMER: 'customer',
  ADDRESS: 'address'
};