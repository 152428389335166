import DropdownWithTitle from '@src/@core/components/ui/dropdown';
import PageHeader from '@src/@core/components/ui/page-header';
import { ADDITIONAL_FILTER_DROPDOWN_STYLE, DATA_TYPE, SETTING_MODULE_URL, TENANT_TYPE } from '@src/App.constants';
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping';
import Settings from '@src/assets/images/icons/primary-navbar/Settings';
import { INPUT_TYPES_MAP } from '@src/components/rule/rule.constant';
import { AbilityContext } from '@src/utility/context/Can';
import { useFilters, usePagination, useSearch } from '@src/utility/hooks/useFilters';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSellersAsyncDataUpdated } from '../../store';
import { fetchAutomationRuleDetail } from '../giftItemAutomation.api';
import { DEFAULT_VALUES, FILTER_KEYS } from '../giftItemAutomation.constants';
import { transformToFrontendActionData } from '../giftItemAutomation.utils';
import GiftItemCreateSection from './ruleCreateSection';
import GiftItemTableSection from './rulesTableSection';

const GiftItemAutomation = () => {

  // TODO: Need to update title
  const pageHeaderProps = {
    breadcrumbIcon: Settings,
    title: SETTING_MODULE_URL.ADD_ITEMS_AUTOMATION.title,
    breadcrumbsData: [{ title: 'Settings' }, { title: 'Automation Rules' }]
  };

  // Hooks
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);

  // Stores
  const tenantType = useSelector((state) => state.auth.userData?.tenant?.type);
  const defaultSeller = useSelector((state) => state.auth.defaultSeller)

  // States
  const [activeRule, setActiveRule] = useState(null);
  const [defaultFormValues, setDefaultFormValues] = useState(DEFAULT_VALUES)
  const [conditionGroup, setConditionGroup] = useState({ conditions: [], logical_operators: [] })
  const [actionGroup, setActionGroup] = useState({ actions: [], logical_operators: [] })
  const [ruleSideSheet, setRuleSideSheet] = useState({ isOpen: false, isEdit: false, isView: false })

  // Variables
  const selfTenant = tenantType === TENANT_TYPE.SELF_USAGE;
  const hasSellerViewPermission = ability.can(abilityMap.seller.view.action, abilityMap.seller.view.resource);
  const hasEditRulePermission = ability.can(abilityMap.automation_rules.add_items.edit.action, abilityMap.automation_rules.add_items.edit.resource);
  const hasCreateRulePermission = ability.can(abilityMap.automation_rules.add_items.create.action, abilityMap.automation_rules.add_items.create.resource);

  // Filters
  const defaultFilterValues = {
    [FILTER_KEYS.SELLER]: {
      label: defaultSeller?.name,
      value: defaultSeller?.seller_id
    }
  };

  const { filter, setFilter } = useFilters(defaultFilterValues);
  const searchColumnList = [{ id: 'name', name: t('Rule Name') }];
  const { searchQuery, setSearchQuery } = useSearch({ column: searchColumnList[0] });
  const { pagination, setPagination } = usePagination();

  // functions

  const filterHandler = ({key, value}) => {
    if (!value) {
      delete filter[key];
      setFilter(filter);
      return;
    }
    setFilter({...filter, [key]: value});
  };

  const handleSetConditionGroupAndActionGroup = (data) => {
    const conditions = data?.condition_group?.conditions
    const conditionsLogicalOperators = data?.condition_group?.logical_operators
    const actions = data?.action_group?.actions
    const actionsLogicalOperators = data?.action_group?.logical_operators
    if (conditions?.length) {
      setConditionGroup({conditions, logical_operators: conditionsLogicalOperators})
    } else {
      setConditionGroup({conditions: [], logical_operators: []})
    }
    if (actions?.length) {
      setActionGroup({actions, logical_operators: actionsLogicalOperators})
    } else {
      setActionGroup({actions: [], logical_operators: []})
    }
  }

  const handleSetDefaultFormValues = (data) => {
    const {condition_group: {conditions}, action_group: {actions}} = data.rule_entity

    const emptyRow = {
      condition: null,
      operator: null,
      value: null
    }

    const conditionsMap = {}
    conditions.forEach((condition) => {
      conditionsMap[condition.key_name] = condition
    })

    const actionsMap = {}
    actions.forEach((action) => {
      actionsMap[action.key_name] = action
    })

    const {sub_conditions, ...firstCondition} = data.condition
    const selectedConditionEntities = sub_conditions ? [firstCondition, ...sub_conditions] : [firstCondition]
    const selectedActionEntities = data.action_group.actions


    const handleGenerateValueForEditRule = (value, condition, operator) => {
     
      const operatorDatatype = operator ? operator?.datatype : condition?.datatype
      const inputType = condition.input_type
      const {display_value, key_value} = value
      if (inputType === INPUT_TYPES_MAP.DROPDOWN || inputType === INPUT_TYPES_MAP.API) {
        if (operatorDatatype?.includes(DATA_TYPE.ARRAY)) {
          return display_value.reduce((acc, label, index) => {
            acc.push({ label, value: key_value[index] })
            return acc
          }, [])
        } else {
          return {
            label: display_value,
            value: key_value
          }
        }
      } else if (inputType === INPUT_TYPES_MAP.INPUT_BOX) {
        if (operatorDatatype?.includes(DATA_TYPE.ARRAY)) {
          return display_value.reduce((acc, label, index) => {
            acc.push({ label, value: key_value[index] })
            return acc
          }, [])
        } else {
          return key_value
        }
      } else if (inputType === INPUT_TYPES_MAP.DATE_PICKER) {
        console.log(value)
      }
    }

    const triggerConditions = selectedConditionEntities.map((entity) => {
      const condition = {label: entity.display_name, value: entity.key_name}
      const operator = conditionsMap[condition.value]?.operators?.find(operator => operator.key_name === entity.operator)
      const value = entity.value?.key_value ? handleGenerateValueForEditRule(entity.value, conditionsMap[condition.value], operator) : null
      return {
        condition,
        operator: operator ? {
          label: operator.display_name,
          value: operator.key_name,
          datatype: operator.datatype
        } : null,
        value
      }
    }).concat(emptyRow)

    const actionValues = selectedActionEntities.map((entity) => {
      const condition = {label: entity.display_name, value: entity.key_name}
      const operator = actionsMap[condition.value]?.operators?.find(operator => operator.key_name === entity.operator)
      const value = entity.value?.key_value ? handleGenerateValueForEditRule(entity.value, actionsMap[condition.value], operator) : null
      // TODO: This logic will shift to BE (in refactoring)
      const transformedValues = value.map(item => 
        transformToFrontendActionData(item.value)
      );

      return {
        condition,
        operator: operator ? {
          label: operator.display_name,
          value: operator.key_name,
          datatype: operator.datatype
        } : null,
        value: transformedValues
      }
    }).concat(emptyRow)

    setDefaultFormValues({
      name: data.name,
      description: data.description,
      is_active: data.is_active,
      triggerConditions,
      actionValues
    })
  }

  const handleAutomationDetailFetchSuccess = (data) => {
    const ruleEntity = data.rule_entity
    handleSetDefaultFormValues(data)
    handleSetConditionGroupAndActionGroup(ruleEntity)
    if (hasEditRulePermission) {
      setRuleSideSheet({isOpen: true, isEdit: true, isView: false})
    } else {
      setRuleSideSheet({isOpen: true, isEdit: false, isView: true})
    }
  }
  
  const {mutate: automationDetail, isPending: isSingleAutomationRuleFetching, isError: isSingleAutomationRuleError} = fetchAutomationRuleDetail({
    onSuccess: handleAutomationDetailFetchSuccess, 
  })

  return (
    <div>
      <PageHeader {...pageHeaderProps} />
      <div className='d-flex flex-column gap-16px p-16px'>
        {hasSellerViewPermission && !selfTenant && (
          <>
            <DropdownWithTitle 
              isAsync
              title="Seller"
              value={filter.seller}
              loadOptionsHandler={getSellersAsyncDataUpdated}
              selectOptionHandler={(value) => {
                filterHandler({key: FILTER_KEYS.SELLER, value});
              }} 
              externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
            />
          </>
        )}

        <GiftItemTableSection
          filter={filter}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          pagination={pagination}
          setPagination={setPagination}
          activeRule={activeRule}
          setActiveRule={setActiveRule}
          hasEditRulePermission={hasEditRulePermission}
          hasCreateRulePermission={hasCreateRulePermission}
          searchColumnList={searchColumnList}
          automationDetail={automationDetail}
          setRuleSideSheet={setRuleSideSheet}
          setDefaultFormValues={setDefaultFormValues} 
          isSingleAutomationRuleFetching={isSingleAutomationRuleFetching}
          handleSetConditionGroupAndActionGroup={handleSetConditionGroupAndActionGroup}
        />
        
        <GiftItemCreateSection
          ruleSideSheet={ruleSideSheet}
          setRuleSideSheet={setRuleSideSheet}
          conditionGroup={conditionGroup}
          actionGroup={actionGroup}
          filter={filter} 
          defaultFormValues={defaultFormValues}
          activeRule={activeRule}
          isSingleAutomationRuleFetching={isSingleAutomationRuleFetching} isSingleAutomationRuleError={isSingleAutomationRuleError}
        />
        
      </div>
    </div>
  );
};

export default GiftItemAutomation;