import { checkPermission, doesPrimaryMenuItemHasVisibleChild } from '@src/@core/layouts/utils'
import { HUBS_NAVIGATION_KEYS } from '@src/App.constants'
import classNames from 'classnames'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PrimaryMenuLink from './PrimaryMenuLink'
import SecondaryMenu from './SecondaryMenu'

const PrimaryMenu = props => {
  const { menuData, hasSecondaryMenu, secondaryNavCollapsed, setSecondaryNavCollapsed } = props
  const [activeItem, setActiveItem] = useState(null)
  const [groupOpen, setGroupOpen] = useState([])
  const [groupActive, setGroupActive] = useState([])
  const [currentActiveGroup, setCurrentActiveGroup] = useState([])
  const permissions = useSelector(state => state.auth.permissions)
  const [secondaryMenuActiveItem, setSecondaryMenuActiveItem] = useState(null)
  const hubId = useSelector(state => state.revampedHubs.selectedHubId)
  const params = useParams()
  const hubsKeys = HUBS_NAVIGATION_KEYS

  const menuItems = menuData.filter((item) => {

    let firstVisibleChild = doesPrimaryMenuItemHasVisibleChild(item.children)

    if (firstVisibleChild && firstVisibleChild.navLink && firstVisibleChild.navLink.includes(':hubId') && (parseInt(hubId) || parseInt(params.hubId))) {
      firstVisibleChild.navLink = firstVisibleChild.navLink.replace(':hubId', String(parseInt(hubId) || parseInt(params.hubId)))
    }

    if (firstVisibleChild && firstVisibleChild.navLink && firstVisibleChild.navLink.includes('/hubs') && (((parseInt(hubId)) && parseInt(params.hubId)) || ((firstVisibleChild && firstVisibleChild.navLink && firstVisibleChild.navLink.includes('/hubs') && !parseInt(hubId)) && parseInt(params.hubId)))) {
      const newRoutePath = location.pathname.split('/')
      newRoutePath[2] = hubId || params.hubId
      const newRotePathName = newRoutePath.join('/')
      const lastWord = newRoutePath.pop()
      const index = Object.values(hubsKeys).find(({key}) => newRotePathName.includes(key))?.index
      firstVisibleChild = item.children[index]?.children?.find(child => child.navLink.includes(lastWord))
      if (firstVisibleChild)  firstVisibleChild.navLink = newRotePathName
    }

    const { action, resource, checkIsPermitted } = item

    const isPermitted = checkPermission({ action, resource, checkIsPermitted })
    const noPermission = !action && !resource

    const hasNoPermissionButChildHas = noPermission && permissions && !!firstVisibleChild
    const firstVisibleChildHasNavLink = typeof firstVisibleChild === 'object' && firstVisibleChild.navLink

    if (isPermitted || hasNoPermissionButChildHas) {
      if (noPermission && firstVisibleChildHasNavLink) item.navLink = firstVisibleChild.navLink
      return true
    }

  })

  const totalNavbarItems = menuItems.length
  return (
    <>
      <div className={classNames('main-menu-ul'
      // {"with-banner": isFeatureNotificationBannerVisible}
      )}>
        <ul className="d-flex flex-column justify-content-center align-items-center gap-2" style={{ margin: '16px 0px' }}>
          {menuItems.map((item, index) => {
            return (
              <PrimaryMenuLink
                item={item}
                key={item.id}
                totalNavbarItems={totalNavbarItems}
                idx={index}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                secondaryMenuActiveItem={secondaryMenuActiveItem}
                setSecondaryMenuActiveItem={setSecondaryMenuActiveItem}
              />
            )
          })}
        </ul>
      </div>
      {hasSecondaryMenu && activeItem && activeItem.children && (
        <SecondaryMenu
          secMenuData={activeItem}
          groupOpen={groupOpen}
          setGroupOpen={setGroupOpen}
          groupActive={groupActive}
          setGroupActive={setGroupActive}
          currentActiveGroup={currentActiveGroup}
          setCurrentActiveGroup={setCurrentActiveGroup}
          secondaryMenuActiveItem={secondaryMenuActiveItem}
          setSecondaryMenuActiveItem={setSecondaryMenuActiveItem}
          secondaryNavCollapsed={secondaryNavCollapsed}
          setSecondaryNavCollapsed={setSecondaryNavCollapsed}
        />
      )}
    </>
  )
}

export default PrimaryMenu