import { API_ENDPOINTS } from '@src/api.urls'

export const getOrdersApi = {
  apiKey: ['get-orders'],
  url: API_ENDPOINTS.ORDERS.GET_ORDERS
}

export const getInvoiceDataApi = {
  apiKey: ['get-order-invoice-data'],
  url: API_ENDPOINTS.ORDERS.GET_INVOICE_DATA
}

export const createShipment = {
  apiKey: ['create-shipment'],
  url: API_ENDPOINTS.ORDERS.CREATE_SHIPMENT
}
  
export const downloadOrderReport = {
  apiKey: ['oms-order-report-download'],
  url: API_ENDPOINTS.ORDERS.DOWNLOAD_REPORT
}

export const orderItemsWithBatchesConfigApi = {
  apiKey: ['order-items-with-batches-config'],
  url: API_ENDPOINTS.ORDERS.GET_ORDER_DETAILS
}

export const reassignHubApi = {
  apiKey: ['reassign_hub'],
  url: API_ENDPOINTS.ORDERS.REASSIGN_HUB
}

export const reorderDataApi = {
  apiKey: ['get-data-for-re-order'],
  url: API_ENDPOINTS.ORDERS.GET_ORDER_DETAILS
}

export const singleManifestDetails = {
  apiKey: ['get-single-manifest-details'],
  url: API_ENDPOINTS.ORDERS.GET_SINGLE_MANIFEST_DETAILS
}

export const bulkRemoveOrdersInManifest = {
  apiKey: ['bulk-remove-manifest-orders'],
  url: API_ENDPOINTS.ORDERS.GET_SINGLE_MANIFEST_DETAILS
}

export const getManifestPrintDataApi = {
  apiKey: ['get-manifest-print-data'],
  url: API_ENDPOINTS.ORDERS.MANIFEST_PRINT_DATA
}

export const LOAD_PRINTERS = {
  apiUrl: (additional) => {
    return `/api/v1/pms/hubs/${additional.hubId}/computers/${additional.printerId}/printers`
  },
  select: (data) => data.data?.printers?.map(ele => ({
    label: ele.name,
    value: ele.id,
  })) || [],
  hasMore: () => false
}

export const LOAD_DEVICES = {
  apiUrl: (additional) => {
    return `/api/v1/pms/hubs/${additional.hubId}/computers`
  },
  select: (data) => {
    return data?.data?.computers?.map(ele => ({
      label: ele.name,
      value: ele.id
    })) || []
  },
  hasMore: () => false
}