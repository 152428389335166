import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UncontrolledTooltip } from 'reactstrap'
import './styles.scss'
import Copy from '@src/assets/images/svg/icons-component/Copy'

const CopyOnClick = ({
  id,
  handleDetails,
  columnKey,
  children,
  isCopyIconVisible,
  isCopyIconVisibleAlways,
  additionalClasses,
  customClassName,
  title,
  textToCopy = ''
}) => {
  const { t } = useTranslation()
  const clearCopiedTimerRef = useRef(null)
  const [isCopied, setIsCopied] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const removeSpacesAndSpecialCharacters = (str = '') => {
    return `${str}`.replace(/[^a-zA-Z0-9]/g, (match) => {
      return `${match.charCodeAt(0).toString(16)}`
    })
  }

  const handleCopy = (text) => {
    try {
      navigator.clipboard.writeText(text)
        .then(() => {
          setIsCopied(true)
          if (clearCopiedTimerRef.current) {
            clearTimeout(clearCopiedTimerRef.current)
          }
          clearCopiedTimerRef.current = setTimeout(() => {
            setIsCopied(false)
          }, 1000)
        })
        .catch(err => {
          console.error('Failed to copy text:', err)
        })
    } catch (err) {
      console.error('Error executing clipboard write:', err)
    }
  }

  const handleClick = () => {
    if (!handleDetails) {
      return
    }
    handleDetails(id)
  }

  useEffect(() => {
    return () => {
      setIsCopied(false)
      if (clearCopiedTimerRef.current) {
        clearTimeout(clearCopiedTimerRef.current)
      }
    }
  }, [])

  return (
    <div className="table-column-order-id-component-wrapper">
      <div className="w-100 d-flex align-items-center table-column-order-id">
        <div className={classNames(`${customClassName}`, {
          'text-primary cursor-pointer': handleDetails && children
        })}>
          <div className="flex-center-start gap-4px"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div title={textToCopy || title || children} className={classNames({'text-truncate': title }, additionalClasses)} onClick={handleClick}>
              {children || '-'}
            </div>
            {(children && isCopyIconVisible) && (
              <div
                id={`${columnKey}${removeSpacesAndSpecialCharacters(id)}`}
                onClick={(e) => {
                  e.stopPropagation()
                  handleCopy(textToCopy || children)
                }}
                className="copy-order-id-container cursor-pointer flex-center-start"
                style={{ visibility: isCopyIconVisibleAlways || isHovered ? 'visible' : 'hidden' }}
              >
                <Copy color={isCopyIconVisibleAlways?'#999999':'#5468FA'}/>
              </div>
            )}
          </div>
        </div>

        {children && isCopyIconVisible && (
          <div onMouseEnter={() => setIsHovered(false)}>
            <UncontrolledTooltip placement="top" target={`${columnKey}${removeSpacesAndSpecialCharacters(id)}`} offset={[0, 5]}>
              {isCopied ? t('Copied') : t('Copy')}
            </UncontrolledTooltip>
          </div>
        )}
      </div>
    </div>
  )
}

CopyOnClick.defaultProps = {
  isCopyIconVisible: true,
  isCopyIconVisibleAlways: false,
  handleDetails: null,
  children: null
}

CopyOnClick.propTypes = {
  id: PropTypes.string.isRequired,
  isCopyIconVisible: PropTypes.bool,
  isCopyIconVisibleAlways: PropTypes.bool,
  handleDetails: PropTypes.func,
  columnKey: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default CopyOnClick
