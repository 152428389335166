import Button from '@src/@core/components/ui/button'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import QuantityButton from '@src/@core/components/ui/quantity-button'
import SKUDetailsCol from '@src/@core/components/ui/sku-details'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import ExpandableChildTable from '@src/views/sales/components/ExpandableChildTable/ExpandableChildTable'
import { SKU_TYPES } from '@src/views/sales/constant/orders.constants'
import { OMS_REASON_CONFIG } from '@src/views/settings/settings.constants'
import classNames from 'classnames'
import { memo, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectReturnReasonsSideSheet from '../selectReasonSidesheet/SelectReturnReasonsSideSheet'
import './createReturnOrderProcessingTable.scss'
import { pluralizeUnit } from '@src/utility/Utils'

const CreateReturnOrderProcessingTable = (props) => {
  const { skuTableData, setSkuTableData, isNextButtonPressed } = props
  
  const [selectReturnRequestReasonsSideSheet, setSelectReturnRequestReasonsSideSheet] = useState({ isOpen: false, selectedItem: {} })

  const customStyles = useRef({
    responsiveWrapper: {
      style: {
        overflow: 'visible'
      }
    }
  })
  const { t } = useTranslation()

  const handleItemQty = useCallback((id, quantity) => {
    const updatedSkuTableData = skuTableData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          fail_quantity: item.fail_quantity + quantity,
          pass_quantity: item.return_quantity - (item.fail_quantity + quantity),
          return_reason: (item.fail_quantity + quantity) === 0 ? null : item.return_reason
        }
      } else {
        return item
      }
    })
    setSkuTableData(updatedSkuTableData)
  }, [skuTableData])

  const handleChildItemQty = useCallback((parent_seller_sku_code, child_seller_sku_code, quantity) => {
    const updatedSkuData = []
    for (const item of skuTableData) {
      let maxCeilValue = 0
      if (item.seller_sku_code === parent_seller_sku_code) {
        const updatedChildSkuData = []
        for (const childItem of item.seller_sku?.child_skus) {
          if (childItem.seller_sku_code === child_seller_sku_code) {
            updatedChildSkuData.push({
              ...childItem,
              fail_quantity: childItem.fail_quantity + quantity,
              pass_quantity: childItem.return_quantity - (childItem.fail_quantity + quantity),
              return_reason: (childItem.fail_quantity + quantity) === 0 ? null : childItem.return_reason,
              ceil_value: Math.ceil((childItem.fail_quantity + quantity) / childItem.quantity)
            })
          } else {
            updatedChildSkuData.push(childItem)
          }
        }
        updatedChildSkuData.forEach((childItem) => {
          maxCeilValue = Math.max(maxCeilValue, childItem.ceil_value || 0)
        })
        updatedSkuData.push({
          ...item,
          fail_quantity: maxCeilValue,
          pass_quantity: item.return_quantity - maxCeilValue,
          seller_sku: {
            child_skus: updatedChildSkuData
          }
        })
      } else {
        updatedSkuData.push(item)
      }
    }
    setSkuTableData(updatedSkuData)
  }, [skuTableData])

  const handleSelectReasons = ({ selectedItem, reasons }) => {
    const updatedSkuTableData = skuTableData.map((item) => {
      if (selectedItem.seller_sku_type === SKU_TYPES.SIMPLE.key) {
        if (item.id === selectedItem.id) {
          return { ...item, qc_fail_reasons: reasons }
        } else {
          return item
        }
      } else {
        if (item.id === selectedItem.parentId) {
          const updatedChildSkus = item.seller_sku.child_skus.map((childItem) => {
            if (childItem.id === selectedItem.id) {
              return { ...childItem, qc_fail_reasons: reasons }
            } else {
              return childItem
            } 
          })
          return { ...item, seller_sku: { ...item.seller_sku, child_skus: updatedChildSkus } }
        }
        return item
      }
    })
    setSkuTableData(updatedSkuTableData)
  }

  const handleSelectReturnRequestReasonsSideSheet = ({ selectedItem } = {}) => {
    if (selectedItem) {
      setSelectReturnRequestReasonsSideSheet({ isOpen: true, selectedItem })
    } else {
      setSelectReturnRequestReasonsSideSheet({ isOpen: false, selectedItem: {} })
    }
  }

  const tableColumns = useMemo(() => [
    {
      id: 1,
      name: t('SKU Details'),
      minWidth: '300px',
      maxWidth: '310px',
      cell: (row) => {
        return (
          <SKUDetailsCol
            skuDetailData={{
              isValidSku: true,
              id: row.id,
              image: {
                src: row.seller_sku_image,
                alt: row.seller_sku_name
              },
              details: {
                name: row.seller_sku_name,
                skuCode: row.seller_sku_code,
                columnKey: 'sku_details'
              },
              customTag: {
                isCustomTagPresent: row.seller_sku_type === SKU_TYPES.BUNDLE.key,
                title: 'Kit',
                className: 'txt-asst-md text-info bg-info-light'
              }
            }}
          />
        )
      }
    },
    {
      id: 2,
      name: <div className="w-100 text-end">{t('Delivered Qty')}</div>,
      minWidth: '120px',
      cell: (row) => <div className="w-100 text-end">{row.delivered_quantity}</div>
    },
    {
      id: 3,
      name: <div className="w-100 text-end">{t('Returned Qty')}</div>,
      minWidth: '120px',
      cell: (row) => <div className="w-100 text-end">{row.return_quantity}</div>
    },
    {
      id: 4,
      name: <div className="w-100 text-end">{t('Pass Qty')}</div>,
      minWidth: '100px',
      cell: (row) => <div className="w-100 text-end">{row.pass_quantity}</div>
    },
    {
      id: 5,
      name: <div className="w-100 text-center">{t('Fail Qty')}</div>,
      minWidth: '100px',
      cell: (row) => {
        const selectedReasonsQty = skuTableData.find((item) => item.id === row.id)?.qc_fail_reasons?.reduce((a, b) => a + b.quantity, 0)
        return (
          row.seller_sku_type !== SKU_TYPES.BUNDLE.key ?
            <div className="qty-input w-100 d-flex justify-content-end">
              <QuantityButton
                quantity={row.fail_quantity}
                minQuantityEnabled={isNextButtonPressed || row.fail_quantity === 0 || selectedReasonsQty === row.fail_quantity}
                maxQuantityEnabled={isNextButtonPressed || row.fail_quantity === row.return_quantity}
                decreaseQuantity={() => handleItemQty(row.id, -1)}
                increaseQuantity={() => handleItemQty(row.id, 1)}
              />
            </div>
            :
            <div className="w-100 text-end">{row.fail_quantity}</div>
        )
      }
    },
    {
      id: 6,
      name: t('Select a Reason'),
      minWidth: '200px',
      cell: (row) => {
        const selectedReasonsQty = skuTableData.find((item) => item.id === row.id)?.qc_fail_reasons?.reduce((a, b) => a + b.quantity, 0)
        return(
          row.seller_sku_type !== SKU_TYPES.BUNDLE.key &&
          <div className="w-100 select-reason-wrapper">
            <Button
              disabled={isNextButtonPressed || row.fail_quantity === 0}
              ofStyle='noBackground'
              onClick={() => handleSelectReturnRequestReasonsSideSheet({ selectedItem: row })}
            >
              {selectedReasonsQty ? `${pluralizeUnit(selectedReasonsQty, 'Reason')} Selected` : 'Select Reasons'}
            </Button>
          </div>
        )
      }
    }
  ], [skuTableData])

  const childTableColumns = useMemo(() => [
    {
      id: 1,
      name: t('SKU'),
      width: '300px',
      cell: (row) => (
        <div className="d-flex align-items-center gap-50 sku-container">
          <div className="sku-image">
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = no_sku_image
              }}
              src={row.seller_sku_image || no_sku_image}
              alt={row.seller_sku_name}
            />
          </div>
          <div>
            <div className="sku-name txt-body-md">{row.seller_sku_name || '-'}</div>
            <div className="sku-code">SKU code: {row.seller_sku_code || '-'}</div>
          </div>
        </div>
      )
    },
    {
      id: 2,
      name: <div className="w-100 text-end">{t('Qty Per Kit')}</div>,
      minWidth: '120px',
      cell: (row) => <div className="w-100 text-end">{row.quantity}</div>
    },
    {
      id: 3,
      name: <div className="w-100 text-end">{t('Returned Qty')}</div>,
      minWidth: '120px',
      cell: (row) => <div className="w-100 text-end">{row.return_quantity}</div>
    },
    {
      id: 4,
      name: <div className="w-100 text-end">{t('Pass Qty')}</div>,
      minWidth: '100px',
      cell: (row) => <div className="w-100 text-end">{row.pass_quantity}</div>
    },
    {
      id: 5,
      name: <div className="w-100 text-center">{t('Fail Qty')}</div>,
      minWidth: '100px',
      cell: (row) => {
        const selectedReasonsQty = skuTableData.find((item) => item.id === row.parentId)?.seller_sku.child_skus?.find((item) => item.id === row.id)?.qc_fail_reasons?.reduce((a, b) => a + b.quantity, 0)
        return (
          <div className="qty-input w-100 d-flex justify-content-end">
            <QuantityButton
              quantity={row.fail_quantity}
              minQuantityEnabled={isNextButtonPressed || row.fail_quantity === 0 || selectedReasonsQty === row.fail_quantity}
              maxQuantityEnabled={isNextButtonPressed || row.fail_quantity === row.return_quantity}
              decreaseQuantity={() => handleChildItemQty(row.parent_seller_sku_code, row.seller_sku_code, -1)}
              increaseQuantity={() => handleChildItemQty(row.parent_seller_sku_code, row.seller_sku_code, 1)}
            />
          </div>
        )
      }
    },
    {
      id: 6,
      name: t('Select a Reason'),
      minWidth: '200px',
      cell: (row) => {
        const selectedReasonsQty = skuTableData.find((item) => item.id === row.parentId)?.seller_sku.child_skus?.find((item) => item.id === row.id)?.qc_fail_reasons?.reduce((a, b) => a + b.quantity, 0)
        return (
          row.seller_sku_type !== SKU_TYPES.BUNDLE.key &&
          <div className="w-100 select-reason-wrapper">
            <Button
              disabled={isNextButtonPressed || row.fail_quantity === 0}
              ofStyle='noBackground'
              onClick={() => handleSelectReturnRequestReasonsSideSheet({ selectedItem: row })}
            >
              { selectedReasonsQty ? `${pluralizeUnit(selectedReasonsQty, 'Reason')} Selected` : 'Select Reasons'}
            </Button>
          </div>
        )
      }
    }
  ], [skuTableData])

  return (
    <div className={classNames('create-return-order-processing-table-container', {'margin-bottom': !isNextButtonPressed})}>
      <ExpandableCustomTable
        loading={false}
        error={false}
        success={true}
        data={skuTableData}
        showPagination={false}
        columns={tableColumns}
        useReactPaginate={false}
        showColumnsTableHeader={false}
        customStyles= {customStyles.current}
        expandableRows
        expandableRowsHideExpander
        expandableRowsComponent={ExpandableChildTable}
        expandableRowsComponentProps={{childTableColumns, customStyles: customStyles.current }}
        expandableRowExpanded={(row) => row.seller_sku_type === SKU_TYPES.BUNDLE.key}
      />
      <SelectReturnReasonsSideSheet
        modalData={selectReturnRequestReasonsSideSheet}
        toggle={handleSelectReturnRequestReasonsSideSheet}
        skuTableData={skuTableData}
        // handleReasonsPerItem={handleReasonsPerItem}
        entity_type={OMS_REASON_CONFIG.qc_fail.key}
        handleSelectReasons={handleSelectReasons}
      />
    </div>
  )
}
export default memo(CreateReturnOrderProcessingTable)
