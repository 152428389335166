import SideSheet from '@src/@core/components/ui/sideSheet'
import SidesheetHeader from '@src/@core/components/ui/sidesheet-header'
import { ModalBody } from 'reactstrap'
import EditRulePriority from './EditRulePriority'

/**
 * A reusable side sheet component for editing automation rule priorities.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.isOpen - Whether the side sheet is open.
 * @param {function} props.toggle - A function to toggle the side sheet.
 * @param {string} [props.sideSheetTitle='Edit Automation Rule Priority'] - The title of the side sheet.
 * @param {string[]} [props.sideSheetSubTextData=['Drag and drop to rearrange the rule priority']] - The subtext data of the side sheet.
 * @return {JSX.Element} The side sheet component.
 */
const EditRulePrioritySideSheet = (props) => {
  const { isOpen, toggle, sideSheetTitle = 'Edit Automation Rule Priority', sideSheetSubTextData = ['Drag and drop to rearrange the rule priority'] } = props
  return (
    <SideSheet
      isOpen={isOpen}
      toggle={toggle}
      size='sm'
      modalClassName='modal-slide-in create-priority-modal'
    >
      <SidesheetHeader
        hasClearButton
        title={sideSheetTitle}
        subTextData={sideSheetSubTextData}
        clearButtonHandler={toggle}
      />
      <ModalBody className='bg-white h-100 d-flex flex-column gap-2 overflow-auto mb-2 p-24px'>
        <EditRulePriority {...props}/>
      </ModalBody>
    </SideSheet>
  )
}

export default EditRulePrioritySideSheet