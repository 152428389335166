import Button from '@src/@core/components/ui/button'
import { Note } from '@src/assets/images/svg/note'
import PropTypes from 'prop-types'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody } from 'reactstrap'
import './styles.scss'

/**
 * Renders a modal component for displaying remarks.
 *
 * @param {Object} prop - The props object containing the following properties:
 *   - isRemarksShow {boolean}: Indicates whether the remarks modal is open or closed.
 *   - setIsRemarksShow {function}: A function to toggle the visibility of the remarks modal.
 *   - remarksDiscription {string}: The description of the remarks.
 * @return {JSX.Element} The rendered remarks modal component.
 */

const RemarksModal = (prop) => {
  const { title, isRemarksShow, setIsRemarksShow, remarksDiscription, icon } = prop
  const { t } = useTranslation()

  /**
 * Toggles the value of `isRemarksShow` state.
 *
 * @return {void}
 */
  const handleToggle = () => {
    setIsRemarksShow(prev => !prev)
  }

  return (
    <Modal
      isOpen={isRemarksShow}
      className="custom-customer-note-modal rounded-24px"
      size="lg"
      centered
      toggle={handleToggle}
    >
      <ModalBody className="m-0 p-0">
        <div className="mt-24px content-body">
          <div className="flex-start-between px-24px w-100 mt-24px customer-note-header pb-12px" >
            <div className="flex-center-start gap-16px">
              <div className="flex-center-start rounded-8px p-10px note-container">
                {icon || <Note color={'var(--bs-primary)'} />}
              </div>
              <div className="txt-h1-sb text-dark">{t(title)}</div>
            </div>
            <div className="cursor-pointer text-dark" onClick={handleToggle} ><X color="var(--bs-dark)" strokeWidth={2} size={20} /></div>
          </div>
          <div className="txt-body-rg text-dark m-24px customer-note-content">
            {remarksDiscription}
          </div>
        </div>
        <div className="customer-note-footer p-24px flex-center-end">
          <Button onClick={handleToggle}>{t('Close')}</Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

RemarksModal.propTypes = {
  title: PropTypes.string,
  isRemarksShow: PropTypes.bool,
  setIsRemarksShow: PropTypes.func,
  remarksDiscription: PropTypes.string
}

export default RemarksModal
