export const TENANT_TYPE = {
  SELF_USAGE: 'Self',
  FULFILLMENT_CENTRE: 'Fulfilment Centre'
}

export const URL_TO_NOT_DISPLAY_GLOBAL_HUB_DROPDOWN = [
  '/hubs',
  '/catalog',
  '/return/return-request',
  '/return/create-return-request',
  '/return/create-return-order'
]

export const CURRENCIES = [
  {
    id: 1,
    value: 'SAR',
    label: 'sar'
  }
]

export const DEFAULT_MAP_CENTER = {
  DEFAULT_COUNTRY: 'Saudi Arbia',
  SAUDI_ARBIA: { lat: 24.7055215, lng: 46.6591862 }
}

export const PAGINATION_PER_PAGE_VALUES = [10, 20, 25, 50, 75, 100]

export const HUBS_NAVIGATION_KEYS = {
  // Indexing according to the secondary navbar tab
  OVERVIEW: {index: 0, key: 'overview'},
  // LAYOUT: {index: 0, key: 'layout'},
  ZONES: { index: 0, key: 'zones' },
  LOCATIONS: {index: 0, key: 'location-layout'},
  BINS: {index: 1, key: 'bins'},
  CARTS: { index: 1, key: 'carts' },
  PALLETS: {index: 1, key: 'pallets'},
  DELIVERY_ZONE: {index: 0, key: 'delivery-zone'},
  BOXES: {index: 1, key: 'boxes'}
}

export const URLS_TO_EXCLUDE_FROM_GLOBAL_HUB_SCOPE = [
  '/analytics',
  '/hubs',
  '/catalog',
  '/return/return-request',
  '/return/create-return-request',
  '/settings',
  '/sales/pending-actions',
  '/purchases/all-suppliers',
  '/purchases/sellers',
  '/reports',
  '/inventory/inventory-reports/demand-forecasting',
  '/sales/shipments-orders',
  '/sales/invoices',
  '/sales/customers',
  '/sales/tax-invoices'
]

export const HUBS_CONFIGURATION = {
  HUB_LOCATION_MAPPING: {name: 'HubLocationMapping', value: 'hub_location_mapping'},
  PICKING: {name: 'PickingConfiguration', value: 'picking'},
  PURCHASE_ORDER: {name: 'PurchaseOrderConfiguration', value: 'purchase_order'},
  GATE_ENTRY: {name: 'GateEntryConfiguration', value: 'gate_entry'},
  LOCATION_INVENTORY: {name: 'LocationInventoryConfiguration', value: 'location_inventory'},
  STOCK_TRANSFER_ORDER: {name: 'StockTransferOrderConfiguration', value: 'stock_transfer_order'},
  INPUT: {name: 'InputConfiguration', value: 'input_configuration'},
  LOCATION: {name: 'LocationConfiguration', value: 'location_configuration'},
  CYCLE_COUNT: {name: 'CycleCountConfiguration', value: 'cycle_count_configuration'},
  PACKING: {name: 'PackingConfiguration', value: 'packing_configuration'},
  BIN_CONFIG: {name: 'BinConfiguration', value: 'bin'}
}
export const KEYBOARD_KEYS = {
  ENTER : 'Enter',
  TAB : 'Tab',
  SPACE : 'Space',
  ARROW_UP : 'ArrowUp',
  ARROW_DOWN : 'ArrowDown',
  ARROW_LEFT : 'ArrowLeft',
  ARROW_RIGHT : 'ArrowRight'
}

export const SORTING_ORDER = {
  ASCENDING: 'asc',
  DESCENDING:'desc'
}

export const OMNIFUL_EMAILS = {
  SUPPORT_EMAIL:'support@omniful.com'
}

export const FILE_TYPES = {
  TXT:{
    type:'text/plain',
    extensions:['.txt', '.text']
  },
  JPG:{
    type:'image/jpeg',
    extensions:['.jpg']
  },
  JPEG:{
    type:'image/jpeg',
    extensions:['.jpeg']
  },
  PNG:{
    type:'image/png',
    extensions:['.png']
  },
  SVG:{
    type:'image/svg+xml',
    extensions:['.svg']
  },
  GIF: {
    type:'image/gif',
    extensions:['.gif']
  },
  CSV:{
    type:'text/csv',
    extensions:['.csv']
  },
  PDF:{
    type:'application/pdf',
    extensions:['.pdf']
  },
  XLS:{
    type:'application/vnd.ms-excel',
    extensions:['.xls']
  },
  XLSX:{
    type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extensions:['.xlsx']
  },
  DOC:{
    type:'application/msword',
    extensions:['.doc']
  },
  DOCX:{
    type:'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    extensions:['.docx']
  },
  ODS:{
    type:'application/vnd.oasis.opendocument.spreadsheet',
    extensions:['.ods']
  },
  IMG:{
    type:'image/*',
    extensions:['.apng', '.avif', '.bmp', '.gif', '.ico', '.jpeg', '.jpg', '.png', '.svg', '.tif', '.tiff', '.webp']
  }
}

export const PRINT_MODES = {
  DEFAULT: { id: 0, key: 'DEFAULT', label: 'Default', size: '8cm 240mm', margin: '3.5mm', padding: '0mm' },
  FOUR_BY_SIX: { id: 1, key: 'FOUR_BY_SIX', label: '4x6', size: '10.2cm 15.2cm', margin: '3.5mm', padding: '0mm' },
  A_FOUR: { id: 2, key: 'A_FOUR', label: 'A4', size: '21cm 29.7cm', margin: '1mm', padding: '0mm', value:'a4' },
  THERMAL: { id: 2, key: 'THERMAL', label: 'THERMAL', size: '8cm 29.7cm', margin: '1mm', padding: '0mm', value:'thermal' }
}

export const INPUT_TYPES = {
  TEXT : 'text', 
  PASSWORD : 'password',
  SEARCH : 'search', 
  NUMBER : 'number',
  FILE : 'file',
  DATE : 'date',
  TIME:'time'
}

export const TEXT_AREA = 'textarea'

export const SKU_ADD_TYPE = {
  MANUAL:{key:'MANUAL', label:'Enter Manually', value:'manual'},
  BULK:{key:'BULK', label:'Bulk Upload', value:'bulk'}
}

export const MANUAL_COURIER_PARTNER = { label: 'MANUAL', value: 'manual' }

export const PRICE_BREAK_DOWN_SECTION = {
  RETURN_ORDER:{key:'Return Order', value:'return_order'},
  FORWARD_ORDER:{key:'Forward Order', value:'forward_order'}
}

export const SELECTED_SELLER = 'all'

export const PACKAGE_SKU_TYPE = {
  UNIT: {label:'Unit', value:'ea'},
  PALLET: {label:'Pallet', value:'pallet'},
  CASE_PACK: {label:'Case Pack', value:'case_pack'}
}

export const URLS_TO_INCULDE_FROM_GLOBAL_HUB_SCOPE = {
  LIVE_ORDER:'/sales/live-orders',
  RETURN_ORDER:'/order/return/return-order'
}

export const REGEX_PATTERN = {
  EMAIL_VALIDATION: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
}

export const ADDITIONAL_FILTER_DROPDOWN_STYLE = {
  control: (baseStyles) => ({
    ...baseStyles,
    width: '120px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'start',
    border: 'none',
    boxShadow: 'none'
  })
}

export const ORDER_SOURCE = {
  MANUAL:{key:'manual', label:'Manual'},
  SALES_CHANNEL:{key:'sales_channel', label:'Sale Channel'}
}

export const AD_HOC_USAGE_ADDED_IN = {
  GRN:'grn',
  PACKING:'packing'
}

export const ORDER_TYPES = {
  RETURN_ORDER:{label:'Return Order', value:'return_order'},
  FORWARD_ORDER:{label:'Forward Order', value:'forward_order'},
  SHIPMENT_ORDER:{label:'Shipment Order', value:'shipment_order'},
  SPLIT_ORDER:{label:'Split Order', value:'split_order'},
  STO:{label:'Stock Transfer Order', value:'STO'},
  RTS:{label:'Return To Sender', value:'RTS'},
}

export const ROUTES_EXCLUDED_IN_SELF_TENANT = ['sellers']

export const isDateDiffGreaterThen90 = (dateRange) => {
  if (!dateRange) {
    return false
  }
  const today = new Date()
  const [day, month, year] = dateRange.start.split('-')
  const startDate = new Date(year, month - 1, day)
  const diffTime = Math.abs(today - startDate)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  return diffDays <= 90
}

export const formatDate = (dateStr) => {
  const [day, month, year] = dateStr?.split('-').map(Number) || [undefined, undefined, undefined]
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ]

  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return 'th'
    switch (day % 10) {
    case 1: return 'st'
    case 2: return 'nd'
    case 3: return 'rd'
    default: return 'th'
    }
  }

  const monthName = months[month - 1]
  const ordinalSuffix = getOrdinalSuffix(day)

  return [`${monthName} ${day}${ordinalSuffix}`, year]
}

export const HUB_TYPE = {
  DARK_STORE:'dark_store',
  VIRTUAL:'virtual',
  WAREHOUSE:'warehouse',
  RETAIL:'retail'
}

export const DEFAULT_COUNTRY_CODE = {
  name: 'Saudi Arabia',
  flag: '🇸🇦',
  country_code: 'SA',
  calling_code: '+966',
  value: 'Saudi Arabia (SA)',
  label: 'Saudi Arabia (+966)',
  maxPhoneNumberLength: 9
}

export const ENVIRONMENT = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production'
}
export const STANDARD_TABLE_COLUMNS = {
  SERIAL_NUMBER: {
    label: 'S. No.',
    key: 'serialNumber',
    width: '64px'
  }
}

export const MIN_FILE_SIZE_ALLOWED_IN_BYTES = 0

export const MAX_FILE_SIZE_ALLOWED_IN_BYTES = 10485760

export const ONE_MB_IN_BYTES = 1048576

export const LANGUAGES = {
  ENGLISH: { label: 'English', value: 'en' },
  ARABIC: { label: 'Arabic', value: 'ar' }
}
export const MANUAL_SHIPPING_ACCOUNT = 'Manual'

export const MAP_STYLES = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f4f5f7'
      }
    ]
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161'
      }
    ]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575'
      }
    ]
  },
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161'
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5'
      }
    ]
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  }
]

export const selfTenantExcludedRouteIds = ['billings']

export const fulfillmentTenantExcludedRouteIds = ['pos-settings']

export const PRINT_PAGE_STYLE = `
  @media print {
    body {
      height: fit-content !important;
    }
  }
`
export const DATA_TYPE = {
  STRING: 'string',
  JSON: 'json',
  BOOL: 'bool',
  DATE: 'date',
  INTEGER: 'integer',
  FLOAT: 'float',
  ARRAY: 'array',
  OBJECT: 'object',
  START_END_FLOAT: 'start_end_float'
}

export const FILE_REJECTED_REASON = {
  FILE_INVALID_TYPE:'file-invalid-type',
  FILE_TOO_LARGE: 'file-too-large',
  FILE_TOO_SMALL:'file-too-small'
}

export const PACKING_CONTAINER_TYPE = {
  CARTON: {label:'Carton', value:'carton'},
  PALLET: {label:'Pallet', value:'pallet'},
  BUNDLE: {label:'Bundle', value:'bundle'}
}

export const CREATE_RETURN_REQUES_PATH = '/orders/return/create-return-request'

export const COLLAPSED_CONTAINER_LIST = {
  INVENTORY_QUANTITIES : 'inventory_quantities',
  BULK_RETURN: 'bulk_return',
  ALL_RETURN_ORDERS: 'all_return_orders',
  COURIER_RETURN: 'courier_return',
  CUSTOM_RETURN: 'custom_return',
  OFF_SYSTEM_RETURN: 'off_system_return',
  TAX_INVOICES: 'tax_invoices',
  PENDING_ACTIONS: 'pending_actions'
}

export const PUSHER_RESPONSE_STATUS = {
  FAILURE: 'failure',
  IN_PROGRESS: 'in_progress'
}
export const CURRENT_DATE = new Date()

export const SETTING_MODULE_URL = {
  AUTOMATION_RULE: {
    id: 'automation-rules',
    title: 'Automation Rules',
    url: '/settings/automation-rules',
  },
  ADD_ITEMS_AUTOMATION: {
    id: 'gifting-automation',
    title: 'Gifting Automation',
    url: '/settings/automation-rules/gifting-automation'
  }
}

export const SEARCH_SKU_FIELDS = [
  { label: 'Name', value: 'name' },
  { label: 'Barcodes', value: 'barcodes' },
  { label: 'Seller SKU Code', value: 'seller_sku_code' },
];