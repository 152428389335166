import MultiValueInput from '@src/@core/components/ui/multi-value-input'
import { useState } from 'react'
import { Controller } from 'react-hook-form'

/**
 * A reusable React component for handling multiple input values.
 *
 * @param {Object} control - The control object from react-hook-form.
 * @param {Object} errors - The error object from react-hook-form.
 * @param {Function} setValue - A function to set the value of a field in the form.
 * @param {String} name - The name of the field.
 * @param {Object} rules - The validation rules for the field.
 * @param {Function} clearErrors - A function to clear the errors of a field in the form.
 * @param {Boolean} [disabled=false] - Whether the input is disabled.
 * @return {JSX.Element} The JSX element representing the multi-input component.
 */
const MultiInputField = ({control, errors, setValue, name, rules, clearErrors, disabled = false, label = 'Enter Values'}) => {
  const [inputValue, setInputValue] = useState('')
  return  <div className='multi-value-input-container'>
    <Controller
      id="value"
      control={control}
      rules={rules}
      name={name}
      render={({ field }) => (
        <MultiValueInput 
          {...field}
          value={inputValue}
          errors={errors}
          valueRemoveHandler={(ind) => {
            setValue(field.name, field.value.filter((_, index) => index !== ind))
          }}
          valueArray={field?.value ? field.value?.map(item => ({ barcode: item.value, isEditable: true })) : []}
          onChange={(e) => {
            clearErrors(name)
            setInputValue(e.target.value)
          }}
          onKeyDown ={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              if (!inputValue.trim()) return 
              clearErrors(name)
              if (!field?.value) {
                setValue(field.name, [{label: inputValue.trim(), value: inputValue.trim()}])
                setInputValue('')
              } else {
                setValue(field.name, [...field.value, {label: inputValue.trim(), value: inputValue.trim()}])
                setInputValue('')
              }
            }
          }}
          onBlur={() => {
            if (!inputValue.trim()) return
            clearErrors(name)
            if (!field?.value) {
              setValue(field.name, [{label: inputValue.trim(), value: inputValue.trim()}])
              setInputValue('')
            } else {
              setValue(field.name, [...field.value, {label: inputValue.trim(), value: inputValue.trim()}])
              setInputValue('')
            }
          }}
          width='100%'
          label='Enter Values'
          isRequired
          disabled={disabled}
          style={{ minWidth: '50px' }}
        />
      )}
    />
  </div>
}

export default MultiInputField