import { API_ENDPOINTS } from '@src/api.urls';
import { axiosInstance } from '@src/network/AxiosInstance';
import { useQuery } from '@tanstack/react-query';

export const useFetchReasons = ({ isModalOpen, entity_type }: { isModalOpen: boolean; entity_type: string }) => {
  const { data } = useQuery({
    queryKey: [API_ENDPOINTS.COMMON.GET_OMS_REASONS, isModalOpen, entity_type],
    queryFn: () => {
      return axiosInstance.get(API_ENDPOINTS.COMMON.GET_OMS_REASONS, { params: { entity_type } });
    },
    enabled: isModalOpen,
  });

  if (data && data.data && data.data.data.length > 0) {
    const reasons = data.data.data[0].reasons;
    const formattedReasons = reasons.map((reason: string) => ({
      label: reason,
      value: reason,
    }));

    return { reasons: formattedReasons };
  }

  return { reasons: null };
};
