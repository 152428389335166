import CustomTag from '@src/@core/components/ui/badge/CustomTag'
import Button from '@src/@core/components/ui/button'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import InputField from '@src/@core/components/ui/input-field'
import { showSkuTypeTag } from '@src/views/sales/sales.utils'
import classNames from 'classnames'
import { useEffect } from 'react'
import { Trash2 } from 'react-feather'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import '../style.scss'

/**
 * A React functional component representing the trigger actions in a rule creation/edit interface.
 *
 * @param {object} props - The component props.
 * @param {object} props.formObject - The form object containing control, errors, watch, and setValue functions.
 * @param {boolean} props.isView - A boolean indicating whether the component is in view mode.
 * @param {array} props.actionValues - An array of action values.
 * @param {object} props.propsMap - An object containing API mappings.
 * @param {function} props.onConditionOrActionChange - A callback function to get value when condition or action change.
 * @return {JSX.Element} The JSX element representing the component.
 */
const CustomActionComponent = (props) => {
  const { formObject, isView, isEdit, actionValues, propsMap } = props

  const { t } = useTranslation()
  const { control, watch, setValue, clearErrors, formState: {errors} } = formObject
  const { append: appendAction } = useFieldArray({
    control,
    name: 'actionValues',
    shouldUnregister: true,
  })

  const actionsRows = watch('actionValues')

  const selectedSkusArray = watch('actionValues.0.value') || []

  const filterOptions = (option) => {
    // Filter out options that have already been selected
    return !selectedSkusArray.find((selected) => selected.value === option.value)
  }

  const handleAddRow = () => {
    const selectedCondition = actionValues[0]
    const selectedOperator = actionValues[0].operators[0]
    const rowObject = {
      condition: {
        label: selectedCondition.display_name,
        value: selectedCondition.key_name,
      },
      operator: {
        label: selectedOperator.display_name,
        value: selectedOperator.key_name,
        datatype: selectedOperator.datatype,
      },
      value: null,
    }
    setValue('actionValues.0.condition', rowObject.condition)
    setValue('actionValues.0.operator', rowObject.operator)
    setValue('actionValues.0.value', null)
    appendAction({
      condition: null,
      operator: null,
      value: null,
    })
  }

  const handleRemoveSelectedAction = (skuCode) => {
    const actionAfterRemove = selectedSkusArray.filter((action) => action.value !== skuCode)
    setValue('actionValues.0.value', actionAfterRemove)
  }

  const updateQuantity = ({ skuCode, val, isWeighted }) => {
  
    const updatedArray = [...selectedSkusArray];
    const item = updatedArray.find((item) => item.value === skuCode);
    if (item) {
      if (isWeighted) {
        item.weight = val;
      } else {
        item.quantity = parseInt(val || 0);
      }
    }
  
    return updatedArray;
  };
  

  useEffect(() => {
    if (actionValues?.length && !(isView || isEdit)) {
      setTimeout(() => {
        handleAddRow()
      }, 0)
    }
  }, [actionValues])

  return (
    <div className='custom-gift-items-rule-container p-24px border border-dark-2 bg-light-2 d-flex flex-column gap-24px rounded-12px'>
      <div className='d-flex flex-column gap-12px pb-24px separator'>
        <div className='txt-h3-sb text-dark'>{t('Then')}</div>
      </div>
      {actionsRows.map((action) => {
        if (!action.condition) return null
        return (
          <div key={action.id} className='add-action-dropdown-container'>
            <FloatingDropDown
              key={action.id}
              name='actionValues.0.value'
              control={control}
              value={null}
              isMulti={true}
              isAsync
              isRequired
              validationSchema={{'actionValues.0.value': { required: t('Required Field')}}}
              isClearable={false}
              filterOption={filterOptions}
              {...propsMap[action.condition.value]}
              onChangeFunc={(option) => {
                clearErrors('actionValues.0.value')
                setValue('actionValues.0.value', [option[0], ...selectedSkusArray])
              }}
              additional={{
                ...propsMap[action.condition.value].additional,
              }}
              disabled={isView}
              errors={errors}
            />
          </div>
        )
      })}
      <div className='flex-start-start flex-column gap-12px'>
        {selectedSkusArray?.map((item, index) => {
          // it will retrun sku tag display value
          const skuTag = showSkuTypeTag(item.type)
          return (
            <div key={item.value} className='flex-center-start gap-16px'>
              <div
                className='selected-action-value-container flex-center-between px-12px border border-primary-light-global bg-primary-lighter-global rounded-8px gap-4px'
                key={index}
              >
                <div className={classNames('flex-start-center flex-column w-100', {
                  'width-200':!!skuTag
                })}>
                  <div className='txt-sub-rg text-dark text-truncate w-100' title={item.name}>{item.name}</div>
                  <div className='txt-asst-rg text-dark-5 text-truncate w-100' title={item.name}>SKU Code: {item.value}</div>
                </div>
                {skuTag && <div>
                  <CustomTag className='text-info bg-info-light' title={skuTag} />
                </div>}
              </div>
              <div className='qty-input-wrapper'>
                <InputField
                  name='qty'
                  isClearable
                  label={t('Qty')}
                  value={item.is_weighted ? item.weight : item.quantity}
                  isRequired
                  onChange={(e) => {
                    const data = updateQuantity({ skuCode: item.value, val: e.target.value, isWeighted: item.is_weighted })
                    setValue('actionValues.0.value', data)
                  }}
                  endAdornment={item.is_weighted ? item.uom : null}
                />
              </div>
              {!isView && (
                <Button
                  ofStyle='noBackground'
                  type='button'
                  className='p-0'
                  icon={() => <Trash2 size={16} color='var(--bs-danger)' />}
                  onClick={() => handleRemoveSelectedAction(item.value)}
                />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CustomActionComponent
