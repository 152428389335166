// ** React Imports
import { Suspense, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

// ** Context Imports
import { bootApiToGetUserDetails } from '@src/redux/authentication'
import { AbilityContext } from '@src/utility/context/Can'
// import { handleVisibilityChange } from '@src/network/RealTime'
import { checkPermission } from '@layouts/utils'
import usePrintersConfigurations from '@src/utility/hooks/usePrintersConfigurations'
import PageTitle from '../page-title/PageTitle'
import ComponentSpinner from '../spinner/Loading-spinner'

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem('auth'))

  const bootResponse = useSelector((state) => state.auth)

  const { permissions, loading, userData, bootApiError } = bootResponse

  const ability = useContext(AbilityContext)

  const pageTitle = route?.title

  const {mutate: getPrintersConfigurations } = usePrintersConfigurations()

  if (route) {
    let action = null
    let resource = null
    let checkIsPermitted = null
    let restrictedRoute = true

    if (route.meta) {
      action = route.meta.action
      resource = route.meta.resource
      checkIsPermitted = route.meta.checkIsPermitted
      restrictedRoute = route.meta.restricted
    }

    const isRouteAuthorised = checkPermission({ action, resource, checkIsPermitted })

    if (!user?.access_token) {
      return <Navigate to="/login" />
    }
    if (user?.access_token && restrictedRoute && !bootApiError) {
      if (!permissions?.length > 0 && !loading && !userData?.name) {
        dispatch(bootApiToGetUserDetails(ability))
        getPrintersConfigurations()
      }

      if (permissions && action && resource && !isRouteAuthorised) {
        return <Navigate to="/misc/not-authorized" replace />
      }
    }
  }

  if (permissions) {
    return <Suspense fallback={
      <div className='position-absolute left-0 top-0 right-0 bottom-0 w-100 flex-center-center'>
        <ComponentSpinner />
      </div>
    }>
      <>
        <PageTitle pageTitle={pageTitle} />
        {children}
      </>
    </Suspense>
  }
}

export default PrivateRoute