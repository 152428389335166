import Button from '@src/@core/components/ui/button'
import { LANGUAGES, PRINT_MODES } from '@src/App.constants'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { getApiUrl } from '@src/utility/Utils'
import PropTypes from 'prop-types'
import { QRCodeCanvas } from 'qrcode.react'
import { forwardRef, useEffect, useRef, useState } from 'react'
import Barcode from 'react-barcode'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import { Spinner } from 'reactstrap'
import { getInvoiceDataApi } from '../../sales.apis'


export const InvoiceLayout = forwardRef(({ data, }, ref) => {

  const printLanguage = data?.invoice_setting.print_language || LANGUAGES.ENGLISH.value
  const { t } = useTranslation('translation', { lng: printLanguage })
  // Render Billing Details
  const showBillingCustomerName = data?.invoice_setting.show_customer_name && data.billing_details.name
  const showBillingCustomerNumber = data?.invoice_setting.show_customer_number && data.billing_details.phone_number
  const showBillingCustomerEmail = data?.billing_details.email
  const showBillingCustomerAddress = data?.invoice_setting.show_customer_address && data.billing_details.address

  const shouldRenderBillingDetail = showBillingCustomerName || showBillingCustomerNumber || showBillingCustomerEmail || showBillingCustomerAddress

  // Render Shipping Details
  const showShippingCustomerName = data?.invoice_setting.show_customer_name && data.shipping_details.name
  const showShippingCustomerNumber = data?.invoice_setting.show_customer_number && data.shipping_details.phone_number
  const showShippingCustomerEmail = data?.shipping_details.email
  const showShippingCustomerAddress = data?.invoice_setting.show_customer_address && data.shipping_details.address

  const shouldRenderShippingDetail = showShippingCustomerName || showShippingCustomerNumber || showShippingCustomerEmail || showShippingCustomerAddress
  const showOrderedQuantity = data?.invoice_setting.show_ordered_quantity
  const showPickedQuantity = data?.invoice_setting.show_picked_quantity
  const showCountryOfOrigin = data?.invoice_setting.show_country_of_origin
  const showOrderIdBarcode = data?.invoice_setting.show_order_id_barcode

  return (
    <div style={{width:'100%'}} ref={ref}>
      <style>{`
.order-invoice {
  width:100%;
  padding: 16px;
  .page-break {
    display: block;
    page-break-before: auto !important;
    page-break-after: always !important;
    page-break-inside: avoid !important;
  }
  .invoice-header {
    display: flex;
    align-items: center;
    gap: 16px;
    .company-logo {
      position:relative;
      width: 74px;
      height: 74px;
      flex-shrink: 0; 
      img {
        width: 100%;
        height: 100%;
      }
    }

    .company-info {
      flex-grow: 1;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap:6px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      .title {
        font-size: 24px;
        color: #222;
        font-weight: 600;
      }
      .vat_number{
        color: #222;
      }
      .description{
        color: #999 !important;
        width:500px;
        word-break: break-all;
      }
    }

    .qr-code-wrapper {
      width: 74px;
      display: flex;
      justify-content: end;
      align-items: start;
      flex-shrink: 0;
    }
  }

  .divider {
    margin: 16px 0px;
    border-bottom: 1px dashed #999999;
  }

  .invoice-barcode-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  }

  .store-info {
    display: flex;
    .detail {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 12px !important;
      .detail-heading {
        color: #222;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
      .info-row {
        display: flex;
        gap: 6px;
        font-size: 10px;
        line-height: normal;
        color: #222;
        .info-col-1 {
          width: 30%;
          color: #222;
          font-weight:300
        }
        .info-col-2 {
          width: 70%;
          display:flex;
          gap: 6px;
          .info-seperator{
            display:inline-block;
            font-weight:300
          }
          .info-value {
            font-weight: 400;
            word-break: break-all;
            display:inline-block;
            width:80%;
          }
        }
      }
    }
  }
  .info-table {
    width: 100%;
    position: relative;

    .invoice-footer {
      position: fixed;
      height: 100px;
      bottom: 0;
      width: 96%;
      display: flex;
      align-items: center;
      justify-content: center;
    text-align: center;
    page-break-after: always;
    }

    .table>:not(caption)>*>* {
      padding: 0.5rem 0.5rem;
      background-color: var(--bs-table-bg);
      border-bottom-width: 1px;
      box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    }
    .table>:not(:last-child)>:last-child>* {
       border-bottom-color: currentColor;
    }

    .main-invoice-table-wrapper {
    width:100%;
    .empty-footer {
      height:100px;
    }
      .main-invoice-table-body {
      width:100%;
        .order-detail-table {
          width: 100%;
          .table-header {
            .table-header-row {
              background: #F2F2F2;
              height: 40px;
              .header-text-left{
                text-align: start;
              }
              .header-text-right{
                text-align: end;
              }
            }
            tr {
              th {
                color: #222222;
                padding: 8px;
                font-size: 8px;
                font-weight: 300;
              }
            }
          }
          .table-body{
            .table-row{
              border-top:1px solid #ccc;
              border-bottom:1px solid #ccc;
              .sku-code, .country-of-origin{
                max-width:100px;
                word-break: break-all;
              }
              .sku-name-wrapper{
                max-width:160px;
                .sku-name-td {
                  word-break: break-all;
                }
              }
              .qty{
                padding-right:10px !important;
              }
              .td-text-right{
                text-align: end;
              }
              .kit-wrapper{
                font-weight: 600;
                margin-right: 8px;
              }
            }
          }
          tbody {
            tr {
              padding-left:10px;
              td {
                padding: 8px;
                color: #222;
              }
            }
          }
          color: #222;
          font-size: 10px;
          font-weight: 400;
          line-height: 18px;
        }
      }
      .order-total {
        display:flex;
        justify-content: end;
        margin-top:12px;
        .qr-code{
          transform: translateX(-50%);
        }
        .order-total-wrapper{
          display:flex;
          gap:16px;
          font-size: 10px;
          font-weight: 300;
          margin-right: 8px;
          .order-total-left-col{
            display:flex;
            flex-direction: column;
            gap:10px;
            color: #222;
            font-weight: 300;
          }
          .order-total-right-col{
            display:flex;
            flex-direction: column;
            gap:10px;
            text-align: right;
            color: #222;
            font-weight: 300;
          }
          .total{
            font-size:12px;
            font-weight:500;
            color: #222 !important;
          }
        }
    }
    }
  }
  .terms-condtions {
    .heading{
      color: #222;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .term{
      color:#222;
      font-size: 10px;
      font-weight:300
    }
  }
`}
      </style>
      {data && <div className='order-invoice' style={{ direction: printLanguage === LANGUAGES.ARABIC.value ? 'rtl' : 'ltr' }}>
        <div className='invoice-header'>
          <div className='company-logo'>
            {data.invoice_setting.show_logo && data.invoice_setting.logo && <img width='74' height='74' src={data.invoice_setting.logo} alt='company-logo' />}
          </div>
          <div className='company-info'>
            <span className='title'>{t(data.invoice_setting.company_name) || t('Invoice')}</span>
            {data.invoice_setting.vat_number && <span className='vat_number'>{`VAT: ${data.invoice_setting.vat_number}`}</span>}
            {data.invoice_setting.description && <span className='description'>{data.invoice_setting.description}</span>}
          </div>

          <div className='qr-code-wrapper'>
            {(data.qr_hash && data.invoice_setting.show_qr_code) && <QRCodeCanvas size={64} value={data.qr_hash} />}
          </div>
        </div>
        <div className='divider'></div>
        <div className='store-info'>
          <div className='detail'>
            <div className='detail-heading'>
              {t('Store Details')}:
            </div>
            <InfoRow label={t('Store Name')} value={data.store_information.name} />
            <InfoRow label={t('Contact No')} value={data.store_information.phone_number} />
            <InfoRow label={t('Address')} value={data.store_information.address} />
          </div>
          <div className='detail'>
            <div className='detail-heading'>
              {t('Invoice Details')}:
            </div>
            {data.sc_invoice_number && <InfoRow label={t('Invoice No')} value={data.sc_invoice_number} />}
            {(data.invoice_setting.show_order_id && data.order_id) && <InfoRow label={t('Order ID')} value={data.order_id} />}
            {(data.invoice_setting.show_reference_order_id && data.reference_order_id) && <InfoRow label={t('Reference Order ID')} value={data.reference_order_id} />}
            {(data.invoice_setting.show_awb_number && data.awb_number) && <InfoRow label={t('AWB No')} value={data.awb_number} />}
            <InfoRow label={t('Invoice Date and Time')} value={`${data.invoice_date} at ${data.invoice_time}`} />
            {/* <InfoRow label={t('Invoice Time')} value={} /> */}
            <InfoRow label={t('Total Quantity')} value={data.total_item_count} />
            <InfoRow label={t('Payment Status')} value={data.payment_status} />
          </div>
        </div>
        <div className='divider'></div>
        <div className='store-info'>
          {shouldRenderBillingDetail && (
            <div className='detail'>
              <div className='detail-heading'>
                {t('Billing To')}:
              </div>
              {showBillingCustomerName && <InfoRow label={t('User Name')} value={data.billing_details.name} />}
              {showBillingCustomerNumber && <InfoRow label={t('Contact No')} value={data.billing_details.phone_number} />}
              {showBillingCustomerEmail && <InfoRow label={t('Email')} value={data.billing_details.email} />}
              {showBillingCustomerAddress && <InfoRow label={t('Address')} value={data.billing_details.address} />}
            </div>
          )}
          {shouldRenderShippingDetail &&
            <div className='detail'>
              <div className='detail-heading'>
                {t('Shipping To')}:
              </div>
              {showShippingCustomerName && <InfoRow label={t('User Name')} value={data.shipping_details.name} />}
              {showShippingCustomerNumber && <InfoRow label={t('Contact No')} value={data.shipping_details.phone_number} />}
              {showShippingCustomerEmail && <InfoRow label={t('Email')} value={data.shipping_details.email} />}
              {showShippingCustomerAddress && <InfoRow label={t('Address')} value={data.shipping_details.address} />}
            </div>}
        </div>
        {(shouldRenderBillingDetail || shouldRenderShippingDetail) && <div className='divider'></div>}
        <div className='info-table'>
          <table className='main-invoice-table-wrapper'>
            <thead></thead>
            <tbody className='main-invoice-table-body'>
              <table className='order-detail-table'>
                <thead className='table-header'>
                  <tr className='table-header-row'>
                    <th className='table-header-th header-text-left'>{t('S. No.')}</th>
                    <th className='table-header-th header-text-left'>{t('SKU Code')}</th>
                    <th className='table-header-th header-text-left'>{t('SKU Name')}</th>
                    {showCountryOfOrigin && <th className='table-header-th header-text-left'>{t('Country of Origin')}</th>}
                    <th className='table-header-th header-text-right'>{t('HS Code')}</th>
                    {showOrderedQuantity && <th className='table-header-th header-text-right'>{t('Ordered Qty')}</th>}
                    {showPickedQuantity && <th className='table-header-th header-text-right'>{t('Picked Qty')}</th>}
                    <th className='table-header-th header-text-right'>{t('Qty')}</th>
                    <th className='table-header-th header-text-right'>{t('Unit Price')}</th>
                    <th className='table-header-th header-text-right'>{t('Tax Amt')}</th>
                    <th className='table-header-th header-text-right'>{t('Total Price')}</th>
                  </tr>
                </thead>
                <tbody className='table-body'>
                  {data.order_items.map((item, index) => {
                    return (<tr className='table-row' key={index}>
                      <td className='serial-number table-row-td'>
                        {index + 1}
                      </td>
                      <td className='sku-code table-row-td'>
                        {item.sku_code || '-'}
                      </td>
                      <td className='sku-name-wrapper table-row-td'>
                        {item.name ? <>
                          {item.child_items.length > 0 && <span className='kit-wrapper'>[Kit]</span>}
                          <span className='sku-name-td'>{item.name}</span>
                        </> : '-'}
                      </td>
                      {showCountryOfOrigin && <td className='country-of-origin table-row-td'>
                        {item.country_of_origin || '-'}
                      </td>}
                      <td className='hs-code table-row-td td-text-right'>
                        {item.hs_code || '-'}
                      </td>
                      {showOrderedQuantity && <td className='qty table-row-td td-text-right'>
                        {item.ordered_quantity || '-'}
                      </td>}
                      {showPickedQuantity && <td className='qty table-row-td td-text-right'>
                        {item.picked_quantity || '-'}
                      </td>}
                      <td className='qty table-row-td td-text-right'>
                        {item.quantity || '-'}
                      </td>
                      <td className='unit-price table-row-td td-text-right'>
                        {`${item.unit_price || '0.00'} ${data.currency || ''}`}
                      </td>
                      <td className='tax-amount table-row-td td-text-right'>
                        {`${item.tax_amount || '0.00'} ${data.currency || ''}`}
                      </td>
                      <td className='total-price table-row-td td-text-right'>
                        {`${item.total_price || '0.00'} ${data.currency || ''}`}
                      </td>
                    </tr>
                    )
                  })}
                </tbody>
              </table>
            </tbody>
            <div className='order-total'>
              <div className='order-total-wrapper'>
                <div className='order-total-left-col'>
                  <span>{t('Sub Total')}</span>
                  <span>{t('Discount')}</span>
                  {data.invoice_setting.show_refund_amount && <span>{t('Refund')}</span>}
                  {data.invoice_setting.show_tax_percentage && <span>{t('Tax Percentage')}</span>}
                  {data.invoice_setting.show_price_includes_tax && <span>{t('Tax')}</span>}
                  {data.invoice_setting.show_shipping_cost && <span>{t('Shipping Cost')}</span>}
                  <span className='total'>{t('Total')}</span>
                </div>
                <div className='order-total-right-col'>
                  <span>{`${data.sub_total || '0.00'} ${data.currency || ''}`}</span>
                  <span>{`${data.discount || '0.00'} ${data.currency || ''}`}</span>
                  {data.invoice_setting.show_refund_amount && <span>{`${data.refund_amount || '0.00'} ${data.currency || ''}`}</span>}
                  {data.invoice_setting.show_tax_percentage && <span>{data.tax_percentage || '0.00'}%</span>}
                  {data.invoice_setting.show_price_includes_tax && <span>{`${data.tax_amount || '0.00'} ${data.currency || ''}`}</span>}
                  {data.invoice_setting.show_shipping_cost && <span>{`${data.shipping_cost || '0.00'} ${data.currency || ''}`}</span>}
                  <span className='total'>{`${data.total_with_tax || '0.00'} ${data.currency || ''}`}</span>
                </div>
              </div>
            </div>
            {data.invoice_setting.terms &&
            <>
              <div className='divider'></div>
              <div className='terms-condtions'>
                <p className='heading'>{t('Terms and Conditions')}:</p>
                {data.invoice_setting.terms.map((ele, idx) => <li key={idx} className='term'>{ele}</li>)}
              </div>
            </>}
            <div className='divider'></div>
            <tfoot className='empty-footer'>
              <div className='d-flex justify-content-center align-items-center w-100'>
                <Barcode  
                  renderer='canvas' 
                  background='transparent' 
                  width={1} 
                  height={44} 
                  fontSize={14} 
                  value={data.order_id}
                  text={`${t('Order ID')}: ${data.order_id}`}
                />
              </div>
            </tfoot>
          </table>
          {showOrderIdBarcode  && <footer className="invoice-footer">
          </footer>}
          <div className='page-break' />
        </div>
      </div>}
    </div>

  )
})

const InfoRow = ({ label, value }) => {
  return (
    <div className='info-row'>
      <div className='info-col-1'>
        {label}
      </div>
      <div className='info-col-2'>
        <span className='me-1 info-seperator'>:</span>
        <span className='info-value'>&lrm;{value}</span>
      </div>
    </div>
  )
}

const Invoice = ({ printInvoiceRef, order_id, cleanupFunction, buttonType='regular' }) => {

  const contentRef = useRef(null)
  const { t } = useTranslation()
  const [invoiceData, setInvoiceData] = useState()
  const [isPrintClicked, setIsPrintClicked] = useState(false)


  const handlePrint  =  useReactToPrint({
    content: () => contentRef.current,
    pageStyle: `@media print {
      @page  {
        size: ${PRINT_MODES.A_FOUR.size};   /* auto is the initial value */
        margin: ${PRINT_MODES.A_FOUR.margin};  /* this affects the margin in the printer settings */
        padding:${PRINT_MODES.A_FOUR.id && PRINT_MODES.A_FOUR.padding};
      }
    }`,
    onAfterPrint:() => setIsPrintClicked(false)
    // onBeforeGetContent: () => setPrintingLoading(true),
    // onBeforePrint: () => setPrintingLohttps://preview-omniful.web.app/2?merchant_id=4125f07e-6c11-46c7-bae4-7c234d7257ab&page=9acd6b98-caae-46e1-bc73-7111edf52f81&status=draftading(false)
    
  })

  const { refetch: handleGetInvoiceData, isFetching: isGetInvoiceDataFetching } = useApi({
    apiKey: [getInvoiceDataApi.apiKey, order_id],
    apiFn: () => {
      return axiosInstance.get(getApiUrl(getInvoiceDataApi.url, { orderId: order_id }))
    },
    onSuccess: (response) => {
      setInvoiceData(response.data)
      setIsPrintClicked(true)
      if (cleanupFunction) {
        cleanupFunction()
      }
    },
    enabled: false
  })

  useEffect(() => {
    if (invoiceData && isPrintClicked) handlePrint()
  }, [invoiceData, isPrintClicked])
  return (
    <div>
      <div className='d-none'>
        <InvoiceLayout data={invoiceData} ref={contentRef} />
      </div>
      <Button
        onClick={handleGetInvoiceData}
        disabled={isGetInvoiceDataFetching}
        ref={printInvoiceRef}
        icon={isGetInvoiceDataFetching && Spinner}
        iconSize='sm'
        ofType={buttonType}
      >
        {t('Print Invoice')}
      </Button>
    </div>
  )
}

Invoice.propTypes = {
  printInvoiceRef: PropTypes.shape({ current: PropTypes.any }),
  order_id: PropTypes.string.isRequired
}

export default Invoice