import { iOrderRecreationParams } from '@src/views/sales/create-order/create-order.types';
import { useNavigate } from 'react-router-dom';

const useOrderRecreation = () => {
  const navigate = useNavigate();

  const handleOrderRecreation = ({ recreationType, orderId, hubId , previousOrderDisplayId }: iOrderRecreationParams) => {
    navigate('/sales/create/order', {
      state: {
        orderId,
        recreationType,
        hubId,
        previousOrderDisplayId
      },
    });
  };

  const cleanOrderRecreationData = () => {
    navigate('/sales/create/order');
  };

  return { handleOrderRecreation, cleanOrderRecreationData };
};

export default useOrderRecreation;
