import CustomDropdownOption from '@src/@core/components/ui/dropdown/customDropdownOption'
import { DATA_TYPE } from '@src/App.constants'
import { INPUT_TYPES_MAP } from './rule.constant'

/**
 * Returns the component props based on the input type.
 *
 * @param {object} obj - An object containing the component properties.
 * @param {string} obj.name - The name of the component.
 * @param {object} obj.formObject - The form object.
 * @param {number} obj.index - The index of the component.
 * @param {string} obj.operatorDatatype - The operator datatype.
 * @param {boolean} [obj.disabled=false] - Whether the component is disabled.
 * @param {string} obj.inputType - The type of input.
 * @param {array} obj.dropdownOptions - The dropdown options.
 * @param {object} obj.selectedCondition - The selected condition.
 * @param {object} obj.propsMap - The props map.
 * @return {object} The component props.
 */
export const getComponentProps = ({
  name,
  formObject,
  index,
  operatorDatatype,
  disabled = false,
  inputType,
  dropdownOptions,
  selectedCondition,
  propsMap = {},
  fieldsName
}) => {
  const {control, register, formState: {errors}, watch, setValue, clearErrors} = formObject
  const keyName = selectedCondition?.key_name
  const isRequired = selectedCondition?.is_required
  const conditionDatatype = selectedCondition?.datatype
  const conditionDisplayName = selectedCondition?.display_name
  const defaultValue = propsMap[keyName]?.defaultValue || null
  const value = watch(name) || defaultValue
  if (inputType === INPUT_TYPES_MAP.DROPDOWN || inputType === INPUT_TYPES_MAP.API) {
    const dropdownProps = {
      name,
      control,
      errors,
      isClearable: false,
      title: conditionDisplayName ? `Select ${conditionDisplayName}` : 'Select Option',
      isRequired,
      validationSchema: {
        [name]: {required: isRequired ? 'Required Field' : undefined}
      },
      disabled,
      value,
      additionalComponents: { Option: CustomDropdownOption },
      openMenuOnFocus: false,
      closeMenuOnSelect: true,
      blurInputOnSelect: true
    }
    if (operatorDatatype?.includes(DATA_TYPE.ARRAY) || conditionDatatype?.includes(DATA_TYPE.ARRAY)) {
      dropdownProps.isMulti = true
      dropdownProps.modifiedStyles = {
        multiValueRemove: (provided, state) => ({
          ...provided,
          display: state.data.value === 'all' ? 'none' : 'flex'
        }),
        multiValueLabel: (provided, state) => ({
          ...provided,
          paddingRight: state.data.value === 'all' ? '6px' : '3px'
        })
      }
    }
    if (dropdownOptions) {
      dropdownProps.options = dropdownOptions
    }
    if (inputType === INPUT_TYPES_MAP.API) {
      dropdownProps.cacheUniqs = [keyName]
      dropdownProps.isAsync = true
    }
    const externalProps = {...propsMap[keyName]}
    delete externalProps.defaultValue
    return {...dropdownProps, ...externalProps}
  } else if (inputType === INPUT_TYPES_MAP.INPUT_BOX && operatorDatatype === DATA_TYPE.START_END_FLOAT) {
    // range case
    const inputPropsForRange = {
      register,
      errors,
      isRequired,
      disabled,
      index,
      fieldsName
    }

    return {...inputPropsForRange, ...propsMap[keyName]}

  } else if (inputType === INPUT_TYPES_MAP.INPUT_BOX) {
    const validation = { required: isRequired ? 'Required Field' : undefined }
    if (operatorDatatype.includes(DATA_TYPE.INTEGER) 
      || operatorDatatype.includes(DATA_TYPE.FLOAT) 
      || conditionDatatype.includes(DATA_TYPE.INTEGER) 
      || conditionDatatype.includes(DATA_TYPE.FLOAT)
    ) {
      validation.validate = (fieldValue) => {
        if (isNaN(fieldValue)) {
          return 'Invalid Value'
        }
      }
    }
    const inputProps = {
      ...register(name, validation),
      errors,
      isRequired,
      disabled,
      label: conditionDisplayName ? `Enter ${conditionDisplayName}` : 'Enter Value',
      name
    }
    if (operatorDatatype?.includes(DATA_TYPE.ARRAY) || conditionDatatype?.includes(DATA_TYPE.ARRAY)) {
      inputProps.control = control
      inputProps.setValue = setValue
      inputProps.rules = validation
      inputProps.clearErrors = clearErrors
    } else if (operatorDatatype.includes(DATA_TYPE.INTEGER) 
      || operatorDatatype.includes(DATA_TYPE.FLOAT) 
      || conditionDatatype.includes(DATA_TYPE.INTEGER) 
      || conditionDatatype.includes(DATA_TYPE.FLOAT)) {
      inputProps.isClearable = true
      inputProps.width = '100%'
      inputProps.type = 'number'
      inputProps.step = 'any'
    } else {
      inputProps.isClearable = true
      inputProps.width = '100%'
    }
    return {...inputProps, ...propsMap[keyName]}
  } else if (inputType === INPUT_TYPES_MAP.DATE_PICKER) {
    const datePickerProps = {
      name,
      control,
      index,
      errors,
      disabled
    }
    return {...datePickerProps, ...propsMap[keyName]}
  }
  return {}
}