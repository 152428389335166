import FilledInfo from '@src/assets/images/icons/FilledInfo'
import { useTranslation } from 'react-i18next'

const AvailableWithoutReservationInfo = () => {
  const { t } = useTranslation()
  return (
    <div className='text-dark p-8px txt-sub-rg rounded-4px bg-primary-lighter-global'>
      <div className='flex-start-start gap-8px'>
        <span className='pt-1px'>
          <FilledInfo height={16} color={'var(--bs-primary)'}/>
        </span>
        <span>
          {t('Due to inventory being reserved for orders in a pending state, this order has been placed in the queue. If you wish to prioritize this order, you can manually approve it, provided sufficient inventory is there under \'Available without Reservation\'.')}
        </span>
      </div>
    </div>
  )
}

export default AvailableWithoutReservationInfo