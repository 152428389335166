export const ALL_COLUMN_NAME_MAPPING = {
  ORDERS_ALL_ORDER: 'all_order',
  NEW_ORDER: 'new_order',
  PICKING_ORDER: 'picking_order',
  SHIPPED_ORDER: 'shipped_order',
  PACKING_ORDER: 'packing_order',
  CONSOLIDATION_ORDER: 'consolidation_order',
  READY_TO_SHIP: 'ready_to_ship',
  PACKED: 'packed',
  ELIGIBILITY_RULE: 'eligibility_rule',
  PURCHASE_ORDER: 'purchase_order',
  SUPPLIERS: 'suppliers',
  SELLERS: 'sellers',
  ORDERS_FOR_APPROVAL: 'orders_for_approval',
  DRAFT: 'draft',
  READY_FOR_HANDOVER: 'ready_for_handover',
  COMPLETED:'completed',
  INVENTORY_BATCH: 'inventory_batches',
  USER_SETTINGS: 'user_settings',
  USERS_ROLES: 'user_roles',
  BIN_PUTAWAY:'bin_putaway',
  ITEM_PUTAWAY:'item_putaway',
  ACCEEPTED:'accepted',
  CANCELED:'cancelled',
  PENDING_REQUEST: 'pending_request',
  APPROVED_STO:'approved_sto',
  EDIT_STO:'edit_sto',
  STO_ORDER_DETAILS:'sto_order_details',
  EMBEDED_BARCODE: 'embedded_barcode',
  ATTRIBUTE_GROUPS: 'attribute_groups',
  INDIVIDUAL_ATTRIBUTE: 'individual_attribute',
  CANCELLED_ITEM_PUTAWAY:'cancelled_item_putaway',
  GRN_PENDING_PUTAWAY:'grn_pending_putaway',
  TOTAL_INVENTORY: 'total_inventory',
  SKUS: 'skus',
  CYCLE_COUNT: 'cycle_count',
  CYCLE_COUNT_LOCATIONS: 'location_cycle_count',
  ONGOING_PICKLIST: 'ongoing_picklist',
  COMPLETED_PICKLIST: 'completed_picklist',
  GRN: 'grn',
  GATE_ENTRIES: 'gate_entry',
  COURIER_PARTNER_RETURN_IN_PROGRESS_SHIPMENT:'courier_partner_return_in_progress_shipment',
  COURIER_PARTNER_RETURN_TO_ORIGIN_SHIPMENT:'courier_partner_return_to_origin_shipment',
  COURIER_PARTNER_ALL_RETURN_SHIPMENT:'courier_partner_all_return_shipment',
  CUSTOMER_RETURN_REVERSE_SHIPMENT_CREATED:'customer_return_reversed_shipment_created',
  CUSTOMER_RETURN_ITEMS_PICKED_UP_SHIPMENT:'customer_return_items_picked_up_shipment',
  CUSTOMER_RETURN_TO_ORIGIN_SHIPMENT:'customer_return_to_origin_shipment',
  FORWARD_SHIPMENT_READY_TO_SHIP: 'forward_shipment_ready_to_ship',
  FORWARD_SHIPMENT_CANCELLED: 'forward_shipment_cancelled',
  FORWARD_SHIPMENT_CREATED: 'forward_shipment_created',
  FORWARD_SHIPMENT_ALL: 'forward_shipment_all',
  FORWARD_SHIPMENT_DELIVERED: 'forward_shipment_delivered',
  FORWARD_SHIPMENT_DISPATCH: 'forward_shipment_dispatch',
  FORWARD_SHIPMENT_IN_TRANSIT: 'forward_shipment_in_transit',
  FORWARD_SHIPMENT_OUT_FOR_DELIVERY: 'forward_shipment_out_for_delivery',
  ORDER_TAGS: 'order_tags',
  CARTS: 'carts',
  CUSTOMERS: 'customers',
  SKU_LOCATIONS: 'sku_locations',
  RETURN_PENDING_REQUEST:'returning_pending_request',
  RETURN_CANCELLED_REQUEST:'returning_cancelled_request',
  ZONES: 'zones',
  BINS: 'bins',
  PICKUP_LOCATION:'pickup_location',
  RETURN_ORDER_PROCESSING : 'return_order_processing',
  SALES_CHANNEL_LISTING: 'sales_channel_listing',
  AFFECTED_BARCODE: 'affected_barcode',
  PURCHASE_ORDER_ITEMS_DETAILS: 'purchase_order_items_details',
  GATE_ENTRY_DETAILS: 'gate_entry_details',
  PURCHASE_ORDER_GRN_DETAILS: 'purchase_order_grn_details',
  PURCHASE_ORDER_PUTAWAY_DETAILS: 'purchase_order_putaway_details',
  PURCHASE_ORDER_SHIPMENT_DETAILS: 'purchase_order_shipment_details',
  CYCLE_COUNT_ASSIGNED_USERS: 'cycle_count_assigned_users',
  CYCLE_COUNT_SKU_LOCATIONS: 'cycle_count_sku_loactions',
  SKU_BARCODES_DETAILS: 'sku_barcode_details',
  ITEM_PICKLIST_DETAILS: 'item_picklist_details',
  ORDER_PICKLIST_STATUS: 'order_picklist_status',
  ASSEMBLY_TASKS: 'assembly_tasks',
  ASSEMBLED_KITS: 'assembled_kits',
  RETURN_GATE_ENTRY_DETAILS: 'return_gate_entry_details',
  OMS_ALL_RETURN_ORDER: 'oms_all_return_order',
  GRN_BIN_PUTAWAY: 'grn_bin_putaway',
  GRN_ITEMS_PUTAWAY: 'grn_items_putaway',
  GRN_UNIQUE_SKUS: 'grn_unique_skus',
  RETURN_ORDER_ITEM_PUTAWAY: 'return_order_item_putaway',
  RETURN_ORDER_BIN_PUTAWAY: 'return_order_bin_putaway',
  ASSEMBLED_KIT_BARCODES: 'assembled_kit_barcodes',
  RETURN_GRN_VIEW_SIDE_SHEET:'return_grn_view_side_sheet',
  SKU_BATCH_DETAILS: 'sku_batch_details',
  SALES_CHANNEL_CONFIG_LOG_TABLE: 'sales_channel_config_log_table',
  SALES_CHANNEL_CONFIG_LOG_ACTION_VALUE_SIDESHEET: 'sales_channel_config_log_action_value_sidesheet',
  TOTAL_INVENTORY_DETAILS: 'total_inventory_details',
  SPLITTED_ORDERS:'splitted_orders',
  SPLITTED_ORDER_ITEMS:'splitted_order_items',
  MULTIPLE_AWB_DETAILS_TABLE: 'multiple_awb_details_table',
  PALLETS: 'pallets',
  BOXES: 'boxes',
  ADJUST_INVENTORY: 'adjust_inventory',
  SHIPMENT_NEW_ORDERS: 'shipment_new_orders',
  READY_TO_SHIP_SHIPMENT_ORDERS: 'ready_to_ship_shipment_orders',
  ALL_SHIPMENT_ORDERS: 'all_shipment_orders',
  DISPATCHED_SHIPMENT_ORDERS: 'dispatched_shipment_order',
  CANCELLED_SHIPMENT_ORDERS: 'cancelled_shipment_order',
  IN_TRANSIT_SHIPMENT_ORDERS: 'in_transit_shipment_order',
  OUT_FOR_DELIVERY_SHIPMENT_ORDERS: 'out_for_delivery_shipment_order',
  RETURN_TO_ORIGIN_SHIPMENT_ORDERS: 'return_to_origin_shipment_order',
  INVOICES: 'invoices',
  WMS_STOCK_TRANSFER : 'wms_stock_transfer',
  STOCK_TRANSFER_ITEM_DETAIL: 'stock_transfer_item_detail',
  CITY_MAPPING: 'city-mapping',
  EXTERNAL_WMS_HUB_STATUS:'external_wms_hub_status',
  EXTERNAL_WMS_ORDER_STATUS_MAPPING: 'external_wms_order_status_mapping',
  BULK_SHIP_SUGGESTED_CLUSTERS: 'bulk_ship_suggested_clusters',
  CLUSTER_DETAILS_PICKING_WAVE: 'cluster_details_picking_wave',
  CLUSTER_DETAILS_SHIPMENT_DETAILS: 'cluster_details_shipment_details',
  SSC_FAILED_EVENT_DETAILS: 'ssc_failed_event_details',
  DELIVERY_ZONES_LIST: 'delivery_zones_list',
  PACKING_AUTOMATION:'packing_automation',
  PACKAGE_DETAILS:'package_details',
  RETURN_ELIGIBILITY_RULES: 'return_eligibility_rules',
  STOCK_TRANSFER_GRN_DETAILS:'sto_grn_details',
  STOCK_TRANSFER_PUT_AWAY_DETAILS: 'sto_put_away_details',
  SALESMEN: 'sales_men_table',
  ADD_ITEMS_AUTOMATION: 'add_items_automation',
}