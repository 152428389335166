import { ORDER_SOURCE } from '@src/App.constants';
import FilledInfo from '@src/assets/images/icons/FilledInfo';
import { ORDER_STATUS } from '@src/views/sales/constant/orders.constants';
import { AUTOMATION_RULE_ORDER_ITEM_STATUS } from '@src/views/sales/sales.constant';
import { useTranslation } from 'react-i18next';

const OrderInfobanner = ({ orderDetails }) => {
  const { t } = useTranslation();

  const assignedHub =
    orderDetails.status === ORDER_STATUS.ON_HOLD.name
      ? orderDetails.assigned_hub?.name
      : orderDetails.hub.name;

  const hubReassignmentDetails = orderDetails.hub_reassignment_details.previous_hub_name;

  const banners = [
    {
      show: orderDetails.is_quantity_edited,
      content:
        orderDetails.source === ORDER_SOURCE.MANUAL.label
          ? t('The order items have been edited.')
          : t('The order has been synchronised and item quantities have been updated accordingly.'),
    },
    {
      show: orderDetails.is_hub_reassigned,
      content: `The order has been reassigned from ${hubReassignmentDetails} to ${assignedHub}.`,
    },
    {
      show:
        orderDetails.automation_rule_items_details.items_status ===
        AUTOMATION_RULE_ORDER_ITEM_STATUS.REMOVED,
      content: t(
        'Gift item(s) have been removed as the updated order no longer satisfies the gifting automation rule.'
      ),
    },
  ].filter((banner) => banner.show);

  if (!banners.length) {
    return null;
  }

  const BannerItem = ({ content }) => (
    <div className="flex-center-start gap-8px">
      <span className="flex-center-center">
        <FilledInfo height={16} width={16} color={'var(--bs-primary)'} />
      </span>
      <span>{content}</span>
    </div>
  );

  return (
    <div className="mb-24px mx-0">
      <div className="text-dark p-12px txt-sub-rg d-flex flex-column gap-6px rounded-4px bg-primary-lighter-global">
        {banners.map((banner, index) => (
          <BannerItem key={index} content={banner.content} />
        ))}
      </div>
    </div>
  );
};

export default OrderInfobanner;
