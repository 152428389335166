import Button from '@src/@core/components/ui/button';
import Select from '@src/@core/components/ui/select';
import { getAllHubListAsyncData } from '@src/views/sales-channel/store';
import React, { useState } from 'react';
import { Info, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';

interface iReorderModalProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  // eslint-disable-next-line no-unused-vars
  handleContinue: ({ recreationType, hubId }: { recreationType: string; hubId: string }) => void;
  preloadData: {
    orderId: string
    assignedHubId: string
    recreationType: string
  }
}

interface iHubOptions {
  label: string
  value: string
}

const ReorderModal: React.FC<iReorderModalProps> = ({ isOpen, setIsOpen, handleContinue, preloadData  }) => {
  
  const { t } = useTranslation();
  const { recreationType } = preloadData;

  const [selectedHub, setSelectedHub] = useState<iHubOptions | null>(null);

  const handleCancel = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Modal isOpen={isOpen} centered contentClassName='rounded-16px'>
      <ModalBody className='p-24px d-flex flex-column gap-16px width-600'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-items-center gap-16px'>
            <div className='p-12px bg-primary-lighter-global rounded-8px'>
              <Info  height={32} width={32} color={'var(--bs-primary)'} />
            </div>
            <div className='txt-h1-sb text-dark'>Reordering</div>
          </div>
          <div>
            <X className='cursor-pointer' color='var(--bs-secondary)' size={24} onClick={handleCancel} />
          </div>
        </div>
        <div className='d-flex flex-column gap-16px'>

          <div className='text-dark'>
            {t('The order you are trying to reorder was fulfilled from a different hub than your current operating hub. Please select the hub you wish to use for fulfilling the reorder.')}
          </div>
          <Select
            name='hub'
            label='Hub'
            // @ts-expect-error FIX ME
            loadOptions={getAllHubListAsyncData}
            isAsync
            isRequired
            isClearable
            validationSchema={{ hub: { required: 'This field is required' } }}
            onChange={(option) => {
              setSelectedHub(option);
            }}
            // @ts-expect-error FIX ME
            value={selectedHub}
            // @ts-expect-error FIX ME
            defaultValue={selectedHub}
          />
          
          <div className='d-flex justify-content-end mt-24px'>
            <div className='d-flex gap-16px'>
              <Button className='flex-center-center' onClick={handleCancel} ofStyle='noBackground'>
                {t('Close')}
              </Button>
              {/* @ts-expect-error FIX ME */}
              <Button className='flex-center-center' onClick={() => handleContinue({ recreationType, hubId: selectedHub?.value })} disabled={!selectedHub} >
                {t('Continue')}
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReorderModal;
