import Checkbox from './components/attributesTypes/Checkbox';
import Dropdown from './components/attributesTypes/Dropdown';
import File from './components/attributesTypes/File';
import Image from './components/attributesTypes/Image';
import Text from './components/attributesTypes/Text';

export const ATTRIBUTES_TYPES = {
  TEXT: 'TEXT',
  DATE: 'DATE',
  TIME: 'TIME',
  FILE: 'FILE',
  NUMBER: 'NUMBER',
  IMAGE: 'IMAGE',
  DROPDOWN: 'DROPDOWN',
  CHECKBOX: 'CHECKBOX'
};

export interface iAttributeValueProp {
  values: string[]
  additionalClasses?: string | object
}

export const getComponentType = (attributeType: string) => {
  switch (attributeType) {
  case ATTRIBUTES_TYPES.IMAGE:
    return Image;
  case ATTRIBUTES_TYPES.FILE:
    return File;
  case ATTRIBUTES_TYPES.CHECKBOX:
    return Checkbox;
  case ATTRIBUTES_TYPES.DROPDOWN:
    return Dropdown;
  case ATTRIBUTES_TYPES.TEXT:
  case ATTRIBUTES_TYPES.NUMBER:
  case ATTRIBUTES_TYPES.DATE:
  case ATTRIBUTES_TYPES.TIME:
    return Text;
  default:
    return Text;
  }
};