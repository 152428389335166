import { useOnClickOutside } from '@src/utility/hooks/useOnClickOutside'
import classNames from 'classnames'
import { memo, useRef, useState } from 'react'
import { ChevronDown } from 'react-feather'
import { useTranslation } from 'react-i18next'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button as ReactstrapButton,
  UncontrolledDropdown
} from 'reactstrap'
import Button from '../button'
import './BulkActions.scss'

const BulkActions = ({
  actions,
  color = 'outlined',
  selectedButton,
  hasDropdownIcon = true,
  isDisabled = false,
  className = ''
  // setSelectedButton
}) => {
  const { t } = useTranslation()
  const Icon = selectedButton?.icon
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  
  const menuToggle = () => {
    setIsMenuOpen((p) => !p)
  }
  const onClickHandler = (event, button, selectedOption) => {
    if (typeof button.onClick === 'function') button.onClick(event, button, selectedOption)
    // if (isMenuOpen) setIsMenuOpen(false)
    else {
      menuToggle()
    }
  }

  const dropdownRef = useRef()
  const onBlurHandler = () => {
    if (isMenuOpen) setIsMenuOpen(false)
  }

  useOnClickOutside(dropdownRef, onBlurHandler)

  return (
    <div id="bulk-actions-btn" ref={dropdownRef} className={`bulk-actions ${color} ${className}`} >
      {actions.length ?
        <UncontrolledDropdown group isOpen={isMenuOpen} className={classNames({
          'btn-disabled': isDisabled
        })}>
          <ReactstrapButton
          // onBlur={onBlurHandler}
            {...selectedButton}
            onClick={(e) => onClickHandler(e, selectedButton, {id: selectedButton.id, text :selectedButton.text})}
            className={`name ${selectedButton?.className} py-10px`}
            disabled={isDisabled}
          >
            {/* <div > */}
            {Icon && (
              <span >
                <Icon
                  color={color === 'outlined' ? 'var(--bs-primary)' : 'var(--bs-white)'}
                  size={20}
                />
              </span>
            )}
            <span className="txt-body-rg text-nowrap">{t(selectedButton?.text)}</span>
            {/* </div> */}
          </ReactstrapButton>
          <DropdownToggle
            className="dropdown-toggle"
            onClick={menuToggle}
            // onBlur={onBlurHandler}
            disabled={isDisabled}
          >
            <ChevronDown
              color={color === 'outlined' ? 'var(--bs-primary)' : 'var(--bs-white)'}
              size={16}
            />
          </DropdownToggle>
          <DropdownMenu>
            {actions.map(({ id, text, icon: Icon, ...rest }, index) => {
              return (
                <DropdownItem
                  {...rest}
                  className={`w-100 ${+id === +selectedButton?.id ? 'active' : ''}`}
                  key={id || index}
                  onClick={(e) => onClickHandler(e, rest, {id, text})}
                >
        
                  {(Icon && hasDropdownIcon) && (
                    <span >
                      <Icon
                        color={+id !== +selectedButton?.id ? 'var(--bs-dark)' : 'var(--bs-white)'}
                        size={14}
                        height={14}
                        width={14}
                      />
                    </span>
                  )}
                  <span className="txt-sub-rg">{t(text)}</span>
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </UncontrolledDropdown> : <Button
          // onBlur={onBlurHandler}
          {...selectedButton}
          onClick={(e) => onClickHandler(e, selectedButton, {id: selectedButton.id, text :selectedButton.text})}
          className={`name ${selectedButton?.className}`}
          disabled={isDisabled}
          ofStyle={color === 'outlined' ? color : 'filled'}
        >
          {/* <div > */}
          {Icon && (
            <span >
              <Icon
                color={color === 'outlined' ? 'var(--bs-primary)' : 'var(--bs-white)'}
                size={20}
              />
            </span>
          )}
          <span className="txt-body-rg">{t(selectedButton?.text)}</span>
          {/* </div> */}
        </Button>}
    </div>
  )
}

export default memo(BulkActions)
