import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { AlertCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { UncontrolledTooltip } from 'reactstrap'
import './styles.scss'

const RadioButton = React.forwardRef(({ id, name, label, size, className = '', disabled, isLabelBeforeInput, tooltipId, tooltipText, description = '', ...props }, ref) => {
  const { t } = useTranslation()
  const inputId = id || React.useRef(Date.now())
  return (
    <div className='omniful-radio-button-component-wrapper'>
      <label className='omniful-radio-button' htmlFor={`${name}_${inputId}`}>
        <div className={classNames('d-flex p-4px', {'flex-row-reverse': isLabelBeforeInput, 'gap-8px': label, 'gap-0': !label})}>
          <input
            id={`${name}_${inputId}`}
            name={name}
            type='radio'
            className={classNames(`cursor-pointer input-radio-overrides mt-2px  radio-input-${size}`, { 'cursor-default': disabled})}
            disabled={disabled}
            {...props}
            ref={ref}
          />
          <div>
            <label htmlFor={`${name}_${inputId}`} className={classNames(`radio-label cursor-pointer ${className}`, { 'cursor-default': disabled })}>
              {label}
            </label>
            {tooltipId && <> <AlertCircle size={16} id={tooltipId}/>
              <UncontrolledTooltip offset={[0, 5]} target={tooltipId}>
                {t(tooltipText || label)}
              </UncontrolledTooltip>
            </>}
            {description !== '' && <p className='text-dark-5'>{description}</p>}
          </div>
        </div>
      </label>
    </div>
  )
})

RadioButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isLabelBeforeInput: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg'])
}

RadioButton.defaultProps = {
  size: 'lg'
}

export default RadioButton
