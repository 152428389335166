import Select from '@src/@core/components/ui/select';
import { GET_CURRENCIES } from '@src/redux/authentication';
import { Controller } from 'react-hook-form';
import { BUNDLE_SKU_FIELDS_NAME, CURRENCY } from '../../catalog.constants';
import './styles.scss';
export function BundleCurrencies({
  t,
  totalRetailPrice,
  bundleCreationLoading,
  imageGettingUpload,
  retailPriceRef,
  skuRetailPrice,
  skuRetailPriceHandler,
  watch,
  control,
  isEdit,
  singleSkuLoading,
  totalSellingPrice,
  sellingPriceRef,
  skuSellingPrice,
  skuSellingPriceHandler,
  updateBundleSkusRes
})

{
  const isLoading = bundleCreationLoading || updateBundleSkusRes.loading || imageGettingUpload

  return (
    <div className="d-flex justify-content-end bundle-pricing w-100">
      <div className="d-flex gap-2 flex-column w-100">
        <div className="d-flex gap-5 align-items-center justify-content-end">
          <div className="text-end txt-h3-md d-flex flex-column justify-content-center align-items-end sku-retail-price-text text-dark-6">
            {t('Kit Retail Price')}
          </div>
          <div className="txt-h3-sb total-retail-price d-flex gap-8px align-items-center justify-content-end">
            {totalRetailPrice >= 0 ? (
              <input
                disabled={isLoading}
                type="number"
                ref={retailPriceRef}
                className="text-end price-input bg-transparent text-dark txt-h3-sb "
                value={
                  skuRetailPrice === 0 || skuRetailPrice > 0
                    ? Number(skuRetailPrice)
                    : Number(totalRetailPrice).toFixed(totalRetailPrice ? 2 : 0)
                }
                onChange={(e) => skuRetailPriceHandler(e)}
                onWheel={(e) => e.target.blur()}
              />
            ) : (
              '-'
            )}
            <div>
              {
                <Controller
                  name={BUNDLE_SKU_FIELDS_NAME.CURRENCY}
                  control={control}
                  render={({ field }) => (
                    <Select
                      isAsync
                      {...field}
                      name={BUNDLE_SKU_FIELDS_NAME.CURRENCY}
                      loadOptions={GET_CURRENCIES}
                      width="100px"
                      isDisabled={
                        (isEdit && singleSkuLoading) || isLoading
                      }
                      defaultValue={CURRENCY.SAR}
                      additional={{
                        page: 1
                      }}
                      customComponents={{ LoadingIndicator: () => null }}
                      menuPlacement='auto'
                      modifiedClassNames={{
                        container: () => 'width-100',
                        loadingMessage: () => 'txt-body-rg',
                        noOptionsMessage: () => 'txt-body-rg',
                      }}
                    />
                  )}
                />
              }
            </div>
          </div>
        </div>
        <div className="d-flex gap-5 align-items-center justify-content-end">
          <div className="text-end txt-h1-sb d-flex flex-column justify-content-center align-items-end sku-retail-price-text text-dark">
            {t('Kit Selling Price')}
          </div>
          <div className="txt-h3-sb total-selling-price d-flex gap-8px align-items-center justify-content-end">
            {totalSellingPrice >= 0 ? (
              <input
                disabled={isLoading}
                type="number"
                ref={sellingPriceRef}
                className="text-end price-input bg-transparent text-dark txt-h3-sb "
                value={
                  skuSellingPrice === 0 || skuSellingPrice > 0
                    ? Number(skuSellingPrice)
                    : Number(totalSellingPrice).toFixed(
                      totalSellingPrice ? 2 : 0
                    )
                }
                onChange={(e) => skuSellingPriceHandler(e)}
                onWheel={(e) => e.target.blur()}
              />
            ) : (
              '-'
            )}
            <div>
              {
                
                <Controller
                  name={BUNDLE_SKU_FIELDS_NAME.CURRENCY}
                  control={control}
                  render={({ field }) => (
                    <Select
                      isAsync
                      {...field}
                      name={BUNDLE_SKU_FIELDS_NAME.CURRENCY}
                      loadOptions={GET_CURRENCIES} 
                      width="100px"
                      isDisabled={
                        (isEdit && singleSkuLoading) || isLoading
                      }
                      defaultValue={CURRENCY.SAR}
                      additional={{
                        page: 1
                      }}
                      modifiedClassNames={{
                        container: () => 'width-100',
                        loadingMessage: () => 'txt-body-rg',
                        noOptionsMessage: () => 'txt-body-rg',
                      }}
                      menuPlacement='auto'
                      customComponents={{ LoadingIndicator: () => null }}
                    />

                  )}
                />
                
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
