import { ErrorIcon } from '@src/assets/images/icons/ErrorIcon'
import { useRTL } from '@src/utility/hooks/useRTL'
import { useState } from 'react'
import Flatpickr from 'react-flatpickr'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

/**
 * A reusable date picker component that allows users to select a date range.
 *
 * @param {string} name - The name of the field.
 * @param {object} control - The control object from react-hook-form.
 * @param {number} index - The index of the condition.
 * @param {object} errors - The error object containing validation errors.
 * @param {boolean} [disabled=false] - Whether the date picker is disabled.
 * @return {JSX.Element} The date picker component.
 */
const DatePicker = ({ name, control, index, errors, disabled = false }) => {
  const [selectedDate, setSelectedDate] = useState(null)
  const { t } = useTranslation()
  const [isRTL] = useRTL()
  let isNested = false
  let nestedError
  if (name?.includes('.')) {
    isNested = true
    nestedError = errors
    for (const key of name.split('.')) {
      nestedError = nestedError?.[key]
    }
  }
  const handleDateChange = (dates) => {
    setSelectedDate(dates || null)
  }
  return (
    <div id='date-picker-input' className='floating-label-container'>
      <Controller
        control={control}
        id='value'
        rules={{ required: t('Required Field') }}
        name={name}
        render={({ field }) => (
          <Flatpickr
            {...field}
            className={`form-control w-100 ${((errors?.[name] || nestedError) && !disabled) ? 'border border-danger' : ''}`}
            placeholder='YYYY/MM/DD H:m - YYYY/MM/DD H:m'
            value={field.value?.length ? `${field.value[0].label} to ${field.value[field.value.length - 1].label}` : null}
            options={{
              mode: 'range',
              enableTime: true,
              dateFormat: 'Y-m-d H:i',
              locale: isRTL ? 'ar' : 'en',
              static: true,
              allowClear: true
            }}
            onChange={(e) => {
              field.onChange(e)
              handleDateChange(e)
            }}
            ref={() => {
              field.ref()
            }}
            disabled={disabled}
          />
        )}
      />
      <label className={`floating-label${selectedDate ? ' active' : ''}`}>{isRTL && <span className='text-danger'>*</span>}{t('Select Date Range')}{isRTL === false && <span className='text-danger'>*</span>}</label>
      <div>
        {(errors?.[name] || nestedError) && !disabled && (
          <div className="d-flex align-items-sm-center error-state">
            <span className="ms-12px me-8px pb-4px"> <ErrorIcon/> </span>
            <span className="text-danger txt-sub-rg">
              {isNested ? nestedError?.message : errors?.[name]?.message}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default DatePicker