import React, { ReactElement } from 'react';
import FloatingDropdown from './FloatingDropdown';
import { iSelectProps } from './select.types';
import './styles.scss';

const Select: React.FC<iSelectProps> = ({ variant = 'floatingDropdown', ...rest }): ReactElement => {

  const variants = {
    floatingDropdown: <FloatingDropdown variant={variant} {...rest} />,
  };

  const renderedDropdown = variants[variant];
  return <div id="_omniful-select-component">{renderedDropdown}</div>;
};

export default Select;
