// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit/dist'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { API_ENDPOINTS } from '@src/api.urls'
import { HUBS_CONFIGURATION, TENANT_TYPE } from '@src/App.constants'
import ability from '@src/configs/acl/ability'
import { axiosInstance } from '@src/network/AxiosInstance'
import { BeamManager, disconnectFromPusher } from '@src/network/RealTime'
import { customPermissionsWithChecks, insertCustomPermission } from '@src/utility/Utils'
import axios from 'axios'

export const beamManager = new BeamManager()

const initialUser = () => {
  const item = window.localStorage.getItem('auth')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}
// const fullAbility = {
//   action: "manage",
//   subject: "all"
// }

const updateUserPermissions = roles => {
  let per = []
  roles.forEach(r => {
    per = per.concat(r.permissions)
  })
  const userPermissions = []
  per.forEach(p => {
    // const [act, sub] = p?.name?.split('_')
    userPermissions.push({ action: p.action, subject: p.entity })
  })
  return userPermissions
}

export const getFreshDeskAuthToken = async () => {
  try {
    const response = await axiosInstance.post('/api/v1/chat_support/authenticate')
    return response?.data
  } catch (err) {
    return err
  }
}

export const tenantlogin = createAsyncThunk('tenantLogin', async data => {
  const { username } = data
  localStorage.setItem('userInfo', JSON.stringify({ username }))
  const response = await axiosInstance.post('/api/v1/users/login', data, { headers: { 'X-UserName': data.email } })
  return response?.data
})

export const checkDomainExistance = createAsyncThunk('checkDomain', async () => {
  const res = await axiosInstance.get('/api/v1/tenant/domain/check')
  return res.data
})

export const bootApiToGetUserDetails = createAsyncThunk('bootApi', async (_, store) => {
  const storedHubId = JSON.parse(localStorage.getItem('selectedGlobalHubId'))
  try {
    const res = await axiosInstance.get('/api/v1/tenant/users/boot', { params: { hub_id: storedHubId } })
    const user = res?.data?.data?.user
    let hubConfig
    let freshDeskAuthData

    if (user) {
      const selectedHubId = user?.hubs?.find(hub => hub.id === storedHubId)?.id || (user?.hubs?.[0]?.id || null)
      if (selectedHubId) {
        const response = await axiosInstance.get(`/api/v1/wms/hubs/${selectedHubId}/configurations`)
        hubConfig = response?.data?.data
      }
      const freshdeskAuthentication = await getFreshDeskAuthToken()
      freshDeskAuthData = freshdeskAuthentication?.data
    }
    return { user, hubConfig, freshDeskAuthData, store }
  } catch (error) {
    return { isError: error.response?.status >= 400, isUnauthorized: error.response?.status === 401 }
  }
})

export const logout = createAsyncThunk('logout', async () => {
  const res = await axiosInstance.post('/api/v1/users/logout')
  try {
    if (beamManager.beamsClient) {
      await beamManager.stopAndLogoutBeam()
    }
  } catch (error) {
    console.warn('Error while stop beam on logout',error)
  }
  return res
})

export const forgotPassword = createAsyncThunk('forgotPassword', async data => {
  const res = await axiosInstance.post('/api/v1/users/forgot_password', data)
  return res
})

export const setPassword = createAsyncThunk('setPassword', async ({ data, token }) => {
  const res = await axiosInstance.post(`/api/v1/users/password?token=${token}`, data)
  return res
})

export const resetPassword = createAsyncThunk('resetPassword', async ({ data, token }) => {
  const res = await axiosInstance.post(`/api/v1/users/reset_password?token=${token}`, data)
  return res
})

export const uploadFileOnS3 = async params => {
  /* STEPS:
    1. generate presigned-url => response will have: "upload_id" and "temp_url"
    2. upload image to "temp_url"
    3. after upload success return "upload_id" to calling function
  */
  try {
    const res = await axiosInstance.post('/api/v1/uploads/temp-url', {
      service: params.service,
      usecase: params.usecase,
      content_type: params.file.type
    })
    const url = res?.data?.data.temp_url
    if (url) {
      const config = {
        headers: {
          'Content-Type': params.file.type
        }
      }

      const response = await axios.put(url, params.file, config)
      if (response.status === 200) {
        return res.data.data.upload_id
      }
    }
  } catch (error) {
    console.warn(error)
    CustomToast('File upload failed. Please try again', { my_type: 'info' })
  }

}

export const GET_CURRENCIES = async (search = '', loadedOptions, { page = 1, currencyWithCountry = false }) => {
  const params = { ...(search && { search_column: 'code', search_query: search }), page }
  try {
    const { data } = await axiosInstance.get(API_ENDPOINTS.COMMON.GET_CURRENCIES, { params })
    const { data: currencies, meta } = data
    const options = currencies.map(({ code, display_name, name }) => ({ value: code, label: currencyWithCountry ? display_name : code, name }))
    return { options, hasMore: page < meta.last_page, additional: { page: page + 1, currencyWithCountry } }
  } catch {
    return { options: [], hasMore: false, additional: { page } }
  }
}

export const uploadMultipleFilesOnS3 = async (images) => {
  try {
    const res = await Promise.all(images.map(image => uploadFileOnS3(image)))
    // Handle the data when all promises are resolved
    return res
  } catch (error) {
    console.warn(error)
    // Handle errors if any of the promises fail
    CustomToast('Upload failed. Please try again', { my_type: 'error', duration: 2000, audioRequired: false })
    return null
  }
}

export const getGlobalHubsList = createAsyncThunk('global-hublist', async (params, store) => {
  const global_hub_id = store.getState().auth.selectedGlobalHubId
  params = { ...params, global_hub_id }
  const res = await axiosInstance.get('/api/v1/wms/hubs', { params })
  return res
})

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    tableFilterDropdownOpen: false,
    authData: initialUser(),
    userData: null,
    permissions: null,
    domainDataAvailable: false,
    domainExist: true,
    success:false,
    loading: false,
    errors: null,
    fileUploadUrl: null,
    userHubs: [],
    userSellers: [],
    selectedGlobalHubId: null,
    bootApiError: false,
    passwordReset: false,
    passwordSet: false,
    selectedHubConfiguration: null,
    globalSeller: null,
    getLimitExceedInfo: null,
    pusherResponse: {},
    globalHubMeta: null,
    loadingState: {},
    globalHubSearchResults: [],
    freshDeskAuthData: null,
    isPopupBlocked: false,
    isFeatureNotificationBannerVisible: false,
    pusherLoading: false,
    domainData: null,
    blocker: null,
    checkDomainLoading: false,
    isOrderTagsVersionTwoEnabled: false,
    redirectState: null,
    selectedGlobalHub: null,
    defaultSeller: null,
    printersConfigurations: {}
  },
  reducers: {
    setTableFilterDropdownOpen: (state, action) => {
      state.tableFilterDropdownOpen = action.payload
    },
    setBlocker: (state, action) => {
      state.blocker = action.payload
    },
    clearLimitExceedResponse: (state) => {
      state.getLimitExceedInfo = null
    },
    handleSelectedHub: (state, action) => {
      state.selectedGlobalHubId = action.payload
      localStorage.setItem('selectedGlobalHubId', JSON.stringify(action.payload))
      window.location.reload()
    },
    handlePusherResponse: (state, action) => {
      state.pusherResponse = { ...state.pusherResponse, ...action.payload }
    },
    cleanupPusherResponse: (state) => {
      state.pusherResponse = {}
    },
    resetUserHubsAndMeta: (state) => {
      state.globalHubSearchResults = []
      state.userHubs = state.userData.hubs
      state.globalHubMeta = state.userData.hub_meta
    },
    handlePupupBlocked: (state, action) => {
      state.isPopupBlocked = action.payload.isPopupBlocked
    },
    handleFeatureNotificationBanner: (state, action) => {
      state.isFeatureNotificationBannerVisible = action.payload.bannerState
    },
    setPusherLoading: (state, action) => {
      state.pusherLoading = action.payload
    },
    setRedirectResponse: (state, action) => {
      state.redirectState = action.payload
    },
    clearRedirectResponse: (state ) => {
      state.redirectState = null
    },
    clearAuthData: (state) => {
      state.authData = null
    },
    updatePrintersConfigurations: (state, action) => {
      state.printersConfigurations = action.payload
    }
  },

  extraReducers: builder => {

    builder.addCase(tenantlogin.pending, state => {
      state.loading = true
      state.success = false
      state.error = false
    })
    builder.addCase(tenantlogin.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.authData = action.payload.data
      }
      if (action.payload?.data?.access_token) {
        const { log_out_info, ...restAuthData } = action.payload.data
        state.getLimitExceedInfo = log_out_info
        window.localStorage.setItem('auth', JSON.stringify(restAuthData))
      }
      state.loading = false
      state.success = true
      state.error = false
    })
    builder.addCase(tenantlogin.rejected, state => {
      state.loading = false
      state.success = false
      state.error = true
    })
    builder.addCase(bootApiToGetUserDetails.pending, state => {
      state.loading = true
    })
    builder.addCase(bootApiToGetUserDetails.fulfilled, (state, action) => {
      if (action.payload?.user) {
        const selectedHubConfiguration = action.payload?.hubConfig
        state.selectedHubConfiguration = selectedHubConfiguration

        const locationHubConfig = selectedHubConfiguration?.find(el => el.configuration_type === HUBS_CONFIGURATION.LOCATION_INVENTORY.value)
        const isLocationInventoryEnabled = locationHubConfig?.configuration_values?.enabled
        const user = action.payload?.user
        const roles = user?.roles || []
        const userPermissions = updateUserPermissions(roles)
        state.isOrderTagsVersionTwoEnabled = action.payload.user.tenant.is_order_tag_v2_enabled

        const updatedPermissions = insertCustomPermission({ userPermissions, customPermissionsWithChecks })
        const isPosEnabled = user.user_plan.is_pos_enabled
        // function to add custom permissions
        const addPermission = (condition, action, subject) => {
          if (condition) userPermissions.push({ action, subject })
        }

      
        state.userData = user
        state.userHubs = user?.hubs || []
        state.globalHubMeta = user?.hub_meta || {}
        const storedHubId = JSON.parse(localStorage.getItem('selectedGlobalHubId'))
        const selectedHub = user?.hubs?.find(hub => hub.id === storedHubId) || (user?.hubs?.[0] || null)
        const selectedHubId = selectedHub?.id
        state.selectedGlobalHub = selectedHub
        
        if (selectedHubId) {
          localStorage.setItem('selectedGlobalHubId', JSON.stringify(selectedHubId))
        }

        const is_external_hub = user.hubs?.find(hub => hub.id === selectedHubId)?.is_external_hub
      
        // adding various permissions
        {
          addPermission(isPosEnabled, 'custom_view', 'pos')
        }

        {
          const hasViewSellerPerm = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'seller')
          const isMultiTenant = user.tenant.type === TENANT_TYPE.FULFILLMENT_CENTRE
          addPermission(hasViewSellerPerm && isMultiTenant, 'custom_view', 'seller')
        }

        {
          const hasViewGrnPerm = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'grn')
          addPermission(!is_external_hub && hasViewGrnPerm && isLocationInventoryEnabled, 'custom_view', 'grn')
        }

        {
          const hasViewGateEntryPerm = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'gate_entry')
          addPermission(!is_external_hub && hasViewGateEntryPerm && isLocationInventoryEnabled, 'custom_view', 'gate_entry')
        }

        {
          const hasViewPutAwayPerm = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'put_away')
          addPermission(!is_external_hub && hasViewPutAwayPerm && isLocationInventoryEnabled, 'custom_view', 'put_away')
        }
      
        {
          const hasViewPendingPutAwayPerm = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'put_away')
          addPermission(!is_external_hub && hasViewPendingPutAwayPerm, 'custom_view', 'pending_put_away')
        }

        {
          const hasViewAssemblyPerm = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'assembly')
          addPermission(hasViewAssemblyPerm && isLocationInventoryEnabled, 'custom_view', 'assembly')
        }

        {
          const hasViewStoPerm = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'sto')
          addPermission(hasViewStoPerm, 'custom_view', 'stock_transfer')
        }

        {
          const hasInvoiceViewPerm = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'invoice')
          addPermission(hasInvoiceViewPerm && !isPosEnabled, 'custom_view', 'tax_invoices')
        }

        {
          const hasProductViewPerm = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'product')
          addPermission(hasProductViewPerm && !isPosEnabled, 'custom_view', 'sales_channel_listing')
          addPermission(hasProductViewPerm && !isPosEnabled, 'custom_view', 'kits')
        }

        {
          const hasInvoiceConfigurePerm = updatedPermissions.find(perm => perm.action === 'configure' && perm.subject === 'invoice')
          addPermission(hasInvoiceConfigurePerm && !isPosEnabled, 'custom_view', 'manage_invoice')
        }

        {
          const hasStoViewPermission = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'sto')
          addPermission(hasStoViewPermission, 'custom_view', 'sto_requests')
        }

        {
          const hasPickingWaveViewPermission = updatedPermissions.find(perm => perm.action === 'view_wave' && perm.subject === 'picking')
          addPermission(!is_external_hub && hasPickingWaveViewPermission, 'custom_view', 'picking_wave')
        }

        {
          const hasOrderViewPerm = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'order')
          addPermission(!is_external_hub && hasOrderViewPerm && !isPosEnabled, 'custom_view', 'bulk_ship')
        }

        {
          const hasHubAssetsViewPermission = updatedPermissions.find(perm => perm.action === 'view_bin' && perm.subject === 'hub_location')
          addPermission(!is_external_hub && hasHubAssetsViewPermission, 'custom_view', 'hub_assets')
        }

        {
          const hasBinViewPerm = updatedPermissions.find(perm => perm.action === 'view_bin' && perm.subject === 'hub_location')
          addPermission(!is_external_hub && hasBinViewPerm, 'custom_view', 'hub_bin')
        }

        {
          const hasHubLocationViewPermission = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'hub_location')
          addPermission(!is_external_hub && hasHubLocationViewPermission, 'custom_view', 'hub_locations_zones')
        }

        {
          const hasSkuMappingViewPerm = updatedPermissions.find(perm => perm.action === 'view_sku_mapping' && perm.subject === 'hub_location')
          addPermission(!is_external_hub && hasSkuMappingViewPerm && !isLocationInventoryEnabled, 'custom_view', 'sku_locations')
        }

        {
          const hasPurchaseOrderViewPermission = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'purchase_order')
          addPermission(hasPurchaseOrderViewPermission, 'custom_view', 'purchase_orders')
        }

        {
          const hasInventoryViewPerm = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'inventory')
          addPermission(!is_external_hub && hasInventoryViewPerm && isLocationInventoryEnabled, 'custom_view', 'inventory')
        }

        {
          addPermission(!is_external_hub, 'custom_view', 'inventory_operations')
        }

        {
          const hasManifestViewPermission = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'manifest')
          addPermission(!is_external_hub && hasManifestViewPermission, 'custom_view', 'manifest_view')
        }
      
        {
          const hasShipmentViewPermission = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'shipment')
          addPermission(!is_external_hub && hasShipmentViewPermission, 'custom_view', 'shipments_tracking_view')
        }
      
        {
          const hasCycleCountViewPermission = updatedPermissions.find(perm => perm.action === 'view' && perm.subject === 'cycle_count')
          addPermission(!is_external_hub && hasCycleCountViewPermission, 'custom_view', 'cycle_count_view')
        }
      
        {
          const hasAdhocUsageViewPermission = updatedPermissions.find(perm => perm.action === 'view_usage' && perm.subject === 'adhoc')
          addPermission(!is_external_hub && hasAdhocUsageViewPermission, 'custom_view', 'adhoc_usage_view')
        }

        const userInfo = { username: user.username, name: user.name, email: user.email }
        localStorage.setItem('userInfo', JSON.stringify(userInfo))

        state.selectedGlobalHubId = selectedHubId
        state.globalSeller = user?.sellers?.[0]
        state.freshDeskAuthData = action.payload?.freshDeskAuthData

        state.userSellers = user?.sellers
        state.defaultSeller = user?.sellers[0]
        state.permissions = updatedPermissions
        ability.update(updatedPermissions)
        if (!localStorage.getItem('is_banner_visible')) {
          localStorage.setItem('is_banner_visible', JSON.stringify('open-notification'))
        }
      }
      if (action.payload.isUnauthorized){
        state.bootApiError = false
      } else {
        state.bootApiError = action.payload.isError
      }
      state.loading = false
    })
    builder.addCase(bootApiToGetUserDetails.rejected, state => {
      state.loading = false
    })
    builder.addCase(checkDomainExistance.pending, (state) => {
      state.checkDomainLoading = true
      state.domainDataAvailable = false
      state.domainData = null
      state.domainExist = false
    })
    builder.addCase(checkDomainExistance.fulfilled, (state, action) => {
      state.domainDataAvailable = true
      state.domainExist = action.payload.data.is_valid
      state.domainData = action.payload.data
      window.localStorage.setItem('tenant_info', JSON.stringify(action.payload.data.tenant_info))
      state.checkDomainLoading = false
    })
    builder.addCase(checkDomainExistance.rejected, (state) => {
      state.domainDataAvailable = false
      state.domainExist = false
      state.domainData = null
      state.checkDomainLoading = false
    })

    builder.addCase(forgotPassword.pending, (state) => {
      state.loading=true
    })
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      if (action?.payload?.status === 200) {
        state.loading=false
        CustomToast('A password reset link has been sent to your email', { my_type: 'success' })
      }
    })
    builder.addCase(forgotPassword.rejected, (state) => {
      state.loading=false
    })
    
    builder.addCase(resetPassword.pending, (state) => {
      state.loading=true
    })
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      if (action?.payload?.status === 200) {
        state.loading=false
        CustomToast('Password reset successful', { my_type: 'success' })
        state.passwordReset = true
      }
    })
    builder.addCase(resetPassword.rejected, (state) => {
      state.loading=false
    })

    builder.addCase(setPassword.pending, (state) => {
      state.loading=true
    })
    builder.addCase(setPassword.fulfilled, (state, action) => {
      if (action?.payload?.status === 200) {
        state.loading=false
        CustomToast('Password set successfully', { my_type: 'success' })
        state.passwordSet = true
      }
    })
    builder.addCase(setPassword.rejected, (state) => {
      state.loading=false
    })

    builder.addCase(getGlobalHubsList.fulfilled, (state, action) => {
      if (action.payload.data.meta.current_page === 1 || action.payload.data.meta.current_page === 0) {
        state.globalHubSearchResults = action.payload.data.data
      } else {
        state.userHubs = [...state.userHubs, ...action.payload.data.data]
      }
      state.globalHubMeta = action.payload.data.meta
      state.loadingState = { globalHubsList: false }
    })
    builder.addCase(getGlobalHubsList.pending, (state) => {
      state.loadingState = { globalHubsList: true }
    })
    builder.addCase(getGlobalHubsList.rejected, (state) => {
      state.loadingState = { globalHubsList: false }
    })
    builder.addCase(logout.pending, (state) => {
      state.loading = true
    })
    builder.addCase(logout.fulfilled, (state, action) => {
      state.loading = false
      if (action.payload?.status === 200) {
        disconnectFromPusher()
        const tenantInfo = window.localStorage.getItem('tenant_info')
        const user_printer_preference =  window.localStorage.getItem('user_printer_preference')
        window.localStorage.clear()
        window.localStorage.setItem('tenant_info', tenantInfo)
        window.localStorage.setItem('user_printer_preference', user_printer_preference)
        window.sessionStorage.clear()
        state.authData = null
        state.userData = null
        state.selectedGlobalHubId = null
        state.selectedGlobalHub = null
        state.userHubs = null
        state.userSellers = null
        state.permissions = null
        state.freshDeskAuthData = null
        window.FreshworksWidget('logout')
        window.location.reload()
        state.defaultSeller = null
      }
    })
    builder.addCase(logout.rejected, (state) => {
      state.loading = false
    })
    // TODO: add rejected case and handle loading
  }
})

export const { handleSelectedHub, handlePusherResponse, cleanupPusherResponse, resetUserHubsAndMeta, clearLimitExceedResponse, setTableFilterDropdownOpen, handlePupupBlocked, handleFeatureNotificationBanner, setPusherLoading, setBlocker, clearAuthData, setRedirectResponse, clearRedirectResponse, updatePrintersConfigurations } = authSlice.actions


export default authSlice.reducer