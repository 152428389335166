import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { updatePrintersConfigurations } from '@src/redux/authentication'
import { useDispatch } from 'react-redux'

const usePrintersConfigurations = () => {
  const dispatch = useDispatch()
  const hubId = JSON.parse(localStorage.getItem('selectedGlobalHubId'))

  return useApi({
    isMutation: true,
    apiKey: ['get-printers-configurations'],
    apiFn: () => {
      const params = { hub_id: hubId }
      return axiosInstance.get('/api/v1/pms/printer_configurations', { params })
    },
    onSuccess: (data) => {
      dispatch(updatePrintersConfigurations(data?.data?.printers || {}))
    }
  })
}

export default usePrintersConfigurations