import { API_ENDPOINTS, getApiUrl } from '@src/api.urls'
import { AUTOMATION_OPERATIONS } from '@src/components/rule/rule.constant'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'

export const API_KEY = {
  FETCH_AUTOMATION_RULES: 'fetch-gift-item-automation-rules',
  FETCH_AUTOMATION_RULE_DETAIL: 'fetch-gift-item-automation-rule-detail',
  CREATE_AUTOMATION_RULE: 'create-gift-item-automation-rule',
  EDIT_AUTOMATION_RULE: 'edit-gift-item-automation-rule',
  FETCH_CONDITIONS: 'fetch-gift-item-conditions',
  UPDATE_AUTOMATION_RULE_STATUS: 'update-gift-item-automation-rule-status',
  UPDATE_PRIORITY_RULES: 'update-gift-item-priority-rules'
}

export const fetchAutomationRules = ({params, onSuccess}) => {
  return useApi({
    apiKey: [API_KEY.FETCH_AUTOMATION_RULES, {params}], 
    apiFn: () => {
      const url = getApiUrl(API_ENDPOINTS.AUTOMATION.ADD_ITEMS.FETCH_RULES, {sellerId: params.seller_id})
      delete params.seller_id
      return axiosInstance.get(url, { params })
    },
    select: (res) => {
      if (res?.data?.length) {
        const data = res.data.map((ele) => {
          return {
            ...ele
          }
        })
        return {data, meta: res.meta}
      }
      return {data: [], meta: res.meta}
    },
    onSuccess: ({data}) => {
      if (onSuccess) onSuccess(data)
    }
  })
}

export const fetchAutomationRuleDetail = ({onSuccess}) => {
  return useApi({
    apiKey: [API_KEY.FETCH_AUTOMATION_RULE_DETAIL], 
    isMutation: true,
    apiFn: ({params}) => {
      const url = getApiUrl(API_ENDPOINTS.AUTOMATION.ADD_ITEMS.FETCH_RULE_DETAIL, {ruleId: params.ruleId, sellerId: params.seller_id})
      return axiosInstance.get(url)
    },
    onSuccess: ({data}) => {
      if (onSuccess) onSuccess(data)
    }
  })
}

export const createAutomationRule = ({onSuccess}) => {
  return useApi({
    apiKey: [API_KEY.CREATE_AUTOMATION_RULE], 
    isMutation: true,
    apiFn: ({body, seller_id}) => {
      const url = getApiUrl(API_ENDPOINTS.AUTOMATION.ADD_ITEMS.CREATE_RULE, {sellerId: seller_id})
      return axiosInstance.post(url, body)
    },
    onSuccess: () => {
      if (onSuccess) onSuccess()
    }
  })
}

export const editAutomationRule = ({onSuccess}) => {
  return useApi({
    apiKey: [API_KEY.EDIT_AUTOMATION_RULE],
    isMutation: true,
    apiFn: ({ruleId, body, seller_id}) => {
      body.operation = AUTOMATION_OPERATIONS.EDIT
      const url = getApiUrl(API_ENDPOINTS.AUTOMATION.ADD_ITEMS.EDIT_RULE, {ruleId, sellerId: seller_id})
      return axiosInstance.put(url, body)
    },
    onSuccess: () => {
      if (onSuccess) onSuccess()
    }
  })
}

export const updateAutomationRuleStatus = ({onSuccess}) => {
  return useApi({
    apiKey: [API_KEY.UPDATE_AUTOMATION_RULE_STATUS],
    isMutation: true,
    apiFn: ({ruleId, body, seller_id}) => {
      const url = getApiUrl(API_ENDPOINTS.AUTOMATION.ADD_ITEMS.EDIT_RULE, {ruleId: ruleId, sellerId: seller_id})
      return axiosInstance.put(url, body)
    },
    onSuccess: () => {
      if (onSuccess) onSuccess()
    }
  })
}

export const fetchConditions = ({onSuccess}) => {
  return useApi({
    apiKey: [API_KEY.FETCH_CONDITIONS], 
    isMutation: true,
    apiFn: ({params}) => {
      const url = getApiUrl(API_ENDPOINTS.AUTOMATION.ADD_ITEMS.FETCH_CONDITIONS, {sellerId: params.seller_id})
      return axiosInstance.get(url)
    },
    onSuccess: ({data}) => {
      if (onSuccess) onSuccess(data)
    }
  })
}

export const updatePriorityRules = ({onSuccess}) => {
  return useApi({
    apiKey: [API_KEY.UPDATE_PRIORITY_RULES],
    isMutation: true,
    apiFn: ({body, seller_id}) => {
      const url = getApiUrl(API_ENDPOINTS.AUTOMATION.ADD_ITEMS.EDIT_PRIORITY, {sellerId: seller_id})
      return axiosInstance.put(url, body)
    },
    onSuccess: () => {
      if (onSuccess) onSuccess()
    }
  })
}
