import InputField from '@src/@core/components/ui/input-field'
import { Info } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { UncontrolledTooltip } from 'reactstrap'

const RangeInputGroup = (props) => {
  const { register, errors, isRequired, disabled, index, fieldsName } = props
  const { t } = useTranslation()

  return (
    <div className='flex-start-between w-100'>
      <div className='flex-start-start gap-1'>
        <InputField
          {...register(`${fieldsName}.${index}.value.start_value`, { required: 'Required' })}
          type='number'
          step='any'
          errors={errors}
          isRequired={isRequired}
          disabled={disabled}
          label='Start Value'
          width='100%'
        />
        <Info size={18} id={'tooltip1'} className='mt-12px' />
        <UncontrolledTooltip offset={[0, 5]} target={'tooltip1'}>
          {t('Does not include the starting value')}
        </UncontrolledTooltip>
      </div>
      <div className='mt-12px'>to</div>
      <div className='flex-start-start gap-1'>
        <InputField
          {...register(`${fieldsName}.${index}.value.end_value`, { required: 'Required' })}
          type='number'
          step='any'
          errors={errors}
          isRequired={isRequired}
          disabled={disabled}
          label='End Value'
          width='100%'
        />
        <Info size={18} id={'tooltip2'} className='mt-12px' />
        <UncontrolledTooltip offset={[0, 5]} target={'tooltip2'}>
          {t('Includes the ending value')}
        </UncontrolledTooltip>
      </div>
    </div>
  )
}

export default RangeInputGroup