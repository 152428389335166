import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'

const SelectBatchSkuDetailCard = ({ sku }) => {
  return (
    <div className="d-flex gap-16px card-detail">
      <div className="px-20px py-14px border border-dark-2 rounded-8px bg-primary-lighter-global img-container">
        <img
          src={sku.image || no_sku_image}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = no_sku_image
          }}
          width={48}
          height={48}
          alt="no image"
          className="align-self-center rounded-8px"
        />
      </div>
      <div className="d-flex flex-column gap-8px">
        <div className="txt-h3-md text-dark">{sku.name}</div>
        <div className="txt-body-rg text-dark-6">{sku.sku_code || sku.seller_sku_code}</div>
      </div>
    </div>
  )
}

export default SelectBatchSkuDetailCard
