import QuantityButton from '@src/@core/components/ui/quantity-button'
import { BUNDLE } from '@src/views/catalog/catalog.constants'
import { updateQtyOfCreateOrderItemAtIndex } from '@src/views/sales/store/store'
import { AlertTriangle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'

const UpdateOrderSkuQuantity = ({sku, orderQuantity, uniqueItemId, totalSelectedBatch, setOrderedQty}) => {

  //hooks
  const {t} = useTranslation()
  const dispatch = useDispatch()

  //function
  const updateQuantityOnChange = (e) => {
    const number = e.target.value.trim()
    const value = parseInt(number)
    if (e.nativeEvent.data === '+') {
      if (typeof setOrderedQty === 'function') setOrderedQty(orderQuantity + 1)
      else dispatch(updateQtyOfCreateOrderItemAtIndex({uniqueItemId, value: orderQuantity + 1}))
      return
    } else if (e.nativeEvent.data === '-') {
      if (orderQuantity > 0) {
        if (typeof setOrderedQty === 'function') setOrderedQty(orderQuantity - 1)
        else dispatch(updateQtyOfCreateOrderItemAtIndex({uniqueItemId, value: orderQuantity - 1}))
      }
      return
    }
    if (/^[1-9]\d*$/.test(value) || e.target.value === '') {
      if (typeof setOrderedQty === 'function') setOrderedQty(value || 0)
      else dispatch(updateQtyOfCreateOrderItemAtIndex({uniqueItemId, value: value || 0}))
    }
  }
  const decreaseQuantity = () => {
    if (orderQuantity > 0) {
      if (typeof setOrderedQty === 'function') setOrderedQty(orderQuantity - 1)
      else dispatch(updateQtyOfCreateOrderItemAtIndex({uniqueItemId, value: orderQuantity - 1}))
    }
  }
  const increaseQuantity = () => {
    if (typeof setOrderedQty === 'function') setOrderedQty(orderQuantity + 1)
    else dispatch(updateQtyOfCreateOrderItemAtIndex({uniqueItemId, value: orderQuantity + 1}))
  }

  return (
    <div className='d-flex flex-column gap-16px'>
      <div className='py-10px px-12px d-flex justify-content-between align-items-center bg-primary-lighter-global rounded-8px'>
        <div className='txt-body-md text-dark'>{t('Ordered Qty')}</div>
        {sku.type !== BUNDLE ? <QuantityButton
          minQuantityEnabled={orderQuantity <= 1}
          maxQuantityEnabled={false}
          decreaseQuantity={decreaseQuantity}
          increaseQuantity={increaseQuantity}
          quantity={orderQuantity}
          onChange={updateQuantityOnChange}
        /> : <div className='txt-h3-md text-dark'>{orderQuantity}</div>}
      </div>
      <div className='py-10px px-12px d-flex justify-content-between bg-primary-lighter-global rounded-8px'>
        <div className='txt-body-md text-dark' >{t('Selected Qty')}</div>
        <div className='txt-h3-md text-dark'>{totalSelectedBatch}/{orderQuantity} 
          {totalSelectedBatch !== orderQuantity && 
            <>
              <AlertTriangle id='qty-mismatch-warning' className='ms-8px mb-2px' size={16} color='var(--bs-warning)'/>
              <UncontrolledTooltip target='qty-mismatch-warning' popperClassName='mt-6px' placement='bottom-end'>
                {t('Selected quantity should be equal to the ordered quantity')}
              </UncontrolledTooltip>
            </> } </div>
      </div>
    </div>
  )
}

export default UpdateOrderSkuQuantity