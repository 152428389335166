import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { AD_HOC_USAGE_ADDED_IN } from '@src/App.constants'
import { API_ENDPOINTS } from '@src/api.urls'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import { axiosInstance } from '@src/network/AxiosInstance'
import { getApiUrl } from '@src/utility/Utils'
import { AbilityContext } from '@src/utility/context/Can'
import { useQuery } from '@tanstack/react-query'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AdHocEmptyIllustration } from '../../../../../../../assets/images/icons/AdHocEmptyIllustration'
import { NoPermissionIllustration } from '../../../../../../../assets/images/icons/NoPermissionIllustration'

export const AdHocActivityDetail = ({ adHocActivityTabIn }) => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(null)
  const selectedHubId = useSelector(store => store.auth.selectedGlobalHubId)
  const grnData = useSelector(
    (state) => state.inventory?.grn?.grnResponse
  )
  const ability = useContext(AbilityContext)

  const packingData = useSelector(
    (state) => state.sales.packingConfiguration.orderDetailsInPackingData
  )

  const queryParams = { entity_type: adHocActivityTabIn }
  if (adHocActivityTabIn === AD_HOC_USAGE_ADDED_IN.GRN) {
    queryParams.entity_id = grnData.id
  } else {
    queryParams.entity_id = packingData?.warehouse_order_id
  }

  const { data: adHocUsagesData, isFetching, isError, isSuccess } = useQuery({
    queryKey: ['fetch-ad-hoc-usages', pagination],
    queryFn: () => {
      const params = {
        hub_id: selectedHubId,
        ...pagination,
        ...queryParams
      }
      const url = getApiUrl(API_ENDPOINTS.BILLINGS.GET_AD_HOC_USAGES)
      return axiosInstance.get(url, { params })
    },
    select: ({ data }) => {
      const copiedResponse = { ...data }
      const meta = copiedResponse?.meta
      const mutatedRes = copiedResponse?.data?.map((res, index) => ({
        ...res,
        serial_no: ((meta?.current_page - 1) * meta?.per_page) + index + 1
      }))
      copiedResponse.data = mutatedRes
      return { data: copiedResponse }
    }
  })

  const handlePagination = page => {
    const pagination = { page: page.selected, per_page: page.per_page ? page.per_page : 10 }
    setPagination(pagination)
  }

  const columns = [
    {
      id: 1,
      name: 'S.No.',
      minWidth: '60px',
      cell: (row) => (
        <div className="w-100 text-center">
          {row.serial_no}
        </div>
      )
    },
    {
      id: 1,
      name: <div>{t('Ad Hoc Activity')}</div>,
      minWidth: '160px',
      key: 'adHoc_activity',
      cell: (row) => {
        return <div>{row.adhoc?.name}</div>
      }
    },
    {
      id: 2,
      name: <div className='w-100 text-end'>{t('Usage Added')}</div>,
      minWidth: '160px',
      key: 'name',
      cell: (row) => {
        return <div className='w-100 text-end'>{row.display_usage} {row.adhoc.display_unit}</div>
      }
    },
    {
      id: 3,
      name: t('Unit'),
      minWidth: '250px',
      key: 'unit',
      cell: (row) => {
        return <div>{row.adhoc.unit}</div>
      }
    }
  ]
  if (!ability.can(abilityMap.custom.adhoc_usage_view.action, abilityMap.custom.adhoc_usage_view.resource)) {
    return (
      <div  className="p-16px">
        <div className="d-flex flex-column align-items-center m-40px">
          <div>
            <NoPermissionIllustration/>
          </div>
        </div>
      </div>
    )
  }
  
  return (
    <>
      {isFetching ? <div className='mt-40px'>
        <ComponentSpinner />
      </div>
        : <div className="p-16px">
          {!adHocUsagesData?.data?.data.length ?
            <div>
              <div className="d-flex flex-column gap-10px align-items-center m-40px">
                <div>
                  <AdHocEmptyIllustration />
                </div>
                <div className="txt-h3-md text-dark">
                  {t('No activity usage added yet.')}
                </div>
                <div className="txt-body-rg text-dark-5">
                  {t('Add ad hoc activity usages.')}
                </div>
              </div>
            </div>
            :
            <div className='mt-16px'>
              <ExpandableCustomTable
                data={adHocUsagesData?.data?.data || []}
                meta={adHocUsagesData?.data?.meta || {}}
                columns={columns}
                loading={isFetching}
                success={isSuccess}
                error={isError}
                useReactPaginate={false}
                showColumnsTableHeader={false}
                handlePagination={handlePagination}
              />
            </div>
          }
        </div>}
    </>
  )
}