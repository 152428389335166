import Button from '@src/@core/components/ui/button'
import DropdownWithTitle from '@src/@core/components/ui/dropdown'
import CustomDropdownOption from '@src/@core/components/ui/dropdown/customDropdownOption'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import { ADDITIONAL_FILTER_DROPDOWN_STYLE } from '@src/App.constants'
import { API_ENDPOINTS } from '@src/api.urls'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import trackingImage from '@src/assets/images/orders/trackingOrder.svg'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { bindToChannelEvent } from '@src/network/RealTime'
import { handlePusherResponse } from '@src/redux/authentication'
import { getApiUrl } from '@src/utility/Utils'
import { AbilityContext } from '@src/utility/context/Can'
import SimpleSkuDetailModal from '@src/views/catalog/components/simple-sku-detail-modal/SimpleSkuDetailModal'
import OrderDetailsSideSlider from '@src/views/sales/OrderDetailsSideSlider'
import { getPicklistsOfStatus, getShippingPartnerAsyncData } from '@src/views/sales/store/store'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import BundleSkuDetailsSideSheet from '../../../@core/components/ui/bundle-sku-details'
import { API_KEYS, BULK_SHIPMENT_STATUSES } from '../bulk-ship.constants'
import { PrintClusterPackingList } from './print-cluster-packing-list'
import ShipmentPrintBtns from './shipment-print-btns'
import ShipmentDetails from './shipmentDetails'

const OrderDetailsCard = ({
  t,
  displayNumberOfOrders,
  displayItemsPerOrder,
  displayItemsToPick,
  displayOrderWeight,
  displayNoOfPackages
}) => {
  return (
    <div className="border rounded-10px bg-warning-light text-warning border border-dark-2">
      <div className="bg-white text-dark flex-start-between txt-h0-sb rounded-10px px-20px py-16px">
        {/* Orders */}
        <div className="d-flex flex-column gap-8px">
          <div>
            {displayNumberOfOrders}
          </div>
          <div className="txt-body-rg text-dark-6">{t('Orders')}</div>
        </div>

        {/* Items To Pick */}
        <div className="d-flex flex-column gap-8px">
          <div>{displayItemsPerOrder}</div>
          <div className="txt-body-rg text-dark-6">{t('Items Per Order')}</div>
        </div>

        {/* Items To Pick */}
        <div className="d-flex flex-column gap-8px">
          <div>{displayItemsToPick}</div>
          <div className="txt-body-rg text-dark-6 flex-center-center gap-4px">
            <span>{t('Items To Pick')}</span>
          </div>
        </div>

        {/* Order Weight */}
        <div className="d-flex flex-column gap-8px">
          <div>{displayOrderWeight}</div>
          <div className="txt-body-rg text-dark-6 flex-center-center gap-4px">
            <span>{t('Order Weight')}</span>
          </div>
        </div>

        {/* Packages Per Order */}
        <div className="d-flex flex-column gap-8px">
          <div>{displayNoOfPackages}</div>
          <div className="txt-body-rg text-dark-6 flex-center-center gap-4px">
            <span>{t('Packages Per Order')}</span>
          </div>
        </div>
      </div>
    </div>
  )
}


const ViewCluster = ({ clusterPickingWave, getPickingWaveLoading, setisPickingWaveDetailsSidebarOpen }) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const ability = useContext(AbilityContext)

  const selectedGlobalHubId = useSelector((store) => store.auth.selectedGlobalHubId)
  const picklistsOfStatus = useSelector((state) => state.sales.picklistsOfStatus)
  const pusherResponse = useSelector((store) => store.auth.pusherResponse)

  const pickingWaveSearchCol = [
    { id: 'sku_name', name: 'SKU Name' },
    { id: 'sku_code', name: 'SKU Code' }
  ]

  const shipmentDetailsSearchCol = [
    { id: 'query', name: 'Order' },
    { id: 'seller_sales_channel_order_id', name: 'Sales Channel Order ID' },
    { id: 'seller_sku_code', name: 'SKU Code' },
    { id: 'barcode', name: 'SKU Barcode' }
  ]

  const [filter, setFilter] = useState({})
  const [pagination, setPagination] = useState({ page: 1, per_page: 20 })
  const [searchQuery, setSearchQuery] = useState({ column: shipmentDetailsSearchCol[0] })
  const [sellerId, setSellerId] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [skuForDetails, setSkuForDetails] = useState({})
  const [openSkuDetailsSidebar, setOpenSkuDetailsSidebar] = useState(false)
  const [loacalSearch, setLocalSearch] = useState({ column: pickingWaveSearchCol[0] })
  const [searchedOrderItems, setSearchedOrderItems] = useState(null)
  const [openBundleSkuDetailsSidebar, setOpenBundleSkuDetailsSidebar] = useState(false)
  const [shipmentTableData, setShipmentTableData] = useState([])
  const [selectedData, setSelectedData] = useState([])

  const handleSkuDetailsSidebar = (data) => {
    if (data) {
      const formattedData = {
        ...data,
        ...data.sku,
        child_skus: data.child_order_items,
        id: data.sku.seller_sku_id
      }
      setSkuForDetails(formattedData)
      setOpenSkuDetailsSidebar(true)
    } else {
      setOpenSkuDetailsSidebar(false)
    }
  }

  const handleBundleSkuDetailsSidebar = (data) => {
    if (data) {
      const formattedData = {
        ...data,
        ...data.sku,
        child_skus: data.child_order_items
      }
      setSkuForDetails(formattedData)
      setOpenBundleSkuDetailsSidebar(true)
    } else {
      setOpenBundleSkuDetailsSidebar(false)
    }
  }
  //***** Get Picking Wave ^ */
  const pickingWave = clusterPickingWave?.data
  const waveDetails = clusterPickingWave?.data.wave
  const clusterItems = clusterPickingWave?.data.cluster_items
  const packingDetails = clusterPickingWave?.data.packing_details
  const boxDetails = clusterPickingWave?.data.packing_details.box_details

  const tableData = useMemo(() => {
    return clusterPickingWave?.data?.cluster_items.map((item, idx) => ({
      ...item,
      serialNumber: idx + 1,
      skuName: item.sku.name,
      skuCode: item.sku.seller_sku_code,
      image: item.sku?.images?.[0]?.url,
      qty: item.display_quantity,
      totalPickedQty: item.display_total_quantity,
      sku_name: item.sku.name,
      images: item.sku?.images
    }))
  }, [clusterPickingWave])

  const handleShowSkuDetails = (data) => {
    if (data.child_order_items?.length > 0) {
      handleBundleSkuDetailsSidebar(data)
    } else {
      handleSkuDetailsSidebar(data)
    }
  }

  const pickingWaveColumn = [
    {
      id: 1,
      name: t('S. No.'),
      key: 'serial_no',
      width: '64px',
      reorder: false,
      cell: (row) => (
        <div className="w-100 text-center">{row.serialNumber}</div>
      )
    },
    {
      id: 2,
      name: 'SKU Details',
      key: 'sku_details',
      minWidth: '40%',
      reorder: true,
      cell: (row) => {
        const { image, skuName, skuCode } = row
        return (
          <div className="flex-center-start gap-16px pe-30px">
            <img
              src={image || no_sku_image}
              height={32}
              width={32}
              className="border rounded-4px p-4px border-dark-2"
              style={{ objectFit: 'contain' }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = no_sku_image
              }}
            />
            <div className="text-primary max-width-400 width-150-per d-flex flex-column gap-4px">
              <p
                className="txt-sub-rg text-truncate cursor-pointer m-0"
                onClick={() => handleShowSkuDetails(row)}
              >
                {skuName}
              </p>
              <p className="txt-asst-rg text-truncation text-dark-6 m-0">
                {skuCode}
              </p>
            </div>
          </div>
        )
      }
    },
    {
      id: 3,
      name: (
        <div className="w-100 text-end text-truncate">
          {t('Qty Per Order')}
        </div>
      ),
      key: 'qty_per_order',
      minWidth: '230px',
      maxWidth: '230px',
      reorder: true,
      cell: (row) => {
        const { qty } = row
        return (
          <div className="w-100">
            <div className="w-100 txt-sub-rg text-end text-truncation">
              {qty}
            </div>
          </div>
        )
      }
    },
    {
      id: 4,
      name: (
        <div className="w-100 text-end text-truncate">
          {t('Total Picked Qty')}
        </div>
      ),
      key: 'total_qty',
      minWidth: '230px',
      maxWidth: '230px',
      reorder: true,
      cell: (row) => {
        const { totalPickedQty } = row
        return (
          <div className="w-100">
            <div className="w-100 txt-sub-rg text-end text-truncation">
              {totalPickedQty}
            </div>
          </div>
        )
      }
    }
  ]


  const getItemColumn = (item) => {
    const orderItem = {
      id: item.sku.seller_sku_id,
      skuName: item.sku?.name,
      skuCode: item.sku?.seller_sku_code,
      image: item.sku?.images?.[0]?.url,
      qty: item.display_quantity
    }
    return {
      id: orderItem.id,
      name: (() => {
        const { image, skuName, skuCode } = orderItem
        return (
          <div className="flex-center-start gap-16px w-100">
            <img
              src={image || no_sku_image}
              height={32}
              width={32}
              className="border rounded-4px p-4px border-dark-2"
              style={{ objectFit: 'contain' }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = no_sku_image
              }}
            />
            <div className="d-flex flex-column gap-4px text-truncation">
              <p
                id={`order-item-${orderItem.id}`}
                className="txt-sub-rg text-truncation m-0"
                title={skuName}
              >
                <span className="d-none " />
                {skuName}
              </p>
              <p className="txt-asst-rg text-truncation text-dark-6 m-0">
                {t('SKU Code')}: {skuCode}
              </p>
            </div>
          </div>
        )
      })(),
      cell: (row) => {
        const displayQuantity = row.sku_details.find(item => item.sku.seller_sku_code === orderItem.skuCode)?.display_quantity || '0'
        return <span className="text-start w-100">{displayQuantity}</span>
      }
    }
  }

  const columns = useMemo(() => {
    let baseColumns = [
      {
        id: 1,
        minWidth: '280px',
        maxWidth: '280px',
        name: t('Box'),
        cell: (row) => {
          return <span>{row.box.name}</span>
        }
      }
    ]

    if (clusterItems && clusterItems.length) {
      const additionalColumns = clusterItems.map((orderItem) => getItemColumn(orderItem))
      baseColumns = baseColumns.concat(...additionalColumns)
    }
    return baseColumns
  }, [clusterItems])

  const handleSearchItem = ({ searchVal, selectedColumn = null }) => {
    const searchQuery = searchVal
      ? { column: selectedColumn, query: searchVal }
      : {}
    setLocalSearch(searchQuery)
    if (searchQuery?.query) {
      const newOrderItems = tableData.filter(order => {
        if (searchQuery.column.id === 'sku_name') {
          return (order.skuName || '').includes(searchQuery?.query ? searchQuery.query : '')
        } else if (searchQuery.column.id === 'sku_code') {
          return (order.skuCode || '').includes(searchQuery?.query ? searchQuery.query : '')
        }
      })
      setSearchedOrderItems(newOrderItems)
    } else {
      setSearchedOrderItems(null)
    }
  }

  const handleViewWaveDetails = () => {
    setisPickingWaveDetailsSidebarOpen(true)
    dispatch(getPicklistsOfStatus({ hubId: selectedGlobalHubId, waveId: waveDetails.id }))
  }

  const handleOrderDetails = (row) => {
    setSellerId(row.id)
    setIsModalOpen(true)
  }

  const {
    data: fetchOrdersResponse,
    refetch: handleGetOrdersData,
    isFetching: isGetOrdersFetching,
    isError: isGetOrdersError,
    isSuccess: isGetOrdersSuccess
  } = useApi({
    apiKey: [API_KEYS.FETCH_WAVE_SHIPMENT_DATA, clusterPickingWave, pagination, filter, searchQuery],
    apiFn: () => {
      const params = {
        picking_wave_id: clusterPickingWave?.data.wave.id,
        creation_status: filter.creation_status?.value,
        shipping_partner_tag: filter.shipping_partner_tag?.value,
        hub_id: selectedGlobalHubId,
        [searchQuery.column?.id]:searchQuery.query,
        ...pagination
      }
      return axiosInstance.get(getApiUrl(API_ENDPOINTS.BULK_SHIP.FETCH_WAVE_SHIPMENT_DATA), { params })
    },
    onSuccess: (props) => {
      const { data, meta } = props || {}
      const newData = data?.orders?.map((order, idx) => {
        const orderTags = [...order.tags]
        orderTags.sort((a, b) => a.name.length - b.name.length)
        return {
          ...order,
          serialNumber: ((meta?.current_page - 1) * meta?.per_page) + idx + 1 || idx + 1,
          orderID: order.seller_sales_channel_order_id,
          createdAt: order.order_created_at,
          orderTags,
          referenceOrderID: order.order_alias,
          orderSource: order.display_source,
          shippingPartner: { label: order.shipment?.shipping_account?.name, value: order.shipment?.shipping_account?.id, ...order.shipment?.shipping_account },
          id: order.id,
          displaySource: order.display_source,
          awbNumber: order.shipment.awb_number
        }
      })
      setShipmentTableData(newData)
    }
  })

  const { mutate: cancelShipment, isPending: cancelShipmentPending } = useApi({
    isMutation: true,
    apiKey: ['cancel-shipments'],
    apiFn: (properties) => {
      const { selectedData } = properties
      return axiosInstance.post('/api/v1/oms/orders/bulk/shipments/cancel', {
        wave_id: `${pickingWave.wave.id}`,
        hub_id: selectedGlobalHubId,
        order_ids: Object.values(selectedData).map((order) => order.id)
      })
    },
    onSuccess: ({ data }) => {
      if (data?.event) bindToChannelEvent(data?.event)
    }
  })

  const filterHandler = ({ filterkey, filterValue }) => {
    if (!filterValue) {
      const newFilter = { ...filter }
      delete newFilter[filterkey]
      setFilter(newFilter)
      return
    }
    setFilter({ ...filter, [filterkey]: filterValue })
  }

  const handlePagination = (page) => {
    const pagination = { page: page.selected, per_page: page.per_page ? page.per_page : 10 }
    setPagination(pagination)
  }


  useEffect(() => {
    if (pusherResponse['bulk-shipments']) {
      const cleanedPusherResp = { ...pusherResponse }
      cleanedPusherResp['bulk-shipments'] = undefined
      dispatch(handlePusherResponse(cleanedPusherResp))
      handleGetOrdersData()
    }
  }, [pusherResponse])

  const hasViewPickingWavePermission = ability.can(abilityMap.picking.view_wave.action, abilityMap.picking.view_wave.resource)

  return (
    <>
      <div className="d-flex flex-column gap-22px">
        <OrderDetailsCard
          t={t}
          displayNumberOfOrders={pickingWave.display_number_of_orders}
          displayItemsPerOrder={pickingWave.display_items_per_order}
          displayItemsToPick={pickingWave.display_items_to_pick}
          displayOrderWeight={pickingWave.packing_details.display_order_weight}
          displayNoOfPackages={pickingWave.packing_details.display_no_of_packages}
        /> 
        <div className="d-flex flex-column gap-22px">
          <div className="bg-white border border-dark-2 rounded-10px">
            <div className="w-100 px-16px py-20px flex-center-between txt-h3-md text-dark">
              <div className="flex-start-start gap-16px">
                <div className="flex-center-center">
                  <span>
                    {t('Picking Wave')}
                  </span>
                </div>
              </div>
              {hasViewPickingWavePermission && <Button
                onClick={(e) => {
                  e.stopPropagation()
                  handleViewWaveDetails()
                }}
                ofStyle="noBackground"
                className="txt-body-md py-4px px-8px"
                disabled={picklistsOfStatus.loading}
              >
                {picklistsOfStatus.loading ? <Spinner size='sm' /> : <img src={trackingImage} />}
                {t('View Wave Details')}
              </Button>}
            </div>
            <div className="p-16px pt-0">
              <ExpandableCustomTable
                loading={getPickingWaveLoading}
                data={searchedOrderItems || tableData || []}
                columns={pickingWaveColumn || []}
                columnName={ALL_COLUMN_NAME_MAPPING.CLUSTER_DETAILS_PICKING_WAVE}
                searchcolumnsList={pickingWaveSearchCol}
                handleQueryParams={handleSearchItem}
                showPagination={false}
                showColumnsTableHeader={true}
                search_column_query={loacalSearch}
              />
            </div>
          </div>

          {/* Packaging Preference */}
          <div className="bg-white border border-dark-2 border-top-1 border-top-dark-2 rounded-10px">
            <div className="w-100 px-16px py-20px flex-start-between gap-16px txt-h3-md text-dark">
              <div>
                {t('Packaging Preference')}
              </div>
              <div>
                <PrintClusterPackingList printData={clusterPickingWave?.data} />
              </div>
            </div>
            <div className="px-16px">
              <ExpandableCustomTable
                data={boxDetails || []}
                loading={getPickingWaveLoading}
                columns={columns || []}
                columnName={ALL_COLUMN_NAME_MAPPING.CLUSTER_DETAILS_PACKING_PREFERENCE}
                showPagination={false}
                showColumnsTableHeader={false}
              />
            </div>
            <div className="d-flex flex-column gap-18px text-dark p-16px pt-28px">
              <p className="m-0 txt-body-md">{t('Packaging Details')}</p>
              <div className="d-flex flex-column gap-16px">
                <div className="d-flex">
                  <div className="width-150 txt-body-rg text-dark-6">{t('Order Weight')}:</div>
                  <div className="w-50 txt-h3-sb">{packingDetails.display_order_weight} Kg</div>
                </div>
                <div className="d-flex">
                  <div className="width-150 txt-body-rg text-dark-6">{t('No of Packages')}:</div>
                  <div className="w-50 txt-h3-sb">{packingDetails.display_no_of_packages}</div>
                </div>
              </div>
            </div>
          </div>

          {/* Shipment */}
          <div className="bg-white border border-dark-2 border-top-1 border-top-dark-2 rounded-10px">
            <div className="w-100 px-16px py-20px flex-start-between gap-16px txt-h3-md text-dark">
              <div className="flex-center-center gap-8px">
                <span>{t('Shipment')}</span>
              </div>
              <ShipmentPrintBtns selectedOrders={selectedData} pickingWave={pickingWave} isAtleastOneShipmentCreated />
            </div>
            <div className="p-16px pt-0">
              <div className="mb-20px d-flex gap-20px">
                <DropdownWithTitle
                  isAsync
                  isClearable={!!filter.shipping_partner_tag}
                  value={!filter.shipping_partner_tag ? { label: 'All', value: '' } : filter.shipping_partner_tag}
                  title={t('Shipping Partner')}
                  loadOptionsHandler={getShippingPartnerAsyncData}
                  selectOptionHandler={(value) => filterHandler({ filterkey: 'shipping_partner_tag', filterValue: value })}
                  externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
                  additionalComponents={{ Option: CustomDropdownOption }}
                />
                <DropdownWithTitle
                  isClearable={!!filter.creation_status}
                  value={!filter.creation_status ? { label: 'All', value: '' } : filter.creation_status}
                  title={t('Shipment Status')}
                  options={Object.values(BULK_SHIPMENT_STATUSES)}
                  selectOptionHandler={(value) => filterHandler({ filterkey: 'creation_status', filterValue: value })}
                  externalStyles={ADDITIONAL_FILTER_DROPDOWN_STYLE}
                />
              </div>
              <ShipmentDetails
                tableData={shipmentTableData}
                isGetOrdersFetching={isGetOrdersFetching}
                isGetOrdersError={isGetOrdersError}
                isGetOrdersSuccess={isGetOrdersSuccess}
                fetchOrdersResponse={fetchOrdersResponse}
                setSearchQuery={setSearchQuery}
                handlePagination={handlePagination}
                pagination={pagination}
                handleOrderDetails={handleOrderDetails}
                pickingWave={clusterPickingWave}
                filterHandler={filterHandler}
                filter={filter}
                cancelShipment={cancelShipment}
                cancelShipmentPending={cancelShipmentPending}
                searchQuery={searchQuery}
                shipmentDetailsSearchCol={shipmentDetailsSearchCol}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
              />
            </div>
          </div>
        </div>
      </div>
      <SimpleSkuDetailModal
        isOpen={openSkuDetailsSidebar}
        skuForDetails={skuForDetails}
        toggleSimpleSkuModal={() => handleSkuDetailsSidebar(false)}
      />

      <BundleSkuDetailsSideSheet
        isOpen={openBundleSkuDetailsSidebar}
        bundleSkuForDetails={skuForDetails}
        toggleBundleSkuModal={() => handleBundleSkuDetailsSidebar(false)}
      />

      <OrderDetailsSideSlider sellerId={sellerId} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} handleGetParentData={handleGetOrdersData} />
    </>
  )
}

export default ViewCluster
