import ErrorNoResultFound from '@src/@core/components/ui/errorNoResultfound'
import SideSheet from '@src/@core/components/ui/sideSheet'
import ComponentSpinner from '@src/@core/components/ui/spinner/Loading-spinner'
import { ModalBody } from 'reactstrap'
import CreateEditRule from './CreateEditRule'

/**
 * A reusable side sheet component for creating, editing, or viewing rules.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.isOpen - Whether the side sheet is open.
 * @param {function} props.toggle - A function to toggle the side sheet.
 * @param {boolean} [props.isEdit=false] - Whether the rule is being edited.
 * @param {boolean} [props.isView=false] - Whether the rule is being viewed.
 * @param {string} props.sideSheetTitle - The title of the side sheet.
 * @param {boolean} [props.isLoading=false] - Whether the side sheet is loading.
 * @param {boolean} [props.isSuccess=false] - Whether the side sheet is successful.
 * @param {boolean} [props.isError=false] - Whether the side sheet is in an error state.
 * @return {JSX.Element} The side sheet component.
 */

const ComponentRenderer = (props) => {
  const {isLoading = false, isError = false, ...rest} = props
  switch (true) {
  case isLoading:
    return <ComponentSpinner />
  case isError:
    return <div className="w-100 h-100 flex-center-center"><ErrorNoResultFound /></div>
  default:
    return <CreateEditRule {...rest} />
  }
}
const CreateEditRulesSideSheet = (props) => {
  const { isOpen, toggle, sideSheetTitle} = props
  return (
    <SideSheet
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      contentClassName="p-0"
      modalClassName="modal-slide-in create-edit-rules-modal"
      title={sideSheetTitle}
    >
      <ModalBody className='create-packing-eligibility-modal p-24px pb-4 mb-5 overflow-auto'>
        <ComponentRenderer {...props} />
      </ModalBody>
    </SideSheet>
  )
}

export default CreateEditRulesSideSheet