import SkuCustomDropdownOption from '@src/@core/components/ui/custom-dropdown-option/sku-custom-dropdown-option'
import { DATA_TYPE, SEARCH_SKU_FIELDS } from '@src/App.constants'
import { INPUT_TYPES_MAP } from '@src/components/rule/rule.constant'
import { getSkusLabels } from '@src/views/catalog/store'
import { getAsyncSellerSalesChannel, getCountriesAsyncData } from '@src/views/sales/store/store'
import { format } from 'date-fns'
import { getCitiesAsyncData, getHubsAsyncData, getOrderTagsAsyncPaginate, getSalesChannel, getSellersAsyncData, getSkuOptionsForActions, getSkuOptionsForConditions } from '../store'

export const propsMapFunc = ({ selectedSellerId }) => {
  
  return {
    sales_channel: {
      loadOptions: getSalesChannel,
    },
    seller_sales_channel: {
      loadOptions: getAsyncSellerSalesChannel,
      additional: {
        seller: selectedSellerId,
      },
    },
    seller: {
      loadOptions: getSellersAsyncData,
    },
    hub: {
      loadOptions: getHubsAsyncData,
    },
    order_tag: {
      loadOptions: getOrderTagsAsyncPaginate,
    },
    sku_tag: {
      loadOptions: getSkusLabels,
    },
    destination_country: {
      loadOptions: getCountriesAsyncData,
    },
    destination_city: {
      loadOptions: getCitiesAsyncData,
    },
    forceful_route_to_hub: {
      loadOptions: getHubsAsyncData,
      title: 'Select Hub',
    },
    route_to_any_hub_with_inv: {
      loadOptions: getHubsAsyncData,
      title: 'Select Hubs',
      DEFAULT_VALUES: { label: 'All', value: 'all' },
    },
    consider_delivery_zone: {
      loadOptions: getHubsAsyncData,
      title: 'Select Hubs',
      DEFAULT_VALUES: { label: 'All', value: 'all' },
    },
    sku: {
      loadOptions: getSkuOptionsForConditions,
      title: 'Select SKU',
      additional: {
        sellerId: selectedSellerId,
        params: {
          search_columns: SEARCH_SKU_FIELDS.map(field => field.value)
        },
      },
      additionalComponents: { Option: SkuCustomDropdownOption },
    },
    add_items: {
      loadOptions: getSkuOptionsForActions,
      title: 'Add \'X\' Quantity of an SKU',
      additional: {
        sellerId: selectedSellerId,
        params: {
          search_columns: SEARCH_SKU_FIELDS.map(field => field.value)
        },
      },
      additionalComponents: { Option: SkuCustomDropdownOption },
    },
  }
}

export const handleModifyDataType = (value, datatype) => {
  if (datatype?.includes(DATA_TYPE.INTEGER)) {
    return parseInt(value)
  } else if (datatype?.includes(DATA_TYPE.FLOAT)) {
    return parseFloat(value)
  } else if (datatype?.includes(DATA_TYPE.STRING)) {
    return String(value)
  } else {
    return value
  }
}

export const handleGenerateValueForCreateRule = (value, condition, operator) => {
  const conditionDatatype = condition?.datatype
  const operatorDatatype = operator?.datatype || conditionDatatype
  const inputType = condition.input_type
  if (inputType === INPUT_TYPES_MAP.DROPDOWN || inputType === INPUT_TYPES_MAP.API) {
    if (operatorDatatype?.includes(DATA_TYPE.ARRAY)) {
      const displayValue = value?.map((val) => {
        return val.label
      })
      const actionValue = value?.map((val) => {
        return handleModifyDataType(val.value, operatorDatatype)
      })
      return {
        display_value: displayValue,
        key_value: actionValue,
      }
    } else {
      return {
        display_value: value?.label,
        key_value: handleModifyDataType(value?.value, operatorDatatype),
      }
    }
  } else if (inputType === INPUT_TYPES_MAP.INPUT_BOX) {
    if (operatorDatatype.includes(DATA_TYPE.ARRAY)) {
      const displayValue = value?.map((val) => {
        return handleModifyDataType(val.value, operatorDatatype)
      })
      const actionValue = value?.map((val) => {
        return handleModifyDataType(val.value, operatorDatatype)
      })
      return {
        display_value: displayValue,
        key_value: actionValue,
      }
    } else {
      return {
        display_value: handleModifyDataType(value, operatorDatatype),
        key_value: handleModifyDataType(value, operatorDatatype),
      }
    }
  } else if (inputType === INPUT_TYPES_MAP.DATE_PICKER) {
    return {
      display_value: value.map((val) => {
        return format(val, 'yyyy-MM-dd HH:mm:ss')
      }),
      key_value: value.map((val) => {
        return format(val, 'yyyy-MM-dd HH:mm:ss')
      }),
    }
  }
}


export const createActionBodyForRequest = (data) => {

  return {
    actions: [
      {
        name: 'add_items',
        operator: 'in',
        value: {
          display_value: data?.map(el => el.value),
          key_value: data?.map((ele) => ({
            sku_code: ele.value,
            type: ele.type,
            uom: ele.uom,
            quantity: ele.quantity,
            name: ele.name,
            is_weighted: ele.is_weighted,
            ...(ele.is_weighted && { weight: parseFloat(ele.weight) }),
            unit_price_without_tax: 0,
            tax_percent: 0
          }))
        }
      }
    ],
    logical_operator: 'and'
  }
  
}

export const checkZeroQuantityOrWeight = (data) => {
  let isZeroQuantity = false;
  let isZeroWeight = false;

  data.forEach((item) => {
    if (item.quantity === 0) {
      isZeroQuantity = true;
    }
    if (item.is_weighted && parseFloat(item.weight || 0) === 0) {
      isZeroWeight = true;
    }
  });

  return { isZeroQuantity, isZeroWeight };
};

export const transformToFrontendActionData = (array) => {
  const transformedData = {};

  array.forEach(item => {
    transformedData[item.Key] = item.Value;
  });

  return {
    quantity: transformedData.quantity,
    label: transformedData.sku_code,
    value: transformedData.sku_code,
    sku_code: transformedData.sku_code,
    tax_percent: transformedData.tax_percent,
    type: transformedData.type,
    unit_price_without_tax: transformedData.unit_price_without_tax,
    uom: transformedData.uom,
    weight: transformedData.weight,
    name: transformedData.name,
    is_weighted: transformedData.is_weighted
  };
}