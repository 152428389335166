import classNames from 'classnames';
import React from 'react';
import { iAttributeValueProp } from '../../attributes.constants';
import './styles.scss';

const Checkbox: React.FC<iAttributeValueProp> = ({values, additionalClasses}) => {
  return <div className={classNames('txt-body-md text-dark attribute-type-container', additionalClasses)}>
    {values.map((value: string, index: number) => <div key={index}>
      {value}
    </div>)}
  </div>;
};

export default Checkbox;