import classNames from 'classnames';
import React from 'react';
import { iAttributeValueProp } from '../../attributes.constants';
import './styles.scss';

const File: React.FC<iAttributeValueProp> = ({values, additionalClasses}) => {
  return <div className={classNames('txt-body-md flex-center-start attribute-type-container', additionalClasses)}>
    {values.map((value:string, index:number) => <div key={index}>
      <a href={value} target='_blank' rel='noopener noreferrer'>
        {`File ${index + 1}`}
      </a>
      <span className='text-dark'>{index !== values.length - 1 && ', '}</span>
    </div>)}
  </div>;
};

export default File;