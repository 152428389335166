import BarcodeScanner from '@src/@core/components/ui/barcodeScanner'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { Info, PlusSquare, X } from 'react-feather'
import { BATCH_STATUS, BIN_STATUS, SEARCH_SKU_TYPES, SERIALISATION_OPTION } from '../../constant'
// import { useReactToPrint } from 'react-to-print'
import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import { KEYBOARD_KEYS } from '@src/App.constants'
import failBin from '@src/assets/images/inventory/icons/failBin.svg'
import passBin from '@src/assets/images/inventory/icons/passBin.svg'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { addSkuInGrnBin, clearAddItemInGrnBinResponse, clearBinStatus, clearDataAfterSKUScanned, clearScannedSkuDetails, getBinStatus, getQcFailReasons, setBinDisabledGrnTableData, setLastScannedBin, setScannedSkuDetails, updateAssignedQtyForCurrentSku, updateEnforcedFailUploadIds, updateGtinSkuDetails, updateIsGtinChanged } from '@src/views/inventory/store'
import classNames from 'classnames'
import Barcode from 'react-barcode'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import BulkUploadSerialisedSkus from '../bulkUploadSerialisedSkus'
import BatchBarcodePreview from './batchBarcodePreview'
import OverReceiveItemModal from './overReceiveItemModal'
import PrintBulkSkuBarcode from './printBulkSkuBarcode'
import SkuNotPartOfOrderModal from './skuNotPartOfOrderModal'

const GRNFinalStep = forwardRef(({ skuDetails, watch, setValue, totalItemQtyToScan, errors, control, qty, setQty, selectedTabId, grnResponse, searchBarcode, mainScannedBarcode }, ref) => {
  const {
    configurations, 
    tableData, 
    displayGrnDetails, 
    returnGrnResponse, 
    singleGrnDetail, 
    enforcedFailUploadIds, 
    enforcedFailReason, 
    isShelfLifeBreached,
    gtinSkuDetails
  } = useSelector(store => ({
    configurations: store.auth.selectedHubConfiguration,
    tableData: store.inventory.binDisabledGrn.tableData,
    displayGrnDetails: store.inventory.grn.displayGrnDetails,
    returnGrnResponse: store.returns.createReturnGrnResponse?.data,
    singleGrnDetail: store.inventory.grn.singleGrnDetail,
    enforcedFailUploadIds: store.inventory.enforcedFailUploadIds,
    enforcedFailReason: store.inventory.enforcedFailReason,
    isShelfLifeBreached: store.inventory.isShelfLifeBreached,
    gtinSkuDetails: store.inventory.gtinSkuDetails
  }))
  const { t } = useTranslation()
  const [isBatchedBarcodePreviewOpen, setIsBatchBarcodePreviewOpen] = useState(false)
  const [qcScannedBinBarcode, setQcScannedBinBarcode] = useState()
  const [omnifulSkuBarcode, setOmnifulSkuBarcode] = useState()
  const [uploadedImages, setUploadedImages] = useState([])
  const [isOverItemModalOpen, setOverItemModalOpen] = useState(false)
  const [isSkuNotPartModalOpen, setSkuNotPartModalOpen] = useState(false)

  const isBinDisabled = !configurations?.find(item => item.configuration_type === 'bin')?.configuration_values?.enabled
  const dispatch = useDispatch()
  const [scannedBarcode, setScannedBarcode] = useState(null)
  const isMaxShelfLifeEnforced = configurations?.find(item => item.configuration_type === 'acceptable_shelf_life_validation')?.configuration_values?.enabled

  const {mutate: enforcedSerialisedFailed} = useApi({
    isMutation:true,
    apiKey: ['enforced-serialised-failed'],
    apiFn: () => {
      let skuInTable
      if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
        skuInTable = tableData.find(item => item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id)
      } else {
        skuInTable = tableData.find(item => item.seller_sku_id === skuDetails?.seller_sku_id)
      }

      const reasons = [{
        id: enforcedFailReason.id,
        quantity: (parseInt(skuDetails?.fail_qty) + 1),
        upload_ids: enforcedFailUploadIds || [],
        barcodes: Array.isArray(skuDetails?.fail_barcodes) && skuDetails?.fail_barcodes?.length ? [...skuDetails.fail_barcodes, scannedBarcode] : [scannedBarcode],
      }]

      const body = {
        pass_quantity: 0,
        sku_barcodes: [],
        seller_sku_code: skuDetails?.seller_sku_code,
        batch_id: skuDetails?.batch_status === BATCH_STATUS.BATCHED ? skuDetails?.batch?.id : undefined,
        over_receive: isOverItemModalOpen || !!skuInTable?.allow_over_receive,
        reasons
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${grnResponse?.hub_id}/grns/${grn_id}`, body)
      
    },
    onSuccess: (data) => {
      if (data?.data?.is_over_receive || data?.data?.is_sku_not_exist) {
        if (data?.data?.is_over_receive) {
          setOverItemModalOpen(true)
        } else {
          setSkuNotPartModalOpen(true)
        }
      } else {
        dispatch(updateEnforcedFailUploadIds([]))
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          const isBatchedSkuPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))

          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isBatchedSkuPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 0,
                fail_qty: 1,
                fail_barcodes:[scannedBarcode],
                pass_barcodes: [],
                batch: skuDetails?.batch
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 0,
              fail_qty: 1,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              pass_barcodes: [],
              fail_barcodes: [scannedBarcode],
              batch: skuDetails?.batch
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            if (isBatchedSkuPresentInTable) {
              if (isBatchedSkuPresentInTable.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: 0,
                    fail_qty:(parseInt(skuDetails?.fail_qty) + 1),
                    pass_barcodes: [],
                    fail_barcodes:Array.isArray(skuDetails?.fail_barcodes) && skuDetails?.fail_barcodes?.length ? [...skuDetails.fail_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                    ...item,
                    pass_qty: 0,
                    fail_qty:(parseInt(item?.fail_qty) + 1),
                    pass_barcodes:[],
                    fail_barcodes: Array.isArray(item?.fail_barcodes) && item?.fail_barcodes?.length ? [...item.fail_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              } else if (!isBatchedSkuPresentInTable.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: 0,
                    fail_qty:(parseInt(skuDetails?.fail_qty) + 1),
                    pass_barcodes:[],
                    fail_barcodes: Array.isArray(skuDetails?.fail_barcodes) && skuDetails?.fail_barcodes?.length ? [...skuDetails.fail_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails?.seller_sku_id ? {
                    ...item,
                    pass_qty: 0,
                    fail_qty: (parseInt(item?.fail_qty) + 1),
                    pass_barcodes:[],
                    fail_barcodes: Array.isArray(item?.fail_barcodes) && item?.fail_barcodes?.length ? [...item.fail_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 0,
                  fail_qty:1,
                  pass_barcodes: [],
                  batch: skuDetails?.batch,
                  fail_barcodes: [scannedBarcode]
                }
              }))

              const dataToPush = {
                id: skuDetails?.seller_sku_id,
                name: skuDetails?.name,
                code: skuDetails?.seller_sku_code,
                image: skuDetails?.images?.[0]?.url,
                is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
                seller_sku_id: skuDetails?.seller_sku_id,
                pass_qty: 0,
                fail_qty: 1,
                total_qty: skuDetails?.quantity || 0,
                purchase_order_id: skuDetails?.purchase_order_id,
                seller_id: skuDetails?.seller_id,
                pass_barcodes: [],
                fail_barcodes: [scannedBarcode],
                batch: skuDetails?.batch
              }
              const updateTableData = [...tableData, dataToPush]
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          }
        }
        CustomToast('1 Item failed successfully', { my_type: 'success', duration:2000 })
      }
    }
  })

  
  const {mutate: enforcedSerialisedFailedModal} = useApi({
    isMutation:true,
    apiKey: ['enforced-serialised-failed-modal'],
    apiFn: () => {
      const reasons = [{
        id: enforcedFailReason.id,
        quantity: (parseInt(skuDetails?.fail_qty) + 1),
        upload_ids: enforcedFailUploadIds || [],
        barcodes: Array.isArray(skuDetails?.fail_barcodes) && skuDetails?.fail_barcodes?.length ? [...skuDetails.fail_barcodes, scannedBarcode] : [scannedBarcode],
      }]

      const body = {
        pass_quantity: 0,
        sku_barcodes: [],
        seller_sku_code: skuDetails?.seller_sku_code,
        batch_id: skuDetails?.batch_status === BATCH_STATUS.BATCHED ? skuDetails?.batch?.id : undefined,
        over_receive: isOverItemModalOpen,
        reasons
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${grnResponse?.hub_id}/grns/${grn_id}`, body)
      
    },
    onSuccess: () => {
      dispatch(updateEnforcedFailUploadIds([]))
      if (isSkuNotPartModalOpen) {
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          const isBatchedSkuPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))

          if (isBatchedSkuPresentInTable) {
            if (isBatchedSkuPresentInTable.batch?.id) {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 0,
                  fail_qty: (parseInt(skuDetails?.fail_qty) + 1),
                  pass_barcodes:[],
                  fail_barcodes: Array.isArray(skuDetails?.fail_barcodes) && skuDetails?.fail_barcodes?.length ? [...skuDetails.fail_barcodes, scannedBarcode] : [scannedBarcode],
                  batch: skuDetails?.batch
                }
              }))

              const updateTableData = tableData.map((item) => {
                return item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                  ...item,
                  pass_qty: 0,
                  fail_qty: (parseInt(item?.fail_qty) + 1),
                  pass_barcodes: [],
                  fail_barcodes: Array.isArray(item?.fail_barcodes) && item?.fail_barcodes?.length ? [...item.fail_barcodes, scannedBarcode] : [scannedBarcode],
                  batch: skuDetails?.batch
                } : item
              })
              dispatch(setBinDisabledGrnTableData(updateTableData))
            } else if (!isBatchedSkuPresentInTable.batch?.id) {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 0,
                  fail_qty: (parseInt(skuDetails?.fail_qty) + 1),
                  pass_barcodes:[],
                  fail_barcodes: Array.isArray(skuDetails?.fail_barcodes) && skuDetails?.fail_barcodes?.length ? [...skuDetails.fail_barcodes, scannedBarcode] : [scannedBarcode],
                  batch: skuDetails?.batch
                }
              }))

              const updateTableData = tableData.map((item) => {
                return item.seller_sku_id === skuDetails?.seller_sku_id ? {
                  ...item,
                  pass_qty: 0,
                  fail_qty: (parseInt(item?.fail_qty) + 1),
                  pass_barcodes: [],
                  fail_barcodes: Array.isArray(item?.fail_barcodes) && item?.fail_barcodes?.length ? [...item.fail_barcodes, scannedBarcode] : [scannedBarcode],
                  batch: skuDetails?.batch
                } : item
              })
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 0,
                fail_qty:1,
                pass_barcodes: [],
                batch: skuDetails?.batch,
                fail_barcodes: [scannedBarcode]
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 0,
              fail_qty: 1,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              pass_barcodes: [],
              fail_barcodes: [scannedBarcode],
              batch: skuDetails?.batch
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        }
      }

      if (isOverItemModalOpen) {
        if (skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          const isBatchedSkuPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))

          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isBatchedSkuPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 0,
                fail_qty: 1,
                pass_barcodes: [],
                batch: skuDetails?.batch,
                fail_barcodes: [scannedBarcode]
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 0,
              fail_qty: 1,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              pass_barcodes: [],
              fail_barcodes: [scannedBarcode],
              batch: skuDetails?.batch,
              allow_over_receive: true
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            if (isBatchedSkuPresentInTable) {
              if (isBatchedSkuPresentInTable.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: 0,
                    fail_qty: (parseInt(skuDetails?.fail_qty) + 1),
                    pass_barcodes: [],
                    fail_barcodes: Array.isArray(skuDetails?.fail_barcodes) && skuDetails?.fail_barcodes?.length ? [...skuDetails.fail_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                    ...item,
                    pass_qty: 0,
                    fail_qty: (parseInt(item?.fail_qty) + 1),
                    pass_barcodes: [],
                    fail_barcodes: Array.isArray(item?.fail_barcodes) && item?.fail_barcodes?.length ? [...item.fail_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch,
                    allow_over_receive: true
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              } else if (!isBatchedSkuPresentInTable.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: 0,
                    fail_qty: (parseInt(skuDetails?.fail_qty) + 1),
                    pass_barcodes: [],
                    fail_barcodes: Array.isArray(skuDetails?.fail_barcodes) && skuDetails?.fail_barcodes?.length ? [...skuDetails.fail_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch,
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails?.seller_sku_id ? {
                    ...item,
                    pass_qty: 0,
                    fail_qty: (parseInt(item?.fail_qty) + 1),
                    pass_barcodes: [],
                    fail_barcodes: Array.isArray(item?.fail_barcodes) && item?.fail_barcodes?.length ? [...item.fail_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch,
                    allow_over_receive: true
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 0,
                  fail_qty:1,
                  pass_barcodes: [],
                  batch: skuDetails?.batch,
                  fail_barcodes: [scannedBarcode]
                }
              }))

              const dataToPush = {
                id: skuDetails?.seller_sku_id,
                name: skuDetails?.name,
                code: skuDetails?.seller_sku_code,
                image: skuDetails?.images?.[0]?.url,
                is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
                seller_sku_id: skuDetails?.seller_sku_id,
                pass_qty: 0,
                fail_qty: 1,
                total_qty: skuDetails?.quantity || 0,
                purchase_order_id: skuDetails?.purchase_order_id,
                seller_id: skuDetails?.seller_id,
                pass_barcodes: [],
                fail_barcodes: [scannedBarcode],
                batch: skuDetails?.batch,
                allow_over_receive: true
              }
              const updateTableData = [...tableData, dataToPush]
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          }
        }
      }
      CustomToast('1 Item failed successfully', { my_type: 'success', duration:2000 })
      setOverItemModalOpen(false)
      setSkuNotPartModalOpen(false)
    }
  })
  
  const { mutate: updateBinDisabledSkuDetails } = useApi({
    isMutation: true,
    apiKey: ['update-no-over-but-exist-Bin-Disabled-Sku-Details-serialised-skus'],
    apiFn: () => {

      let skuInTable
      if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
        skuInTable = tableData.find(item => item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id)
      } else {
        skuInTable = tableData.find(item => item.seller_sku_id === skuDetails?.seller_sku_id)
      }
      
      const body = {
        pass_quantity: (parseInt(skuDetails?.pass_qty) + 1),
        sku_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode],
        seller_sku_code: skuDetails?.seller_sku_code,
        batch_id: skuDetails?.batch_status === BATCH_STATUS.BATCHED ? skuDetails?.batch?.id : undefined,
        over_receive: isOverItemModalOpen || !!skuInTable?.allow_over_receive,
        reasons: Array.isArray(skuDetails?.reasons) && skuDetails?.reasons?.length ? skuDetails?.reasons : undefined,
        return_order_id: returnGrnResponse?.return_order_detail?.id
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${grnResponse?.hub_id}/grns/${grn_id}`, body)
    },
    onSuccess: (data) => {
      if (data?.data?.is_over_receive || data?.data?.is_sku_not_exist) {
        if (data?.data?.is_over_receive) {
          setOverItemModalOpen(true)
        } else {
          setSkuNotPartModalOpen(true)
        }
      } else {
        if (skuDetails?.batch_status === BATCH_STATUS.NOT_BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          const isSkuPresentInTable = tableData.find(item => item.seller_sku_id === skuDetails?.seller_sku_id)
          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isSkuPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 1,
                pass_barcodes: [scannedBarcode],
                fail_barcodes: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 1,
              fail_qty: 0,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              pass_barcodes: [scannedBarcode],
              fail_barcodes: []
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: (parseInt(skuDetails?.pass_qty) + 1),
                pass_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode]
              }
            }))

            const updateTableData = tableData.map((item) => {
              return item.seller_sku_id === skuDetails?.seller_sku_id ? {
                ...item,
                pass_qty: (parseInt(item?.pass_qty) + 1),
                pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode]
              } : item
            })
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        } else if (skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          const isBatchedSkuPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))

          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isBatchedSkuPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 1,
                pass_barcodes: [scannedBarcode],
                batch: skuDetails?.batch,
                fail_barcodes: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 1,
              fail_qty: 0,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              pass_barcodes: [scannedBarcode],
              fail_barcodes: [],
              batch: skuDetails?.batch
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            if (isBatchedSkuPresentInTable) {
              if (isBatchedSkuPresentInTable.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: (parseInt(skuDetails?.pass_qty) + 1),
                    pass_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                    ...item,
                    pass_qty: (parseInt(item?.pass_qty) + 1),
                    pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              } else if (!isBatchedSkuPresentInTable.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: (parseInt(skuDetails?.pass_qty) + 1),
                    pass_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails?.seller_sku_id ? {
                    ...item,
                    pass_qty: (parseInt(item?.pass_qty) + 1),
                    pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 1,
                  pass_barcodes: [scannedBarcode],
                  batch: skuDetails?.batch,
                  fail_barcodes: []
                }
              }))

              const dataToPush = {
                id: skuDetails?.seller_sku_id,
                name: skuDetails?.name,
                code: skuDetails?.seller_sku_code,
                image: skuDetails?.images?.[0]?.url,
                is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
                seller_sku_id: skuDetails?.seller_sku_id,
                pass_qty: 1,
                fail_qty: 0,
                total_qty: skuDetails?.quantity || 0,
                purchase_order_id: skuDetails?.purchase_order_id,
                seller_id: skuDetails?.seller_id,
                pass_barcodes: [scannedBarcode],
                fail_barcodes: [],
                batch: skuDetails?.batch
              }
              const updateTableData = [...tableData, dataToPush]
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          }
        }
        CustomToast('1 Item passed successfully', { my_type: 'success', duration:2000 })
      }
    }
  })

  const { mutate: updateBinDisabledSkuDetailsFromModal, isPending:loadingStateBinDisabled } = useApi({
    isMutation: true,
    apiKey: ['update-over-or-not-exist-Bin-Disabled-Sku-Details-for-modal-serialised-skus'],
    apiFn: () => {
      const body = {
        pass_quantity: (parseInt(skuDetails?.pass_qty) + 1),
        sku_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode],
        seller_sku_code: skuDetails?.seller_sku_code,
        batch_id: skuDetails?.batch_status === BATCH_STATUS.BATCHED ? skuDetails?.batch?.id : undefined,
        over_receive: isOverItemModalOpen || isSkuNotPartModalOpen,
        reasons: Array.isArray(skuDetails?.reasons) && skuDetails?.reasons?.length ? skuDetails?.reasons : undefined,
        return_order_id: returnGrnResponse?.return_order_detail?.id
      }
      const grn_id = grnResponse?.id || returnGrnResponse?.id
      return axiosInstance.post(`/api/v1/wms/hubs/${grnResponse?.hub_id}/grns/${grn_id}`, body)
    },
    onSuccess: () => {
      if (isSkuNotPartModalOpen) {
        if (skuDetails?.batch_status === BATCH_STATUS.NOT_BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {

          const isPresentInTable = tableData.find(item => item.seller_sku_id === skuDetails?.seller_sku_id)

          if (isPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: (parseInt(skuDetails?.pass_qty) + 1),
                pass_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode]
              }
            }))
  
            const updateTableData = tableData.map((item) => {
              return item.seller_sku_id === skuDetails?.seller_sku_id ? {
                ...item,
                pass_qty: (parseInt(item?.pass_qty) + 1),
                pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode]
              } : item
            })
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 1,
                pass_barcodes: [scannedBarcode],
                fail_barcodes: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 1,
              fail_qty: 0,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              pass_barcodes: [scannedBarcode],
              fail_barcodes: []
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        } else if (skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          const isBatchedSkuPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))

          if (isBatchedSkuPresentInTable) {
            if (isBatchedSkuPresentInTable.batch?.id) {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: (parseInt(skuDetails?.pass_qty) + 1),
                  pass_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode],
                  batch: skuDetails?.batch
                }
              }))

              const updateTableData = tableData.map((item) => {
                return item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                  ...item,
                  pass_qty: (parseInt(item?.pass_qty) + 1),
                  pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode],
                  batch: skuDetails?.batch
                } : item
              })
              dispatch(setBinDisabledGrnTableData(updateTableData))
            } else if (!isBatchedSkuPresentInTable.batch?.id) {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: (parseInt(skuDetails?.pass_qty) + 1),
                  pass_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode],
                  batch: skuDetails?.batch
                }
              }))

              const updateTableData = tableData.map((item) => {
                return item.seller_sku_id === skuDetails?.seller_sku_id ? {
                  ...item,
                  pass_qty: (parseInt(item?.pass_qty) + 1),
                  pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode],
                  batch: skuDetails?.batch
                } : item
              })
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          } else {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 1,
                pass_barcodes: [scannedBarcode],
                batch: skuDetails?.batch,
                fail_barcodes: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 1,
              fail_qty: 0,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              pass_barcodes: [scannedBarcode],
              fail_barcodes: [],
              batch: skuDetails?.batch
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          }
        }
      }

      if (isOverItemModalOpen) {
        if (skuDetails?.batch_status === BATCH_STATUS.NOT_BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          dispatch(setScannedSkuDetails({
            data: {
              ...skuDetails,
              pass_qty: (parseInt(skuDetails?.pass_qty) + 1),
              pass_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode]
            }
          }))

          const updateTableData = tableData.map((item) => {
            return item.seller_sku_id === skuDetails?.seller_sku_id ? {
              ...item,
              pass_qty: (parseInt(item?.pass_qty) + 1),
              pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode],
              allow_over_receive: true
            } : item
          })
          dispatch(setBinDisabledGrnTableData(updateTableData))
        } else if (skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
          const isBatchedSkuPresentInTable = tableData.find(item => (item.batch?.id ? item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id : item.seller_sku_id === skuDetails?.seller_sku_id))

          if (!displayGrnDetails?.is_purchase_order && !displayGrnDetails?.is_sto && !isBatchedSkuPresentInTable) {
            dispatch(setScannedSkuDetails({
              data: {
                ...skuDetails,
                pass_qty: 1,
                pass_barcodes: [scannedBarcode],
                batch: skuDetails?.batch,
                fail_barcodes: []
              }
            }))

            const dataToPush = {
              id: skuDetails?.seller_sku_id,
              name: skuDetails?.name,
              code: skuDetails?.seller_sku_code,
              image: skuDetails?.images?.[0]?.url,
              is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
              seller_sku_id: skuDetails?.seller_sku_id,
              pass_qty: 1,
              fail_qty: 0,
              total_qty: skuDetails?.quantity || 0,
              purchase_order_id: skuDetails?.purchase_order_id,
              seller_id: skuDetails?.seller_id,
              pass_barcodes: [scannedBarcode],
              fail_barcodes: [],
              batch: skuDetails?.batch,
              allow_over_receive: true
            }
            const updateTableData = [...tableData, dataToPush]
            dispatch(setBinDisabledGrnTableData(updateTableData))
          } else {
            if (isBatchedSkuPresentInTable) {
              if (isBatchedSkuPresentInTable.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: (parseInt(skuDetails?.pass_qty) + 1),
                    pass_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails?.seller_sku_id && item.batch?.id === skuDetails?.batch?.id ? {
                    ...item,
                    pass_qty: (parseInt(item?.pass_qty) + 1),
                    pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch,
                    allow_over_receive: true
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              } else if (!isBatchedSkuPresentInTable.batch?.id) {
                dispatch(setScannedSkuDetails({
                  data: {
                    ...skuDetails,
                    pass_qty: (parseInt(skuDetails?.pass_qty) + 1),
                    pass_barcodes: Array.isArray(skuDetails?.pass_barcodes) && skuDetails?.pass_barcodes?.length ? [...skuDetails.pass_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch,
                  }
                }))

                const updateTableData = tableData.map((item) => {
                  return item.seller_sku_id === skuDetails?.seller_sku_id ? {
                    ...item,
                    pass_qty: (parseInt(item?.pass_qty) + 1),
                    pass_barcodes: Array.isArray(item?.pass_barcodes) && item?.pass_barcodes?.length ? [...item.pass_barcodes, scannedBarcode] : [scannedBarcode],
                    batch: skuDetails?.batch,
                    allow_over_receive: true
                  } : item
                })
                dispatch(setBinDisabledGrnTableData(updateTableData))
              }
            } else {
              dispatch(setScannedSkuDetails({
                data: {
                  ...skuDetails,
                  pass_qty: 1,
                  pass_barcodes: [scannedBarcode],
                  batch: skuDetails?.batch,
                  fail_barcodes: []
                }
              }))

              const dataToPush = {
                id: skuDetails?.seller_sku_id,
                name: skuDetails?.name,
                code: skuDetails?.seller_sku_code,
                image: skuDetails?.images?.[0]?.url,
                is_serialised: skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
                seller_sku_id: skuDetails?.seller_sku_id,
                pass_qty: 1,
                fail_qty: 0,
                total_qty: skuDetails?.quantity || 0,
                purchase_order_id: skuDetails?.purchase_order_id,
                seller_id: skuDetails?.seller_id,
                pass_barcodes: [scannedBarcode],
                fail_barcodes: [],
                batch: skuDetails?.batch,
                allow_over_receive: true
              }
              const updateTableData = [...tableData, dataToPush]
              dispatch(setBinDisabledGrnTableData(updateTableData))
            }
          }
        }
      }
      CustomToast('1 Item passed successfully', { my_type: 'success', duration:2000 })
      setOverItemModalOpen(false)
      setSkuNotPartModalOpen(false)
    }
  })

  const [bulkUploadSkuSidesheetState, setBulkUploadSkuSidesheetState] = useState(false)

  const toggleOverItemModal = () => {
    setOverItemModalOpen(pre => !pre)
  }
  const toggleSkuNotPartModal = () => {
    setSkuNotPartModalOpen(pre => !pre)
  }

  const selectedGlobalHubID = useSelector(
    (store) => store.auth.selectedGlobalHubId
  )
  const singleBarcodeValue = useSelector(store => store.inventory.grn.generatedBarcode)
  const addSkuInGrnBinResponse = useSelector(store => store.inventory.grn.addSkuInGrnBinResponse)
  const binStatus = useSelector(store => store.inventory.grn.bin_status)
  const loadingState = useSelector(store => store.inventory.loadingState)
  const omnifulSkuBarcodeRef = useRef(null)
  const singleBarcodeRef = useRef()
  const fileRef = useRef(null)
  const latestSkuDetails = useSelector(store => store.inventory.grn.sku_details?.data)
  const currentSkuId = useSelector(store => store.inventory.grn.current_sku_id)

  const handlefocusOmnifulSKUBarcode = () => {
    omnifulSkuBarcodeRef.current?.focus()
  }

  const handlefocusQCBinBarcode = () => {
    ref.current?.focus()
  }


  // const handlePrintSingleBarcode = useReactToPrint({
  //   content: () => singleBarcodeRef.current,
  //   pageStyle: `@page {size: 378px ${skuDetails?.batch_status === BATCH_STATUS.BATCHED ? '270px' : '220px'}; margin: auto 0}`,
  //   onAfterPrint: () => {
  //     if (skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id) {
  //       omnifulSkuBarcodeRef.current?.focus()
  //     } else {
  //       ref.current?.focus()
  //     }
  //   }
  // })

  const handleOmnifulSkuBarcode = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      if (e.target.value === '') {
        CustomToast(('Please enter a barcode'), { my_type: 'error', audioRequired: false })
        return
      }
      setOmnifulSkuBarcode(e.target.value)
      ref.current?.focus()
    }
  }

  const handleBinDisabledOmnifulSkuBarcode = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      if (e.target.value === '') {
        CustomToast(('Please enter a barcode'), { my_type: 'error', audioRequired: false })
        return
      }

      const isPresentInPassBarcodes = skuDetails?.pass_barcodes?.includes(e.target.value)
      const isPresentInFailBarocdes = skuDetails?.fail_barcodes?.includes(e.target.value)

      if (!isPresentInPassBarcodes && !isPresentInFailBarocdes) {
        setScannedBarcode(e.target.value)
        if (isMaxShelfLifeEnforced && skuDetails?.batch_status === BATCH_STATUS.BATCHED && isShelfLifeBreached) {
          enforcedSerialisedFailed()
        } else {
          updateBinDisabledSkuDetails()
        }
      } else {
        CustomToast('The scanned Barcode is already in use. Please add a different Barcode.', { my_type: 'error', audioRequired: true })
      }
      setValue('omniful-sku-barcode', '')
      ref.current?.focus()
    }
  }

  const handleAssignItemToBin = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      const value = e.target.value.trim()
      if (value === '') {
        CustomToast(('Please enter a barcode'), { my_type: 'error',audioRequired: false })
        return
      }
      setQcScannedBinBarcode(value)
      if (value) {
        const containerType = value.startsWith('PL-') ? 'pallet' : 'bin'
        const body = {
          grn_id: grnResponse.id,
          hub_id: selectedGlobalHubID,
          bin_barcode: value,
          seller_sku_id: skuDetails?.seller_sku_id,
          type: containerType,
          errorConfig: {
            audioRequired: true
          }
        }

        const isPassBinOrPallet = singleGrnDetail?.pass_bin?.some((item) => item.barcode === value)
        if (isMaxShelfLifeEnforced && skuDetails?.batch_status === BATCH_STATUS.BATCHED && isShelfLifeBreached && isPassBinOrPallet) {
          CustomToast(('Due to items not meeting the acceptable shelf life threshold, they must be marked as failed. Please scan the fail bin/pallet to proceed.'), { my_type: 'error', audioRequired: false })
        } else {
          dispatch(getBinStatus(body))
        }
      }
    }
  }
  const toggleBatchModal = () => {
    setIsBatchBarcodePreviewOpen(pre => !pre)
  }


  // const printSingleBarcode = async () => {
  //   const body = {
  //     seller_id: skuDetails.seller_id,
  //     seller_sku_id: skuDetails.seller_sku_id,
  //     quantity: 1,
  //     hub_id: selectedGlobalHubID
  //   }
  //   if (skuDetails.batch_status === BATCH_STATUS.BATCHED) {
  //     body.batch_id = watch('selected_batch').id
  //   }
  //   await dispatch(generateBarcode(body))
  //   if (skuDetails.batch_status === BATCH_STATUS.BATCHED) {
  //     await setIsBatchBarcodePreviewOpen(true)
  //   } else {
  //     await handlePrintSingleBarcode()
  //   }
  // }

  const handleUploadImages = (e) => {
    const images = [...e.target.files]

    if (uploadedImages.length + images.length <= 5) {
      setUploadedImages([...uploadedImages, ...images])
      e.target.value = null
    }
  }

  const removeImage = (index) => {
    const newImages = [...uploadedImages]
    newImages.splice(index, 1)
    setUploadedImages(newImages)
  }

  const handleTriggerAddImage = () => {
    fileRef.current.click()
  }
  const handleChangeQuantity = () => {
    setValue('omniful-sku-barcode', '')
    setValue('scanned-bin', '')
    setValue('fail_reason', '')
    setUploadedImages([])
    toggleOverItemModal()

    if (!isBinDisabled) {
      dispatch(clearAddItemInGrnBinResponse())
      dispatch(clearBinStatus())
    }
  }
  const handleSkipThisSku = () => {
    setValue('search_sku', '')
    setValue('search_barcode', '')
    setValue('selected_batch', null)
    toggleSkuNotPartModal()

    if (!isBinDisabled) {
      dispatch(clearAddItemInGrnBinResponse())
      dispatch(clearScannedSkuDetails())
    }
  }
  const handleAddOverReceiveItem = () => {
    const body = {
      hub_id: selectedGlobalHubID,
      grn_id: grnResponse.id,
      seller_id: grnResponse.seller_id,
      bin_barcode: qcScannedBinBarcode?.toString()?.trim(),
      is_qc_qualified: binStatus === BIN_STATUS.PASS,
      over_receive: true,
      quantity: skuDetails?.is_gtin_enabled && skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id ? gtinSkuDetails?.scannedQty : skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id ? totalItemQtyToScan : 1,
      bin_status: binStatus === BIN_STATUS.PASS ? 'pass' : 'fail',
      seller_sku_code: skuDetails?.seller_sku_code,
      return_order_id: grnResponse.return_order_id,
      seller_sku_id: skuDetails?.seller_sku_id,
      parent_id: skuDetails?.parent_id || 0
    }
    if (binStatus === BIN_STATUS.FAIL) {
      body.reason_images = uploadedImages
      body.reason_id = watch('fail_reason').value
    }
    if (SEARCH_SKU_TYPES.search_sku.id === selectedTabId && skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id) {
      body.sku_barcode = skuDetails?.is_gtin_enabled ? null : ''
    } else {
      body.sku_barcode = skuDetails?.is_gtin_enabled && skuDetails?.batch_status === BATCH_STATUS.BATCHED ? (searchBarcode?.trim() || mainScannedBarcode?.trim()) : skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id ? searchBarcode.trim() : omnifulSkuBarcode
    }
    if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
      body.batch_id = skuDetails?.batch?.id || watch('selected_batch')?.id
    }
    if (currentSkuId === skuDetails?.seller_sku_id) {
      dispatch(addSkuInGrnBin(body))
    }
  }

  const handleSubmitFailBinReason = () => {
    const body = {
      hub_id: selectedGlobalHubID,
      grn_id: grnResponse.id,
      seller_id: grnResponse.seller_id,
      bin_barcode: qcScannedBinBarcode?.toString()?.trim(),
      is_qc_qualified: false,
      over_receive: false,
      quantity: skuDetails?.is_gtin_enabled && skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id ? gtinSkuDetails?.scannedQty : skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id ? totalItemQtyToScan : 1,
      bin_status: 'fail',
      reason_images: uploadedImages,
      reason_id: watch('fail_reason').value,
      seller_sku_code: skuDetails?.seller_sku_code,
      return_order_id: grnResponse.return_order_id,
      seller_sku_id: skuDetails?.seller_sku_id,
      parent_id: skuDetails?.parent_id || 0
    }
    if (SEARCH_SKU_TYPES.search_sku.id === selectedTabId && skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id) {
      body.sku_barcode = skuDetails?.is_gtin_enabled ? null : ''
    } else {
      body.sku_barcode = skuDetails?.is_gtin_enabled && skuDetails?.batch_status === BATCH_STATUS.BATCHED ? (searchBarcode?.trim() || mainScannedBarcode?.trim()) : skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id ? '' : omnifulSkuBarcode
    }
    if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
      body.batch_id = skuDetails?.batch?.id || watch('selected_batch')?.id
    }
    if (currentSkuId === skuDetails?.seller_sku_id) {
      dispatch(addSkuInGrnBin(body))
    }
  }

  const handleUploadBulkBarcode = () => {
    setBulkUploadSkuSidesheetState(true)
  }

  useEffect(() => {
    if (binStatus === BIN_STATUS.PASS) {
      const body = {
        hub_id: selectedGlobalHubID,
        grn_id: grnResponse.id,
        seller_id: grnResponse.seller_id,
        bin_barcode: qcScannedBinBarcode?.toString()?.trim(),
        is_qc_qualified: true,
        over_receive: false,
        quantity: skuDetails?.is_gtin_enabled && skuDetails?.batch_status === BATCH_STATUS.BATCHED && skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id ? gtinSkuDetails?.scannedQty : skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id ? totalItemQtyToScan : 1,
        bin_status: 'pass',
        seller_sku_code: skuDetails?.seller_sku_code,
        seller_sku_id: skuDetails?.seller_sku_id,
        return_order_id: grnResponse.return_order_id,
        parent_id: skuDetails?.parent_id || 0
      }
      if (SEARCH_SKU_TYPES.search_sku.id === selectedTabId && skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id) {
        body.sku_barcode = skuDetails?.is_gtin_enabled ? null : ''
      } else {
        body.sku_barcode = skuDetails?.is_gtin_enabled && skuDetails?.batch_status === BATCH_STATUS.BATCHED ? (searchBarcode?.trim() || mainScannedBarcode?.trim()) : skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id ? '' : omnifulSkuBarcode
      }
      if (skuDetails?.batch_status === BATCH_STATUS.BATCHED) {
        body.batch_id = skuDetails?.batch?.id || watch('selected_batch')?.id
      }

      if (currentSkuId === skuDetails?.seller_sku_id) {
        dispatch(addSkuInGrnBin(body))
      }
    }
  }, [binStatus])

  useEffect(() => {
    if (addSkuInGrnBinResponse.is_success) {
      if (skuDetails?.isRoot) {
        // logic for root sku
        if (addSkuInGrnBinResponse.data.is_sku_not_exist || addSkuInGrnBinResponse.data.is_over_receive) {
          if (addSkuInGrnBinResponse.data.is_sku_not_exist) {
            setSkuNotPartModalOpen(true)
          }
          if (addSkuInGrnBinResponse.data.is_over_receive) {
            setOverItemModalOpen(true)
          }
        } else {
          if (addSkuInGrnBinResponse.seller_sku_id === skuDetails?.seller_sku_id) {
            // multi serialised logic
            if (skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id && qty > 1) {
              dispatch(setLastScannedBin(watch('scanned-bin').trim()))
              dispatch(clearAddItemInGrnBinResponse())
              dispatch(clearBinStatus())
              setValue('omniful-sku-barcode', '')
              setValue('scanned-bin', '')
              setValue('fail_reason', '')
              setSkuNotPartModalOpen(false)
              setOverItemModalOpen(false)
              setUploadedImages([])
              omnifulSkuBarcodeRef.current?.focus()
              setQty(pre => pre - 1)
              dispatch(updateAssignedQtyForCurrentSku({ skuId: skuDetails?.seller_sku_id, qtyToAssign: 1, prevState: latestSkuDetails }))
            } else {
              // single serialised or non-serialised logic
              dispatch(setLastScannedBin(watch('scanned-bin')?.trim()))
              dispatch(clearDataAfterSKUScanned())
              dispatch(updateGtinSkuDetails({}))
              dispatch(updateIsGtinChanged(false))
              setValue('search_barcode', '')
              setValue('search_sku', '')
              dispatch(updateAssignedQtyForCurrentSku({ skuId: skuDetails?.seller_sku_id, qtyToAssign: totalItemQtyToScan, prevState: latestSkuDetails }))
            }
          }
        }
      } else {
        // logic for nested skus
        if (addSkuInGrnBinResponse.seller_sku_id === skuDetails?.seller_sku_id) {
          // multiple serialised logic
          if (skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id && skuDetails?.maxQty - (skuDetails?.openedAssignedQty + skuDetails?.closedAssignedQty) > 1) {
            dispatch(setLastScannedBin(watch('scanned-bin').trim()))
            dispatch(clearAddItemInGrnBinResponse())
            dispatch(clearBinStatus())
            setValue('omniful-sku-barcode', '')
            setValue('scanned-bin', '')
            setValue('fail_reason', '')
            setSkuNotPartModalOpen(false)
            setOverItemModalOpen(false)
            setUploadedImages([])
            omnifulSkuBarcodeRef.current?.focus()
            // setQty(pre => pre - 1)
            dispatch(updateAssignedQtyForCurrentSku({ skuId: skuDetails?.seller_sku_id, qtyToAssign: 1, prevState: latestSkuDetails }))
          } else {
            // single serialised or non-serialised logic
            dispatch(setLastScannedBin(watch('scanned-bin').trim()))
            dispatch(clearDataAfterSKUScanned())
            dispatch(updateGtinSkuDetails({}))
            dispatch(updateIsGtinChanged(false))
            setValue('search_barcode', '')
            setValue('search_sku', '')
            dispatch(updateAssignedQtyForCurrentSku({ skuId: skuDetails?.seller_sku_id, qtyToAssign: totalItemQtyToScan, prevState: latestSkuDetails }))
          }
        }
      }
    }
  }, [addSkuInGrnBinResponse])

  const handleSaveAnyway = () => {
    if (isMaxShelfLifeEnforced && skuDetails?.batch_status === BATCH_STATUS.BATCHED && isShelfLifeBreached) {
      enforcedSerialisedFailedModal()
    } else {
      updateBinDisabledSkuDetailsFromModal()
    }
  }

  useEffect(() => {
    if (skuDetails?.is_gtin_enabled && skuDetails.batch_status === BATCH_STATUS.BATCHED && skuDetails.serialisation_status === SERIALISATION_OPTION.serialised.id & !isBinDisabled) {
      handlefocusQCBinBarcode()
    }
  }, [skuDetails])

  return (
    <div className={classNames('d-flex flex-column', {
      'mt-4px': skuDetails?.is_gtin_enabled && skuDetails.batch_status === BATCH_STATUS.BATCHED,
      'mt-28px gap-28px': !skuDetails?.is_gtin_enabled
    })}>
      <div className='d-flex flex-column gap-12px'>
        {/* <Button disabled={loadingState.generateBarcode} onClick={printSingleBarcode}>

          {loadingState.generateBarcode && <Spinner size="sm" />}
          <span className="align-middle ms-25">{t("Print Barcode")}</span>
        </Button> */}
        {skuDetails?.batch_status === BATCH_STATUS.BATCHED && <BatchBarcodePreview isOpen={isBatchedBarcodePreviewOpen} toggle={toggleBatchModal} barcodeValue={singleBarcodeValue.barcodes?.[0]} batchNum={skuDetails?.batch?.value?.batch_num || watch('selected_batch')?.value?.batch_num} expDate={skuDetails?.batch?.value?.expiry_date || watch('selected_batch')?.value?.expiry_date} handlefocusOmnifulSKUBarcode={handlefocusOmnifulSKUBarcode} handlefocusQCBinBarcode={handlefocusQCBinBarcode} />}
        <div className='d-none'>
          <div className='d-flex flex-column align-items-center justify-content-center' ref={singleBarcodeRef}>
            <Barcode value={singleBarcodeValue.barcodes?.[0]} />
            {skuDetails?.batch_status === BATCH_STATUS.BATCHED && <>
              <div className='text-dark'>{t('Batch ID')}: {skuDetails?.batch?.value?.batch_num || watch('selected_batch')?.value?.batch_num}</div>
              <div className='text-dark'>{t('Expiry Date')} : {skuDetails?.batch?.value?.expiry_date || watch('selected_batch')?.value?.expiry_date}</div>
            </>}
          </div>
        </div>
        <div className='flex-center-start gap-1'>
          {skuDetails?.is_gtin_enabled && skuDetails?.batch_status === BATCH_STATUS.BATCHED ? null : <div className={classNames({
            'w-50': skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id,
            'w-100': skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id || (isMaxShelfLifeEnforced && skuDetails?.batch_status === BATCH_STATUS.BATCHED && isShelfLifeBreached)
          })}>
            <PrintBulkSkuBarcode qty={qty} setQty={setQty} skuDetails={skuDetails} batchDetails={skuDetails?.batch?.value?.id ? skuDetails.batch : watch('selected_batch')} handlefocusOmnifulSKUBarcode={handlefocusOmnifulSKUBarcode} handlefocusQCBinBarcode={handlefocusQCBinBarcode} />
          </div>}
          {((skuDetails?.is_gtin_enabled && skuDetails?.batch_status === BATCH_STATUS.BATCHED ) || (isMaxShelfLifeEnforced && skuDetails?.batch_status === BATCH_STATUS.BATCHED && isShelfLifeBreached)) ? null : skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id &&
            <div className='w-50'>
              <Button className='flex-grow-1 justify-content-center w-100' onClick={handleUploadBulkBarcode} ofStyle='outlined'>{t('Upload CSV for Bulk Allocation')}</Button>
            </div>
          }
        </div>
      </div>
      {skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id && singleBarcodeValue && isBinDisabled && <div>
        <BarcodeScanner
          ref={omnifulSkuBarcodeRef}
          startIcon={
            <img
              src="https://cdn-icons-png.flaticon.com/128/1550/1550324.png"
              alt="QR Code"
              width="16px"
              height="16px"
            />
          }
          placeholder="Scan Serialised Barcode"
          control={control}
          name="omniful-sku-barcode"
          onKeyDown={handleBinDisabledOmnifulSkuBarcode}
        />
        { isMaxShelfLifeEnforced && skuDetails?.batch_status === BATCH_STATUS.BATCHED && isShelfLifeBreached ?
          <div className='txt-sub-rg text-dark-5 pt-4px flex-center-start gap-6px'>
            <Info size={13}/>
            <span style={{paddingTop:'1px'}}>
              {t('All items belonging to the above batch will be marked as failed.')}
            </span>
          </div>
          : null
        }
      </div>}

      {<div className='d-flex flex-column gap-16px'>
        {(skuDetails?.is_gtin_enabled && skuDetails.batch_status === BATCH_STATUS.BATCHED ||
          skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id && singleBarcodeValue || 
          skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id
        ) && !isBinDisabled ? <div className='text-dark txt-body-md'>{t('Assign Items To Bin/Pallet')}</div> : null}

        {skuDetails?.is_gtin_enabled && skuDetails.batch_status === BATCH_STATUS.BATCHED ? null : 
          skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id && singleBarcodeValue && !isBinDisabled &&
        <BarcodeScanner
          ref={omnifulSkuBarcodeRef}
          startIcon={
            <img
              src="https://cdn-icons-png.flaticon.com/128/1550/1550324.png"
              alt="QR Code"
              width="16px"
              height="16px"
            />
          }
          placeholder="Scan Serialised Barcode"
          control={control}
          name="omniful-sku-barcode"
          onKeyDown={handleOmnifulSkuBarcode}
        />}

        {(skuDetails?.is_gtin_enabled && skuDetails.batch_status === BATCH_STATUS.BATCHED || 
        skuDetails?.serialisation_status === SERIALISATION_OPTION.serialised.id && singleBarcodeValue || 
        skuDetails?.serialisation_status === SERIALISATION_OPTION.non_serialised.id
        ) && !isBinDisabled ? <BarcodeScanner
            width="380px"
            ref={ref}
            startIcon={
              <img
                src={binStatus === BIN_STATUS.PASS ? passBin : binStatus === BIN_STATUS.FAIL ? failBin : 'https://cdn-icons-png.flaticon.com/128/1550/1550324.png'}
                alt="QR Code"
                width="16px"
                height="16px"
              />
            }
            placeholder="Scan Bin/Pallet Barcode"
            control={control}
            name="scanned-bin"
            onKeyDown={handleAssignItemToBin}
          /> : null}

        {binStatus === BIN_STATUS.FAIL ? <>
          <FloatingDropDown
            name="fail_reason"
            control={control}
            value={watch('fail_reason')}
            errors={errors}
            loadOptions={getQcFailReasons}
            validationSchema={{
              fail_reason: { required: 'This field is required' }
            }}
            isAsync
            isRequired
            isClearable
            title="Select Reason"
            additional={{
              page: 1
            }}
          />
          <div className='d-flex gap-16px align-items-center'>
            {uploadedImages.length ?
              <div className='d-flex gap-16px'>
                {
                  uploadedImages?.map((image, index) => {
                    return <div className='position-relative' key={index}>
                      <X className='bg-primary text-white rounded-circle position-absolute top-0 start-100 translate-middle' onClick={removeImage} size={12} />
                      <img className='rounded-8px' src={URL.createObjectURL(image)} width={60} height={60} alt='Image Preview' />
                    </div>
                  })
                }
              </div>
              : null}
            {uploadedImages.length < 5 && <label
              htmlFor='images_upload'
            >
              <input
                ref={fileRef}
                className='d-none'
                type='file'
                multiple
                id='images_upload'
                name='images_upload'
                accept='image/*'
                onChange={(e) => handleUploadImages(e)}
              />
              <Button className='flex-column gap-8px pt-12px pb-8px txt-asst-md' icon={PlusSquare} ofStyle='outlined' onClick={handleTriggerAddImage}>{t('Image')}</Button>
            </label>}
          </div>
          <Button disabled={!watch('fail_reason') || loadingState.addSkuInGrnBin} onClick={handleSubmitFailBinReason}>
            {loadingState.addSkuInGrnBin && <Spinner size="sm" />}
            <span className="align-middle ms-25">{t('Scan Next SKU')}</span>
          </Button>
        </> : null}

      </div>}
      <OverReceiveItemModal
        isOpen={isOverItemModalOpen}
        toggle={toggleOverItemModal}
        handleSaveAnyway={isBinDisabled ? handleSaveAnyway : handleAddOverReceiveItem}
        handleChangeQuantity={handleChangeQuantity}
        loading={loadingStateBinDisabled}
      />
      <SkuNotPartOfOrderModal
        isOpen={isSkuNotPartModalOpen}
        toggle={toggleSkuNotPartModal}
        handleSaveAnyway={isBinDisabled ? handleSaveAnyway : handleAddOverReceiveItem}
        handleSkipThisSku={handleSkipThisSku}
        loading={loadingStateBinDisabled}
      />

      <BulkUploadSerialisedSkus
        bulkUploadSkuSidesheetState={bulkUploadSkuSidesheetState}
        setBulkUploadSkuSidesheetState={setBulkUploadSkuSidesheetState}
        watch={watch}
        sku_details={skuDetails}
      />
    </div>
  )
})

export default GRNFinalStep