import Button from '@src/@core/components/ui/button'
import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import { ALL_COLUMN_NAME_MAPPING } from '@src/@core/components/ui/globalTable/globalTable.constant'
import PageHeader from '@src/@core/components/ui/page-header'
import SKUDetailsCol from '@src/@core/components/ui/sku-details/index'
import { API_ENDPOINTS } from '@src/api.urls'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import Inventory from '@src/assets/images/icons/primary-navbar/Inventory'
import { WarningLogo } from '@src/assets/images/omniful/warning'
import { useApi } from '@src/configs/react-query/useApi'
import { axiosInstance } from '@src/network/AxiosInstance'
import { bindToChannelEvent, initRealTime } from '@src/network/RealTime'
import { handlePusherResponse } from '@src/redux/authentication'
import { getApiUrl } from '@src/utility/Utils'
import { AbilityContext } from '@src/utility/context/Can'
import { PACKAGE_TYPE } from '@src/views/Purchases/purchase.constants'
import { BUNDLE, SKU_TYPE_OPTIONS } from '@src/views/catalog/catalog.constants'
import SimpleSkuDetailModal from '@src/views/catalog/components/simple-sku-detail-modal/SimpleSkuDetailModal'
import SidebarContainer from '@src/views/sales/picking-wave/pages/sidebarContainer/SidebarContainer'
import { showSkuTypeTag } from '@src/views/sales/sales.utils'
import { getPicklistsOfStatus } from '@src/views/sales/store/store'
import classNames from 'classnames'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Check, ChevronDown, Edit2, Info } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
  UncontrolledAccordion,
  UncontrolledTooltip
} from 'reactstrap'
import BundleSkuDetailsSideSheet from '../../../@core/components/ui/bundle-sku-details'
import {
  API_KEYS,
  BULK_SHIP_PATH_NAMES,
  BULK_SHIP_TABS_DATA,
  BULK_SHIPMENT_STATUSES,
  MAX_ORDERS_IN_CLUSTER as MAX_ORDERS_IN_CLUSTER_BULKSHIP,
  PROCESS_CLUSTER_STEPS
} from '../bulk-ship.constants'
import { setCreationStatuses } from '../store'
import SkuExpander from './SkuExpander'
import PackagingPreference from './packaging-preference'
import { PrintClusterPackingList } from './print-cluster-packing-list'
import ShipmentPrintBtns from './shipment-print-btns'
import ShipmentProcessing from './shipment-processing'
import ViewCluster from './view-cluster'

const AVL_QTY_COL_KEY = 'AVL_QTY_COL_KEY'
const DEFAULT_BOX = { '': {} }

function backendToUiState(boxDetails) {
  const uiState = {}

  boxDetails.forEach((box, index) => {
    const refId = Date.now() + index // Generate unique refId

    const skuDetails = box.sku_details.reduce((acc, skuDetail) => {
      acc[skuDetail.sku.seller_sku_id] = {
        seller_sku_id: skuDetail.sku.seller_sku_id,
        quantity: skuDetail.quantity
      }
      return acc
    }, {})

    uiState[refId] = {
      value: box.box.id,
      label: box.box.name,
      refId,
      sku_details: skuDetails
    }
  })

  return uiState
}

const DisabledAccordionHeader = ({ heading, order }) => {
  return (
    <div className="border border-2 border-light-1 rounded-8px opacity-75 border-dashed cursor-not-allowed bg-light-2">
      <div className="w-100 px-16px py-20px flex-start-start gap-16px outline-0 bg-transparent txt-h3-md my-accordion-button">
        {/* <span className='rounded-circle accordion-order-circle txt-sub-md flex-center-center bg-dark-1 text-dark'></span> */}
        <span className="rounded-circle accordion-order-circle txt-sub-md flex-center-center bg-dark-1 text-dark">
          {order}
        </span>
        <div className="flex-center-center gap-8px">
          {/* <span>{PROCESS_CLUSTER_STEPS.PACKAGING_PREFERENCE.HEADING}</span> */}
          <span>{heading}</span>
          <span className="accordion-arrow">
            <ChevronDown size={20} />
          </span>
        </div>
      </div>
    </div>
  )
}

const OrderDetailsCard = ({
  t,
  noOfPackages = 0,
  totalOrders,
  itemsPerOrder,
  orderWeight
}) => {
  const isTotalOrdersMoreThanMaxCap =
    +totalOrders > MAX_ORDERS_IN_CLUSTER_BULKSHIP
  const ordersToProcess =
    +totalOrders > MAX_ORDERS_IN_CLUSTER_BULKSHIP
      ? MAX_ORDERS_IN_CLUSTER_BULKSHIP
      : totalOrders
  const itemsToPick = (+ordersToProcess || 0) * (+itemsPerOrder || 0)

  return (
    <div className="border rounded-10px bg-warning-light text-warning border border-dark-2">
      <div className="bg-white text-dark flex-start-between txt-h0-sb rounded-10px px-20px py-16px">
        {/* Orders */}
        <div className="d-flex flex-column gap-8px">
          <div>
            {isTotalOrdersMoreThanMaxCap ? (
              <>
                {MAX_ORDERS_IN_CLUSTER_BULKSHIP}/
                <span className="txt-h3-sb">{totalOrders}</span>
              </>
            ) : (
              <>{ordersToProcess || '-'}</>
            )}
          </div>
          <div className="txt-body-rg text-dark-6">{t('Orders')}</div>
        </div>

        {/* Items To Pick */}
        <div className="d-flex flex-column gap-8px">
          <div>{itemsPerOrder || '-'}</div>
          <div className="txt-body-rg text-dark-6">{t('Items Per Order')}</div>
        </div>

        {/* Items To Pick */}
        <div className="d-flex flex-column gap-8px">
          <div>{itemsToPick || '-'}</div>
          <div className="txt-body-rg text-dark-6 flex-center-center gap-4px">
            <span>{t('Items To Pick')}</span>
            {isTotalOrdersMoreThanMaxCap && (
              <>
                <Info id="info-icon-for-tooltip" size={16} />
                <UncontrolledTooltip
                  placement="bottom"
                  target={'info-icon-for-tooltip'}
                  offset={[0, 5]}
                >
                  {t('Total number of items to be picked in this cluster, belonging to the first 200 orders.')}
                </UncontrolledTooltip>{' '}
              </>
            )}
          </div>
        </div>

        {/* Order Weight */}
        <div className="d-flex flex-column gap-8px">
          <div>{orderWeight || '-'}</div>
          <div className="txt-body-rg text-dark-6 flex-center-center gap-4px">
            <span>{t('Order Weight')}</span>
          </div>
        </div>

        {/* Packages Per Order */}
        <div className="d-flex flex-column gap-8px">
          <div>{noOfPackages || '-'}</div>
          <div className="txt-body-rg text-dark-6 flex-center-center gap-4px">
            <span>{t('Packages Per Order')}</span>
          </div>
        </div>
      </div>

      {isTotalOrdersMoreThanMaxCap ? (
        <div className="p-18px flex-center-start gap-10px txt-sub-rg">
          <span>
            <Info size={18} />
          </span>
          <span>
            {t('A maximum of')} {MAX_ORDERS_IN_CLUSTER_BULKSHIP}{' '}
            {t(
              'orders are processed in a cluster at once, selected on a first-come, first-served basis.'
            )}
          </span>
        </div>
      ) : null}
    </div>
  )
}

const ProcessCluster = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const ability = useContext(AbilityContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const [insufficientInventoryData, setInsufficientInventoryData] = useState({
    isOpen: false,
    data: null
  })
  const [noOfPackages, setNoOfPackages] = useState(0)
  const [isSavePackagingDetailsApi, setIsSavePackagingDetailsApi] = useState(false)

  const selectedGlobalHubId = useSelector((store) => store.auth.selectedGlobalHubId)
  const selectedCluster = useSelector((state) => state.bulkShip.selectedCluster)
  const picklistsOfStatus = useSelector((state) => state.sales.picklistsOfStatus)
  const selectedPicklistStatus = useSelector((state) => state.sales.selectedPicklistStatus)
  const [skuForDetails, setSkuForDetails] = useState({})
  const [openSkuDetailsSidebar, setOpenSkuDetailsSidebar] = useState(false)
  const [openBundleSkuDetailsSidebar, setOpenBundleSkuDetailsSidebar] = useState(false)
  const [orderWeight, setOrderWeight] = useState('')
  const [boxes, setBoxes] = useState(DEFAULT_BOX)
  const selectedOrders = useSelector(store => store.bulkShip.selectedOrdersForShipments)
  const pusherLoading = useSelector(state => state.auth.pusherLoading)
  const pusherResponse = useSelector(state => state.auth.pusherResponse)
  const [isAtleastOneShipmentCreated, setIsAtleastOneShipmentCreated] = useState(false)
  const [isPickingWaveDetailsSidebarOpen, setisPickingWaveDetailsSidebarOpen] = useState(false)
  const [isPickingWaveGenerating, setPickingWaveGenerating] = useState(false)
  const [generatePickingWaveResponse, setGeneratePickingWaveResponse] = useState(null)

  const generatePickingWaveTenSecondTimeoutId = useRef(null)
  
  const identification = searchParams.get('identification')
  const searchParamWaveId = searchParams.get('wave_id')
  const navigate = useNavigate()

  //***** Get Picking Wave ^ */
  const {
    data: clusterPickingWave,
    isFetching: getPickingWaveLoading,
    isError
  } = useApi({
    apiKey: [
      API_KEYS.FETCH_PICKING_WAVE,
      { identification, searchParamWaveId }
    ],
    apiFn: () => {
      let url
      const params = {} 
      dispatch(setCreationStatuses(null))
      if (identification) {
        url = getApiUrl(API_ENDPOINTS.BULK_SHIP.GET_CLUSTER_PICKING_WAVE_WITH_IDENTIFICATION, {
          hubId: selectedGlobalHubId
        })
        params.identification = identification
      } else {
        url = getApiUrl(API_ENDPOINTS.BULK_SHIP.GET_CLUSTER_PICKING_WAVE, {
          hubId: selectedGlobalHubId,
          waveId: searchParamWaveId
        })
      }
      return axiosInstance.get(url, { params })
    },
    onSuccess: ({ data }) => {
      setIsSavePackagingDetailsApi(false)
      if (data.packing_details?.no_of_packages) {
        setBoxes(backendToUiState(data.packing_details.box_details))
        setOrderWeight(data.packing_details.display_order_weight)
      }
    },
    enabled: !!(identification || searchParamWaveId)
  })

  useEffect(() => {
    if (isError) {
      setIsSavePackagingDetailsApi(false)
      setPickingWaveGenerating(false)
    }
  }, [isError])
  //***** Get Picking Wave $ */
  const pickingWave = clusterPickingWave?.data
  const isPickingWaveGenerated = Boolean(pickingWave?.wave?.id)
  const hasPackagingPreference = Boolean(
    pickingWave?.packing_details?.no_of_packages
  )
  const waveId = pickingWave?.wave?.id || searchParamWaveId
  const hasCreatePickingWavePermission = ability.can(abilityMap.picking.create_wave.action, abilityMap.picking.create_wave.resource)
  const hasViewPickingWavePermission = ability.can(abilityMap.picking.view_wave.action, abilityMap.picking.view_wave.resource)
  const hasViewBinPermission = ability.can(abilityMap.hub_location.view_bin.action, abilityMap.hub_location.view_bin.resource)
  // const hasCreateBinPermission = ability.can(abilityMap.hub_location.view_bin.action, abilityMap.hub_location.view_bin.resource)

  const orderItems = isPickingWaveGenerated
    ? pickingWave?.cluster_items || []
    : selectedCluster?.order_items || []

  //***** Table Related ^ */

  const [searchedOrderItems, setSearchedOrderItems] = useState(null)

  const tableData = useMemo(() => {
    return orderItems?.map((item, idx) => ({
      id: item.sku?.seller_sku_id,
      seller_id: item.sku?.seller_id,
      serialNumber: idx + 1,
      name: item.sku?.name,
      seller_sku_code: item.sku?.seller_sku_code,
      image: item.sku?.images?.[0]?.url,
      qty: item.display_quantity,
      avlQty: item.display_available_quantity,
      totalPickedOrToBePickedQty: item.display_total_quantity,
      child_skus: item.child_order_items,
      type: item.sku?.type,
      packageType: item.sku?.package_type
    }))
  }, [orderItems, isPickingWaveGenerated, clusterPickingWave])


  const handleSkuDetailsSidebar = (data) => {
    if (data) {
      const formattedData = {
        ...data,
        sku_name: data.name,
        images: [{url: data.image, type: ''}]
      }
      setSkuForDetails(formattedData)
      setOpenSkuDetailsSidebar(true)
    } else {
      setOpenSkuDetailsSidebar(false)
    }
  }

  const handleBundleSkuDetailsSidebar = (data) => {
    if (data) {
      setSkuForDetails(data)
      setOpenBundleSkuDetailsSidebar(true)
    } else {
      setOpenBundleSkuDetailsSidebar(false)
    }
  }

  const columns = [
    {
      id: 1,
      name: t('S. No.'),
      key: 'serial_no',
      width: '64px',
      reorder: false,
      cell: (row) => (
        <div className="w-100 text-center">{row.serialNumber}</div>
      )
    },
    {
      id: 2,
      name: <span className="txt-body-md">{t('SKU Details')}</span>,
      minWidth: '300px',
      maxWidth: '500px',
      reorder: true,
      cell: (row) => {
        const { image, name, seller_sku_code } = row

        return (
          <div className="w-100">
            <SKUDetailsCol
              skuDetailData={{
                isValidSku: true,
                id: row.id,
                image: {
                  src: image,
                  alt: name
                },
                details: {
                  name,
                  handleSkuDetails: () => (row.child_skus?.length > 0 ? handleBundleSkuDetailsSidebar(row) : handleSkuDetailsSidebar(row)),
                  skuCode: seller_sku_code,
                  columnKey: 'sku_details'
                },
                customTag: {
                  isCustomTagPresent: row.type === BUNDLE || row.package_type === PACKAGE_TYPE.CASE_PACK || row.package_type === PACKAGE_TYPE.PALLET,
                  className: classNames({
                    'bg-brown-light text-brown': row.package_type === PACKAGE_TYPE.PALLET,
                    'bg-info-light text-info': row.type === BUNDLE || row.package_type === PACKAGE_TYPE.CASE_PACK
                  }),
                  title: showSkuTypeTag(row.type === BUNDLE ? row.type : row.packageType)
                }
              }}
            />
          </div>
        
        )
      }
    },
    {
      id: 3,
      name: (
        <div className="txt-body-md w-100 text-end text-truncate">
          {t('Qty Per Order')}
        </div>
      ),
      key: 'qty_per_order',
      minWidth: '120px',
      reorder: true,
      cell: (row) => {
        const { qty } = row
        return (
          <div className="w-100">
            <div className="w-100 txt-sub-rg text-end text-truncation">
              {qty}
            </div>
          </div>
        )
      }
    },
    {
      id: 4,
      name: (
        <div className="txt-body-md w-100 text-end text-truncate">
          {isPickingWaveGenerated ? t('Total Picked Qty') : t('Total Qty To Be Picked')}
        </div>
      ),
      key: 'total_qty',
      minWidth: '230px',
      reorder: true,
      cell: (row) => {
        const { totalPickedOrToBePickedQty } = row
        return (
          <div className="w-100">
            <div className="w-100 txt-sub-rg text-end text-truncation">
              {totalPickedOrToBePickedQty}
            </div>
          </div>
        )
      }
    }
    // {
    //   id: 4,
    //   name: (
    //     <div className="txt-body-md w-100 text-end text-truncate">
    //       {t("Avl Qty")}
    //     </div>
    //   ),
    //   key: AVL_QTY_COL_KEY,
    //   minWidth: "130px",
    //   maxWidth: "130px",
    //   reorder: true,
    //   cell: (row) => {
    //     const { avlQty } = row
    //     return (
    //       <div className="w-100">
    //         <div className="w-100 txt-sub-rg text-end text-truncation">
    //           {avlQty}
    //         </div>
    //       </div>
    //     )
    //   }
    // }
  ].filter((el) => (isPickingWaveGenerated ? el.key !== AVL_QTY_COL_KEY : true)
  )

  const handleSearchItem = ({ searchVal, selectedColumn = null }) => {
    const searchQuery = searchVal ? { column: selectedColumn, query: searchVal } : null

    if (searchQuery?.query) {
      const newOrderItems = tableData.filter(order => {
        const query = searchQuery.query.toLowerCase()
        if (searchQuery.column.id === 'sku_name') {
          return (order.name || '').toLowerCase().includes(query)
        } else if (searchQuery.column.id === 'sku_code') {
          return (order.seller_sku_code || '').toLowerCase().includes(query)
        }
      })
      setSearchedOrderItems(newOrderItems)
    } else {
      setSearchedOrderItems(null)
    }
  }

  const handleGeneratePickingWaveWhenPusherDown = (identification) => {
    if (isPickingWaveGenerated) {
      return
    }
    if (generatePickingWaveTenSecondTimeoutId.current) {
      clearTimeout(generatePickingWaveTenSecondTimeoutId.current)
      generatePickingWaveTenSecondTimeoutId.current = null
    }
    setPickingWaveGenerating(true)
    setSearchParams({ identification })
  }

  const handleWhenGeneratePickingWaveSuccess = (data) => {
    CustomToast('Generating your picking wave. We\'ll notify you once it\'s done', {
      my_type: 'info'
    })
    const identification = data.identification
    bindToChannelEvent(data.event)    
    generatePickingWaveTenSecondTimeoutId.current = setTimeout(() => {
      handleGeneratePickingWaveWhenPusherDown(identification)
    }, 10000)
  }

  //***** Table Related $ */

  //***** Generate Picking Wave ^ */
  const { mutate: generatePickingWave} = useApi({
    isMutation: true,
    apiKey: [API_KEYS.GENERATE_PICKING_WAVE],
    apiFn: ({ order_hash, seller }) => {
      const body = {
        order_hash,
        seller_id: seller.id,
        picking_type: 2,
        order_tags: Array.isArray(selectedCluster.orderTags) ? selectedCluster.orderTags.map(tags => tags.value) : undefined,
      }
      return axiosInstance.put(
        getApiUrl(API_ENDPOINTS.BULK_SHIP.GENERATE_CLUSTER_PICKING_WAVE, {
          hubId: selectedGlobalHubId
        }),
        body
      )
    },
    onSuccess: ({ data }) => {
      handleWhenGeneratePickingWaveSuccess(data)
      setGeneratePickingWaveResponse(data)
    },
    onError: () => {
      setPickingWaveGenerating(false)
    }
  })
  //***** Generate Picking Wave $ */
  const handleCreatePickingWave = useCallback(() => {
    setPickingWaveGenerating(true)
    initRealTime(() => {
      generatePickingWave(selectedCluster)
    })
    
  }, [])

  const orderDetails = {
    totalOrders: isPickingWaveGenerated
      ? pickingWave?.display_number_of_orders
      : selectedCluster?.display_number_of_orders,
    itemsPerOrder: isPickingWaveGenerated
      ? pickingWave?.display_items_per_order
      : selectedCluster?.display_items_per_order
  }

  const openAccordion = (() => {
    if (!isPickingWaveGenerated) return PROCESS_CLUSTER_STEPS.GENERATE_PICKING_WAVE.ID
    if (!hasPackagingPreference) return PROCESS_CLUSTER_STEPS.PACKAGING_PREFERENCE.ID
    return PROCESS_CLUSTER_STEPS.SHIPMENT.ID
  })()

  const pickingWaveItemsLoading =
    getPickingWaveLoading && !isSavePackagingDetailsApi
  const packagingDetailsItemsLoading =
    getPickingWaveLoading && isSavePackagingDetailsApi

  // const boxes = isPickingWaveGenerated ? backendToUiState(pickingWave?.packing_details?.box_details || l[]) :

  //***** Page Header ^ */
  const pageHeaderProps = {
    breadcrumbIcon: Inventory,
    title: `${orderDetails.totalOrders || 0} ${t('Orders')}`,
    breadcrumbsData: [
      { title: 'Orders' },
      { title: 'Bulk Ship' }
    ],
    hasBackButton: true,
    backButtonHandler: () => {
      const path =  `${BULK_SHIP_PATH_NAMES.CLUSTERS}?tab=${BULK_SHIP_TABS_DATA.CLUSTERS.id}`
      navigate(path)
    }
  }
  //***** Page Header $ */

  const creationStatuses = useSelector(state => state.bulkShip.creationStatuses)

  const showViewCluster = useMemo(() => {
    const totalCount = creationStatuses?.reduce((acc, statusObj) => {
      if (statusObj.status === BULK_SHIPMENT_STATUSES.CREATED.value ||
          statusObj.status === BULK_SHIPMENT_STATUSES.AWB_PRINTED.value) {
        acc += statusObj.count
      }
      return acc
    }, 0)
    
    return totalCount === (pickingWave?.number_of_orders || 0)
  }, [creationStatuses, pickingWave?.number_of_orders])

  const handlePicklist = () => {
    setisPickingWaveDetailsSidebarOpen(true)
    dispatch(getPicklistsOfStatus({ hubId: selectedGlobalHubId,  waveId: pickingWave?.wave?.id }))
  }
  const packingAccordionItemRef = useRef(null)
  const [isEditingPackagePreference, setIsEditingPackagePreference] = useState(false)
  const handleEditPackagingPreference = (e) => {
    const accordionElement = packingAccordionItemRef.current.querySelector('div.accordion-collapse.collapse.show')
    if (accordionElement !== null) {
      e.stopPropagation()
    }

    setIsEditingPackagePreference(val => !val)
  }

  useEffect(() => {
    if (pusherResponse?.generate_wave === 'success') {
      setPickingWaveGenerating(false)
      if (generatePickingWaveTenSecondTimeoutId.current) {
        clearTimeout(generatePickingWaveTenSecondTimeoutId.current)
        generatePickingWaveTenSecondTimeoutId.current = null
      }
      if (isPickingWaveGenerated) {
        return
      }
      const identification = generatePickingWaveResponse?.identification
      if (identification) {
        setSearchParams({ identification })
      }
      dispatch(handlePusherResponse({
        generate_wave: null
      }))
    }
  }, [pusherResponse])

  useEffect(() => {
    return () => {
      setGeneratePickingWaveResponse(null)
      if (generatePickingWaveTenSecondTimeoutId.current) {
        clearTimeout(generatePickingWaveTenSecondTimeoutId.current)
        generatePickingWaveTenSecondTimeoutId.current = null
      }
    }
  }, [])

  if (!selectedCluster && !(identification || waveId)) {
    const path =  `${BULK_SHIP_PATH_NAMES.CLUSTERS}?tab=${BULK_SHIP_TABS_DATA.SUGGESTIONS.id}`
    return <Navigate to={path} />
  }

  return (
    <>
      <PageHeader {...pageHeaderProps} />
      <div className="height-2px bg-primary-lighter" />
      <div className="p-16px d-flex flex-column gap-22px">
        {showViewCluster && clusterPickingWave ? <ViewCluster 
          clusterPickingWave={clusterPickingWave}
          getPickingWaveLoading={getPickingWaveLoading}
          setisPickingWaveDetailsSidebarOpen={setisPickingWaveDetailsSidebarOpen}
          isPickingWaveDetailsSidebarOpen={isPickingWaveDetailsSidebarOpen}
        /> 
          : 
          <>
            <OrderDetailsCard
              t={t}
              noOfPackages={noOfPackages}
              orderWeight={orderWeight}
              totalOrders={orderDetails.totalOrders}
              itemsPerOrder={orderDetails.itemsPerOrder}
            />
            <UncontrolledAccordion
              key={openAccordion}
              className="d-flex flex-column gap-14px"
              defaultOpen={openAccordion}
            >
              <AccordionItem className="bg-white border border-dark-2 rounded-10px">
                <AccordionHeader
                  targetId={PROCESS_CLUSTER_STEPS.GENERATE_PICKING_WAVE.ID}
                  cssModule={{
                    'accordion-button':
                  'w-100 px-16px py-20px flex-center-between border-0 outline-0 bg-transparent txt-h3-md my-accordion-button'
                  }}
                >
                  <div className="flex-start-start gap-16px">
                    {isPickingWaveGenerated ? (
                      <span className="rounded-circle border border-2 border-success height-24px width-24px flex-center-center">
                        <Check
                          size={15}
                          strokeWidth="3px"
                          color="var(--bs-success)"
                        />
                      </span>
                    ) : (
                      <span className="rounded-circle accordion-order-circle txt-sub-md flex-center-center">
                        {PROCESS_CLUSTER_STEPS.GENERATE_PICKING_WAVE.ORDER}
                      </span>
                    )}
                    <div className="flex-center-center gap-4px">
                      <span>
                        {PROCESS_CLUSTER_STEPS.GENERATE_PICKING_WAVE.HEADING}
                      </span>
                      <span className="accordion-arrow">
                        <ChevronDown size={20} />
                      </span>
                    </div>
                  </div>
                  {hasViewPickingWavePermission && isPickingWaveGenerated ? (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        handlePicklist()
                      }}
                      ofStyle="noBackground"
                      className="txt-body-md py-4px px-8px"
                      disabled={picklistsOfStatus.loading}
                    >
                      {picklistsOfStatus.loading && <Spinner size='sm'/>}
                      {t('View Wave Details')}
                    </Button>
                  ) : null}
                </AccordionHeader>
                <AccordionBody
                  accordionId={PROCESS_CLUSTER_STEPS.GENERATE_PICKING_WAVE.ID}
                  className="pt-0"
                >
                  <div className="">
                    <ExpandableCustomTable
                      TableHeaderComponent={
                        <div>
                          {hasCreatePickingWavePermission && !isPickingWaveGenerated ? (
                            <Button onClick={handleCreatePickingWave} disabled={pusherLoading || isPickingWaveGenerating}>
                              {(pusherLoading || isPickingWaveGenerating) && <Spinner size='sm'/>} {t('Generate Picking Wave')}
                            </Button>
                          ) : null}
                        </div>
                      }
                      data={searchedOrderItems || tableData || []}
                      loading={pickingWaveItemsLoading}
                      columns={columns || []}
                      columnName={
                        ALL_COLUMN_NAME_MAPPING.BULK_SHIP_SUGGESTED_CLUSTERS
                      }
                      searchcolumnsList={[
                        { id: 'sku_name', name: 'SKU Name' },
                        { id: 'sku_code', name: 'SKU Code' }
                      ]}
                      handleQueryParams={handleSearchItem}
                      // meta={orderItems?.meta || {}}
                      showPagination={false}
                      showColumnsTableHeader={true}
                      expandableRows
                      expandableRowDisabled={(row) => row?.type !== SKU_TYPE_OPTIONS.BUNDLE.value}
                      expandableRowsComponent={SkuExpander}    
                    />
                  </div>
                </AccordionBody>
              </AccordionItem>

              {/* Packaging Preference */}
              {isPickingWaveGenerated ? (
                <AccordionItem innerRef={packingAccordionItemRef} className="bg-white border border-dark-2 border-top-1 border-top-dark-2 rounded-10px">
                  <AccordionHeader
                    targetId={PROCESS_CLUSTER_STEPS.PACKAGING_PREFERENCE.ID}
                    cssModule={{
                      'accordion-button':
                    'w-100 px-16px py-20px flex-start-between gap-16px border-0 outline-0 bg-transparent txt-h3-md my-accordion-button'
                    }}
                  >
                    <div className="flex-start-start gap-16px">
                      {hasPackagingPreference  ? (
                        <span className="rounded-circle border border-2 border-success height-24px width-24px flex-center-center">
                          <Check
                            size={15}
                            strokeWidth="3px"
                            color="var(--bs-success)"
                          />
                        </span>
                      ) : (
                        <span className="rounded-circle accordion-order-circle txt-sub-md flex-center-center">
                          {PROCESS_CLUSTER_STEPS.PACKAGING_PREFERENCE.ORDER}
                        </span>
                      )}
                      <div className="flex-center-center gap-4px">
                        <span>
                          {PROCESS_CLUSTER_STEPS.PACKAGING_PREFERENCE.HEADING}
                        </span>
                        <span className="accordion-arrow">
                          <ChevronDown size={20} />
                        </span>
                      </div>
                    </div>
                    <div className="d-flex gap-16px">
                      {(creationStatuses?.find(el => el.status === BULK_SHIPMENT_STATUSES.PENDING.value)?.count === pickingWave?.number_of_orders) && hasPackagingPreference && !isEditingPackagePreference ? (
                        <Button
                          icon={Edit2}
                          ofStyle="noBackground"
                          onClick={handleEditPackagingPreference}
                          className="txt-body-md py-4px px-8px"
                        >
                          {t('Edit')}
                        </Button>
                      ) : null}
                      {hasPackagingPreference ? 
                        <PrintClusterPackingList printData={clusterPickingWave?.data} />
                        : null}
                    </div>
                  </AccordionHeader>
                  <AccordionBody
                    accordionId={PROCESS_CLUSTER_STEPS.PACKAGING_PREFERENCE.ID}
                  >
                    <PackagingPreference
                      boxes={boxes}
                      setBoxes={setBoxes}
                      packagingDetailsItemsLoading={packagingDetailsItemsLoading}
                      isSavePackagingDetailsApi={isSavePackagingDetailsApi}
                      setIsSavePackagingDetailsApi={setIsSavePackagingDetailsApi}
                      waveId={waveId}
                      orderItems={orderItems}
                      orderWeight={orderWeight}
                      setOrderWeight={setOrderWeight}
                      setNoOfPackages={setNoOfPackages}
                      noOfPackages={noOfPackages}
                      hasPackagingPreference={hasPackagingPreference}
                      isEditingPackagePreference={isEditingPackagePreference}
                      setIsEditingPackagePreference={setIsEditingPackagePreference}
                      hasViewBinPermission={hasViewBinPermission}
                    />
                  </AccordionBody>
                </AccordionItem>
              ) : (
                <DisabledAccordionHeader
                  heading={PROCESS_CLUSTER_STEPS.PACKAGING_PREFERENCE.HEADING}
                  order={PROCESS_CLUSTER_STEPS.PACKAGING_PREFERENCE.ORDER}
                />
              )}

              {/* Shipment */}
              {isPickingWaveGenerated && hasPackagingPreference ? (
                <AccordionItem className="bg-white border border-dark-2 border-top-1 border-top-dark-2 rounded-10px">
                  <AccordionHeader
                    targetId={PROCESS_CLUSTER_STEPS.SHIPMENT.ID}
                    cssModule={{
                      'accordion-button':
                    'w-100 px-16px py-20px flex-start-between gap-16px border-0 outline-0 bg-transparent txt-h3-md my-accordion-button'
                    }}
                  >
                    <div className="flex-start-start gap-16px">
                      <span className="rounded-circle accordion-order-circle txt-sub-md flex-center-center">
                        {PROCESS_CLUSTER_STEPS.SHIPMENT.ORDER}
                      </span>
                      <div className="flex-center-center gap-8px">
                        <span>{PROCESS_CLUSTER_STEPS.SHIPMENT.HEADING}</span>
                        <span className="accordion-arrow">
                          <ChevronDown size={20} />
                        </span>
                      </div>
                    </div>
                    <div className="d-flex gap-16px">
                      <ShipmentPrintBtns selectedOrders={selectedOrders} pickingWave={pickingWave} isAtleastOneShipmentCreated={isAtleastOneShipmentCreated} />
                    </div>
                  </AccordionHeader>
                  <AccordionBody accordionId={PROCESS_CLUSTER_STEPS.SHIPMENT.ID}>
                    <ShipmentProcessing pickingWave={pickingWave} setIsAtleastOneShipmentCreated={setIsAtleastOneShipmentCreated} />
                  </AccordionBody>
                </AccordionItem>
              ) : (
                <DisabledAccordionHeader
                  heading={PROCESS_CLUSTER_STEPS.SHIPMENT.HEADING}
                  order={PROCESS_CLUSTER_STEPS.SHIPMENT.ORDER}
                />
              )}
            </UncontrolledAccordion>
          </>
        }
      </div>

      <SidebarContainer
        status={selectedPicklistStatus?.value}
        waveDetails={{wave: {
          waveId: pickingWave?.wave?.id,
          name: pickingWave?.wave?.name,
          time: pickingWave?.wave?.created_at
        }}}
        is_sidebar_open={isPickingWaveDetailsSidebarOpen}
        set_is_sidebar_open={setisPickingWaveDetailsSidebarOpen}
        hub_id={selectedGlobalHubId}
      />

      <Modal
        container="__bulk-ship-module"
        isOpen={insufficientInventoryData.isOpen}
        toggle={() => setInsufficientInventoryData({ isOpen: false, data: null })
        }
        centered
        contentClassName="rounded-24px"
        modalClassName="modal-max-w-450"
      >
        <ModalBody className="max-height-300 overflow-auto p-24px d-flex flex-column gap-28px">
          <p className="flex-center-center">
            <WarningLogo size={80} />
          </p>
          <div className="d-flex flex-column gap-12px">
            <h2 className="m-0 p-0 txt-h1-sb">{t('Insufficient Inventory')}</h2>
            <p className="p-0 m-0 txt-body-rg text-dark">
              Only {} Orders can be process due to limited inventory, you can
              continue with {} orders now, and process the remaining orders
              later.
            </p>
          </div>
        </ModalBody>
        <ModalFooter className="height-80px d-flex gap-16px">
          <Button type="button" ofStyle="noBackground">
            {t('Cancel')}
          </Button>
          <Button type="button">{t('Proceed')}</Button>
        </ModalFooter>
      </Modal>

      <SimpleSkuDetailModal
        isOpen={openSkuDetailsSidebar}
        skuForDetails={skuForDetails}
        toggleSimpleSkuModal={() => handleSkuDetailsSidebar(false)}
      />

      <BundleSkuDetailsSideSheet
        isOpen={openBundleSkuDetailsSidebar}
        bundleSkuForDetails={skuForDetails}
        toggleBundleSkuModal={() => handleBundleSkuDetailsSidebar(false)}
      />

    </>
  )
}

export default ProcessCluster
