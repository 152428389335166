import Button from '@src/@core/components/ui/button'
import CopyOnClick from '@src/@core/components/ui/click-and-copy'
import { DROPDOWN_TYPES } from '@src/@core/components/ui/customTableFilterComponent/customTableFilterComponent.constant'
import { TENANT_TYPE } from '@src/App.constants'
import abilityMap from '@src/assets/data/abilityMapping/abilityMapping'
import Bug from '@src/assets/images/icons/Bug'
import noCourierPartnerImage from '@src/assets/images/omniful/no_courier_partner_image.svg'
import noSalesChannelImage from '@src/assets/images/omniful/no_sales_channel_image.svg'
import FailBin1 from '../../assets/images/inventory/icons/FailBin1.svg'
import FailBin2 from '../../assets/images/inventory/icons/FailBin2.svg'
import FailBinPlus from '../../assets/images/inventory/icons/FailBinPlus.svg'
import PassBin1 from '../../assets/images/inventory/icons/PassBin1.svg'
import PassBin2 from '../../assets/images/inventory/icons/PassBin2.svg'
import PassBinPlus from '../../assets/images/inventory/icons/PassBinPlus.svg'
import { getShippingPartnerAsyncData } from '../sales/store/store'
import AllReturnOrders from './pages/return-order/pages/allReturnOrders'
import CourierPartnerReturnOrder from './pages/return-order/pages/courierPartnerReturn'
import CustomerReturn from './pages/return-order/pages/customerReturn'
import OffSystemReturn from './pages/return-order/pages/offSystemReturn'

export const PATH_NAME = {
  returnRequest: '/order/return/return-request',
  returnOrder: '/order/return/return-order',
  createReturnOrder: '/order/return/create-return-order',
  createReturnRequest: '/orders/return/create-return-request',
  returnOrderProcessing: '/order/return/return-order-processing',
  createBulkReturn: '/order/return/create-bulk-return',
  returnGateEntry: '/order/return/return-gate-entry'
}

export const RETURN_TABLE_COLUMN_HEADER = {
  SR_NO: {name: 'S. No.'},
  RETURN_REQUEST_NUMBER : {name: 'Return Request Number'},
  ORDER_ID: {name: 'Order ID'},
  SELLER: {name: 'Seller'},
  CUSTOMER_NAME: {name: 'Customer Name'},
  CUSTOMER_EMAIL: {name: 'Customer Email'},
  CUSTOMER_PHONE: {name: 'Customer Phone'},
  RETURN_SOURCE: {name: 'Return Source'},
  REQUEST_CREATED_AT: {name: 'Created At'},
  REQUEST_CANCELLED_AT: {name: 'Request Cancelled At'},
  TOTAL_QUANTITY: {name: 'Total Return Qty'},
  SKU: {name: 'SKU Details'},
  DELIVERED_QTY: {name: 'Delivered Qty'},
  RETURNED_QTY: {name: 'Returned Qty'},
  UNIT_PRICE: {name: 'Unit Price'},
  REASON: {name: 'Return Reasons'},
  TOTAL_PRICE: {name: 'Total Price'},
  TOTAL_AMOUNT: {name: 'Total Amount'},
  RETURN_ELIGIBILITY: {name: 'Return Eligibility'},
  RETURN_ORDER_ID: {name: 'Return Order ID', key: 'id'},
  AWB_NUMBER: {name: 'Reverse AWB Number', key:'awb_number'},
  DRIVER_NAME: {name: 'Driver Name', key: 'driver_name'},
  ARRIVED_AT: {name: 'Arrived At', key: 'arrived_at'},
  SHIPPING_PARTNER : {name: 'Shipping Partner', key: 'shipping_partner_name'},
  CREATED_AT : {name: 'Created At', key: 'created_at'},
  CREATED_BY : {name: 'Created By', key: 'created_by'},
  SHIPMENT_CREATED_AT : {name: 'Shipment Created At'},
  SHIPMENT_CREATED_BY : {name: 'Shipment Created By'},
  ACTION: {name: 'Action'},
  DELIVERY_ATTEMPT: {name: 'Delivery Attempts'},
  PICKED_BY: {name: 'Picked By'},
  PICKED_AT: {name: 'Picked At'},
  SALES_CHANNEL: {name: 'Sales Channel'},
  STATUS: {name: 'Status'},
  RETURN_ORDER_TYPE: {name: 'Return Order Type'},
  TOTAL_SKU: {name: 'Total SKU(s)'},
  RETURN_RECEIVED_FROM: {name: 'Returned Received From'},
  REFUND_STATUS: {name: 'Refund Status'},
  TOTAL_ITEMS: {name: 'Total Items'},
  QC_PASS_ITEM: {name: 'QC Pass Item(s)'},
  QC_FAIL_ITEM: {name: 'QC Fail Item(s)'},
  RETURN_PROCESSED_AT: {name: 'Return Processed At'},
  RETURN_PROCESSED_BY: {name: 'Return Processed By'},
  ASSIGNED_HUB: {name: 'Assigned Hub'},
  COURIER_PARTNER: {name: 'Courier Partner'},
  RETURNED_WEIGHT: {name: 'Returned Weight'},
  REFERENCE_RETURN_ID: {name: 'Reference Return ID'},
  RETURN_QTY: {name: 'Return Qty'},
  RETURN_WEIGHT: {name: 'Return Weight'}
}

export const customReactSelectStyles = (isTransparent) => {
  return {
    container: (provided) => ({
      ...provided,
      width: '100%'
    }),
    control: (provided) => ({
      ...provided,
      // width: `230px`,
      borderColor: isTransparent ? 'transparent' : '#DEDEDE',
      minHeight: '35px',
      maxHeight: '35px',
      boxShadow: 'none',
      cursor: 'pointer',

      '&:hover': {
        borderColor: isTransparent ? 'transparent' : '#5468fa'
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      overflow: 'visible',
      textAlign: 'start',
      paddingLeft: isTransparent ? 0 : '10px'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#222222'
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: 'absolute',
      top: state.hasValue || state.selectProps.inputValue ? '-47%' : '13%',
      transition: 'top 0.1s, font-size 0.1s',
      fontSize: (state.hasValue || state.selectProps.inputValue) && 12,
      backgroundColor: 'white',
      color: '#999999'
    }),
    clearIndicator: (provided) => ({
      ...provided,
      paddingRight: 0
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'lightgray',
      paddingLeft: 0,
      paddingRight: 0,
      marginInlineEnd: '10px',
      '&:hover': {
        color: 'gray'
      },
      svg: {
        color: '#666666 !important',
        width: '15px',
        height: '15px'
      }
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    menuList: (provided, state) => ({
      ...provided,
      textAlign: 'start',
      color: '#222222',
      maxHeight: state.selectProps.menuIsOpen ? '230px' : '40px'
    }),
    option: (provided) => ({
      ...provided,
      cursor: 'pointer'
    })
  }
}

export const tableFilterReactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    borderColor: state.isFocused ? '#d8d6de !important' : '#d8d6de !important',
    minHeight: '30px',
    maxHeight: '30px',
    minWidth: '180px',
    fontSize: '12px',
    paddingTop: '0px',
    '&:hover': {
      borderColor: '#5468FA !important',
      cursor: 'pointer'
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black !important'
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: 'visible'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: state.hasValue || state.selectProps.inputValue ? '-37%' : '20%',
    marginTop: '-2px',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 12,
    backgroundColor: 'transparent'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0
    // fontSize: 2
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    marginInlineEnd: '10px',
    width: '20px'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  })
}

export const CUSTOMER_RETURN_OPTIONS = (location_inventory_enabled) => (
  location_inventory_enabled
    ? [
      { label: 'New', value: 'initiated' },
      { label: 'Reverse Shipment Created', value: 'return_shipment_created' },
      { label: 'Item(s) Picked Up', value: 'order_picked_up' },
      { label: 'Return To Origin', value: 'return_to_origin' },
      { label: 'QC In Progress', value: 'qc_in_progress'},
      { label: 'QC Completed', value: 'qc_processed'}
    ]
    : [
      { label: 'New', value: 'initiated' },
      { label: 'Reverse Shipment Created', value: 'return_shipment_created' },
      { label: 'Item(s) Picked Up', value: 'order_picked_up' },
      { label: 'Return To Origin', value: 'return_to_origin' },
      { label: 'QC Completed', value: 'qc_processed'}
    ]
)

export const COURIER_RETURN_OPTIONS = (location_inventory_enabled) => (
  location_inventory_enabled 
    ? [
      { label: 'Return in Progress', value: 'initiated' },
      { label: 'Return To Origin', value: 'return_to_origin' },
      { label: 'QC In Progress', value: 'qc_in_progress'},
      { label: 'QC Completed', value: 'qc_processed'}
    ]
    : [
      { label: 'Return in Progress', value: 'initiated' },
      { label: 'Return To Origin', value: 'return_to_origin' },
      { label: 'QC Completed', value: 'qc_processed'}
    ]
)
export const REASON_OPTIONS = [{ label: 'Item Damaged', value: 'item_damaged' }]

export const SHIPMENT_OPTIONS = {
  CUSTOMER_GENERATED : {name: 'customer_generated'},
  OMNIFUL_GENERATED : {name: 'omniful_generated'},
  NOT_REQUIRED: { name: 'not_required' },
  SALES_CHANNEL: { name: 'sales_channel' },
}

export const REFUND_STATUS_OPTIONS = [
  { label: 'Completed', value: 'completed' },
  { label: 'Pending', value: 'pending' }
]

export const RETURN_ORDER_COLUMNS_LIST = [
  {
    id: 'seller_sales_channel_order_ids',
    name: 'Order ID'
  },
  {
    id: 'return_order_id',
    name: 'Return Order ID'
  },
  {
    id: 'awb_number',
    name: 'AWB Number'
  },
  { 
    id: 'customer_phone',
    name: 'Customer Phone' 
  },
  { 
    id: 'customer_email',
    name: 'Customer Email'
  }
]

export const BTACH_VARIANT = {
  Pending: {hexValue: '#9428C7', opacity: '1A'},
  Completed: {hexValue: '#027300', opacity: '1A'}
}

export const RETURN_STATUS = {
  INITIATED : {key: 'initiated', name:'Initiated'},
  REVERSE_SHIPMENT_CREATED: {key: 'return_shipment_created', name:'Return Shipment Created'},
  DRIVER_ASSIGNED: {key: 'driver_assigned', name: 'driver_assigned'},
  ITEMS_PICKED_UP: {key: 'order_picked_up', name: 'Picked Up'},
  ARRIVED_AT_HUB : {key: 'return_to_origin', name: 'Return To Origin'},
  RETURN_TO_ORIGIN: {key: 'return_to_origin', name: 'Return To Origin'},
  REACHED_AT_HUB: {key: 'reached_at_hub', name: 'reached_at_hub'},
  QC_IN_PROGRESS: { key: 'qc_in_progress', name: 'Order In Processing'},
  QC_PROCESSED: {key: 'qc_processed', name: 'Order Processed'},
  RETURN_CANCELLED: {key: 'return_cancelled', name: 'Return Cancelled'},
  RETURN_COMPLETED: {key: 'return_completed', name: 'Return Completed'}
}
export const ALL_RETURN_STATUS_FILTER_OPTIONS = (location_inventory_enabled) => (
  location_inventory_enabled 
    ? [
      { value: 'initiated', label: 'New' },
      { value: 'return_shipment_created', label: 'Reverse Shipment Created' },
      { value: 'order_picked_up', label: 'Item(s) Picked Up' },
      { value: 'return_to_origin', label: 'Return To Origin' },
      { value: 'qc_in_progress', label: 'QC In Progress' },
      { value: 'qc_processed', label: 'QC Completed' }
    ]
    : [
      { value: 'initiated', label: 'New' },
      { value: 'return_shipment_created', label: 'Reverse Shipment Created' },
      { value: 'order_picked_up', label: 'Item(s) Picked Up' },
      { value: 'return_to_origin', label: 'Return To Origin' },
      { value: 'qc_processed', label: 'QC Completed' }
    ]
)
export const RETURN_REQUEST_STATUS = {
  NEW: {key: 'New', name: 'New'},
  APPROVED: {key: 'Approved', name: 'Approved'},
  REJECTED: {key: 'Rejected', name: 'Rejected'}
}

export const REFUND_STATUS = {
  COMPLETETD : {name: 'Completed'}
}

export const RETURN_REQUEST_TABS = {
  PENDING: { name: 'Pending Request', key: 'new' },
  CANCELLED: { name: 'Cancelled', key: 'rejected' }
  // APPROVED: {name: "Approved", key: "approved"}
}

export const RETURN_ORDER_TABS = {
  COURIER_PARTNER_RETURN: { name: 'Courier Partner Return', key: 'courier_partner_return', id: 'courier_partner_return', component: CourierPartnerReturnOrder},
  CUSTOMER_RETURN: { name: 'Customer Return', key: 'customer_return', id:'customer_return', component: CustomerReturn },
  OFF_SYSTEM_RETURN: { name: 'Off System Return', key: 'off_system_return', id:'off_system_return', component: OffSystemReturn},
  ALL: { name: 'All', key: 'all', id: 'all', component: AllReturnOrders}
  // APPROVED: {name: "Approved", key: "approved"}
}

export const UPLOAD_FILE_SERVICE_USECASES = {service: 'oms', useCase: 'bulk_return_order'}

export const SEARCH_OPTIONS = [
  { id: 'seller_sales_channel_order_id', name: 'Order ID' },
  { id: 'return_request_number', name: 'Return Request Number' },
  { id: 'customer_phone', name: 'Customer Mobile Number' },
  { id: 'customer_email', name: 'Customer Email' }
]

export const OMS_QC_STATUS = {
  COMPLETED: {key: 'completed', name: 'Completed'},
  IN_PROGRESS: {key: 'qc_in_progress', name: 'Order In Processing'}
}

export const RETURN_SOURCE = {
  SALES_CHANNEL: {name: 'Sales Channel'}
}

export const RETURN_GRN_STEPS = {
  IDENTIFICATION: 'Identification',
  PRINT_BARCODES: 'Print Barcode',
  QUALITY_CHECK: 'Quality Check'
}
export const GRN_BIN = {
  PASS: 'pass',
  FAIL: 'fail'
}

export const RETURN_GRN_DETAILS_MODAL_VIEWS = {
  GRN_DETAILS: 'GRN Details',
  ORDER_DETAILS: 'Order Details'
}

export const RETURN_GRN_ITEMS_VIEW_TABS = isPutawayVisible => ({
  OVERVIEW: 'Overview',
  PUTAWAY_DETAILS: isPutawayVisible ? 'Putaway Details' : undefined
})

// PUTAWAY
export const PUTAWAY_TYPES = {
  ITEM_PUTAWAY: 'Item Putaway',
  BIN_PUTAWAY: 'Bin Putaway'
}

export const SHIPPING_PARTNER = {
  TOROD: {tag: 'torod'}
}

export const DATE_FILTER_KEY = {
  CREATED: 'created',
  UPDATED: 'updated',
  CANCELLED:'cancelled'
}

export const ADDITIONAL_FILTER_DROPDOWN_STYLE = {
  control: (baseStyles) => ({
    ...baseStyles,
    width: '120px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'start',
    border: 'none',
    boxShadow: 'none'
  })
}

export const RETURN_ORDER_PROCESSING_STEPS = {
  RETURN_GRN_DETAILS: {  id: 1},
  QC: {id: 2}
}

export const SERIALISATION_OPTION = {
  NON_SERIALISED: {id:'non_serialised', name :'Non Serialised'},
  SERIALISED:{id:'serialised', name : 'Serialised'}
}
export const BIN_TYPE = {
  pass_bin: { id: 'pass_bin', status: 'Pass', name: 'Pass Bin', bin_icon: PassBin1, plus_icon: PassBinPlus, bin_border_icon: PassBin2 },
  fail_bin: {id: 'fail_bin', status: 'Fail', name: 'Fail Bin', bin_icon: FailBin1, plus_icon: FailBinPlus, bin_border_icon: FailBin2}
}

export const BIN_BARCODE_STATUS = {
  pass : 'pass',
  fail: 'fail'
}

export const SEARCH_RETURN_ORDER_TYPES = {
  AWB_NUMBER: { id: 'awb_number', name: 'AWB Number' },
  RETURN_ORDER_ID: { id: 'return_order_id', name: 'Return Order ID' }
}

const COMMON_COLUMNS = ({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey}) => [
  {
    id: 2,
    name: t(RETURN_TABLE_COLUMN_HEADER.ORDER_ID.name),
    key: 'order_id',
    minWidth: '280px',
    reorder: true,
    cell: (row) => (row.seller_sales_channel_order_id ? <CopyOnClick id={row.orderID} handleDetails={() => handleSetOrderToShowOrderDetail(row)} columnKey="order_id">{row.seller_sales_channel_order_id}</CopyOnClick> : '-')
  },
  {
    id: 3,
    name: t(RETURN_TABLE_COLUMN_HEADER.RETURN_ORDER_ID.name),
    key: 'return_order_id',
    minWidth: '250px',
    reorder: true,
    cell: (row) => (row.return_order_id ? <CopyOnClick id={row.return_order_id} handleDetails={() => handleSetReturnOrderToShowDetail(row)} columnKey="return_order_id">{row.return_order_id}</CopyOnClick> : '-')
  },
  {
    id: 4,
    name: t(RETURN_TABLE_COLUMN_HEADER.REFERENCE_RETURN_ID.name),
    key: 'reference_return_id',
    width: '180px',
    reorder: false,
    cell: (row) => (row.order_alias ? <CopyOnClick id={`${row.id}_reference_return_id`} columnKey="reference_return_id">{row.order_alias}</CopyOnClick> : '-')
  },
  {
    id: 5,
    name: t(RETURN_TABLE_COLUMN_HEADER.RETURN_ORDER_TYPE.name),
    key: 'type',
    minWidth: '180px',
    reorder: true,
    cell: (row) => row.type || '-'
  },
  {
    id: 6,
    name: t(RETURN_TABLE_COLUMN_HEADER.STATUS.name),
    key: 'status',
    minWidth: '190px',
    reorder: true,
    cell: (row) => row.status || '-'
  },
  {
    id: 7,
    name: t(RETURN_TABLE_COLUMN_HEADER.ASSIGNED_HUB.name),
    key: 'hub_name',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.hub?.name || '-'
  },
  {
    id: 8,
    name: t(RETURN_TABLE_COLUMN_HEADER.SELLER.name),
    key: 'seller_name',
    minWidth: '160px',
    reorder: true,
    cell: (row) => row.seller_name || '-',
    omit: tenantType === TENANT_TYPE.SELF_USAGE
  },
  {
    id: 9,
    name: t(RETURN_TABLE_COLUMN_HEADER.SALES_CHANNEL.name),
    key: 'sales_channel_name',
    minWidth: '150px',
    reorder: true,
    cell: (row) => (row.source === 'Sales Channel' ? (
      <div className="d-flex align-items-center gap-1">
        <div>
          <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = noSalesChannelImage
            }}
            src={row.seller_sales_channel?.sales_channel?.logo_url}
            alt="Sales Channel Logo"
            className="sales-channel-cell-image"
          />
        </div>
      </div>
    ) : (
      '-'
    ))
  },
  {
    id: 10,
    name: t(RETURN_TABLE_COLUMN_HEADER.AWB_NUMBER.name),
    key: 'awb_number',
    reorder: true,
    minWidth: '200px',
    cell: (row) => (
      <div className="w-100 h-100 d-flex align-items-center justify-content-between sellerOrderId">
        {row.awb_number ? <>
          <CopyOnClick id={row.orderID} handleDetails={() => handleSetReturnOrderToShowDetail(row)} columnKey="return_order_id">{row.awb_number}</CopyOnClick>
          {row.shipping_partner_tag !== 'manual' && Object.values(RETURN_ORDER_TABS).find(tab => tab.key === currentTabKey) &&
            <div className="cursor-pointer height-20px width-20px flex-center-center" onClick={() => handleShipmentsTracking(row)}>
              <Bug stroke="var(--bs-primary)" strokeWidth={1.5} />
            </div>
          }
        </> : '-'}
      </div>
    )
  },
  {
    id: 11,
    minWidth: '200px',
    key: 'shipping_partner_name',
    reorder: true,
    name: t(RETURN_TABLE_COLUMN_HEADER.SHIPPING_PARTNER.name),
    selector: (row) => row.shipping_partner_name || '-'
  },
  {
    id: 12,
    name:t(RETURN_TABLE_COLUMN_HEADER.COURIER_PARTNER.name),
    key: 'courier_partner_name',
    minWidth: '140px',
    reorder: true,
    cell: row => (
      <div className="d-flex align-items-center justify-content-center gap-50 w-100">
        {row.courier_partner?.logo 
          ? <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = noCourierPartnerImage
            }}
            src={row.courier_partner.logo || noCourierPartnerImage}
            alt='courier-logo'
            height={50} width={50}
            className="object-fit-contain border rounded"
          />
          : <span>{row.courier_partner?.name || '-'}</span>
        }
      </div>
    )   
  }
]

const RETURN_IN_PROGRESS_COLUMN = ({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey}) => [
  ...COMMON_COLUMNS({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey}),
  {
    id: 20,
    name: t(RETURN_TABLE_COLUMN_HEADER.CUSTOMER_NAME.name),
    key: 'customer_name',
    minWidth: '180px',
    reorder: true,
    cell: (row) => row.customer?.customer_name || '-'
  },
  {
    id: 17,
    name: t(RETURN_TABLE_COLUMN_HEADER.CUSTOMER_EMAIL.name),
    key: 'customer_name',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.customer.email || '-'
  },
  {
    id: 18,
    name: t(RETURN_TABLE_COLUMN_HEADER.CREATED_AT.name),
    key: 'created_at',
    minWidth: '210px',
    reorder: true,
    cell: (row) => row.created_at || '-'
  },
  {
    id: 19,
    name: t(RETURN_TABLE_COLUMN_HEADER.CREATED_BY.name),
    key: 'created_by',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.created_by || '-'
  }
]

const RETURN_TO_ORIGIN_COLUMN = ({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, handleCreateReturnOrderProcessingModal, isLocationInventoryEnabled, ability, t, tenantType, handleShipmentsTracking, currentTabKey, isExternalHub}) => [
  {
    id: 1,
    name: <div className="w-100 text-end">{t('S. No.')}</div>,
    minWidth: '60px',
    maxWidth: '60px',
    cell: (row) => (
      <div className="w-100 text-center">
        {row.serialNo}
      </div>
    )
  },
  ...COMMON_COLUMNS({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey}),
  {
    id: 20,
    name: <div className="w-100 text-end">{t(RETURN_TABLE_COLUMN_HEADER.TOTAL_QUANTITY.name)}</div>,
    key: 'total_return_quantity',
    minWidth: '160px',
    reorder: true,
    cell: (row) => <div className="w-100 text-end">{row.total_return_quantity}</div> 
  },
  {
    id: 17,
    name: <div className="w-100 text-end">{t(RETURN_TABLE_COLUMN_HEADER.TOTAL_PRICE.name)}</div>,
    key: 'total_price',
    minWidth: '140px',
    reorder: true,
    cell: (row) => <div className="w-100 text-end">{`${row.invoice.currency} ${row.total_price.toFixed(2)}`}</div> 
  },
  {
    id: 18,
    name: t(RETURN_TABLE_COLUMN_HEADER.ARRIVED_AT.name),
    key: 'arrived_at',
    minWidth: '210px',
    reorder: true,
    selector: (row) => row.arrived_at || '-'
  },
  {
    id: 19,
    name: t(RETURN_TABLE_COLUMN_HEADER.ACTION.name),
    key: 'action',
    minWidth: '160px',
    maxWidth: '160px',
    omit: (isLocationInventoryEnabled || !ability.can(abilityMap.return.qc_order.action, abilityMap.return.qc_order.resource) || isExternalHub),
    cell: (row) => (
      <div className='w-100 flex-center-center'>
        <Button ofType="compressed" onClick={() => handleCreateReturnOrderProcessingModal(row)}>{t('Start Processing')}</Button>
      </div>
    )
  }
]

const QC_IN_PROGRESS_COLUMN = ({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey}) => [
  {
    id: 1,
    name: <div className="w-100 text-center">{t('S. No.')}</div>,
    minWidth: '60px',
    maxWidth: '60px',
    cell: (row) => (
      <div className="w-100 text-center">
        {row.serialNo}
      </div>
    )
  },
  ...COMMON_COLUMNS({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey}),
  {
    id: 20,
    name: <div className="w-100 text-end">{t(RETURN_TABLE_COLUMN_HEADER.TOTAL_ITEMS.name)}</div>,
    key: 'total_return_quantity',
    minWidth: '130px',
    reorder: true,
    cell: (row) => <div className="w-100 text-end">{row.total_return_quantity}</div>
  },
  {
    id: 17,
    name: <div className="w-100 text-end">{t(RETURN_TABLE_COLUMN_HEADER.QC_PASS_ITEM.name)}</div>,
    key: 'total_passed_quantity',
    minWidth: '130px',
    reorder: true,
    cell: (row) => <div className="qc-pass-item text-success w-100 text-end">{row.total_passed_quantity}</div>
  },
  {
    id: 18,
    name: <div className="w-100 text-end">{t(RETURN_TABLE_COLUMN_HEADER.QC_FAIL_ITEM.name)}</div>,
    key: 'total_failed_quantity',
    minWidth: '130px',
    reorder: true,
    cell: (row) => <div className="qc-fail-item text-danger w-100 text-end">{row.total_failed_quantity}</div>
  }
]

const QC_PROCESSED_COLUMN = ({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey}) => [
  {
    id: 1,
    name: t('S. No.'),
    minWidth: '60px',
    maxWidth: '60px',
    cell: (row) => (
      <div className="w-100 text-center">
        {row.serialNo}
      </div>
    )
  },
  ...COMMON_COLUMNS({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey}),
  {
    id: 20,
    name: <div className="w-100 text-end">{t(RETURN_TABLE_COLUMN_HEADER.TOTAL_ITEMS.name)}</div>,
    key: 'total_return_quantity',
    minWidth: '130px',
    reorder: true,
    cell: (row) => <div className="w-100 text-end">{row.total_return_quantity}</div>
  },
  {
    id: 17,
    name: <div className="w-100 text-end">{t(RETURN_TABLE_COLUMN_HEADER.QC_PASS_ITEM.name)}</div>,
    key: 'total_passed_quantity',
    minWidth: '130px',
    reorder: true,
    cell: (row) => <div className="qc-pass-item text-success w-100 text-end">{row.total_passed_quantity}</div>
  },
  {
    id: 18,
    name: <div className="w-100 text-end">{t(RETURN_TABLE_COLUMN_HEADER.QC_FAIL_ITEM.name)}</div>,
    key: 'total_failed_quantity',
    minWidth: '130px',
    reorder: true,
    cell: (row) => <div className="qc-fail-item text-danger w-100 text-end">{row.total_failed_quantity}</div>
  },
  {
    id: 19,
    name: t(RETURN_TABLE_COLUMN_HEADER.RETURN_PROCESSED_AT.name),
    key: 'qc_processed_at',
    minWidth: '210px',
    reorder: true,
    cell: (row) => row.qc_processed_at || '-'
  },
  {
    id: 20,
    name: t(RETURN_TABLE_COLUMN_HEADER.RETURN_PROCESSED_BY.name),
    key: 'qc_processed_by',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.qc_processed_by || '-'
  }
]

export const ALL_RETURN_STATUS_COLUMN = ({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey}) => [
  {
    id: 1,
    name: <div className="w-100 text-center">{t('S. No.')}</div>,
    minWidth: '60px',
    maxWidth: '60px',
    cell: (row) => (
      <div className="w-100 text-center">
        {row.serialNo}
      </div>
    )
  },
  ...COMMON_COLUMNS({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey}),
  {
    id: 16,
    name: t(RETURN_TABLE_COLUMN_HEADER.CUSTOMER_NAME.name),
    key: 'customer_name',
    minWidth: '180px',
    reorder: true,
    cell: (row) => row.customer?.customer_name || '-'
  },
  {
    id: 17,
    name: t(RETURN_TABLE_COLUMN_HEADER.CUSTOMER_EMAIL.name),
    key: 'customer_email',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.customer.email || '-'
  },
  {
    id: 18,
    name: <div className="w-100 text-end">{t(RETURN_TABLE_COLUMN_HEADER.TOTAL_QUANTITY.name)}</div>,
    key: 'total_return_quantity',
    minWidth: '160px',
    reorder: true,
    cell: (row) => <div className="w-100 text-end">{row.total_return_quantity}</div> 
  },
  {
    id: 19,
    name: <div className="w-100 text-end">{t(RETURN_TABLE_COLUMN_HEADER.TOTAL_PRICE.name)}</div>,
    key: 'total_price',
    minWidth: '140px',
    reorder: true,
    cell: (row) => <div className="w-100 text-end">{`${row.invoice.currency} ${row.total_price.toFixed(2)}`}</div> 
  },
  {
    id: 20,
    name: t(RETURN_TABLE_COLUMN_HEADER.RETURN_RECEIVED_FROM.name),
    key: 'source',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.source || '-'
  },
  {
    id: 21,
    name: t(RETURN_TABLE_COLUMN_HEADER.CREATED_AT.name),
    key: 'created_at',
    minWidth: '210px',
    reorder: true,
    cell: (row) => row.created_at || '-'
  },
  {
    id: 22,
    name: t(RETURN_TABLE_COLUMN_HEADER.CREATED_BY.name),
    key: 'created_by',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.created_by || '-'
  },
  {
    id: 23,
    name: t(RETURN_TABLE_COLUMN_HEADER.SHIPMENT_CREATED_AT.name),
    key: 'shipment_created_at',
    minWidth: '210px',
    reorder: false,
    cell: (row) => row.shipment_created_at || '-'
  },
  {
    id: 24,
    name: t(RETURN_TABLE_COLUMN_HEADER.SHIPMENT_CREATED_BY.name),
    key: 'shipment_created_by',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.shipment_created_by.name || '-'
  },
  {
    id: 25,
    name: t(RETURN_TABLE_COLUMN_HEADER.ARRIVED_AT.name),
    key: 'arrived_at',
    minWidth: '210px',
    reorder: true,
    cell: (row) => row.arrived_at || '-'
  },
  {
    id: 26,
    name: t(RETURN_TABLE_COLUMN_HEADER.RETURN_PROCESSED_AT.name),
    key: 'qc_processed_at',
    minWidth: '210px',
    reorder: true,
    cell: (row) => row.qc_processed_at || '-'
  },
  {
    id: 27,
    name: t(RETURN_TABLE_COLUMN_HEADER.RETURN_PROCESSED_BY.name),
    key: 'qc_processed_by',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.qc_processed_by || '-'
  }
]

const NEW_CUSTOMER_RETURN_COLUMN = ({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType}) => [
  {
    id: 1,
    name: <div className="w-100 text-center">{t('S. No.')}</div>,
    minWidth: '60px',
    maxWidth: '60px',
    cell: (row) => (
      <div className="w-100 text-center">
        {row.serialNo}
      </div>
    )
  },
  {
    id: 2,
    name: t(RETURN_TABLE_COLUMN_HEADER.ORDER_ID.name),
    key: 'order_id',
    minWidth: '280px',
    reorder: true,
    cell: (row) => (row.seller_sales_channel_order_id ? <CopyOnClick id={row.orderID} handleDetails={() => handleSetOrderToShowOrderDetail(row)} columnKey="order_id">{row.seller_sales_channel_order_id}</CopyOnClick> : '-')
  },
  {
    id: 3,
    name: t(RETURN_TABLE_COLUMN_HEADER.RETURN_ORDER_ID.name),
    key: 'return_order_id',
    minWidth: '250px',
    reorder: true,
    cell: (row) => (row.return_order_id ? <CopyOnClick id={row.orderID} handleDetails={() => handleSetReturnOrderToShowDetail(row)} columnKey="return_order_id">{row.return_order_id}</CopyOnClick> : '-')
  },
  {
    id: 4,
    name: t(RETURN_TABLE_COLUMN_HEADER.REFERENCE_RETURN_ID.name),
    key: 'reference_return_id',
    width: '180px',
    reorder: false,
    cell: (row) => (row.order_alias ? <CopyOnClick id={row.orderID} columnKey="reference_return_id">{row.order_alias}</CopyOnClick>  : '-')
  },
  {
    id: 5,
    name: t(RETURN_TABLE_COLUMN_HEADER.STATUS.name),
    key: 'status',
    minWidth: '190px',
    reorder: true,
    cell: (row) => row.status || '-'
  },
  {
    id: 6,
    name: t(RETURN_TABLE_COLUMN_HEADER.ASSIGNED_HUB.name),
    key: 'hub_name',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.hub?.name || '-'
  },
  {
    id: 7,
    name: t(RETURN_TABLE_COLUMN_HEADER.SELLER.name),
    key: 'seller_name',
    minWidth: '160px',
    reorder: true,
    omit: tenantType === TENANT_TYPE.SELF_USAGE,
    cell: (row) => row.seller_name || '-'
  },
  {
    id: 8,
    name: t(RETURN_TABLE_COLUMN_HEADER.SALES_CHANNEL.name),
    key: 'sales_channel_name',
    minWidth: '150px',
    reorder: true,
    cell: (row) => (row.source === 'Sales Channel' ? (
      <div className="d-flex align-items-center gap-1">
        <div>
          <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = noSalesChannelImage
            }}
            src={
              noSalesChannelImage
            }
            alt="Sales Channel Logo"
            className="sales-channel-cell-image"
          />
        </div>
      </div>
    ) : (
      '-'
    ))
  },
  {
    id: 9,
    name: t(RETURN_TABLE_COLUMN_HEADER.CUSTOMER_NAME.name),
    key: 'customer_name',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.customer?.customer_name || '-'
  },
  {
    id: 10,
    name: t(RETURN_TABLE_COLUMN_HEADER.CUSTOMER_EMAIL.name),
    key: 'customer_email',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.customer.email || '-'
  },
  {
    id: 11,
    name: <div className="w-100 text-end">{t(RETURN_TABLE_COLUMN_HEADER.TOTAL_SKU.name)}</div>,
    key: 'total_sku_count',
    minWidth: '140px',
    reorder: true,
    cell: (row) => <div className="w-100 text-end">{row.total_sku_count}</div>
  },
  {
    id: 12,
    name: <div className="w-100 text-end">{t(RETURN_TABLE_COLUMN_HEADER.TOTAL_QUANTITY.name)}</div>,
    key: 'total_return_quantity',
    minWidth: '160px',
    reorder: true,
    cell: (row) => <div className="w-100 text-end">{row.total_return_quantity}</div>
  },
  {
    id: 13,
    name: <div className="w-100 text-end">{t(RETURN_TABLE_COLUMN_HEADER.TOTAL_PRICE.name)}</div>,
    key: 'total_price',
    minWidth: '140px',
    reorder: true,
    cell: (row) => <div className="w-100 text-end">{row.total_price?.toFixed(2) || '-'}</div>
  },
  {
    id: 14,
    name: t(RETURN_TABLE_COLUMN_HEADER.RETURN_RECEIVED_FROM.name),
    key: 'source',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.source || '-'
  },
  {
    id: 15,
    name: t(RETURN_TABLE_COLUMN_HEADER.CREATED_AT.name),
    key: 'created_at',
    minWidth: '210px',
    reorder: true,
    cell: (row) => row.created_at || '-'
  },
  {
    id: 16,
    name: t(RETURN_TABLE_COLUMN_HEADER.CREATED_BY.name),
    key: 'created_by',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.created_by || '-'
  }
]

const REVERSE_SHIPMENT_CREATED_COLUMN = ({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey}) => [
  ...COMMON_COLUMNS({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey}),
  {
    id: 16,
    name: t(RETURN_TABLE_COLUMN_HEADER.CREATED_AT.name),
    key: 'shipment_created_at',
    minWidth: '210px',
    reorder: true,
    cell: (row) => row.shipment_created_at || '-'
  },
  {
    id: 17,
    name: t(RETURN_TABLE_COLUMN_HEADER.CREATED_BY.name),
    key: 'shipment_created_by',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.shipment_created_by.name || '-'
  }
]
const ITEMS_PICKED_UP_COLUMN = ({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey}) => [
  {
    id: 1,
    name: <div className="w-100 text-center">{t('S. No.')}</div>,
    minWidth: '60px',
    maxWidth: '60px',
    cell: (row) => (
      <div className="w-100 text-center">
        {row.serialNo}
      </div>
    )
  },
  ...COMMON_COLUMNS({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey}),
  {
    id: 12,
    name: t(RETURN_TABLE_COLUMN_HEADER.PICKED_BY.name),
    key: 'updated_by',
    minWidth: '200px',
    reorder: true,
    cell: (row) => row.updated_by.name || '-'
  },
  {
    id: 17,
    name: t(RETURN_TABLE_COLUMN_HEADER.PICKED_AT.name),
    key: 'updated_at',
    minWidth: '210px',
    reorder: true,
    cell: (row) => row.updated_at 
  }
]
export const getCourierPartnerReturnColumns = ({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, handleCreateReturnOrderProcessingModal, status, isLocationInventoryEnabled, ability, t, tenantType, handleShipmentsTracking, currentTabKey}) => {
  switch (status) {
  case RETURN_STATUS.INITIATED.key:
    return RETURN_IN_PROGRESS_COLUMN({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey})
  case RETURN_STATUS.RETURN_TO_ORIGIN.key:
    return RETURN_TO_ORIGIN_COLUMN({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, handleCreateReturnOrderProcessingModal, isLocationInventoryEnabled, ability, t, tenantType, handleShipmentsTracking, currentTabKey})
  case RETURN_STATUS.QC_IN_PROGRESS.key:
    return QC_IN_PROGRESS_COLUMN({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey})
  case RETURN_STATUS.QC_PROCESSED.key:
    return QC_PROCESSED_COLUMN({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey})
  default:
    return ALL_RETURN_STATUS_COLUMN({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey})
  }
}

export const getCustomerReturnColumns = ({ handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, handleCreateReturnOrderProcessingModal, status, isLocationInventoryEnabled, ability, t, tenantType, handleShipmentsTracking, currentTabKey, isExternalHub }) => {
  switch (status) {
  case RETURN_STATUS.INITIATED.key:
    return NEW_CUSTOMER_RETURN_COLUMN({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType})
  case RETURN_STATUS.REVERSE_SHIPMENT_CREATED.key:
    return REVERSE_SHIPMENT_CREATED_COLUMN({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey})
  case RETURN_STATUS.ITEMS_PICKED_UP.key:
    return ITEMS_PICKED_UP_COLUMN({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey})
  case RETURN_STATUS.RETURN_TO_ORIGIN.key:
    return RETURN_TO_ORIGIN_COLUMN({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, handleCreateReturnOrderProcessingModal, isLocationInventoryEnabled, ability, t, tenantType, handleShipmentsTracking, currentTabKey, isExternalHub})
  case RETURN_STATUS.QC_IN_PROGRESS.key:
    return QC_IN_PROGRESS_COLUMN({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey})
  case RETURN_STATUS.QC_PROCESSED.key:
    return QC_PROCESSED_COLUMN({handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey})
  default:
    return ALL_RETURN_STATUS_COLUMN({ handleSetOrderToShowOrderDetail, handleSetReturnOrderToShowDetail, t, tenantType, handleShipmentsTracking, currentTabKey })
  }
}

export const FILTER_DATA = {
  shipping_partner_tag: {
    filterKey: 'shipping_partner_tag',
    displayName: 'Shipping Partners',
    type: DROPDOWN_TYPES.ASYNC_DROPDOWN,
    options: getShippingPartnerAsyncData
  }
}

export const GET_RETURN_STATUS_WISE_FILTERS = (status) => {
  switch (status) {
  case RETURN_STATUS.INITIATED.key:
    return [
      {
        clientSideKey: 'created_at',
        filterKey: ['created_at_start', 'created_at_end'],
        displayName: 'Created Date',
        type: DROPDOWN_TYPES.DATE_DROPDOWN
      }
    ]
  case RETURN_STATUS.RETURN_TO_ORIGIN.key:
    return [
      {
        clientSideKey: 'arrived_at',
        filterKey: ['arrived_at_start', 'arrived_at_end'],
        displayName: 'Arrived Date',
        type: DROPDOWN_TYPES.DATE_DROPDOWN
      }
    ]
  case RETURN_STATUS.QC_PROCESSED.key:
    return [
      {
        clientSideKey: 'qc_processed_at',
        filterKey: ['qc_processed_at_start', 'qc_processed_at_end'],
        displayName: 'QC Processed Date',
        type: DROPDOWN_TYPES.DATE_DROPDOWN
      }
    ]
  case RETURN_STATUS.REVERSE_SHIPMENT_CREATED.key:
    return [
      {
        clientSideKey: 'created_at',
        filterKey: ['created_at_start', 'created_at_end'],
        displayName: 'Created Date',
        type: DROPDOWN_TYPES.DATE_DROPDOWN
      }
    ]
  case RETURN_STATUS.ITEMS_PICKED_UP.key:
    return [
      {
        clientSideKey: 'picked_at',
        filterKey: ['picked_at_start', 'picked_at_end'],
        displayName: 'Picked Date',
        type: DROPDOWN_TYPES.DATE_DROPDOWN
      }
    ]
  default:
    return null
  }
}

export const STATUS_FILTER_DROPDOWN_STYLE = {
  control: (baseStyles) => ({
    ...baseStyles,
    width: '242px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'start',
    border: 'none',
    boxShadow: 'none'
  })
}
export const MANUAL_SHIPPING_PARTNER = {label:'Manual', value: 'manual'}

export const STORE_VARIABLE_NAME_MAPPING = {
  RETURN_ORDER_DETAIL: 'returnOrderDetail',
  RETURN_ORDER_HUB_DETAIL: 'returnOrderHubDetail'
}

export const RETURN_ORDER_PUTAWAY_DETAILS_TYPE = [
  { value: 'item_putaway', label: 'Item Putaway'},
  { value: 'bin_putaway', label: 'Bin Putaway'}
]

export const RETURN_ORDER_CATEGORY = {
  COURIER_PARTNER_RETURN: { name: 'Courier Partner Return', value: 'courier_partner_return'},
  CUSTOMER_RETURN: { name: 'Customer Return', value: 'customer_return'},
  OFF_SYSTEM_RETURN: { name: 'Off System Return', value: 'off_system_return'}
}

export const REASONS_ACTION = {
  ADD: { label: 'Add', value: 'add' },
  REMOVE: { label: 'Remove', value: 'remove' }
}

export const EDITABLE_RETURN_FEES = Object.freeze({
  RETURN_FEE: {
    KEY: 'return_fee',
    IS_EDITING: false,
    LABEL: 'Return Fee',
    PREFIX: 'RETURN_FEE',
    DEFAULT_VALUES: {
      return_fee_tax_inclusive: '',
      is_return_fee_applied: false,
      return_fee_tax_percent: ''
    },
    FORM_FIELD_NAMES: {
      RETURN_FEE: {
        name: 'return_fee_tax_inclusive',
        label: 'Return Fee Tax Incl.',
        includeCurrencyInLabel: true
      },
      RETURN_FEE_APPLIED: {
        name: 'is_return_fee_applied',
        omitRendering: true
      },
      RETURN_FEE_TAX_PERCENTAGE: {
        name: 'return_fee_tax_percent',
        label: 'Tax % in Return Fee'
      }
    }
  },
  RETURN_SHIPMENT_FEE: {
    KEY: 'return_shipment_fee',
    IS_EDITING: false,
    LABEL: 'Return Shipment Fee',
    PREFIX: 'RETURN_SHIPMENT_FEE',
    DEFAULT_VALUES: {
      return_shipment_fee_tax_inclusive: '',
      is_return_shipment_fee_applied: false,
      return_shipment_fee_tax_percent: ''
    },
    FORM_FIELD_NAMES: {
      RETURN_SHIPMENT_FEE: {
        label: 'Return Shipment Fee Tax Incl.',
        name: 'return_shipment_fee_tax_inclusive',
        includeCurrencyInLabel: true,
      },
      RETURN_SHIPMENT_FEE_APPLIED: {
        name: 'is_return_shipment_fee_applied',
        omitRendering: true
      },
      RETURN_SHIPMENT_FEE_TAX_PERCENTAGE: {
        name: 'return_shipment_fee_tax_percent',
        label: 'Tax % in Return Shipment Fee'
      }
    }
  },
})

export const FORM_FIELD_TO_API_MAP = {
  [EDITABLE_RETURN_FEES.RETURN_FEE.FORM_FIELD_NAMES.RETURN_FEE.name]: 'cost',
  [EDITABLE_RETURN_FEES.RETURN_FEE.FORM_FIELD_NAMES.RETURN_FEE_TAX_PERCENTAGE.name]: 'tax_percentage',
  [EDITABLE_RETURN_FEES.RETURN_FEE.FORM_FIELD_NAMES.RETURN_FEE_APPLIED.name]: 'is_applied',
  [EDITABLE_RETURN_FEES.RETURN_SHIPMENT_FEE.FORM_FIELD_NAMES.RETURN_SHIPMENT_FEE.name]: 'cost',
  [EDITABLE_RETURN_FEES.RETURN_SHIPMENT_FEE.FORM_FIELD_NAMES.RETURN_SHIPMENT_FEE_TAX_PERCENTAGE.name]: 'tax_percentage',
  [EDITABLE_RETURN_FEES.RETURN_SHIPMENT_FEE.FORM_FIELD_NAMES.RETURN_SHIPMENT_FEE_APPLIED.name]: 'is_applied'
}

export const BULK_RETURN_ORDER_FIELDS = {
  SELLER: {label: 'Sellers', value:'seller'},
  CONFIRM_SELLER_NAME: {label: 'Confirm Seller Name', value: 'confirmSellerName'},
  ORDER_TYPE: { label: 'Return Order Type', value: 'returnOrderType' }
}