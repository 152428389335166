
export const INPUT_TYPES_MAP = {
  DROPDOWN: 'dropdown',
  INPUT_BOX: 'inputbox',
  API: 'api',
  DATE_PICKER: 'datepicker',
  NOT_APPLICABLE: 'not_applicable'
}

export const AUTOMATION_OPERATIONS = {
  UPDATE_STATUS : 'update_status',
  EDIT: 'edit',
  DELETE: 'delete'
}
