import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import RuleRow from '../components/RuleRow'

/**
   * A React functional component representing the trigger conditions in a rule creation/edit interface.
   *
   * @param {object} props - The component props.
   * @param {object} props.formObject - The form object containing control, errors, watch, and setValue functions.
   * @param {boolean} props.isView - A boolean indicating whether the component is in view mode.
   * @param {array} props.triggerConditions - An array of trigger condition options.
   * @param {object} props.propsMap - An object containing API mappings.
   * @param {function} props.onConditionOrActionChange - A callback function to get value when condition or action change.
   * @return {JSX.Element} The JSX element representing the component.
   */
const Condition = (props) => {
  const { 
    formObject,
    isView,
    triggerConditions,
    propsMap,
    onConditionOrActionChange,
    showHelperText
  } = props

  const { t } = useTranslation()
  const {control, watch} = formObject
  const { append: appendRule, remove: removeRule } = useFieldArray({ 
    control, 
    name: 'triggerConditions', 
    shouldUnregister: true
  })

  const conditionRows = watch('triggerConditions')
  const conditionRowsLength = conditionRows.length 
  const usedTriggerConditionSet = new Set(watch('triggerConditions')?.map((triggerCondition) => triggerCondition.condition?.value))
  const triggerConditionOptions = triggerConditions.filter((condition) => !usedTriggerConditionSet.has(condition.key_name)).map((condition) => {
    return {
      label: condition.display_name,
      value: condition.key_name
    }
  })

  return (
    <div className="p-24px border border-dark-2 bg-light-2 rounded-12px d-flex flex-column gap-24px trigger-conditions-wrapper">
      {showHelperText && <div className="d-flex flex-column gap-12px pb-24px separator">
        <div className="txt-h3-sb text-dark">{t('If This Happens')}</div>
        <div className="txt-body-rg text-dark-5">{t('Choose Trigger')}</div>
      </div>}
      {
        conditionRows.map((field, index) => {
          const isLastRow = (index === conditionRowsLength - 1)
          if (isLastRow && (triggerConditionOptions?.length === 0 || isView)) return null
          return (
            <RuleRow 
              key={field.id}
              formObject={formObject}
              fieldsName='triggerConditions'
              append={appendRule}
              remove={removeRule}
              index={index}
              isLastRow={isLastRow}
              conditions={triggerConditions}
              conditionOptions={triggerConditionOptions}
              conditionLabel={'Select Condition'}
              propsMap={propsMap}
              disabled={isView}
              onConditionOrActionChange={onConditionOrActionChange}
              logicType={t('If')}
            />
          )
        })
      }
    </div>
  )
}

export default Condition