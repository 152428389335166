import { FILE_TYPES, PACKAGE_SKU_TYPE } from '@src/App.constants'
import { ChevronRight, Plus } from 'react-feather'

export const SKUS_TABS = {
  SKU_ALL_TAB: 'All',
  SKU_LIVE_TAB: 'Live',
  SKU_READY_TO_LIST_TAB: 'Ready To List',
  SKU_DRAFT_TAB: 'Draft',
  SKU_UNDER_REVIEW_TAB: 'Under Review',
  SKU_UNSYNC_TAB: 'Unsync',
  SKU_OTHERS_TAB: 'Others',
  SKU_CATEGORY_TREE_TAB: 'Category Tree',
  SKU_ARCHIVE_TAB: 'Archived'
}

export const KITS_TABS = {
  KIT_LIVE_TAB: 'Live',
  KIT_ARCHIVE_TAB: 'Archived'
}

export const SKU_MASTER_ENUMS = {
  ADD_VARIATION_DETAIL: 'AddVariationDetail',
  SEARCH_PRODUCT_ADD_DETAIL: 'SearchProductAddDetail',
  SHOW_UPLOAD_PRODUCT: 'ShowUploadProduct',
  SHOW_CREATE_BUNDLE: 'ShowCreateBundle'
}

export const WEIGHTED_ITEM_BARCODE_CONFIG = {
  ALL_BARCODE_CONFIG: 'AllBarcodeConfig',
  CREATE_BARCODE_CONFIG: 'CreateBarcodeConfig',
  AFFECTED_BARCODE: 'AffectedBarcode',
  EDIT_BARCODE_CONFIG: 'EditBarcodeConfig'
}

export const SKU_DETAILS_TABS = (isProductVisible) => {
  return isProductVisible ? [
    { id: 1, name: 'Individual' },
    { id: 2, name: 'Variants' }
  ] : [{ id: 1, name: 'Individual' }]
}

export const CATALOG_COMPARISON_TABS = {
  NEW_COMPARISON: { id: 1, name: 'New Comparison', title: 'Catalog Comparison' },
  PREVIOUS_COMPARISON: { id: 2, name: 'Previous Comparisons', title: 'Previous Comparison' }
}

export const WEIGHTED_ITEM_BARCODE_CONFIG_CHECK_DIGIT_OPTIONS = [
  { id: 'yes', label: 'Yes' },
  { id: 'no', label: 'No' }
]
export const WEIGHTED_ITEM_BARCODE_CONFIG_PRECISION_OPTIONS = [
  { value: 1, label: '1 Decimal places' },
  { value: 2, label: '2 Decimal places' },
  { value: 3, label: '3 Decimal places' }
]

export const WEIGHTED_VARIATION_OPTIONS = () => {
  return Array.from({ length: 10 }, (_, index) => {
    return { value: index + 1, label: `${index + 1}%` }
  })
}

export const SKU_DOES_NOT_EXIT_FILTER_OPTIONS = (domainName) => {
  return (
    {
      ALL: { id: 1, type: 'All' },
      OMNIFUL_CATALOG: { id: 2, type: `${domainName} Catalog` }
    }
  )
}

export const SKU_ISSUE_TYPES = {
  DUPLICATE_SKU_CODE: { id: 'DUPLICATE_SKU_CODE', label: 'Duplicate SKU Code',  value: 'duplicate_seller_sku_code'},
  SKU_CODE_CHANGED: { id: 'SKU_CODE_CHANGED', label: 'SKU Code Changed', value: 'sku_code_changed'  },
  DUPLICATE_BARCODE: { id: 'DUPLICATE_BARCODE', label: 'Duplicate Barcodes',  value: 'duplicate_barcode' },
  SKU_DOES_NOT_EXIST: { id: 'SKU_DOES_NOT_EXIST', label: 'SKU does not exist', value: 'sku_not_exist' }
}

export const COMPARISON_RESPONSES = {
  COMPARE_CATALOG: { id: 1, response: 'success' },
  RESYNC_COMPARED_CATALOG: { id: 2, response: 'success' }
}

export const CATALOG_FLOATING_YES_AND_NO_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
]


export const CURRENCY = {
  SAR: { value: 'SAR', label: 'SAR' },
  USD: { value: 'USD', label: 'USD'}
}


export const CATALOG_UNIT_OF_MEASUREMENT = {
  EA: { label: 'EA', value: 'ea' },
  KG: { label: 'Kg', value: 'kg' },
  G: { label: 'G', value: 'g' },
  LBS: { label: 'Lbs', value: 'lbs' },
  L: { label: 'L', value: 'l' },
  Ml: { label: 'Ml', value: 'ml' },
  // PACK : {label: 'Pack', value: 'pack'}
}


export const BUNDLE_SKU_FIELDS_NAME = {
  TYPE: 'type',
  SELLER: 'seller',
  STATUS: 'status',
  SKU_CODE: 'seller_sku_code',
  SKU_NAME: 'name',
  DESCRIPTION: 'description',
  MEASUREMENT_UNIT: 'uom',
  status: 'status',
  DISCOUNT_TYPE: 'discount.type',
  DISCOUNT_VALUE: 'discount.value',
  CURRENCY: 'currency',
  BARCODES: 'barcodes',
  SELLING_PRICE_INCLUDES_TAX: 'selling_price_tax_inclusive',
  TAX_PERCENTAGE: 'selling_price_tax_percentage',
  TAX_CATEGORY: 'tax_category',
  LABELS: 'labels'
}

export const ASSEMBLED_KIT_FIELDS_NAME = {
  TYPE: 'type',
  SELLER: 'seller',
  STATUS: 'status',
  SKU_CODE: 'seller_sku_code',
  SKU_NAME: 'name',
  DESCRIPTION: 'description',
  MEASUREMENT_UNIT: 'uom',
  status: 'status',
  DISCOUNT_TYPE: 'discount.type',
  DISCOUNT_VALUE: 'discount.value',
  CURRENCY: 'currency',
  BARCODES: 'barcodes',
  SELLING_PRICE_INCLUDES_TAX: 'selling_price_tax_inclusive',
  TAX_PERCENTAGE: 'selling_price_tax_percentage',
  TAX_CATEGORY: 'tax_category',
  LABELS: 'labels'
}

export const SIMPLE_SKU_FIELDS_NAME = {
  SELLER: 'seller',
  SKU_CODE: 'seller_sku_code',
  SKU_NAME: 'name',
  CATEGORY: 'category',
  TEMPERATURE_CONTROLLED: 'temperature_controlled',
  PERISHABLE: 'perishable',
  BARCODES: 'barcodes',
  RETAIL_PRICE: 'retail_price',
  SELLING_PRICE: 'selling_price',
  SELLING_PRICE_INCLUDES_TAX: 'selling_price_including_tax',
  PRICE_UNIT: 'price_unit',
  TAX_PERCENTAGE: 'tax_percentage',
  DIMENSION_UOM: 'dimension_uom',
  WEIGHT_UOM: 'weight_uom',
  SKU_LENGTH: 'sku_length',
  SKU_WIDTH: 'sku_width',
  SKU_HEIGHT: 'sku_height',
  SKU_WEIGHT: 'sku_weight',
  BRAND: 'brand',
  MANUFACTURER: 'manufacturer',
  COUNTRY_OF_ORIGIN: 'country_of_origin',
  HS_CODE: 'hs_code',
  DESCRIPTION: 'description',
  MEASUREMENT_UNIT: 'uom',
  GTIN: 'gtin',
  IS_GTIN_PICKING_ENABLED: 'is_gtin_picking_enabled',
  PACKAGE_TYPE:'package_type',
  PACKAGE_TYPE_SKU:'package_type_sku',
  PACKAGE_TYPE_SKU_QTY:'package_type_sku_qty',
  LABELS: 'labels',
  TAX_CATEGORY: 'tax_category',
  WHOLESALE_PRICE: 'wholesale_price'
}

export const SIMPLE_SKU_DEFAULT_VALUE = {
  [SIMPLE_SKU_FIELDS_NAME.SELLER]: null,
  [SIMPLE_SKU_FIELDS_NAME.SKU_CODE]: '',
  [SIMPLE_SKU_FIELDS_NAME.SKU_NAME]: '',
  [SIMPLE_SKU_FIELDS_NAME.CATEGORY]: '',
  [SIMPLE_SKU_FIELDS_NAME.TEMPERATURE_CONTROLLED]: null,
  [SIMPLE_SKU_FIELDS_NAME.PERISHABLE]: CATALOG_FLOATING_YES_AND_NO_OPTIONS[1],
  [SIMPLE_SKU_FIELDS_NAME.BARCODES]: '',
  [SIMPLE_SKU_FIELDS_NAME.RETAIL_PRICE]: '',
  [SIMPLE_SKU_FIELDS_NAME.SELLING_PRICE]: '',
  [SIMPLE_SKU_FIELDS_NAME.SELLING_PRICE_INCLUDES_TAX]: CATALOG_FLOATING_YES_AND_NO_OPTIONS[0],
  [SIMPLE_SKU_FIELDS_NAME.PRICE_UNIT]: CURRENCY.SAR,
  [SIMPLE_SKU_FIELDS_NAME.TAX_PERCENTAGE]: '',
  [SIMPLE_SKU_FIELDS_NAME.TAX_CATEGORY]: null,
  [SIMPLE_SKU_FIELDS_NAME.DIMENSION_UOM]: null,
  [SIMPLE_SKU_FIELDS_NAME.WEIGHT_UOM]: CATALOG_UNIT_OF_MEASUREMENT.KG,
  [SIMPLE_SKU_FIELDS_NAME.SKU_LENGTH]: '',
  [SIMPLE_SKU_FIELDS_NAME.SKU_WIDTH]: '',
  [SIMPLE_SKU_FIELDS_NAME.SKU_HEIGHT]: '',
  [SIMPLE_SKU_FIELDS_NAME.SKU_WEIGHT]: '',
  [SIMPLE_SKU_FIELDS_NAME.MANUFACTURER]: '',
  [SIMPLE_SKU_FIELDS_NAME.COUNTRY_OF_ORIGIN]: null,
  [SIMPLE_SKU_FIELDS_NAME.HS_CODE]: '',
  [SIMPLE_SKU_FIELDS_NAME.DESCRIPTION]: '',
  [SIMPLE_SKU_FIELDS_NAME.MEASUREMENT_UNIT]: CATALOG_UNIT_OF_MEASUREMENT.EA,
  [SIMPLE_SKU_FIELDS_NAME.GTIN]: '',
  [SIMPLE_SKU_FIELDS_NAME.IS_GTIN_PICKING_ENABLED]: CATALOG_FLOATING_YES_AND_NO_OPTIONS[1],
  [SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE]:PACKAGE_SKU_TYPE.UNIT,
  [SIMPLE_SKU_FIELDS_NAME.LABELS]: []
}

export const SIMPLE_SKU_FORM_VALIDATION_SCHEMA = {
  [SIMPLE_SKU_FIELDS_NAME.TYPE]: { required: 'This field is required' },
  [SIMPLE_SKU_FIELDS_NAME.SELLER]: { required: 'This field is required' },
  [SIMPLE_SKU_FIELDS_NAME.SKU_NAME]: { required: 'This field is required' },
  [SIMPLE_SKU_FIELDS_NAME.SKU_CODE]: { required: 'This field is required' },
  [SIMPLE_SKU_FIELDS_NAME.RETAIL_PRICE]: { required: 'This field is required' },
  [SIMPLE_SKU_FIELDS_NAME.SELLING_PRICE]: { required: 'This field is required' },
  [SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE]: { required: 'This field is required' },
  [SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE_SKU]: { required: 'This field is required' },
  [SIMPLE_SKU_FIELDS_NAME.PACKAGE_TYPE_SKU_QTY] : { required: 'This field is required' }
}

export const DISCOUNT_OPTIONS = {
  DISCOUNT_PRICE: { label: 'Discount Price', value: 'price' },
  DISCOUNT_PERCENTAGE: { label: 'Discount Percentage (%)', value: 'percentage' }
}

export const SKU_TYPE_OPTIONS = {
  BUNDLE: { label: 'Kit SKU', value: 'bundle' },
  ASSEMBLED: { label: 'Assembled Kit', value: 'assembled' },
  SIMPLE: { label: 'Simple SKU', value: 'simple' }
}

export const BUNDLE_SKU_DEFAULT_VALUE = {
  [BUNDLE_SKU_FIELDS_NAME.TYPE]: SKU_TYPE_OPTIONS.BUNDLE,
  [BUNDLE_SKU_FIELDS_NAME.SELLER]: null,
  [BUNDLE_SKU_FIELDS_NAME.SKU_NAME]: '',
  [BUNDLE_SKU_FIELDS_NAME.status]: true,
  [BUNDLE_SKU_FIELDS_NAME.SKU_CODE]: '',
  [BUNDLE_SKU_FIELDS_NAME.MEASUREMENT_UNIT]: null,
  [BUNDLE_SKU_FIELDS_NAME.DESCRIPTION]: '',
  discount: {
    type: DISCOUNT_OPTIONS.DISCOUNT_PERCENTAGE.value,
    value: 0
  },
  [BUNDLE_SKU_FIELDS_NAME.CURRENCY]: CURRENCY.SAR,
  [BUNDLE_SKU_FIELDS_NAME.BARCODES]: '',
  [BUNDLE_SKU_FIELDS_NAME.SELLING_PRICE_INCLUDES_TAX]: CATALOG_FLOATING_YES_AND_NO_OPTIONS[0],
  [BUNDLE_SKU_FIELDS_NAME.TAX_PERCENTAGE]: '',
  [SIMPLE_SKU_FIELDS_NAME.TAX_CATEGORY]: null,
  [BUNDLE_SKU_FIELDS_NAME.LABELS]: []
}

export const ASSEMBLED_KIT_DEFAULT_VALUE = {
  [ASSEMBLED_KIT_FIELDS_NAME.TYPE]: SKU_TYPE_OPTIONS.BUNDLE,
  [ASSEMBLED_KIT_FIELDS_NAME.SELLER]: null,
  [ASSEMBLED_KIT_FIELDS_NAME.SKU_NAME]: '',
  [ASSEMBLED_KIT_FIELDS_NAME.status]: true,
  [ASSEMBLED_KIT_FIELDS_NAME.SKU_CODE]: '',
  [ASSEMBLED_KIT_FIELDS_NAME.MEASUREMENT_UNIT]: null,
  [ASSEMBLED_KIT_FIELDS_NAME.DESCRIPTION]: '',
  discount: {
    type: DISCOUNT_OPTIONS.DISCOUNT_PERCENTAGE.value,
    value: 0
  },
  [ASSEMBLED_KIT_FIELDS_NAME.CURRENCY]: CURRENCY.SAR,
  [ASSEMBLED_KIT_FIELDS_NAME.BARCODES]: '',
  [ASSEMBLED_KIT_FIELDS_NAME.SELLING_PRICE_INCLUDES_TAX]: CATALOG_FLOATING_YES_AND_NO_OPTIONS[0],
  [ASSEMBLED_KIT_FIELDS_NAME.TAX_PERCENTAGE]: '',
  [SIMPLE_SKU_FIELDS_NAME.TAX_CATEGORY]: null,
  [ASSEMBLED_KIT_FIELDS_NAME.LABELS]: []
}

export const BUNDLE_BULK_SKU_DEFAULT_VALUE = {
  [BUNDLE_SKU_FIELDS_NAME.SELLER]: null
}

export const BUNDLE_SKU_FORM_VALIDATION_SCHEMA = {
  [BUNDLE_SKU_FIELDS_NAME.TYPE]: { required: 'This field is required' },
  [BUNDLE_SKU_FIELDS_NAME.SELLER]: { required: 'This field is required' },
  [BUNDLE_SKU_FIELDS_NAME.SKU_NAME]: { required: 'This field is required' },
  [BUNDLE_SKU_FIELDS_NAME.SKU_CODE]: { required: 'This field is required' },
  [BUNDLE_SKU_FIELDS_NAME.MEASUREMENT_UNIT]: { required: 'This field is required' }
}

export const ASSEMBLED_KIT_FORM_VALIDATION_SCHEMA = {
  [ASSEMBLED_KIT_FIELDS_NAME.TYPE]: { required: 'This field is required' },
  [ASSEMBLED_KIT_FIELDS_NAME.SELLER]: { required: 'This field is required' },
  [ASSEMBLED_KIT_FIELDS_NAME.SKU_NAME]: { required: 'This field is required' },
  [ASSEMBLED_KIT_FIELDS_NAME.SKU_CODE]: { required: 'This field is required' },
  [ASSEMBLED_KIT_FIELDS_NAME.MEASUREMENT_UNIT]: { required: 'This field is required' }
}

export const GET_SIMPLE_SKU_ACTIONS = ({t, toggleSkuBulkModal, toggleSingleSkuCreate,togglArchiveSkuBulkModal}) => ({
  CREATE: {
    id: 1,
    text: t('Create SKUs'),
    icon: Plus,
    onClick: toggleSingleSkuCreate
  },
  BULK_UPLOAD_SKUS: {
    id: 2,
    text: t('Bulk Create SKU'),
    icon: Plus,
    onClick: toggleSkuBulkModal
  },
  BULK_UPLOAD_ARCHIVE: {
    id: 2,
    text: t('Bulk Archive SKU'),
    icon: Plus,
    onClick: togglArchiveSkuBulkModal
  }
  // SINGLE_SKU: {
  //   id: 3,
  //   text: t('Single SKU'),
  //   icon: Plus,
  //   onClick: toggleSingleSkuCreate
  // }

})

export const GET_SALES_CHANNEL_ACTIONS = ({t, exportClickHandler}) => ({
  CREATE: {
    id: 'salesChannelListing',
    text: t('Bulk Actions')
  },
  BULK_UPLOAD_SKUS: {
    id: 'exportBulkSkus',
    text: t('Export SKU\'s'),
    onClick: exportClickHandler
  }
})

export const GET_BUNDLE_ACTIONS = ({t, singleBundleModalToggle}) => ({
  CREATE: {
    id: 1,
    text: t('Create Kits'),
    icon: Plus
  },
  // BULK_UPLOAD_SKUS: {
  //   id: 2,
  //   text: t("Bulk Create Kit"),
  //   icon: Plus,
  //   onClick: bundleBulkModalToggle
  // },
  SINGLE_SKU: {
    id: 3,
    text: t('Single Kit'),
    icon: Plus,
    onClick: singleBundleModalToggle
  }

})

export const GET_ASSEMBLE_ACTIONS = ({t, singleAssembleModalToggle}) => ({
  CREATE: {
    id: 1,
    text: t('Create Assembled Kits'),
    icon: Plus
  },
  // BULK_UPLOAD_SKUS: {
  //   id: 2,
  //   text: t("Bulk Create Assembled Kit"),
  //   icon: Plus,
  //   onClick: assembleBulkModalToggle
  // },
  EXPORT_SKUS: {
    id: 3,
    text: t('Single Assembled Kit'),
    icon: Plus,
    onClick: singleAssembleModalToggle
  }

})

export const BUNDLE = 'bundle'
export const ASSEMBLED = 'assembled'
export const SIMPLE = 'simple'
export const POS = 'pos'
export const ARCHIVESKUS = 'archived'

export const MAX_BUNDLE_IMAGE_CAN_UPLOAD = 40

export const PRICE_TYPES = {
  SELLING_PRICE: 'SELLING_PRICE',
  RETAIL_PRICE: 'RETAIL_PRICE'
}

export const ADDITIONAL_FILTER_DROPDOWN_STYLE_FOR_CATALOG = {
  control: (baseStyles) => ({
    ...baseStyles,
    width: '120px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'start',
    border: 'none',
    boxShadow: 'none'
  })
}

export const PRODUCT_UPLOAD_CONFIG = {
  [SIMPLE]: {
    sheetUrl: 'https://omniful-production-uploads.s3.eu-central-1.amazonaws.com/products/samples/Sku\'s+upload+sample+file.xlsx',
    title: '(Acceptable file formats is .xlsx, .csv. and maximum 5000 rows can be uploaded).',
    fileFormat: { [FILE_TYPES.CSV.type]: FILE_TYPES.CSV.extensions, [FILE_TYPES.XLSX.type]: FILE_TYPES.XLSX.extensions}
  },
  [BUNDLE]: {
    sheetUrl: 'https://omniful-production-uploads.s3.eu-central-1.amazonaws.com/products/samples/bundle.csv',
    title: '(Acceptable file formats is .csv. and maximum 1000 rows can be uploaded).',
    fileFormat: {[FILE_TYPES.CSV.type]: FILE_TYPES.CSV.extensions}
  },
  [ASSEMBLED]: {
    sheetUrl: 'https://omniful-production-uploads.s3.eu-central-1.amazonaws.com/products/samples/assembled.csv',
    title: '(Acceptable file formats is .csv. and maximum 1000 rows can be uploaded).',
    fileFormat: { [FILE_TYPES.CSV.type]: FILE_TYPES.CSV.extensions}
  },
  [POS]: {
    sheetUrl: 'https://omniful-production-uploads.s3.eu-central-1.amazonaws.com/products/samples/sku_sample.xlsx',
    title: '(Acceptable file formats is .csv. and maximum 2000 rows can be uploaded).',
    fileFormat: { [FILE_TYPES.CSV.type]: FILE_TYPES.CSV.extensions}
  },
  [ARCHIVESKUS]:{
    sheetUrl: 'https://omniful-staging-uploads.s3.eu-central-1.amazonaws.com/products/samples/skus/archive/sample_archive_skus.csv',
    title: '(Acceptable file formats is .csv. and maximum 1000 rows can be uploaded).',
    fileFormat: { [FILE_TYPES.CSV.type]: FILE_TYPES.CSV.extensions}
  }
}

export const MAX_SINGLE_SKU_DESC_LENGTH = 400
export const SKU_CARD_TYPE = {
  SIMPLE : 'simple',
  BUNDLE : 'bundle',
  ASSEMBLED : 'assembled'
}

export const KIT = 'Kit'
export const SKU = 'SKU'

export const MAX_SKU_CAN_ADD_IN_BUNDLE_AND_ASSEMBLE = 20

export const ADD = 'add'
export const REMOVE = 'remove'
export const SINGLE_SKU_UPDATE_TYPE = 'full'
export const ARCHIVE = 'archived'

export const SIMPLE_SKU_SEARCH_FIELDS = {
  SKUS: { id: 'query', name: 'SKUs' },
  SKU_CODE: { id: 'seller_sku_code', name: 'SKU Code' },
  SKUS_NAME: { id: 'name', name: 'SKUs Name' },
  SKUS_BARCODE: { id: 'barcode', name: 'SKUs Barcode' },
  GTIN: { id: 'gtin', name: 'GTIN' }
}

export const ARCHIVE_SKU_RESPONSE_TYPES = {
  NO_ERROR: 'NO_ERROR',
  INVENTORY_ERROR: 'INVENTORY_ERROR',
  BUNDLE_AND_ASSEMBLED_ERROR: 'BUNDLE_AND_ASSEMBLED_ERROR',
  BUNDLE_ERROR: 'BUNDLE_ERROR',
  ASSEMBLED_ERROR: 'ASSEMBLED_ERROR',
  ERROR: 'ERROR'
}

export const KITS_SEARCH_FIELDS = {
  KITS:  { id: 'query', name: 'Kits' },
  SKU_CODE: { id: 'seller_sku_code', name: 'SKU Code' },
  KIT_NAME: { id: 'name', name: 'Kits Name' },
  KITS_BARCODES: { id: 'barcode', name: 'Kits Barcode' },
  CHILD_SKU_CODE: { id: 'child_sku_code', name: 'Child SKU Code' }
}

export const ASSEMBLED_KITS_SEARCH_FIELDS = {
  ASSEMBLED_KITS: { id: 'query', name: 'Assembled Kits' },
  SKU_CODE: { id: 'seller_sku_code', name: 'SKU Code' },
  ASSEMBLED_KITS_NAME: { id: 'name', name: 'Assembled Kits Name' },
  ASSEMBLED_KITS_BARCODE: { id: 'barcode', name: 'Assembled Kits Barcode' },
  CHILD_SKU_CODE: { id: 'child_sku_code', name: 'Child SKU Code' }
}

export const WARNING_MODAL_TITLE_AND_CONTENT = {
  ARCHIVE_SKU_CONFIRMATION: {
    TITLE: 'Are you sure you want to archive this SKU?',
    CONTENT: 'Confirming will remove the SKU from the active list. However, you can restore it later from the archived section.'
  },
  ARCHIVE_SKU_HAS_KIT_AND_ASSEMBLED_PARENT: {
    TITLE: ({kit = 1, assembled = 1}) => {
      return `This SKU is part of ${kit} ${kit > 1 ? 'Kits' : 'Kit'} and ${assembled} Assembled ${assembled > 1 ? 'Kits' : 'Kit'}, Cannot be Archived.`
    },
    CONTENT: 'Archiving this SKU is restricted as it is an integral part of the few Kits and Assembled Kit. To proceed, please ensure you remove the SKU from all Kits and associated Kits before attempting this action.'
  },
  ARCHIVE_SKU_HAS_KIT_PARENT: {
    TITLE: ({kit = 1}) => {
      return `You cannot archive this SKU as it is associated with ${kit} ${kit > 1 ? 'Kits' : 'Kit'}.`
    },
    CONTENT: 'Archiving this SKU is restricted as it is an integral part of the Kit. To proceed, please ensure you remove the SKU from all associated Kits before attempting this action.'
  },
  ARCHIVE_SKU_HAS_ASSEMBLED_PARENT: {
    TITLE: ({assembled = 1}) => {
      return `This SKU is part of ${assembled} Assembled ${assembled > 1 ? 'Kits' : 'Kit'}, Cannot be Archived.`
    },
    CONTENT: 'Archiving this SKU is restricted as it is an integral part of the Assembled Kit. To proceed, please ensure you remove the SKU from all associated Kits before attempting this action.'
  },
  ARCHIVE_SKU_IS_PRESENT_IN_INVENTORY: {
    TITLE: (title) => {
      return title
    },
    CONTENT: ({ message = ''}) => {
      return <>
        <span>{message}</span>
      </>
    }
  },
  ARCHIVE_KIT_CONFIRMATION: {
    TITLE: 'Are you sure you want to archive this Kit?',
    CONTENT: 'Confirming will remove the Kit from the active list. However, you can restore it later from the archived section.'
  },
  ARCHIVE_KIT_IS_PRESENT_IN_INVENTORY: {
    TITLE: (title) => {
      return title
    },
    CONTENT: ({ message = ''}) => {
      return <>
        <span>{message}</span>
      </>
    }
  }
}

const CTA_HANDLERS = (KEY, functionHandler, defaultHandler) => {

  switch (KEY) {
  case 'kits':
    return functionHandler
  default:
    return defaultHandler
  }
}

export const HANDLE_ARCHIVE_SKU_MODAL_CASES = ({ data, archiveSkuOperation, cancelArchiveModalHandler, confirmArchiveHandler, redirectToKitsHandler, redirectToAssembledKitsHandler, setArchiveSkuInfo, setIsArchiveFailedWarningModalOpen, t, type, setIsArchiveWarningModalOpen }) => {
  let responseObj = {}
  switch (archiveSkuOperation) {
  case ('NO_ERROR'):
    setIsArchiveWarningModalOpen(true)
    responseObj = {
      title: type === SKU ? WARNING_MODAL_TITLE_AND_CONTENT.ARCHIVE_SKU_CONFIRMATION.TITLE : WARNING_MODAL_TITLE_AND_CONTENT.ARCHIVE_KIT_CONFIRMATION.TITLE,
      content: type === SKU ? WARNING_MODAL_TITLE_AND_CONTENT.ARCHIVE_SKU_CONFIRMATION.CONTENT : WARNING_MODAL_TITLE_AND_CONTENT.ARCHIVE_KIT_CONFIRMATION.CONTENT,
      primaryButtonText: 'Don’t archive',
      secondaryButtonText: 'Yes, archive',
      closeModalHandler: cancelArchiveModalHandler,
      primaryButtonHandler: cancelArchiveModalHandler,
      secondaryButtonHandler: confirmArchiveHandler,
      hasComponentSideContentTranslation: true,
      skuCanArchive: true
    }
    return responseObj
    
  case ('ERROR'):
    setIsArchiveFailedWarningModalOpen(true)
    responseObj = {
      title: type === SKU ?
        WARNING_MODAL_TITLE_AND_CONTENT.ARCHIVE_SKU_IS_PRESENT_IN_INVENTORY.TITLE(data.reason.heading) : WARNING_MODAL_TITLE_AND_CONTENT.ARCHIVE_KIT_IS_PRESENT_IN_INVENTORY.TITLE(data.reason.heading),
      content: type === SKU ? WARNING_MODAL_TITLE_AND_CONTENT.ARCHIVE_SKU_IS_PRESENT_IN_INVENTORY.CONTENT({
        message: data.reason.body, t
      }) : WARNING_MODAL_TITLE_AND_CONTENT.ARCHIVE_KIT_IS_PRESENT_IN_INVENTORY.CONTENT({
        message: data.reason.body, t
      }),
      secondaryButtonText: `${data.reason.cta.primary}`,
      primaryButtonText: `${data.reason.cta.secondary}`,
      closeModalHandler: cancelArchiveModalHandler,
      secondaryButtonHandler: CTA_HANDLERS(data.reason.cta.redirect_on === 'primary' && data.reason.cta.redirect_to, redirectToKitsHandler, cancelArchiveModalHandler),
      primaryButtonHandler: cancelArchiveModalHandler,
      hasComponentSideContentTranslation: false,
      skuCanArchive: false
    }
    setArchiveSkuInfo({})
    return responseObj

  case ('BUNDLE_AND_ASSEMBLED_ERROR'): 
    setIsArchiveFailedWarningModalOpen(true)
    responseObj = {
      title: WARNING_MODAL_TITLE_AND_CONTENT.ARCHIVE_SKU_HAS_KIT_AND_ASSEMBLED_PARENT.TITLE({
        assembled: data.reason.assembled_sku_count,
        kit: data.reason.bundle_sku_count
      }),
      content: WARNING_MODAL_TITLE_AND_CONTENT.ARCHIVE_SKU_HAS_KIT_AND_ASSEMBLED_PARENT.CONTENT,
      primaryButtonText: `See all ${data.reason.bundle_sku_count} Kits having this SKUs`,
      secondaryButtonText: `See all ${data.reason.assembled_sku_count} Assembled Kits having this SKUs`,
      isButtonVerticallyAligned: true,
      icon: <ChevronRight />,
      closeModalHandler: cancelArchiveModalHandler,
      primaryButtonHandler: redirectToKitsHandler,
      secondaryButtonHandler: redirectToAssembledKitsHandler,
      hasComponentSideContentTranslation: true,
      skuCanArchive: false
    }
      
    return responseObj

  case ('BUNDLE_ERROR'):
    setIsArchiveFailedWarningModalOpen(true)
    responseObj = {
      title: WARNING_MODAL_TITLE_AND_CONTENT.ARCHIVE_SKU_HAS_KIT_PARENT.TITLE({
        kit: data.reason.bundle_sku_count
      }),
      content: WARNING_MODAL_TITLE_AND_CONTENT.ARCHIVE_SKU_HAS_KIT_PARENT.CONTENT,
      secondaryButtonText: `See all ${data.reason.bundle_sku_count} Kits having this SKUs`,
      isButtonVerticallyAligned: true,
      icon: <ChevronRight />,
      closeModalHandler: cancelArchiveModalHandler,
      secondaryButtonHandler: redirectToKitsHandler,
      hasComponentSideContentTranslation: true,
      skuCanArchive: false
    }
    return responseObj

  case ('ASSEMBLED_ERROR'):
    setIsArchiveFailedWarningModalOpen(true)
    responseObj = {
      title: WARNING_MODAL_TITLE_AND_CONTENT.ARCHIVE_SKU_HAS_ASSEMBLED_PARENT.TITLE({
        assembled: data.reason.assembled_sku_count
      }),
      content: WARNING_MODAL_TITLE_AND_CONTENT.ARCHIVE_SKU_HAS_ASSEMBLED_PARENT.CONTENT,
      secondaryButtonText: `See all ${data.reason.assembled_sku_count} Assembled Kits having this SKUs`,
      isButtonVerticallyAligned: true,
      icon: <ChevronRight />,
      closeModalHandler: cancelArchiveModalHandler,
      secondaryButtonHandler: redirectToAssembledKitsHandler,
      hasComponentSideContentTranslation: true,
      skuCanArchive: false
    }
    return responseObj

  default:
    return {}
  }
}

export const PRODUCT_CONFIGURATION = {
  WEIGHTED_SKU: {name: 'Weighted SKU', value: 'weighted_sku'}
 
}

export const LABELS_ADDITIONAL_OPTIONS = {
  ADD_LABEL: {label: 'Create', value:'add_label'}
}

export const DOWNLOAD_TYPE = {
  ALL: {type: 'All', value: 'all'}
}