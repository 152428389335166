import SortHeaderColumn from '@src/@core/components/ui/table-header-col'
import { TENANT_TYPE } from '@src/App.constants'
import no_sku_image from '@src/assets/images/omniful/no_sku_image.svg'
import classnames from 'classnames'
import { useState } from 'react'
import { Bookmark, MoreVertical, X } from 'react-feather'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { BULK_SHIP_PATH_NAMES, BULK_SHIP_TABS_DATA } from '../bulk-ship.constants'
import { bulkShipmentStatus } from '../utils'

const ItemDetailsPill = ({ itemDetails, t, handleBundleSkuDetailsSidebar, handleSkuDetailsSidebar }) => {
  const { image, skuName, skuCode, skuQtyPerOrder, totalQty, child_order_items } = itemDetails
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <div className='position-relative'>
      <div className='position-absolute bg-dark-6 text-white top-0 start-100 translate-middle text-nowrap z-1 rounded-10px px-6px txt-asst-rg py-0'>
        {skuQtyPerOrder}
      </div>

      <Dropdown className='position-relative rounded-16px p-0' isOpen={isDropdownOpen} onMouseEnter={() => setIsDropdownOpen(true)} onMouseLeave={() => setIsDropdownOpen(false)} toggle={() => {}}>
        <DropdownToggle className='invisible position-absolute top-20px h-0 w-0 p-0 m-0 d-flex'/>
        <DropdownMenu container={'body'} className='py-0'>
          <div className='p-16px d-flex gap-16px pe-30px'>
            <img
              src={image || no_sku_image}
              height={90}
              width={90}
              className='border rounded-4px p-4px border-dark-2'
              style={{ objectFit: 'contain' }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = no_sku_image
              }}
            />
            <div className='text-primary max-width-300 width-150-per d-flex flex-column gap-4px'>
              <p title={skuName} className='txt-body-md two-line-truncation cursor-pointer m-0'
                onClick={() => {
                  if (child_order_items?.length > 0) {
                    handleBundleSkuDetailsSidebar(itemDetails)
                  } else {
                    handleSkuDetailsSidebar(itemDetails)
                  }
                }}
              >
                {skuName}
              </p>
              <div className='d-flex flex-column gap-8px'>
                <p className='txt-sub-rg text-truncation text-dark-6 m-0'>{skuCode}</p>
                <div className='flex-center-start gap-8px'>
                  <p className='txt-asst-rg text-truncation text-dark-6 m-0'>
                    {t('Qty Per Order:')} {skuQtyPerOrder}
                  </p>

                  <div className='height-2px width-2px rounded-circle bg-dark-6' />

                  <p className='txt-asst-rg text-truncation text-dark-6 m-0'>
                    {t('Total Qty:')} {totalQty}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </DropdownMenu>
        <div className={classnames('position-relative flex-center-start gap-8px bg-light-1 text-primary rounded-34px border border-dark-1 height-28px px-10px')}>
          <img
            src={image || no_sku_image}
            height={20}
            width={20}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = no_sku_image
            }}
          />
          <span className='cursor-pointer max-width-150 text-truncation'>{skuName}</span>
        </div>
      </Dropdown>
    </div>
  )
}

const useClustersColumns = ({ tab, t, openItemsListModal, onNavigateToProcessCluster, saveCluster, removeSavedCluster, handleSkuDetailsSidebar, handleBundleSkuDetailsSidebar, orderTags}) => {
  const tenantType = useSelector((state) => state.auth.userData.tenant.type)
  switch (tab) {
  case BULK_SHIP_TABS_DATA.SUGGESTIONS.id:
    return [
      {
        id: 1,
        name: t('S. No.'),
        key: 'serial_no',
        minWidth: '64px',
        maxWidth: '64px',
        reorder: false,
        cell: ({ serialNumber }) => <div className='w-100 text-center'>{ serialNumber }</div>
      },
      {
        id: 2,
        name: t('Clusters'),
        key: 'clusters',
        minWidth: '120px',
        maxWidth: '140px',
        reorder: true,
        cell: (row) => {
          const ordersLength = +row.number_of_orders || 0
          const text = (() => {
            if (!ordersLength) return `${row.display_number_of_orders} Orders`
            if (ordersLength === 1) return `${row.display_number_of_orders} Order`
            return `${row.display_number_of_orders} Orders`
          })()
          return ordersLength > 0 ? <Link onClick={() => onNavigateToProcessCluster({ ...row, orderTags})} className={classnames('cursor-pointer', { 'text-primary': ordersLength })} to={BULK_SHIP_PATH_NAMES.PROCESS_CLUSTER}>{text}</Link> : 
            <div>{text}</div>
        }
      },
      {
        id: 3,
        name: t('Items Per Order'),
        key: 'items_per_order',
        minWidth: '500px',
        reorder: true,
        cell: (row) => {
          const items = row.order_items?.map((el) => {
            return {
              image: el.sku?.images?.[0]?.url,
              skuName: el.sku?.name,
              skuCode: el.sku?.seller_sku_code,
              skuQtyPerOrder: el.display_quantity,
              totalQty: el.display_total_order_quantity,
              seller_id:row.seller?.id,
              ...el
            }
          })
          return (
            <>
              <div className={classnames('flex-center-start gap-16px')}>
                {items?.slice(0, 2)?.map((item) => (
                  <ItemDetailsPill key={item.id} itemDetails={item} t={t} handleSkuDetailsSidebar={handleSkuDetailsSidebar} handleBundleSkuDetailsSidebar={handleBundleSkuDetailsSidebar} />
                ))}
                {items?.length > 2 ? <span
                  onClick={() => { openItemsListModal({...row, isOpen: true}) }}
                  className='cursor-pointer text-primary border border-primary rounded-34px px-8px py-4px'
                >+ {items.length - 2}</span> : null}
              </div>
            </>
          )
        }
      },
      {
        id: 4,
        name: t('Seller'),
        key: 'seller',
        minWidth: '160px',
        maxWidth: '180px',
        reorder: true,
        omit:tenantType === TENANT_TYPE.SELF_USAGE,
        cell: (row) => {
          const sellerName = row.seller?.name || '-'
          return <span className='max-width-250 text-truncation'>{sellerName}</span>
        }
      },
      {
        id: 5,
        name: (() => <div className='w-100 text-end text-truncation'>{t('Total Qty')}</div>)(),
        key: 'total_qty',
        minWidth: '100px',
        maxWidth: '120px',
        reorder: true,
        cell: (row, idx) => {
          const qty = row.display_total_order_quantity || '-'
          const id = row.id || idx
          return (
            <div key={id} className='w-100'>
              <div className='w-100 text-end text-truncation'>{qty}</div>
            </div>
          )
        }
      },
      {
        id: 6,
        name: t(''),
        key: 'actions',
        width: '70px',
        cell: (row) => {
          const isClusterSaved = row.is_cluster_saved
          return <div className='d-flex justify-content-center cursor-pointer m-auto'>
            <UncontrolledDropdown>
              <DropdownToggle className='pe-1 cursor-pointer more_vert' tag='span'>
                <div className='border p-4px rounded-4px border-dark-1'>
                  <MoreVertical size={16} />
                </div>
              </DropdownToggle>
              <DropdownMenu end container='body' className='p-0 overflow-hidden'>
                <DropdownItem
                  className='w-100 text-dark'
                  key='1'
                  onClick={() => {
                    if (isClusterSaved) {
                      removeSavedCluster(row)
                    } else {
                      saveCluster(row)
                    }
                  }}
                >
                  {isClusterSaved ? <X size={16} /> : <Bookmark size={16} />}
                  <span className='align-middle ms-50 txt-sub-rg'> {t(isClusterSaved ? 'Remove From Saved' : 'Save Cluster')}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        }
      }
    ]
  case BULK_SHIP_TABS_DATA.SAVED_SUGGESTIONS.id:
    return [
      {
        id: 1,
        name: t('S. No.'),
        key: 'serial_no',
        minWidth: '64px',
        maxWidth: '64px',
        reorder: false,
        cell: ({ serialNumber }) => <div className='w-100 text-center'>{ serialNumber }</div>
      },
      {
        id: 2,
        name: t('Clusters'),
        key: 'clusters',
        minWidth: '180px',
        maxWidth: '200px',
        reorder: true,
        cell: (row) => {
          const ordersLength = +row.number_of_orders || 0
          const text = (() => {
            if (!ordersLength) return `${row.display_number_of_orders} Orders`
            if (ordersLength === 1) return `${row.display_number_of_orders} Order`
            return `${row.display_number_of_orders} Orders`
          })()
          return ordersLength > 0 ? <Link onClick={() => onNavigateToProcessCluster(row)} className={classnames('cursor-pointer', { 'text-primary': ordersLength })} to={BULK_SHIP_PATH_NAMES.PROCESS_CLUSTER}>{text}</Link> : 
            <div>{text}</div> 
        }
      },
      {
        id: 3,
        name: t('Items Per Order'),
        key: 'items_per_order',
        minWidth: '500px',
        reorder: true,
        cell: (row) => {
          const items = row.order_items?.map((el) => {
            return {
              image: el.sku?.images?.[0]?.url,
              skuName: el.sku?.name,
              skuCode: el.sku?.seller_sku_code,
              skuQtyPerOrder: el.display_quantity,
              totalQty: el.display_total_order_quantity,
              seller_id:row.seller?.id,
              ...el
            }
          })
          return (
            <>
              <div className={classnames('flex-center-start gap-16px')}>
                {items?.slice(0, 2)?.map((item) => (
                  <ItemDetailsPill key={item.id} itemDetails={item} t={t} handleSkuDetailsSidebar={handleSkuDetailsSidebar} handleBundleSkuDetailsSidebar={handleBundleSkuDetailsSidebar}/>
                ))}
                {items?.length > 2 ? <span
                  onClick={() => { openItemsListModal({...row, isOpen: true}) }}
                  className='cursor-pointer text-primary border border-primary rounded-34px px-8px py-4px'
                >+ {items.length - 2}</span> : null}
              </div>
            </>
          )
        }
      },
      {
        id: 4,
        name: t('Seller'),
        key: 'seller',
        minWidth: '160px',
        maxWidth: '180px',
        reorder: true,
        omit:tenantType === TENANT_TYPE.SELF_USAGE,
        cell: (row) => {
          const sellerName = row.seller?.name || '-'
          return <span className='max-width-250 text-truncation'>{sellerName}</span>
        }
      },
      {
        id: 5,
        name: (() => <div className='w-100 text-end text-truncation'>{t('Total Qty')}</div>)(),
        key: 'total_qty',
        minWidth: '120px',
        maxWidth: '140px',
        reorder: true,
        cell: (row, idx) => {
          const qty = row.display_total_order_quantity || '-'
          const id = row.id || idx
          return (
            <div key={id} className='w-100'>
              <div className='w-100 text-end text-truncation'>{qty}</div>
            </div>
          )
        }
      },
      {
        id: 6,
        name: t(''),
        key: 'actions',
        width: '70px',
        cell: (row) => {
          const isClusterSaved = row.is_cluster_saved
          return <div className='d-flex justify-content-center cursor-pointer m-auto'>
            <UncontrolledDropdown>
              <DropdownToggle className='pe-1 cursor-pointer more_vert' tag='span'>
                <div className='border p-4px rounded-4px border-dark-2'>
                  <MoreVertical size={16} />
                </div>
              </DropdownToggle>
              <DropdownMenu end container='body' className='p-0 overflow-hidden'>
                <DropdownItem
                  className='w-100 text-dark'
                  key='1'
                  onClick={() => {
                    if (isClusterSaved) {
                      removeSavedCluster(row)
                    } else {
                      saveCluster(row)
                    }
                  }}
                >
                  {isClusterSaved ? <X size={16} /> : <Bookmark size={16} />}
                  <span className='align-middle ms-50 txt-sub-rg'> {t(isClusterSaved ? 'Remove From Saved' : 'Save Cluster')}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        }
      }
    ]
  case BULK_SHIP_TABS_DATA.CLUSTERS.id:
    return [
      {
        id: 1,
        name: t('S. No.'),
        key: 'serial_no',
        minWidth: '64px',
        maxWidth: '64px',
        reorder: false,
        cell: (row) => <div className='w-100 text-center'>{row.serialNumber}</div>
      },
      {
        id: 2,
        name: t('Cluster ID'),
        key: 'clusters',
        minWidth: '180px',
        maxWidth: '200px',
        reorder: true,
        cell: (row) => {
          const numberOfOrders = row.number_of_orders
          const text = (() => {
            if (!numberOfOrders) return '0 Orders'
            if (numberOfOrders === 1) return '1 Order'
            if (numberOfOrders > 1) return `${numberOfOrders} Orders`
          })()
          return <div>
            <Link className={classnames('cursor-pointer', { 'text-primary': numberOfOrders })} to={`${BULK_SHIP_PATH_NAMES.PROCESS_CLUSTER}?wave_id=${row.wave.id}`}>{row.wave.name}</Link>
            <br />
            <span>{text}</span>
          </div>
        }
      },
      {
        id: 3,
        name: t('Status'),
        key: 'status',
        minWidth: '160px',
        maxWidth: '180px',
        reorder: true,
        cell: (row) => {
          const status = row.status
          return bulkShipmentStatus(status)

        }
      },
      {
        id: 4,
        name: t('Items Per Order'),
        key: 'items_per_order',
        minWidth: '500px',
        reorder: true,
        cell: (row) => {
          const items = row.cluster_items?.map((el) => {
            return {
              image: el.sku?.images?.[0]?.url,
              skuName: el.sku?.name,
              skuCode: el.sku?.seller_sku_code,
              skuQtyPerOrder: el.display_quantity,
              totalQty: el.display_total_quantity,
              seller_id:el.sku?.seller_id,
              ...el
            }
          })

          return (
            <>
              <div className={classnames('flex-center-start gap-16px')}>
                {items?.slice(0, 2)?.map((item) => (
                  <ItemDetailsPill key={item.id} itemDetails={item} t={t} handleSkuDetailsSidebar={handleSkuDetailsSidebar} handleBundleSkuDetailsSidebar={handleBundleSkuDetailsSidebar}/>
                ))}
                {items?.length > 2 ? <span
                  onClick={() => { openItemsListModal({...row, isOpen: true}) }}
                  className='cursor-pointer text-primary border border-primary rounded-34px px-8px py-4px'
                >+ {items.length - 2}</span> : null}
              </div>
            </>
          )
        }
      },
      {
        id: 5,
        name: <SortHeaderColumn title="Created At"/>,
        key: 'createdAt',
        minWidth: '180px',
        reorder: true,
        sortable:true,
        cell: (row) => {
          const created_at = row.wave.created_at || '-'
          return <div className='w-100 text-truncation'>{created_at}</div>
        }
      }
    ]
  default:
    return []
  }
}

export default useClustersColumns
