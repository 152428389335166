import { useEffect, useMemo, useState } from 'react'
import { AlertCircle, Info, Plus, Trash2 } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Table, UncontrolledTooltip } from 'reactstrap'

import FloatingDropDown from '@src/@core/components/ui/floating-dropdown'
import InputField from '@src/@core/components/ui/input-field'
import NewCheckbox from '@src/@core/components/ui/new-checkbox'
import QuantityButton from '@src/@core/components/ui/quantity-button'
import Select from '@src/@core/components/ui/select'
import SKUDetailsCol from '@src/@core/components/ui/sku-details/index'
import { KEYBOARD_KEYS } from '@src/App.constants'
import { GET_CURRENCIES } from '@src/redux/authentication'
import { debounceAction } from '@src/utility/Utils'
import { SKU_TYPES } from '@src/views/sales/constant/orders.constants'
import { CUSTOM_BATCH_SELECT } from '@src/views/sales/sales.constant'
import { disableEnterKeyEvent, isValidUptoTwoDecimal } from '@src/views/sales/sales.utils'
import { applyExpiryStatus, clearSellerSkusForCreateOrder, getSellerSkusForCreateOrder, removeCreateOrderItemFromIndex, updateCreateOrderItemWiseNoteValue, updateCreateOrderItemWiseWeightValue, updateCreatedOrderTotalWeight, updateQtyOfCreateOrderItemAtIndex, updateTaxPercentOfCreateOrderItem, updateUnitPriceOfCreateOrderItem } from '@src/views/sales/store/store'
import classNames from 'classnames'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BatchSelectWarningModal from './BatchSelectWarningModal'
import './ItemTable.scss'
import SelectBatchSideSheet from './SelectBatchSideSheet'

const expiryStatuses = [
  { value: 'fefo', label: 'Close To Expiry' },
  { value: 'lefo', label: 'Far From Expiry' },
  { value: 'custom', label: 'Custom Batch'}
]

const preventFormSubmitOnEnter = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault()
  }
}

const ItemsTableRow = (props) => {
  const { itemDetails, selectedSeller, selectedSkuBatches, setSelectedSkuBatches, isSelectBatchError, setIsSelectedBatchError, activeTab, hideBatch, hideWeight, uniqueItemId, createOrderItems, expiryStatusesfilterOption } = props
  const [isSelectBatchSideSheetOpen, setIsSelectBatchSideSheetOpen] = useState(false)
  const [isSelectBatchWarningModalOpen, setIsSelectBatchWarningModalOpen] = useState(false)
  const quantity = itemDetails?.quantity
  const unitPrice = +itemDetails?.cutoff_price
  const weight = itemDetails?.skuWeight?.value || 0
  const uom = itemDetails?.skuWeight?.uom || ''
  const taxPercentage = +itemDetails?.tax_percentage || 0
  const taxAmount = itemDetails?.tax_amount ? Number((itemDetails.tax_amount * quantity).toFixed(2)) : 0

  const dispatch = useDispatch()
  const { t } = useTranslation()
  
  const totalSelectedQtyFromBatch = useMemo(() => {
    const selectedBatches = selectedSkuBatches?.[uniqueItemId];
    return selectedBatches 
      ? Object.values(selectedBatches).reduce((acc, { quantity = 0 }) => acc + quantity, 0)
      : 0;
  }, [selectedSkuBatches, uniqueItemId]);

  const checkAndSetBatchError = (value) => {
    if (itemDetails?.config?.is_batching_enable && createOrderItems[uniqueItemId]?.expiry_status === CUSTOM_BATCH_SELECT) {
      if (totalSelectedQtyFromBatch !== value) {
        setIsSelectedBatchError(pre => ({ ...pre, [uniqueItemId]: true }));
      } else {
        setIsSelectedBatchError(pre => ({ ...pre, [uniqueItemId]: false }));
      }
    }
  };
  

  const handlOnChange = (data) => {
    if (data.value === CUSTOM_BATCH_SELECT) {
      setIsSelectBatchSideSheetOpen(true)
      dispatch(applyExpiryStatus({uniqueItemId, value: data.value}))
    } else {
      setIsSelectedBatchError(pre => ({...pre, [uniqueItemId]: false}))
      dispatch(applyExpiryStatus({uniqueItemId, value: data?.value}))
    }
  }
  const handleToggleSelectBatchSideSheet = () => {
    setIsSelectBatchWarningModalOpen(pre => !pre)
  }

  const decreaseQuantity = () => {
    if (quantity > 0) {
      checkAndSetBatchError(quantity - 1);
      dispatch(updateQtyOfCreateOrderItemAtIndex({ uniqueItemId, value: quantity - 1 }))
    }
  }
  const increaseQuantity = () => {
    checkAndSetBatchError(quantity + 1);
    dispatch(updateQtyOfCreateOrderItemAtIndex({ uniqueItemId, value: quantity + 1 }))
  }

  const handleConfirmSelectedSideSheet = () => {
    setIsSelectedBatchError(pre => ({...pre, [uniqueItemId]: false}))
    setIsSelectBatchSideSheetOpen(false)
  }

  // select batch update functions
  const handleIncreaseQtyOfSelectedBatch = (uniqueItemId, batchNumber) => {
    setSelectedSkuBatches(pre => ({...pre, [uniqueItemId]: {...pre[uniqueItemId], [batchNumber] : {batch_number: batchNumber, quantity: pre[uniqueItemId]?.[batchNumber]?.quantity + 1 || 1}}}))
  }
  const handleDecreaseQtyOfSelectedBatch = (uniqueItemId, batchNumber) => {
    if (selectedSkuBatches[uniqueItemId][batchNumber]?.quantity - 1) {
      setSelectedSkuBatches(pre => ({...pre, [uniqueItemId]: {...pre[uniqueItemId], [batchNumber] : {batch_number: batchNumber, quantity: pre[uniqueItemId][batchNumber].quantity - 1}}}))
    } else {
      setSelectedSkuBatches(pre => {
        const removedSelectedBatch = {...pre}
        delete removedSelectedBatch[uniqueItemId][batchNumber]
        return { ...removedSelectedBatch }
      })
    }
  }
  const handleChangeQtyOfSelectedBatch = (uniqueItemId, batchNumber, avlQty, quantity) => {
    const changedQty = quantity !== '' ? (parseInt(quantity.trim()) <= avlQty ? parseInt(quantity.trim()) : avlQty) : 0
    setSelectedSkuBatches(pre => ({...pre, [uniqueItemId]: {...pre[uniqueItemId], [batchNumber] : {batch_number: batchNumber, quantity: changedQty }}}))
  }

  const handleDiscardProcess = () => {
    setSelectedSkuBatches(pre => {
      const removedSelectedBatch = {...pre}
      delete removedSelectedBatch[uniqueItemId]
      return { ...removedSelectedBatch }
    })
    setIsSelectedBatchError(pre => ({...pre, [uniqueItemId]: true}))
    setIsSelectBatchSideSheetOpen(false)
    setIsSelectBatchWarningModalOpen(false)
  }
  //------------------------------------------/
  const onChange = (e) => {
    const number = e.target.value.trim()
    const value = parseInt(number || 0)
    if (/^[1-9]\d*$/.test(value) || e.target.value === '') {
      checkAndSetBatchError(value);
      dispatch(updateQtyOfCreateOrderItemAtIndex({ uniqueItemId, value }))
    }
  }
  const onKeyDown = (e) => {
    if (e.key === KEYBOARD_KEYS.ENTER) {
      e.preventDefault()
    }
  }
  const handleDeleteSku = () => {
    dispatch(removeCreateOrderItemFromIndex(uniqueItemId))
    setSelectedSkuBatches(pre => {
      const removedSelectedBatch = { ...pre }
      delete removedSelectedBatch[uniqueItemId]
      return { ...removedSelectedBatch }
    }
    )
    setIsSelectedBatchError(pre => ({ ...pre, [uniqueItemId]: false }))
  }

  const getDefaultExpiryStatus = () => {
    let defaultExpiryStatus = expiryStatuses.find(status=>status.value===createOrderItems[uniqueItemId]?.expiry_status)
    return defaultExpiryStatus
  }
  
  useEffect(() => {
    if (itemDetails?.config?.is_batching_enable) {
      dispatch(applyExpiryStatus({ uniqueItemId, value: createOrderItems[uniqueItemId]?.expiry_status ? getDefaultExpiryStatus().value : expiryStatuses[0].value }))
    }
  }, [])
  

  return (
    <tr>
      <td>
        <div className="trash-icon cursor-pointer">
          <Trash2
            className='trash-icon-enabled'
            onClick={handleDeleteSku}
          />
        </div>
      </td>
      <td className='col-sku'>
        <div className="d-flex gap-8px w-100 text-dark">
          <SKUDetailsCol
            skuDetailData={{
              isValidSku: true,
              id: itemDetails.id,
              image: {
                src: itemDetails.image,
                alt: itemDetails.name
              },
              details: {
                name: itemDetails.name,
                skuCode: itemDetails.sku_code,
                columnKey: 'items_sku_details'
              },
              customTag: {
                isCustomTagPresent: itemDetails.type === SKU_TYPES.BUNDLE.key,
                className:'text-info bg-info-light',
                title: 'Kit'
              }
            }}
          />
        </div>
      </td>
      <td className='col-qty'>
        <div className='d-flex align-items-center gap-8px w-100'>
          <QuantityButton
            decreaseQuantity={decreaseQuantity}
            increaseQuantity={increaseQuantity}
            quantity={quantity}
            minQuantityEnabled={quantity <= 1}
            maxQuantityEnabled={false}
            onChange={onChange}
            disabled={itemDetails?.isWeighted}
            onKeyDown={onKeyDown}
          />
                
          <div className={classNames({'hide-visibility':!(itemDetails?.config?.is_batching_enable && isSelectBatchError[uniqueItemId])})}>
            <Info id='select-batch-error' size={16} color='var(--bs-danger)'/>
            <UncontrolledTooltip  target='select-batch-error' placement='right' innerClassName='fit' popperClassName='ms-6px text-start text-nowrap'>
              {t('Please specify the batches of updated quantity before creating order')}
            </UncontrolledTooltip>
          </div>
                
        </div>
      </td>

      {activeTab.id !== 2 && !hideBatch && <td className='col-expiry'>
        <div id={`infoText_${uniqueItemId}`} className='w-100'>
          <div className={classNames('batch', {'batch-disabled': !itemDetails?.config?.is_batching_enable})}>
            <FloatingDropDown
              isClearable={false}
              value={itemDetails?.config?.is_batching_enable ? getDefaultExpiryStatus() : null}
              options={expiryStatuses}
              filterOption={expiryStatusesfilterOption}
              onChange={handlOnChange}
              modifiedStyles={{option: (provided, state) => ({
                ...provided,
                cursor: 'pointer',
                fontSize: 12,
                color: state.isSelected ? 'var(--bs-white)' : state.data.value === CUSTOM_BATCH_SELECT ? 'var(--bs-primary)' : 'var(--bs-dark)',
              }),
              menuList: (base) => ({ ...base, zIndex: 9999 })
              }}
              placeholder={itemDetails?.config?.is_batching_enable ? t('Select Duration') : t('Not Applicable')}
              width='160px'
              disabled={!itemDetails?.config?.available_batch_count || itemDetails?.config?.available_batch_count <= 1}
            />
            {itemDetails?.config?.is_batching_enable &&
                         isSelectBatchSideSheetOpen && 
                         <>
                           <SelectBatchSideSheet 
                             isOpen={isSelectBatchSideSheetOpen}
                             toggle={handleToggleSelectBatchSideSheet}
                             itemDetails={itemDetails}
                             uniqueItemId={uniqueItemId}
                             selectedSeller={selectedSeller}
                             selectedSkuBatches={selectedSkuBatches[uniqueItemId]}
                             handleIncreaseQtyOfSelectedBatch={handleIncreaseQtyOfSelectedBatch}
                             handleDecreaseQtyOfSelectedBatch={handleDecreaseQtyOfSelectedBatch}
                             handleChangeQtyOfSelectedBatch={handleChangeQtyOfSelectedBatch}
                             handleConfirmSelectedSideSheet = {handleConfirmSelectedSideSheet}
                           />
                           <BatchSelectWarningModal
                             isOpen={isSelectBatchWarningModalOpen}
                             toggle={() => setIsSelectBatchWarningModalOpen(pre => !pre)}
                             handleDiscardProcess={handleDiscardProcess}
                           />
                         </>
            }
          </div>
          {!itemDetails?.config?.is_batching_enable && (
            <UncontrolledTooltip placement="top" target={`infoText_${uniqueItemId}`}>
              {t('Only the SKUs which are part of a batch have an expiry status.')}
            </UncontrolledTooltip>
          )}
        </div>
      </td>}

      {/* <td>
                <div className="mrp">{`${itemDetails?.skuWeight?.display_weight || '0'}`}</div>
            </td> */}
      {!hideWeight && <td className='col-weight text-dark'>
        {itemDetails?.isWeighted 
                    && <div className="weight-wrapper w-100">
                      <input
                        className="custom-input-wrapper text-end"
                        value={weight}
                        type='number'
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={preventFormSubmitOnEnter}
                        onChange={e => {
                          e.preventDefault()
                          const value = parseFloat(e.target.value) || 0
                          if (isValidUptoTwoDecimal(value)) {
                            dispatch(updateCreateOrderItemWiseWeightValue({ uniqueItemId, value }))
                          }
                        }}
                        onFocus={(e) => e.target.select()}
                      />
                      <span>{uom}</span>
                    </div>
        }
      </td>}

      <td className='col-unit-price text-dark'>
        <input
          className="custom-input-wrapper"
          value={unitPrice}
          type='number'
          onWheel={(e) => e.target.blur()}
          onKeyDown={preventFormSubmitOnEnter}
          onFocus={(e) => e.target.select()}
          onChange={e => {
            e.preventDefault()
            const value = parseFloat(e.target.value) || 0
            if (isValidUptoTwoDecimal(value)) {
              dispatch(updateUnitPriceOfCreateOrderItem({ uniqueItemId, taxPercentage, value }))
            }
          }}
        />
      </td>
      <td className='col-unit-price text-dark'>
        <input
          className="custom-input-wrapper"
          value={taxPercentage}
          type='number'
          onWheel={(e) => e.target.blur()}
          onKeyDown={preventFormSubmitOnEnter}
          onFocus={(e) => e.target.select()}
          onChange={e => {
            e.preventDefault()
            const value = parseFloat(e.target.value) || 0
            if (isValidUptoTwoDecimal(value)) {
              dispatch(updateTaxPercentOfCreateOrderItem({ uniqueItemId, unitPrice, value }))
            }
          }}
        />
      </td>
      <td className='col-sub-total text-dark'>
        <div className="mrp">
          {taxAmount}
        </div>
      </td>
      <td className='col-sub-total text-dark'>
        <div className="mrp">
          {itemDetails?.isWeighted
            ? !isNaN(weight) && !isNaN(unitPrice) && unitPrice > 0 && weight > 0 ? ((weight * unitPrice) + taxAmount).toFixed(2) : 0
            : !isNaN(quantity) && !isNaN(unitPrice) && unitPrice > 0 ? ((quantity * unitPrice) + taxAmount).toFixed(2) : 0
          }
        </div>
      </td>
      <td className='col-customer-note text-dark'>
        <input
          className="custom-input-wrapper"
          value={itemDetails.customer_note || ''}
          type='text'
          onWheel={(e) => e.target.blur()}
          onKeyDown={preventFormSubmitOnEnter}
          onFocus={(e) => e.target.select()}
          onChange={e => {
            e.preventDefault()
            dispatch(updateCreateOrderItemWiseNoteValue({ uniqueItemId, value:e.target.value }))
          }}
        />
      </td>
    </tr>
  )
}

const ItemsTable = ({ register, handleShowAddItemsDropdown, inputRef, handleTotalCost, selectedSeller, hubID, setSearchParams, control, watch, errors, setIsCreateOrderButtonDisabled, selectedSkuBatches, setSelectedSkuBatches, isSelectBatchError, setIsSelectedBatchError, activeTab = 1, showTotal = true, hideBatch = false, hideWeight = false, hideHeading = false, autoFocusOnTable = true, expiryStatusesfilterOption }) => {
  const createOrderItems = useSelector(state => state.sales.createOrderItems)
  const createdOrderTotalWeight = useSelector(state => state.sales.createdOrderTotalWeight)
  const [ItemTableData, setItemTableData] = useState(createOrderItems)
  const [skuColumn, setSkuColumn] = useState(
    [
      {name: 'SKU Name', key: 'name', value: true},
      {name: 'SKU Barcode', key: 'barcodes', value: true},
      {name: 'Seller SKU Code', key: 'seller_sku_code', value: true}
    ]
  )

  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (Object.values(ItemTableData) !== Object.values(createOrderItems)) setItemTableData(createOrderItems)
  }, [createOrderItems, inputRef.current])

  const orderTotal = Object.values(ItemTableData).reduce((acc, item) => {
    const { cutoff_price = 0, tax_amount = 0, isWeighted, skuWeight = {}, quantity = 0 } = item
    const price = cutoff_price + tax_amount
    const qty = isWeighted ? (skuWeight.value || 0) : quantity
    return acc + (price * qty)
  }, 0)

  const paramsArray = skuColumn.filter(val => val.value).map(item => item.key)
  const params = { search_columns: paramsArray }
    
  const debouncedDispatch = debounceAction(payload => dispatch(getSellerSkusForCreateOrder({ hubID, sku_name: payload, seller_id: selectedSeller?.value, ...params })), 500)
    
  const createOrderButtonState = useMemo(() => {
    return (Object.values(ItemTableData).some(
      (item) => (item.isWeighted && (!item.skuWeight?.value || !createdOrderTotalWeight))
    ))
  }, [ItemTableData, createdOrderTotalWeight])

  useEffect(() => {
    setIsCreateOrderButtonDisabled(createOrderButtonState)
  }, [createOrderButtonState])

  const customStyles = {
    control: provided => ({
      ...provided,
      borderColor: '#d8d6de',
      height: '32px',
      boxShadow: 'none',
      fontSize: '12px',
      '&:hover': {
        borderColor: '#5468FA',
        cursor: 'pointer'
      }
    }),
    valueContainer: provided => ({
      ...provided,
      overflow: 'visible',
      width: '100%'
    }),
    option: provided => ({
      ...provided,
      cursor: 'pointer',
      fontSize: '12px'
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: 'absolute',
      top: state.hasValue || state.selectProps.inputValue ? '-50%' : '15%',
      transition: 'top 0.1s, font-size 0.1s',
      fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
      backgroundColor: state.isDisabled ? 'transparent' : 'white'
    }),
    clearIndicator: provided => ({
      ...provided,
      paddingRight: 0
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: 'lightgray',
      paddingLeft: 0,
      width: '20px',
      '&:hover': {
        color: 'gray'
      }
    }),
    indicatorContainer: provided => ({
      ...provided,
      padding: '2px',
      border: '1px solid red !important',
      backgroundColor: 'red'
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none'
    })
  }

  const handleSkuFilter = (e) => {
    inputRef.current.value = ''
    inputRef.current.focus()
    dispatch(clearSellerSkusForCreateOrder())
    const updatedData = skuColumn.map(val => (val.key === e.target.name ? { ...val, value: !val.value } : val)
    )
    setSkuColumn(updatedData)
  }
  const skuSearchCount = skuColumn.filter((item) => item.value).length
  return (
    <div className="items-table-create-manual-order">
      <div className={classNames('mb-16px', {'ms-12px':!hideHeading})}>
        {!hideHeading && <p className="txt-h3-md text-dark mb-16px">{t('Add Order Items')}</p>}
        <div className="flex-center-start">
          <p className="m-0 me-12px txt-body-rg text-dark-5">{t('Search results shown by')}: </p>
          <div className="d-flex">
            {skuColumn.map((item) => (
              <NewCheckbox
                id={item.key}
                key={item.key}
                label={item.name}
                name={item.key}
                onChange={handleSkuFilter}
                register={register}
                defaultChecked={item.value}
                disabled={skuSearchCount === 1 && item.value}
                isRequired={false}
              />
            ))}
          </div>
        </div>
      </div>
      <Table borderless responsive>
        <thead>
          <tr>
            <th className="table-header trash-icon-column"></th>
            <th className='table-header col-sku txt-sub-md text-dark'>{t('SKU Details')}</th>
            <th className='table-header col-qty txt-sub-md text-dark'>
              <div className='d-flex align-items-center gap-8px'>
                <div className='w-100'>{t('Ordered Qty')}</div>
                <span className='hide-visibility'><AlertCircle size={16} color='var(--bs-danger)' /></span>
              </div>
            </th>
            {activeTab.id !== 2 && !hideBatch && <th className='table-header col-expiry txt-sub-md text-dark'>{t('Expiry Status')}</th>}
            {!hideWeight && <th className='table-header col-weight txt-sub-md text-dark'>
              <div className='d-flex align-items-center gap-8px'>
                <div className='w-100'>{t('Total Weight')}</div>
                <span className='hide-visibility'>kg</span>
              </div>
            </th>}
            <th className='table-header col-unit-price txt-sub-md text-dark'>{t('Unit Price (Tax excl.)')}</th>
            <th className='table-header col-unit-price txt-sub-md text-dark'>{t('Tax Percentage')}</th>
            <th className='table-header col-sub-total txt-sub-md text-dark'>{t('Tax Amount')}</th>
            <th className='table-header col-sub-total txt-sub-md text-dark'>{t('Item Total')}</th>
            <th className='table-header col-customer-note txt-sub-md text-dark'>{t('Customer Note')}</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(ItemTableData).map((item) => (
            <ItemsTableRow
              // seller_sku_id={item.seller_sku_id}
              key={item.uniqueItemId}
              itemDetails={item}
              handleTotalCost={handleTotalCost}
              selectedSeller={selectedSeller}
              orderTotal={orderTotal}
              customStyles={customStyles}
              selectedSkuBatches={selectedSkuBatches}
              setSelectedSkuBatches={setSelectedSkuBatches}
              isSelectBatchError = {isSelectBatchError}
              setIsSelectedBatchError={setIsSelectedBatchError}
              activeTab={activeTab}
              hideBatch={hideBatch}
              hideWeight={hideWeight}
              uniqueItemId={item.uniqueItemId}
              createOrderItems={createOrderItems}
              expiryStatusesfilterOption={expiryStatusesfilterOption}
            />
          ))}

          <tr>
            <td>
              <div className="trash-icon d-none"></div>
            </td>
            <td className='col-sku'>
              <div className="d-flex w-100">
                {/* <img className="" src={''} alt="" height="48px" width="48px" /> */}
                <div
                  style={{ height: '48px', width: '48px', border: '2px dotted var(--bs-primary)', borderRadius: '4px' }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    className="h-100 w-100 d-flex align-items-center justify-content-center"
                    onClick={() => {
                      inputRef.current.focus()
                    }}
                  >
                    <Plus color= 'var(--bs-primary)' />
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center input-container">
                  <input
                    ref={inputRef}
                    autoFocus={autoFocusOnTable ? activeTab.id !== 2 : autoFocusOnTable}
                    placeholder={t('Search for SKU')}
                    className="borderless-input" 
                    style={{border: '0px'}}
                    onKeyUp={e => {
                      const value = e.target.value.trim()
                      if (value) {
                        dispatch(clearSellerSkusForCreateOrder())
                        debouncedDispatch(value)
                        setSearchParams({sku_name : value})
                      }
                    }}
                    onChange={e => {
                      if (e.target.value) {
                        handleShowAddItemsDropdown(true)
                      } else {
                        dispatch(clearSellerSkusForCreateOrder())
                        handleShowAddItemsDropdown(false)
                      }
                    }}
                    onKeyDown={disableEnterKeyEvent}
                  />
                </div>
              </div>
            </td>
            <td className='col-qty'>
              <div className='d-flex align-items-center gap-8px w-100'>
                <QuantityButton quantity={' '} />
                <div className="hide-visibility">
                  <AlertCircle size={16} color='var(--bs-danger)' />
                </div>
              </div>
            </td>
            {activeTab.id !== 2 && !hideBatch && <td className='col-expiry'>
              <div className="w-100"></div>
            </td>}
            {!hideWeight && <td className='col-weight'>
              <div className="w-100"></div>
            </td>}
            <td className='col-unit-price'>
              <div className="w-100"></div>
            </td>
            <td className='col-unit-price'>
              <div className="w-100"></div>
            </td>
            <td className='col-sub-total'>
              <div className="w-100"></div>
            </td>
            <td className='col-sub-total'>
              <div className="w-100"></div>
            </td>
            <td className='col-sub-total'>
              <div className="w-100"></div>
            </td>
          </tr>
        </tbody>
      </Table>
      {!hideHeading && <hr/>}
      {showTotal && <><div className='order-total mt-1'>
        <div className='row-wrapper'>
          <div className='first-column'>
            <h3 className='m-0 txt-h3-md text-dark-6'>{t('Total Weight')}</h3>
          </div>
          <div className='second-column flex-stretch-center'>
            <InputField
              className="text-end fw-bold"
              value={createdOrderTotalWeight}
              type='number'
              onWheel={(e) => e.target.blur()}
              onFocus={(e) => e.target.select()}
              onChange={e => {
                e.preventDefault()
                const value = parseFloat(e.target.value) || 0
                if (isValidUptoTwoDecimal(value)) {
                  dispatch(updateCreatedOrderTotalWeight(value))
                }
              }}
              disabled={Object.values(createOrderItems).length === 0}
            />
            <div className='weight-input-wrapper'>
              <InputField
                width="100%"
                value="Kg"
                disabled
              />
            </div>
          </div>
        </div>
        <div className='row-wrapper'>
          <div className='first-column'>
            <h3 className='m-0 txt-h3-md text-dark-6'>{t('Order Total')}</h3>
          </div>
          <div className='second-column'>
            <div className='txt-h2-md text-dark'>{orderTotal > 0 ? orderTotal.toFixed(2) : 0}</div>
            <div className='currency-input-wrapper'>
              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isAsync
                    errors={errors}
                    loadOptions={GET_CURRENCIES}
                    value={field.value}
                    defaultValue={field.value}
                    isLoading={false}
                    additional={{
                      page: 1
                    }}
                    styles={{
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: '180px',
                        overflowY: 'auto'
                      })
                    }}
                  />
                )}
              />
            </div>
                            
          </div>
        </div>
      </div>
      <hr className='my-24px'/>
      </>}
    </div>
  )
}

export default ItemsTable
