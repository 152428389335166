import Button from '@src/@core/components/ui/button'
import { PrintIcon } from '@src/assets/images/icons/printIcon'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PrintInvoice from './print-invoice'
import PrintAwbConfirmationPopup from './PrintAwbConfirmationPopup'

const ShipmentPrintBtns = ({ selectedOrders, pickingWave, isAtleastOneShipmentCreated }) => {
  const { t } = useTranslation()
  const printInvoiceRef = useRef(null)
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)

  return (
    <>
      <div className='flex-center-center gap-8px'>
        <PrintInvoice printInvoiceRef={printInvoiceRef} selectedOrders={selectedOrders} pickingWave={pickingWave} />
        {isAtleastOneShipmentCreated && <>
          <div style={{ width: '1px' }} className='h-75 bg-dark-2' />
          <Button
            ofStyle="noBackground"
            to="/"
            className="txt-body-md py-4px px-8px"
            onClick={(e) => {
              e.stopPropagation()
              setIsPopUpOpen(true)
            }}
          >
            <PrintIcon color='var(--bs-primary)' size={20} />
            {t('Print AWB')}
          </Button>
        </>}
      </div>
      <PrintAwbConfirmationPopup
        isPopUpOpen={isPopUpOpen}
        setIsPopUpOpen={setIsPopUpOpen}
        selectedOrders={selectedOrders}
        pickingWave={pickingWave}
      />
    </>
  )
}

export default ShipmentPrintBtns