import CustomToast from '@src/@core/components/ui/custom-toast/CustomToast'
import axios from 'axios'
export const axiosInstance = axios.create({
  withCredentials: true
})

// Add a request interceptor
axiosInstance.interceptors.request.use((request) => {
  const authDataStringified = localStorage.getItem('auth')
  const userInfoStringified = localStorage.getItem('userInfo')
  
  const authData = JSON.parse(authDataStringified)
  const userInfo = JSON.parse(userInfoStringified)

  const access_token = authData?.access_token
  const username = userInfo?.username
  
  // Do something before request is sent
  request.headers = {
    'x-omniful-platform': 'web',
    'X-UserName': username
    // 'x-omniful-version': '1.0',
  }
  if (access_token) {
    request.headers.Authorization = `Bearer ${access_token}`
  }

  return request
}, (error) => {
  // Do something with request error
  return Promise.reject(error)
})


// Add a response interceptor
axiosInstance.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, async (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger

  // if 401, then hit refresh_token
  // SUCCESS: hit original request
  // FAIL: clear local & session storage, redirect to login page
  const originalRequest = error.config
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true
    const authData = localStorage.getItem('auth')
    const userInfoStringified = localStorage.getItem('userInfo')

    const userInfo = JSON.parse(userInfoStringified)

    const username = userInfo?.username

    const refresh_token = JSON.parse(authData)?.refresh_token
    if (!refresh_token) {
      // If there is no refresh token, redirect to login page
      localStorage.removeItem('auth')
      window.location.replace('/login')
      return Promise.reject(error)
    }
    try {
      const response = await axios.post('/api/v1/users/oauth/token', {
        oauth_refresh_token: refresh_token
      }, {
        headers: {
          'x-omniful-platform': 'web',
          'X-UserName': username
        }
      })
      const accessToken = response?.data?.data?.access_token
      localStorage.setItem('auth', JSON.stringify(response?.data?.data))
      axiosInstance.defaults.headers['Authorization'] = `Bearer ${accessToken}`
      return axiosInstance(originalRequest)
    } catch (error) {
      // If refresh token request fails, clear storage & redirect to login page
      const user_printer_preference =  localStorage.getItem('user_printer_preference')
      localStorage.clear()
      localStorage.setItem('user_printer_preference', user_printer_preference)
      sessionStorage.clear()
      window.location.replace('/login')
      return Promise.reject(error)
    }
  } else if (error.response.status === 401 && originalRequest._retry) {
    const user_printer_preference =  localStorage.getItem('user_printer_preference')
    localStorage.clear()
    localStorage.setItem('user_printer_preference', user_printer_preference)
    sessionStorage.clear()
    window.location.replace('/login')
    return Promise.reject(error)
  }
  if (error.response.status === 400 || error.response.status === 403 || error.response.status === 429) {
    if (!error.config.showCustomMessage && error.response.data.error.message) {
      CustomToast(error.response.data.error.message, {my_type: 'error', audioRequired: error.config._audioRequired})
    } else if (error.config.showCustomMessage && error.config.customError?.message) {
      CustomToast(error.config.customError.message, {my_type: 'error', audioRequired: error.config._audioRequired})
    }
  }
  return Promise.reject(error)
})
    