import { ExpandableCustomTable } from '@src/@core/components/ui/globalTable/ExpandableCustomTable'
import SideSheet from '@src/@core/components/ui/sideSheet'
import { useTranslation } from 'react-i18next'
import { ModalBody } from 'reactstrap'
// import './reasonsModal.scss'

const ReturnReasonsPopup = ({ returnReasonsPopUp, handleReturnReasonsPopUp }) => {
  const { t } = useTranslation()

  const { isOpen, reasonsList } = returnReasonsPopUp

  const tableColumns = [
    {
      id: 1,
      name: <div className="w-100 text-end text-truncate" title={t('Return Qty')}>{t('Return Qty')}</div>,
      width: '100px',
      cell: row => <div className="w-100 text-end">{row.quantity || '-'}</div>
    },
    {
      id: 2,
      name: t('Reason'),
      minWidth: '200px',
      cell: row => row.reason || '-'
    },
  ]

  return (
    <>
      <SideSheet
        isOpen={isOpen}
        toggle={handleReturnReasonsPopUp}
        title='Return Reasons'
        modalClassName="modal-slide-in"
        size="sm"
      >
        <ModalBody className='mt-1 p-24px'>
          <ExpandableCustomTable
            loading={false}
            error={false}
            success={true}
            columns={tableColumns}
            data={reasonsList || []}
            showColumnsTableHeader={false}
            showPagination={false}
          />
        </ModalBody>
      </SideSheet>
    </>
  )
}

export default ReturnReasonsPopup