import Button from '@src/@core/components/ui/button'
import Select from '@src/@core/components/ui/select'
import { WarningLogo } from '@src/assets/images/omniful/warning'
import { OMS_REASON_CONFIG } from '@src/views/settings/settings.constants'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Form, Modal, ModalBody } from 'reactstrap'
import StripForReasonNotAvailable from '../components/reasonsConfigrationRequired/StripForReasonNotAvailable'
import { useFetchReasons } from '../hooks/useFetchReasons'
import { cancelShipment } from '../store/store'

const CancelShipmentPopUp = (prop) => {
  const { shipmentOrderID, deleteModalOpen, setDeleteModalOpen, shipping_partner_tag, awb_number } = prop
  const { reasons } = useFetchReasons({
    isModalOpen: deleteModalOpen,
    entity_type: OMS_REASON_CONFIG.cancel_shipment.key,
  })

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { control, formState: { errors }, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      shipping_cancel_reasons: null
    },
    mode: 'onChange'
  })

  const onSubmit = () => {
    dispatch(cancelShipment({ reason: watch('shipping_cancel_reasons').value, shipping_partner_tag, awb_number }))
    setDeleteModalOpen(false)
  }

  const handleToggle = () => {
    setDeleteModalOpen(prev => !prev)
    reset()
  }

  return (
    <Modal isOpen={deleteModalOpen} className='custom-cancel-order-modal rounded-5' size='lg' centered toggle={handleToggle}>
      <ModalBody className='m-0 p-24px'>
        <div className='content-body'>
          <div className='d-flex justify-content-center content-logo'>
            <WarningLogo size='120' />
          </div>
          <div className='d-flex flex-column align-items-start gap-12px mb-24px'>
            <div className='txt-h1-sb text-dark'>
              <div>{t('Are you sure you want to cancel this shipment for')}</div>
              <div>
                {shipmentOrderID && (
                  <span>
                    {t('Order ID')}: {shipmentOrderID}?
                  </span>
                )}
              </div>
            </div>
            <div className='txt-body-rg text-dark'>{t('This action cannot be reversed')}.</div>
          </div>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='shipping_cancel_reasons'
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <Select
                {...field}
                errors={errors}
                options={reasons}
                isAsync={false}
                isRequired
                label={t('Reason for Cancellation')}
                isDisabled={reasons?.length === 0}
              />
            )}
          />
          {reasons?.length === 0 && <StripForReasonNotAvailable entity_type={OMS_REASON_CONFIG.cancel_shipment.key}/>}
          <div className='flex-center-end gap-1 mt-32px'>
            <Button className='flex-center-center' onClick={handleToggle} ofStyle='noBackground' type='button'>
              {t('Take Me Back')}
            </Button>
            <Button className='flex-center-center' disabled={!watch('shipping_cancel_reasons')}>
              <span>{t('Yes, Cancel')}</span>
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default CancelShipmentPopUp
