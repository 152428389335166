import { API_ENDPOINTS } from './api.urls'

export const sampleFetchApi = {
  apiKey: ['sample-fetch-api'],
  url: API_ENDPOINTS.BILLINGS.CREATE_AD_HOC_ACTIVITY
}

export const getOmsReasonsListApi = {
  apiKey: ['get-oms-reasons-list'],
  url: API_ENDPOINTS.COMMON.GET_OMS_REASONS
}
